import { TextField, Stack, Box, IconButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState, useEffect } from 'react';
import UndoIcon from '@mui/icons-material/Undo';

import { getVisualImage, getFriendlyVisualTypeName } from '../../utils/visual';

function VisualDetailsModal(props) {
  const { modalOpen, cancel, confirm, title, canUndo, undoTitle, visualGuid, visualName, customName, visualType } =
    props;
  const [newName, setNewName] = useState('');

  useEffect(() => {
    if (customName) {
      setNewName(customName);
    } else if (visualName) {
      setNewName(visualName);
    }
    // eslint-disable-next-line
  }, [customName, visualName]);

  const submitForm = () => {
    confirm(newName);
    setNewName('');
  };

  const cancelForm = () => {
    setNewName('');
    cancel();
  };

  const onUndo = () => {
    setNewName(visualName);
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={cancelForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent
        sx={{ width: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '5px' }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{
            width: '100px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ height: '60px', width: '60px' }}>{getVisualImage(visualType)}</Box>
          <Box>{getFriendlyVisualTypeName(visualType)}</Box>
        </Stack>

        <Stack direction="column" spacing={2} flexGrow={1}>
          <TextField
            className="text-field"
            disabled={true}
            label="Visual Id"
            variant="filled"
            fullWidth
            id="newName"
            name="newName"
            value={visualGuid}
          />
          <Box className="form-field-container" display="flex" flexDirection="row" justifyContent={'space-between'}>
            <Box flexGrow={1}>
              <TextField
                className="text-field"
                label="Visual Name"
                variant="filled"
                fullWidth
                id="newName"
                name="newName"
                value={newName}
                onChange={(event) => {
                  setNewName(event.target.value);
                }}
              />
            </Box>
            <Tooltip title={undoTitle}>
              <IconButton disabled={!canUndo} onClick={onUndo}>
                <UndoIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelForm} color="primary">
          Cancel
        </Button>
        <Button onClick={submitForm} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VisualDetailsModal;

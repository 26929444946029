import React from 'react';
import { Box, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import Info from '../../../components/Info';

export default function ConnectionViewProperties(props) {
  const { connectionSummary, isLoading } = props;

  const getLoadingSkeleton = () => {
    return (
      <Box className="info-container" py={2} px={4} mb={4}>
        <Stack direction="row" spacing={4}>
          <Skeleton height="200px" width="80px" />
          <Skeleton height="200px" width="80px" />
          <Skeleton height="200px" width="80px" />
          <Skeleton height="200px" width="80px" />
        </Stack>
      </Box>
    );
  };

  const getSummaryInfo = () => [
    { label: '# of Test Cases', value: connectionSummary?.testCaseTotal },
    { label: '# of Test Plans', value: connectionSummary?.testPlanTotal },
    { label: '# of Test Runs', value: connectionSummary?.testRunTotal },
    { label: '# of Test Case Executions', value: connectionSummary?.testCaseExecutionTotal },
  ];

  return <React.Fragment>{isLoading ? getLoadingSkeleton() : <Info data={getSummaryInfo()} />}</React.Fragment>;
}

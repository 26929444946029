import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: false, // No more ripple, on the whole application 💣!
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1280,
      lg: 1366,
      xl: 1920,
    },
  },

  palette: {
    mode: 'light',
    primary: {
      main: '#FF7227',
      contrastText: '#000',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#bba98e',
    },
    white: {
      default: '#fff',
    },
    black: {
      default: '#000',
    },
    grey: {
      main: '#BFBCBC',
    },
    warning: { main: '#ff9100', contrastText: '#fff' },
    inProgress: { main: '#2d9cdb', contrastText: '#fff' },
    info: { main: '#0288D1', contrastText: '#fff' },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    h1: {
      fontSize: '32px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
    },

    h5: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h6: {
      fontSize: '14px',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 400,
    },
    subtitle3: {
      fontSize: '12px',
      fontWeight: 500,
      fontStyle: 'italic',
    },

    body1: {
      fontSize: '13px',
      fontWeight: 500,
    },
    body1Expired: {
      fontSize: '13px',
      fontWeight: 500,
      color: '#D2042D',
    },
    bodyBold: {
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export default theme;

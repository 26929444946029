import React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import TestPlanSummaryInfo from './Info';
import Graph from './Graph';

export default function Summary(props) {
  const { graphData, summary, age, handleDateChange, isLoading } = props;

  const getSkeleton = () => {
    return (
      <React.Fragment>
        <Box className="info-container" py={2} px={4} mb={4}>
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Skeleton variant="text" height="30px" width="100px" />
            <Skeleton variant="text" height="50px" width="100px" />
          </Box>
          <Skeleton height="300px" />
        </Box>
        <Box
          className="info-container"
          py={2}
          px={4}
          mb={4}
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
        >
          <Skeleton height="200px" width="100px" />
          <Skeleton height="200px" width="100px" />
          <Skeleton height="200px" width="100px" />
        </Box>
      </React.Fragment>
    );
  };

  const getEmptyGraph = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" py={2} px={4} mb={4} height="200px">
        <h3>No data to display</h3>
      </Box>
    );
  };

  return isLoading ? (
    getSkeleton()
  ) : (
    <React.Fragment>
      <Box className="info-container" py={2} px={4} mb={4}>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <h3>Previous Runs</h3>
          <Select
            className="select-dropdown"
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={age}
            onChange={handleDateChange}
            variant="outlined"
          >
            <MenuItem value={7}>7 Days</MenuItem>
            <MenuItem value={14}>14 Days</MenuItem>
            <MenuItem value={30}>30 Days</MenuItem>
          </Select>
        </Box>
        {summary?.durationInMs > 0 ? <Graph graphData={graphData} /> : getEmptyGraph()}
      </Box>
      <TestPlanSummaryInfo summary={summary} />
    </React.Fragment>
  );
}

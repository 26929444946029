import { Box, MenuItem, Select } from '@mui/material';

import React from 'react';
import Graph from './Graph';

export default function History(props) {
  const { graphData, age, handleDateChange } = props;
  return (
    <React.Fragment>
      <Box className="info-container" py={2} px={4} mb={4}>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <h3>Previous Runs</h3>

          <Select
            className="select-dropdown"
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={age}
            onChange={handleDateChange}
          >
            <MenuItem value={7}>7 Days</MenuItem>
            <MenuItem value={14}>14 Days</MenuItem>
            <MenuItem value={30}>30 Days</MenuItem>
          </Select>
        </Box>
        <Graph graphData={graphData} />
      </Box>
    </React.Fragment>
  );
}

import React from 'react';

export const overview_steps = [
  {
    content: (
      <div>
        <h2>Welcome to PowerTester!</h2> <br />
        PowerTester enables you to validate data in a Power BI Report against a source database query <br />
      </div>
    ),

    placement: 'center',
    target: 'body',
  },
  {
    target: '.nav_connections',
    content: (
      <div>
        Getting started is easy. First create a connection to Power BI and your source. <br />
        <br />
        A connection contains the information needed for PowerTester to connect to a source or Power BI to retrieve the
        expected and actual results in a test case.
        <br />
        <br />
        <a
          href="https://wild-data-solutions.outseta.com/support/kb/categories/Kj9b4nmn/connections"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          View knowledge base article
        </a>
      </div>
    ),
  },
  {
    target: '.nav_reports',
    content: 'Then tell PowerTester which reports are in scope for testing.',
  },
  {
    target: '.nav_test_cases',
    content: (
      <div>
        Next create a test case. <br />
        <br />
        Test cases execute a query against a source connection and compare it with Power BI. If the two match, the test
        passes.
        <br />
        <br />
        <a
          href="https://wild-data-solutions.outseta.com/support/kb/categories/O79OPGWE/test-cases"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          View knowledge base article
        </a>
      </div>
    ),
  },
  {
    target: '.nav_test_plans',
    content: (
      <div>
        Lastly create a test plan to hold your test cases. <br />
        <br />
        Test Plans are collections of tests that can then be executed either ad-hoc or on a schedule.
        <br />
        <br />
        <a
          href="https://wild-data-solutions.outseta.com/support/kb/categories/2amRBxmJ/test-plans"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          View knowledge base article
        </a>
      </div>
    ),
  },
  {
    content: (
      <div>
        <h2>Thats it!</h2> <br />
        Easy as 1,2,3...4 <br /> <br />
        Contact
        <a href="mailto:support@powertester.app" style={{ textDecoration: 'underline' }}>
          support@powertester.app
        </a>
        if you have any questions or would like a detailed walkthrough
      </div>
    ),

    placement: 'center',
    target: 'body',
  },
];

import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';
import { InteractionType } from '@azure/msal-browser';

import ErrorPage from '../../containers/global/Error';
import NavigationDrawer from '../../components/NavigationDrawer';
import NotificationPanel from '../../components/NotificationPanel';
import TopBanner from '../../components/TopBanner';
import { loginRequest } from '../../configs/authConfig';
import PageLoadingBox from '../PageLoadingBox';
import navigation from '../../constants/navigation';
import { SnackbarProvider } from 'notistack';
import Collapseable from '../Snackbars/Collapseable';
import { useGetAnnouncements } from '../../api/queries/announcement';
import { useGetNotifications } from '../../api/queries/notifications';
import appConfig from '../../configs/appConfig';

export const ProtectedRoute = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [appIsLoading, setAppIsLoading] = useState(true);

  let location = useLocation();

  const { data: notifications } = useGetNotifications(true);
  const { data: announcements } = useGetAnnouncements(true);

  useEffect(() => {
    if (announcements && announcements.length > 0) {
      setShowBanner(true);
    }

    // eslint-disable-next-line
  }, [announcements]);

  React.useEffect(() => {
    const path = location.pathname;

    // Split the subPath by '/' and get the first segment
    const firstSegment = path.split('/')[1];

    // Find the object in the navigation array where route matches lastPathPart
    const route = navigation.find((item) => item.route === '/' + firstSegment);

    const title = route ? route.name : '';

    if (title !== '') {
      document.title = 'PowerTester | ' + title;
    } else {
      document.title = 'PowerTester | Portal';
    }

    if (appConfig.PT_ENV !== 'production') document.title += ' | ' + appConfig.PT_ENV;
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const authRequest = {
    ...loginRequest,
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, errorInfo) => {
        // log the error
        console.error('ErrorBoundary caught!');
        console.error(error);
        console.error(errorInfo);

        // record the error in an APM tool...
      }}
    >
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
        <SnackbarProvider
          maxSnack={4}
          autoHideDuration={3000}
          Components={{
            collapseable: Collapseable,
          }}
        >
          <TopBanner showBanner={showBanner} closeBanner={() => setShowBanner(false)} />
          <Box className={`pt-web-auth-container ${announcements && showBanner ? 'with-banner' : ''}`} display="flex">
            {appIsLoading ? (
              <PageLoadingBox setAppIsLoading={setAppIsLoading} />
            ) : (
              <React.Fragment>
                <NavigationDrawer notificationCount={notifications?.length ?? 0} openNotification={handleClick} />
                <NotificationPanel notifications={notifications} anchorEl={anchorEl} onClose={handleClose} />
                <Box flex="auto">
                  <Outlet />
                </Box>
              </React.Fragment>
            )}
          </Box>
        </SnackbarProvider>
      </MsalAuthenticationTemplate>
    </ErrorBoundary>
  );
};

import appConfig from './appConfig';
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

export const msalConfig = {
  auth: {
    clientId: appConfig.MS_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
  },
};

export const powerBiOauthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${appConfig.MS_CLIENT_ID}&response_type=code&redirect_uri=${appConfig.MS_REDIRECT_URL}&response_mode=query&scope=https%3A%2F%2Fanalysis.windows.net%2Fpowerbi%2Fapi/Report.Read.All%20offline_access%20openid%20user.read&state=12345&prompt=select_account`;

// Add here the scopes that you would like the user to consent during sign-in
export const loginRequest = {
  scopes: ['user.read'],
  prompt: 'select_account',
};

// Add here the scopes to request when obtaining an access token for MS Graph API
export const tokenRequest = {
  scopes: ['user.read'],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchFilter from '../../../components/SearchFilter';
import UserList from './UserList';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { useGetUserInvites, useGetUsers, useUpdateUser } from '../../../api/queries/setting';
import { SNACKBAR_ERROR } from '../../../constants/snackbar';
import { findObjectByProperty } from '../../../utils/array';
import Header from '../../../components/Header';
import withFormInteraction from '../../../hocs/withFormInteraction';
import InviteNewUser from './InviteUserForm';
import SideDrawer from '../../../components/SideDrawer';

function ManageUsers(props) {
  const { formOpen, toggleForm, openSnackbar } = props;
  const [userFilter, setUserFilter] = useState('');
  const [modalProps, setModalProps] = useState({ open: false, title: '', user: {} });
  const [isReloading, setIsReloading] = useState(false);
  const [dotInidcator, setDotInidcator] = useState('');

  const navigate = useNavigate();

  // Get data
  const { data: users, isLoading: usersIsLoading, refetch: refetchUsers } = useGetUsers(true, userFilter);
  const { data: userInvites } = useGetUserInvites(true);

  // Updates
  const { mutate: updateUserHook } = useUpdateUser();

  useEffect(() => {
    if (userInvites && userInvites.length > 0) {
      const currentUTC = new Date().toISOString();

      const hasFutureItem = userInvites.some((item) => item.expiresAtUTC > currentUTC);

      if (hasFutureItem) {
        // Call your setDotIndicator function here
        setDotInidcator('Invites');
      }
    }
  }, [userInvites]);

  const reload = (showProgress) => {
    if (showProgress) setIsReloading(true);

    const promises = [refetchUsers()];

    Promise.all(promises)
      .then(() => {
        if (showProgress) setIsReloading(false);
      })
      .catch((error) => {
        // Handle any errors that might occur during the fetch
        if (showProgress) setIsReloading(false);
        console.error('Error fetching data:', error);
      });
  };

  const setFilterUsers = (data) => {
    setUserFilter(data);
  };

  const searchFilterProps = {
    searchProps: {
      placeholder: 'Search users',
      list: users,
      searchKey: 'userPrincipal',
      setFilterListHook: setFilterUsers,
      returnValue: true,
    },
  };

  const toggleModal = (userPrincipal) => {
    if (userPrincipal) {
      const userToUpdate = findObjectByProperty(users, 'userPrincipal', userPrincipal);

      setModalProps({
        open: true,
        title: `Are you sure you want to ${userToUpdate.status === 'Active' ? 'deactivate' : 'activate'}?`,
        user: {
          userPrincipal: userToUpdate.userPrincipal,
          role: userToUpdate.role,
          isActive: userToUpdate.status === 'Active' ? false : true,
        },
      });
    } else {
      setModalProps({ open: false, title: '', user: {} });
    }
  };

  const confirmDeactivate = () => {
    if (!modalProps?.user?.userPrincipal) {
      openSnackbar('Unknown Error deleting user', SNACKBAR_ERROR);
      return;
    }
    updateUserHook({
      userPrincipal: modalProps?.user.userPrincipal,
      role: modalProps?.user.role,
      isActive: modalProps?.user.isActive,
    });
    toggleModal();
  };

  const togglePage = (page) => {
    if (page !== '/settings/users') navigate(page);
  };

  const switchOptions = {
    list: [
      { label: 'Users', action: () => togglePage('/settings/users') },
      { label: 'Invites', action: () => togglePage('/settings/invites') },
    ],
    activeSwitch: 'Users',
  };

  return (
    <Box className="page-details" sx={{ flexGrow: 1 }}>
      <Header
        title="Settings | Manage Users"
        buttonText="Invite new user"
        toggleForm={() => {
          toggleForm();
        }}
        switchOptions={switchOptions}
        buttonDisabled={formOpen}
        refreshAction={() => reload(true)}
        dotIndicatorOption={dotInidcator}
        headerVariantOverride={'h3'}
      />
      <ConfirmationModal
        title="Update user"
        modalOpen={modalProps.open}
        confirm={confirmDeactivate}
        cancel={() => toggleModal()}
        msg={modalProps.title}
      />
      <Box px={4} className="page-details__list-container" display="flex" pb={4} flexDirection="row">
        <Box className="page-details-container" pr={3} flex={1}>
          <SearchFilter {...searchFilterProps} />
          <UserList
            users={users}
            loading={isReloading || usersIsLoading}
            toggleModal={toggleModal}
            formOpen={formOpen}
          />
        </Box>
        <SideDrawer
          toggleForm={() => {
            toggleForm();
          }}
          formOpen={formOpen}
          title={'Invite new user'}
        >
          <InviteNewUser toggleForm={toggleForm} formOpen={formOpen} />
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withFormInteraction(ManageUsers);

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import TablePaginationActions from '../../../../components/TablePaginationActions';
import { VIEW_DETAILS } from '../../../../constants/sidedrawer';
import { formatDate, formatMillisecondsToHourlyString } from '../../../../utils/date';
import { getRowIndex } from '../../../../utils/TablePagination';
import TableSkeleton from '../../../../components/TableSkeleton';
import StatusBadge from '../../../../components/StatusBadge';

export default function QuickRunList(props) {
  const { quickRuns, selectQuickRun, smallScreen, isLoading } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [quickRuns]);

  return (
    <TableContainer>
      <Table className="list-table fixed" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" className="column">
              Test Case Name
            </TableCell>
            <TableCell component="th" className="column">
              Run time
            </TableCell>
            <TableCell component="th" className="column">
              Status
            </TableCell>
            {!smallScreen && (
              <>
                <TableCell component="th" className="column">
                  Duration
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            (quickRuns && quickRuns?.length > 0
              ? rowsPerPage > 0
                ? quickRuns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : quickRuns
              : []
            ).map((row, index) => (
              <TableRow
                className="pointer"
                onClick={() => selectQuickRun(getRowIndex(index, page, rowsPerPage), VIEW_DETAILS)}
                key={row.testRunGuid}
              >
                <TableCell className="column">{row.testCaseName}</TableCell>
                <TableCell className="column">{formatDate(row.runTime)}</TableCell>
                <TableCell className="column">
                  <StatusBadge status={row.testRunResult} showText={!smallScreen} />
                </TableCell>
                {!smallScreen && (
                  <>
                    <TableCell className="column">{formatMillisecondsToHourlyString(row.durationInMs) || ''}</TableCell>
                  </>
                )}
              </TableRow>
            ))
          ) : (
            <TableSkeleton columns={4} rows={10} hasMenu />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={quickRuns?.length ?? 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

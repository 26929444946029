import * as Yup from 'yup';
import errors from '../../constants/errors';

const embeddedSQLAuthentication = () => {
  return Yup.object().shape({
    serverName: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.SERVER_NAME.REQUIRED).max(800, errors.SERVER_NAME.MAX_LENGTH(800)),
    }),
    database: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.DATABASE.REQUIRED).max(800, errors.DATABASE.MAX_LENGTH(800)),
    }),
    username: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.USERNAME.REQUIRED).max(800, errors.USERNAME.MAX_LENGTH(800)),
    }),
    password: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.PASSWORD.REQUIRED).max(800, errors.PASSWORD.MAX_LENGTH(800)),
    }),
    port: Yup.number(errors.PORT.TYPE).integer(errors.PORT.TYPE).typeError(errors.PORT.TYPE).nullable(),
  });
};

const embeddedSPAuthentication = () => {
  return Yup.object().shape({
    serverName: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.SERVER_NAME.REQUIRED).max(800, errors.SERVER_NAME.MAX_LENGTH(800)),
    }),
    database: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.DATABASE.REQUIRED).max(800, errors.DATABASE.MAX_LENGTH(800)),
    }),
    servicePrincipalAppId: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.SP_APP_ID.REQUIRED).max(800, errors.USERNAME.MAX_LENGTH(800)),
    }),
    servicePrincipalSecret: Yup.string().when('connectionString', {
      is: (val) => !val,
      then: Yup.string().required(errors.SP_APP_SECRET.REQUIRED).max(800, errors.USERNAME.MAX_LENGTH(800)),
    }),
    port: Yup.number(errors.PORT.TYPE).integer(errors.PORT.TYPE).typeError(errors.PORT.TYPE).nullable(),
  });
};

const dbKeyVaultAuthentication = () => {
  return Yup.object()
    .nullable()
    .shape({
      connectionStringKeyVault: Yup.string().nullable().required(errors.CONNECTION_STRING_KV.REQUIRED),
    });
};

export const databaseSchema = () => {
  return Yup.object().when('authenticationType.authenticationType', {
    is: 'SQL Authentication',
    then: Yup.object().when('credentialMethod', {
      is: 'Embedded',
      then: embeddedSQLAuthentication(),
      otherwise: dbKeyVaultAuthentication(),
    }),
    otherwise: Yup.object().when('authenticationType.authenticationType', {
      is: 'Service principal',
      then: Yup.object().when('credentialMethod', {
        is: 'Embedded',
        then: embeddedSPAuthentication(),
        otherwise: dbKeyVaultAuthentication(),
      }),
    }),
  });
};

export const exportToFile = (type, data, filename) => {
  if (type === 'csv') {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    downloadFile(url, filename);
  } else if (type === 'json') {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    downloadFile(url, filename);
  }
};

const downloadFile = (url, filename) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertToCSV = (data) => {
  const header = Object.keys(data[0]).join(',') + '\n';
  const rows = data.map((obj) => Object.values(obj).join(',')).join('\n');
  return header + rows;
};

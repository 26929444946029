import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';
import { nullIfEmpty } from '../../utils/string';
import { Box } from '@mui/material';

const TimezoneSelector = (props) => {
  const { name, onValueChange, initialTimezone, errors, touched } = props;
  const [timezoneList, setTimeZoneList] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [searchText, setSearchText] = useState('');

  const handleTimezoneChange = (event, newValue) => {
    setSelectedTimezone(newValue);
    onValueChange(name, newValue);
  };

  const handleAutocompleteInputChange = (event, newInputValue) => {
    setSearchText(newInputValue);
  };

  useEffect(() => {
    if (!timezoneList || timezoneList?.length === 0) {
      setTimeZoneList(moment.tz.names());
    }
    // Set initial value to props default, moments guess (local computer Tz) OR UTC
    const defaultTz = nullIfEmpty(initialTimezone) ?? moment.tz.guess() ?? 'UTC';

    handleTimezoneChange(null, defaultTz);
  }, []);

  const getTimezoneLabel = (tz) => {
    const offset = moment.tz(tz).format('Z');
    return `(${offset}) ${tz}`;
  };

  return (
    <Box>
      <Autocomplete
        id="timezone-select"
        options={timezoneList}
        inputValue={searchText}
        value={selectedTimezone}
        onChange={handleTimezoneChange}
        onInputChange={handleAutocompleteInputChange}
        renderInput={(params) => (
          <TextField {...params} label="Select Timezone" variant="outlined" error={errors && touched} />
        )}
        getOptionLabel={(tz) => getTimezoneLabel(tz)}
      />
      {errors && touched && (
        <Box color="error.main" className="form-field-error-text dynamic">
          {errors}
        </Box>
      )}
    </Box>
  );
};

export default TimezoneSelector;

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const validateToken = (token) => {
  if (!token) return false;

  var parsedToken = parseJwt(token);

  if (!parsedToken) return false;

  // If the token has expired ie the expiry date is in the past
  if (parsedToken.exp < Date.now() / 1000) {
    console.error('Token expired');
    console.error(parsedToken.exp);

    return false;
  }

  return true;
};

function parseJwt(token) {
  try {
    if (!token) return null;
    var base64Url = token.split('.')[1];
    if (!base64Url) return null;
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch {
    console.error('Token validation failed');
    return null;
  }
}

export const invalidatePowerBIBearerTokenStore = (connectionGuid) => {
  cookies.remove(`powerBIToken-${connectionGuid.toLowerCase()}`);
};

import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index';
import { useSnackbar } from 'notistack';

import * as testPlan from '../endpoints/testPlan';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { mapSummaryData } from '../mappings/testPlan';

const allTestPlansKey = ['testPlan', 'all'];

// Queries
export const useGetTestPlan = (enabled, queryKey, nameFilter) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testPlan', queryKey],
    queryFn: () => {
      if (queryKey !== 'all') {
        return testPlan.get(queryKey);
      } else {
        return testPlan.get();
      }
    },
    enabled: enabled,
    select: (testPlans) => {
      if (!nameFilter) return testPlans;
      return testPlans.filter((p) => p.testPlanName?.toLowerCase().includes(nameFilter.toLowerCase()));
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test plan data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestPlanSummary = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testPlanSummary', queryKey.testPlanGuid],
    queryFn: () => {
      const { testPlanGuid, ...params } = queryKey;
      return testPlan.summary(params, testPlanGuid);
    },
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test plan data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
    select: (data) => {
      return mapSummaryData(data);
    },
  });
};

export const useGetTestCaseForTestPlan = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testPlan', 'testcase', queryKey.testPlanGuid],
    queryFn: () => {
      const { testPlanGuid, ...params } = queryKey;
      return testPlan.getTestCases(params, testPlanGuid);
    },
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test plan data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
    select: (data) => {
      return mapSummaryData(data);
    },
  });
};

// Mutations
export const useCreateTestPlan = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => testPlan.create({ ...rawData }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: allTestPlansKey });

      // Show success snackbar
      enqueueSnackbar('Test plan created successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the test plan');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useUpdateTestPlan = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { testPlanGuid, ...body } = rawData;
      return testPlan.update(body, testPlanGuid);
    },

    onSuccess: (data, updatedTestPlan) => {
      // Invalidate queries
      queryClient.invalidateQueries({
        queryKey: [
          'testCase',
          {
            testPlanGuid: updatedTestPlan?.testPlanGuid,
          },
        ],
      }); // invalidate the test case list
      queryClient.invalidateQueries({ queryKey: ['testCase', updatedTestPlan?.testPlanGuid] }); // invalidate the test case list
      queryClient.invalidateQueries({ queryKey: ['testPlanSummary', updatedTestPlan?.testPlanGuid] }); // invalidate the summary
      queryClient.invalidateQueries({ queryKey: allTestPlansKey }); // invalidate the test plan list

      // Show success snackbar
      enqueueSnackbar('Test plan updated successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the test plan');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDeleteTestPlan = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (testPlanGuid) => testPlan.remove(testPlanGuid),

    onSuccess: (data, testPlanGuid) => {
      // Remove the tc from the reports cache (faster than invalidating)
      queryClient.setQueryData(allTestPlansKey, (old) => old.filter((t) => t.testPlanGuid !== testPlanGuid));

      // Show success snackbar
      enqueueSnackbar('Test plan deleted successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to delete the test plan');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useExecuteTestPlan = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (rawData) => {
      const { testPlanGuid, ...body } = rawData;
      return testPlan.execute(body, testPlanGuid);
    },

    onSuccess: (data) => {
      if (!data?.data) {
        enqueueSnackbar('Test exeution status unknown.', { variant: SNACKBAR_WARNING });
        return;
      }

      if (data.data.Message === 'Nothing to execute') {
        enqueueSnackbar('Nothing to execute', { variant: SNACKBAR_WARNING });
        return;
      }

      const msg = data.data.Message ?? 'Test plan running';
      const guid = data.data.TestRunGuid;
      const type = data.data.Type ?? 'Test Run';
      let linkTo = type === 'Test Case' ? '/testcases/runs' : '/testruns';

      if (guid) linkTo = linkTo + `/${guid}`;

      enqueueSnackbar(msg, {
        subtitle: 'Request submitted succesfully',
        variant: 'collapseable',
        linkText: `Browse ${type} for progress information`,
        linkTo: linkTo,
        isExtenalLink: false,
      });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Cannot execute test plan');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

// Prefetch
export const prefetchTestPlanList = () => {
  return queryClient.prefetchQuery(allTestPlansKey, () => testPlan.get());
};
export const prefetchTestPlanSummary = (params) => {
  return queryClient.prefetchQuery(['testPlanSummary', params.testPlanGuid], () => testPlan.summary(params));
};

import _ from 'lodash';
import * as moment from 'moment';

export const mapSummaryData = (data) => {
  if (!data) return;
  return _.map(data, (item) => ({
    ...item,
    startDateTime: moment.utc(item.startDateTime).format('YYYY-MM-DD'),
  }));
};

export const mapFormattedSummaryData = (data, fromDate) => {
  if (!data) return;

  // Filter based on fromDate
  const filteredData = data.filter((item) => new Date(item.startDateTime) >= new Date(fromDate));

  const summary = _.reduce(filteredData, (acc, curr) => ({
    durationInMs: acc.durationInMs + curr.durationInMs,
    failedTest: acc.failedTest + curr.failedTest,
    TotalPass: acc.passedTest + curr.passedTest,
    passedTest: acc.passedTest + curr.passedTest,
    inconclusiveTest: acc.inconclusiveTest + curr.inconclusiveTest,
    totalDuration: acc.durationInMs + curr.durationInMs,
  }));

  if (!summary) return;

  let totalTests = summary.failedTest + summary.passedTest + summary.inconclusiveTest;
  summary.avgDuration = summary.totalDuration / filteredData.length;
  summary.avgPass = summary.passedTest / totalTests;
  summary.totalNumberOfTests =
    filteredData[filteredData.length - 1].passedTest +
    filteredData[filteredData.length - 1].failedTest +
    filteredData[filteredData.length - 1].inconclusiveTest;
  _.omit(summary, ['failedTest', 'passedTest', 'totalDuration', 'inconclusiveTest']);

  return summary;
};

export const mapFormatedSummaryGraphData = (data, fromDate) => {
  if (!data) return;
  // Filter based on fromDate
  const filteredData = data.filter((item) => new Date(item.startDateTime) >= new Date(fromDate));

  const newData = _.chain(filteredData)
    .groupBy('startDateTime')
    .map((value, key) => ({
      StartDateTime: key,
      filteredData: _.reduce(
        value.map((a) => ({ P: a.passedTest, F: a.failedTest, I: a.inconclusiveTest })),
        (acc, curr) => ({ P: acc.P + curr.P, F: acc.F + curr.F, I: acc.I + curr.I }),
      ),
    }))
    .value();
  const sortedData = _.orderBy(
    newData,
    (o) => {
      if (o.StartDateTime) {
        return moment(o.StartDateTime).format('YYYYMMDD');
      }
    },
    ['asc'],
  );
  const passData = _.map(sortedData, (cb) => cb.filteredData.P);
  const failData = _.map(sortedData, (cb) => cb.filteredData.F);
  const inconclusiveData = _.map(sortedData, (cb) => cb.filteredData.I);
  const labels = _.map(sortedData, (cb) => moment.utc(cb.StartDateTime).format('DD MMM'));
  return { passData, failData, inconclusiveData, labels };
};

import http from '../../services/http';
import { TEST_CASES } from '../../constants/api';

export const get = (params) => http.get(TEST_CASES, params, { redirect: true }).then((res) => res.data);

export const getQuickRuns = (params) =>
  http.get(TEST_CASES + '/quickruns', params, { redirect: true }).then((res) => res.data);

export const history = (params, testCaseGuid) =>
  http.get(`${TEST_CASES}/${testCaseGuid}/history`, params, { redirect: true }).then((res) => res.data);

export const summary = (params, testCaseGuid) =>
  http.get(`${TEST_CASES}/${testCaseGuid}/summary`, params, { redirect: true }).then((res) => res.data);

export const getTestPlans = (testCaseGuid) =>
  http.get(`${TEST_CASES}/${testCaseGuid}/testplans`, null, { redirect: true }).then((res) => res.data);

// mutation
export const create = (body) => http.post(TEST_CASES, body);
export const execute = (testCaseGuid) => http.post(`${TEST_CASES}/${testCaseGuid}/execute`);
export const update = (body, testCaseGuid) => http.put(`${TEST_CASES}/${testCaseGuid}`, body);
export const rename = (body, testCaseGuid) => http.put(`${TEST_CASES}/${testCaseGuid}/rename`, body);
export const remove = (testCaseGuid) => http.delete(`${TEST_CASES}/${testCaseGuid}`);

import moment from 'moment';

export const formatDate = (date, nullValue = '-') => {
  if (!date) {
    return nullValue;
  }
  return moment(date).format('YYYY/MM/DD HH:mm:ss');
};

export const formatMilliseconds = (ms) => {
  if (!ms) {
    return '';
  }
  return moment.utc(ms).format('H:mm:ss');
};

export const formatSmallMilliseconds = (ms) => {
  if (!ms) {
    return '';
  }
  return moment.utc(ms).format('mm:ss.SS');
};

export const formatSecondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (s === 0 && m === 0 ? 'h' : 'h ') : '';
  var mDisplay = m > 0 ? m + (s === 0 ? 'm' : 'm ') : '';
  var sDisplay = s > 0 ? s + 's' : '';
  return hDisplay + mDisplay + sDisplay;
};

export const formatMillisecondsToHourlyString = (ms) => {
  if (!ms) return '';
  let res = '';
  let time = formatMilliseconds(ms)
    .split(':')
    .map((t) => parseInt(t));

  if (time[0]) res += time[0] + 'hr ';
  if (time[1]) res += time[1] + 'm ';
  if (time[2]) res += time[2] + 's ';

  if (!res) return ms + 'ms';

  return res;
};

export const subtractDate = (number, subtractType, includeToday, date) => {
  if (!includeToday) {
    number--;
  }

  let m = moment();

  if (date) {
    m = moment(date);
  }

  return m.subtract(number, subtractType).format('YYYY-MM-DD');
};

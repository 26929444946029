import http from '../../services/http';
import { SETTINGS, USERS } from '../../constants/api';
import { validateResponse } from '../../utils/response';

export const getNotifications = async () => {
  try {
    const response = await http.get(SETTINGS + '/notifications');
    if (validateResponse(response)) {
      return response.data;
    }
  } catch (e) {
    return [];
  }
};

export const updateNotifications = async (body) => {
  const response = await http.put(SETTINGS + '/notifications', body);
  if (validateResponse(response)) {
    return response.data;
  } else {
    throw new Error('Cannot update notification');
  }
};

// TODO MOVE THESE TO USER FILE
export const getUsers = () => http.get(USERS).then((res) => res.data);
export const getUserInvites = () => http.get(USERS + '/invites').then((res) => res.data);
export const updateUser = (body) => http.put(USERS, body).then((res) => res.data);
export const createInvite = (body) => http.post(USERS + '/invite', body).then((res) => res.data);
export const deactivateInvite = (body) => http.delete(USERS + '/invite', body).then((res) => res.data);

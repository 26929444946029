import React, { useRef, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const OverflowTooltip = ({ text, maxWidth, style, variant }) => {
  const textRef = useRef(null);
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTextTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  const mergedStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: maxWidth || '100%',
    ...style, // Merge with the provided style prop
  };

  return (
    <Tooltip title={isTextTruncated ? text : ''}>
      <Typography ref={textRef} sx={mergedStyles} variant={variant ?? 'body1'}>
        {text}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTooltip;

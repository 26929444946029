import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { mapReportData } from '../../../api/mappings/report';
import { useCreateReport, useGetReportVisuals, useUpdateReport } from '../../../api/queries/report';
import { GENERIC_ERRORS } from '../../../constants/errors';
import CreateReportSchema from '../../../schema/createReportSchema';
import { getResponseErrorMsg } from '../../../utils/response';
import { getSideDrawerSubHeading } from '../../../utils/sidedrawer';
import CreateReportForm from './Form';

function CreateReport(props) {
  const LAST_FORM_NUMBER = 3;
  const { reportGuid } = props.reportData;
  const { resetReportData, toggleForm, actionType, reportData, connections, connectionsIsLoading, reportDataLoading } =
    props;
  const initValues = {
    reportGuid: '',
    connection: null,
    visuals: [],
    powerBIReport: null,
  };

  const [formNumber, setFormNumber] = useState(1);
  const [values, setValues] = useState(initValues);
  const [isDetailsSubmitted, setDetailsSubmitted] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseError, setResponseError] = useState('');
  const [isDetailsSubmitting, setDetailsSubmitting] = useState(false);

  const {
    data: reportVisuals,
    isLoading: reportVisualsIsLoading,
    isError: reportVisualsError,
  } = useGetReportVisuals(!!reportData?.reportGuid, reportData?.reportGuid);

  const updateMutation = useUpdateReport();
  const createMutation = useCreateReport();

  useEffect(() => {
    if (reportData?.reportGuid && !reportVisualsIsLoading && !reportVisualsError) {
      // map values here
      setValues(mapReportData(reportData, reportVisuals, connections));
    } else {
      setValues(initValues);
    }
    // eslint-disable-next-line
  }, [reportData, reportVisuals]);

  const submitForm = async (values) => {
    try {
      setDetailsSubmitting(true);
      const data = {
        powerBIReportGuid: values.powerBIReport.reportGuid,
        reportName: values.reportName || values.powerBIReport.reportName,
        connectionGuid: values.connection.connectionGuid,
        visuals: values.visuals.filter((obj) => obj.objType === 'visual'),
      };

      if (reportGuid) {
        // Update report
        await updateMutation.mutateAsync({ ...data, reportGuid });
      } else {
        // New report
        await createMutation.mutateAsync({ ...data });
      }
      resetReportData();
      toggleForm();
      setResponseStatus('success');
    } catch (error) {
      setResponseStatus('error');
      let fallbackErr;
      if (reportGuid) {
        fallbackErr = GENERIC_ERRORS.REPORT.CREATE;
      } else {
        fallbackErr = GENERIC_ERRORS.REPORT.UPDATE;
      }
      setResponseError(getResponseErrorMsg(error.response, fallbackErr));
    } finally {
      setDetailsSubmitted(true);
      setDetailsSubmitting(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (formNumber === LAST_FORM_NUMBER) {
      submitForm(values);
      return;
    }
    actions.setTouched({});
    actions.setSubmitting(false);
    setFormNumber(formNumber + 1);
  };

  const handleBack = async () => {
    setDetailsSubmitted(false);
    if (isDetailsSubmitted) {
      // If the user has hit save then back should 'unsubmit' the form
      return;
    }
    setFormNumber(formNumber - 1);
    setResponseStatus('');
    setResponseError('');
  };

  const getSubHeader = () => {
    if (isDetailsSubmitted) {
      return getSideDrawerSubHeading('REPORT', actionType);
    }
    return 'Report Details';
  };

  return (
    <Formik
      validationSchema={CreateReportSchema[formNumber - 1]}
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <CreateReportForm
          responseStatus={responseStatus}
          responseError={responseError}
          formNumber={formNumber}
          LAST_FORM_NUMBER={LAST_FORM_NUMBER}
          handleBack={handleBack}
          isDetailsSubmitting={isDetailsSubmitting}
          isDetailsSubmitted={isDetailsSubmitted}
          subheader={getSubHeader()}
          toggleForm={props.toggleForm}
          actionType={actionType}
          connections={connections}
          connectionsIsLoading={connectionsIsLoading}
          reportDataLoading={reportDataLoading}
          {...formikProps}
        />
      )}
    </Formik>
  );
}
export default CreateReport;

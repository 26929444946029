const appConfig = {
  BASE_UTL: process.env.REACT_APP__PT_BASE_URL,
  MS_CLIENT_ID: process.env.REACT_APP__MS_CLIENT_ID,
  MS_REDIRECT_URL: process.env.REACT_APP__MS_REDIRECT_URL,
  PT_SERVER_URL: process.env.REACT_APP__PT_SERVER_URL,
  PT_ENV: process.env.REACT_APP__PT_ENV,
  redirect: false,
};

export default appConfig;

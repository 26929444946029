import { useQuery, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import * as token from '../endpoints/token';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';

// Queries
export const useGetPowerBIBearerToken = (connectionGuid, isServicePrincipalAuthentication, isKeyVault) => {
  return useQuery({
    queryKey: ['powerBIToken', connectionGuid],
    queryFn: () => token.getPowerBIBearerToken({ connectionGuid, isServicePrincipalAuthentication, isKeyVault }),
    staleTime: 45 * (60 * 1000), // 45 mins
  });
};

export const useGetPowerBIRefreshToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (code) => token.getPowerBIRefreshToken({ code }),

    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to save the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

import { Stack, Link, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import FormTextField from '../../../../components/FormTextField';
import withSnackbar from '../../../../hocs/withSnackbar';
import { SNACKBAR_ERROR } from '../../../../constants/snackbar';
import { useGetPowerBIPages } from '../../../../api/queries/powerBI';
import { findObjectByPropertyCI } from '../../../../utils/array';

function UrlVisualSelectionForm(props) {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    visualUrl,
    openSnackbar,
    reportConnection,
    reports,
    setErrors,
  } = props;

  const [reportUrlValues, setReportUrlValues] = useState({});

  const {
    data: powerBIReportPages,
    isLoading: powerBIPagesIsLoading,
    isFetching: powerBIPagesIsFetching,
    isError: powerBIReportsIsError,
  } = useGetPowerBIPages(
    !!reportUrlValues?.powerBIReportGuid && !!reportConnection?.connectionGuid, // enabled
    reportConnection,
    reportUrlValues.workspaceId,
    reportUrlValues.powerBIReportGuid,
    reportUrlValues.pageGuid,
    reportUrlValues.includeReportData,
  );
  useEffect(() => {
    if (powerBIReportsIsError) {
      setErrors({ visualUrl: 'Cannot retrieve report data, ensure you have created the report in PowerTester.' });
    }

    // eslint-disable-next-line
  }, [powerBIReportsIsError]);

  const onTextChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const getPowerBIDetailsFromUrl = (visualUrl) => {
    try {
      if (!visualUrl) return;

      const url = new URL(visualUrl);
      if (!url) return;

      const workspaceId = url.pathname.split('/')[2];
      const powerBIReportGuid = url.pathname.split('/')[4];
      const pageGuid = url.pathname.split('/')[5];
      const visualGuid = url.searchParams.get('visual');

      if ((workspaceId, powerBIReportGuid, pageGuid, visualGuid)) {
        getReport(powerBIReportGuid);
        setFieldValue('visual.visualGuid', visualGuid);
        return { workspaceId, powerBIReportGuid, pageGuid, visualGuid };
      } else {
        openSnackbar('Cannot parse Url', SNACKBAR_ERROR);
      }
    } catch (error) {
      console.error(error);
      openSnackbar('Cannot parse Url', SNACKBAR_ERROR);
    }
  };

  const getReport = (powerBIReportGuid) => {
    if (reports) {
      const report = findObjectByPropertyCI(reports, 'powerBIReportGuid', powerBIReportGuid);

      if (report?.connectionGuid) {
        setFieldValue('report', report);
      } else {
        setErrors({ visualUrl: 'Cannot retrieve report data, ensure you have created the report first' });
      }
    }
  };

  useEffect(() => {
    if (!errors.visualUrl && visualUrl) {
      const reportUrl = getPowerBIDetailsFromUrl(visualUrl);
      const workspaceId = reportUrl.workspaceId;
      const powerBIReportGuid = reportUrl.powerBIReportGuid;
      const pageGuid = reportUrl.pageGuid;
      const includeReportData = true;

      setReportUrlValues({ workspaceId, powerBIReportGuid, pageGuid, includeReportData });
    }

    // eslint-disable-next-line
  }, [errors.visualUrl, visualUrl]);

  useEffect(() => {
    // If the page is returned
    if (!errors.visualUrl && visualUrl && powerBIReportPages && powerBIReportPages.length > 0) {
      // set page value in page obj
      setFieldValue('page', powerBIReportPages[0]);
    } else if (!errors.visualUrl && visualUrl && (!powerBIReportPages || powerBIReportPages?.length === 0)) {
      // If we have a valid Url but no pages are returned then set error
      setErrors({ visualUrl: 'Error retrieving details from Power BI. Please check Url and try again' });
    }

    // eslint-disable-next-line
  }, [powerBIReportPages]);

  return (
    <React.Fragment>
      <Stack spacing={3} mb={2}>
        <Box display={'flex'} flexDirection={'column'}>
          <span>PowerTester can ulitise the share visual url to identify the visualisation required for testing.</span>
          <Link
            href="https://wild-data-solutions.outseta.com/support/kb/articles/EWBjv1Wr/how-to-find-visual-url"
            target="_blank"
          >
            View more on Power BI Share visual Url
          </Link>
        </Box>
        <Box>
          <FormTextField
            label="Visual Url"
            variant="filled"
            fullWidth
            id="visualUrl"
            name="visualUrl"
            value={visualUrl}
            onChange={(event) => {
              onTextChange('visualUrl', event.target.value);
            }}
            error={touched.visualUrl && Boolean(errors.visualUrl)}
            touched={touched}
            errors={errors}
          />

          {powerBIPagesIsLoading && powerBIPagesIsFetching && <LinearProgress />}
        </Box>
        {visualUrl && !errors.visualUrl && powerBIReportPages && powerBIReportPages.length > 0 && (
          <Box
            className="info-container"
            px={4}
            py={2}
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box mb={2}>
              <b>{'Report Name'}</b>
              <div>{powerBIReportPages[0].reportName}</div>
            </Box>
            <Box>
              <b>{'Page Name'}</b>
              <div>{powerBIReportPages[0].pageDisplayName}</div>
            </Box>
          </Box>
        )}
      </Stack>
    </React.Fragment>
  );
}

export default withSnackbar(UrlVisualSelectionForm);

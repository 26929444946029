import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Link as ExternalLink, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import React from 'react';

import { useGetPowerBIReportVisuals, useGetPowerBIReports } from '../../../api/queries/powerBI';
import Autocomplete from '../../../components/Autocomplete';
import ResponseCard from '../../../components/ResponseCard';
import StepperComponent from '../../../components/Stepper';
import withSnackbar from '../../../hocs/withSnackbar';
import { getSideDrawerSubHeading } from '../../../utils/sidedrawer';
import VisualDetailsForm from './VisualDetailsForm';
import VisualForm from './VisualForm';
import MissingObjectWarningComponent from '../../../components/MissingObjectWarning';

function CreateReportForm(props) {
  const {
    values: { connection, powerBIReport, visuals, reportName },
    responseStatus,
    responseError,
    errors,
    touched,
    formNumber,
    LAST_FORM_NUMBER,
    handleBack,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    isDetailsSubmitted,
    isDetailsSubmitting,
    toggleForm,
    actionType,
    connections,
    connectionsIsLoading,
    reportDataLoading,
    openSnackbar,
  } = props;

  const { data: powerBIReports, isLoading: powerBIReportsIsLoading } = useGetPowerBIReports(
    !!connection?.connectionGuid,
    connection,
    connection?.workspaceId,
  );

  // Get this now to improve performance of next screen
  useGetPowerBIReportVisuals(
    !!powerBIReport?.reportGuid,
    false, // transform
    connection,
    powerBIReport?.reportGuid,
    true, // isRefreshTrue for new reports
  );

  const refreshReportList = () => {
    try {
      // getPowerBIReports();
      openSnackbar('Reports refreshed from Power BI', 'success');
    } catch {
      openSnackbar('Error refreshing, please try again', 'rrror');
    }
  };

  const getNextButtonText = () => {
    if (formNumber === LAST_FORM_NUMBER) {
      return 'Save';
    }
    return 'Next';
  };

  const steps = ['Report', 'Visual selection', 'Visual detais'];

  const getResponseCard = () => {
    const result = [{ key: 'Name', value: reportName }];
    return (
      <ResponseCard
        title={!responseError ? getSideDrawerSubHeading('REPORT', actionType) : responseError}
        values={result}
        responseType={responseStatus}
        toggleForm={toggleForm}
      />
    );
  };
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box display="flex" pr={4} py={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <StepperComponent steps={steps} activeStep={formNumber - 1} />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ minWidth: '150px' }}
        >
          <Stack direction="row" spacing={1}>
            {formNumber !== 1 && (!isDetailsSubmitted || responseError) && (
              <Button className="grey form-button" variant="contained" onClick={handleBack}>
                Back
              </Button>
            )}
            {!isDetailsSubmitted && (
              <LoadingButton
                type="submit"
                className="yellow form-button"
                variant="contained"
                loading={isDetailsSubmitting}
              >
                {getNextButtonText()}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box px={4} mt={3}>
        {!isDetailsSubmitted && (
          <React.Fragment>
            {formNumber === 1 && (
              <Box className="form-field-container">
                <Box mb={3}>
                  <p>Reports in PowerTester hold all the visualisations in scope for testing.</p>
                  <p>You can either add visualisations from a list or manually add any visual from your reports</p>
                  <ExternalLink
                    href="https://wild-data-solutions.outseta.com/support/kb/articles/rQVZq1Q6/how-to-create-reports"
                    target="_blank"
                  >
                    Learn more about PowerTester reports
                  </ExternalLink>
                </Box>
                <Box mb={2} mt={3}>
                  <span>Select your connection with the workspace that holds the report</span>
                </Box>

                {reportDataLoading ? (
                  <Box display="flex" pt={1} pl={1}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <React.Fragment>
                    <Box mb={5} className="form-field-container">
                      <Autocomplete
                        // loading={connectionsLoading}
                        multiple={false}
                        name="connection"
                        setFieldValue={setFieldValue}
                        options={connections}
                        label={'connectionName'}
                        value={'connectionGuid'}
                        selectedItem={connection}
                        inputLabel={'Selection connection'}
                        error={touched.connection && Boolean(errors.connection)}
                      />
                      {errors.connection && touched.connection && (
                        <Box color="error.main" className="form-field-error-text">
                          {errors.connection}
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="form-field-container"
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      mb={3}
                    >
                      <Box flexGrow={1}>
                        <Autocomplete
                          disabled={Boolean(!connection?.connectionGuid)}
                          loadingText="Loading reports from Power BI..."
                          loading={powerBIReportsIsLoading}
                          multiple={false}
                          name="powerBIReport"
                          setFieldValue={setFieldValue}
                          options={powerBIReports}
                          label={'reportName'}
                          value={'reportGuid'}
                          selectedItem={powerBIReport}
                          inputLabel={
                            powerBIReportsIsLoading && Boolean(connection?.connectionGuid)
                              ? 'Loading...'
                              : 'Select Power BI report'
                          }
                          error={touched.powerBIReport && Boolean(errors.powerBIReport)}
                        />
                        {errors.powerBIReport && touched.powerBIReport && (
                          <Box color="error.main" className="form-field-error-text">
                            {errors.powerBIReport}
                          </Box>
                        )}
                      </Box>
                      <IconButton onClick={refreshReportList} disabled={Boolean(!connection?.connectionGuid)}>
                        <RefreshIcon />
                      </IconButton>
                    </Box>
                    <MissingObjectWarningComponent
                      isLoading={connectionsIsLoading}
                      array={connections}
                      alertTitle="No Power BI connections detected."
                      linkTo="/connections/add"
                      linkText="Click to go to new connection screen."
                    />
                  </React.Fragment>
                )}
              </Box>
            )}
            {formNumber === 2 && (
              <VisualForm
                powerBIReport={powerBIReport}
                reportName={reportName || powerBIReport.reportName}
                errors={errors}
                touched={touched}
                visuals={visuals}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                powerBIReports={powerBIReports}
                connection={connection}
              />
            )}
            {formNumber === 3 && (
              <VisualDetailsForm
                visuals={visuals}
                errors={errors}
                touched={touched}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
                reportName={reportName}
              />
            )}
          </React.Fragment>
        )}

        {isDetailsSubmitted && getResponseCard()}
      </Box>
    </form>
  );
}
export default withSnackbar(CreateReportForm);

import { Box } from '@mui/material';
import React from 'react';
import { cronExpressionToHumanReadable } from '../../utils/cron';
import moment from 'moment-timezone';

function CronExpression(props) {
  return (
    <div>
      <Box p={2} className="cron-details-container">
        <Box mb={1} className="font-600">
          {`CRON Value`}
        </Box>
        <Box>
          <p>
            {cronExpressionToHumanReadable(props.expression)}
            {props.timezone && <span> - {moment.tz(props.timezone).zoneAbbr()}</span>}
          </p>
        </Box>
      </Box>
    </div>
  );
}
export default CronExpression;

import { Stack, Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

function CopilotSQLConfirmModal(props) {
  const { modalOpen, cancel, confirm } = props;
  const [hideModal, setHideModal] = useState(false);

  return (
    <Dialog
      open={modalOpen}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Experimental feature</DialogTitle>
      <DialogContent
        dividers
        sx={{ width: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '5px' }}
      >
        <Stack direction="column" spacing={2} flexGrow={1}>
          <Box>
            Generate SQL using PowerTester Co-pilot?
            <br />
            <br />
            This is an experimental feature that uses the Power BI query to generate SQL for the test case.
            <br />
            <br />
            It is not recommended to use this SQL without first validating its logic and testing on the data source.
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', paddingLeft: '24px' }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox value={hideModal} onChange={(e) => setHideModal(e.target.checked)} />}
            label="Do not show again"
          />
        </FormGroup>
        <Box>
          <Button onClick={() => cancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => confirm(hideModal)} color="primary" autoFocus>
            Generate
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default CopilotSQLConfirmModal;

import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index';
import { useSnackbar } from 'notistack';

import * as connection from '../endpoints/connection';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { invalidatePowerBIBearerTokenStore } from '../../services/token';
import { createTestDatabaseConnectionBody, createTestPowerBIConnectionBody } from '../mappings/connection';

const allConnectionsKey = ['connections', 'all'];

// Queries
export const useGetConnections = (enabled, queryKey, nameFilter, typeFilter, groupFilter) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['connections', queryKey],
    queryFn: () => {
      if (queryKey !== 'all' && queryKey !== 'Database') {
        return connection.get(queryKey);
      } else {
        return connection.get();
      }
    },
    enabled: enabled,
    select: (conns) => {
      if (nameFilter) {
        return conns.filter((c) => c.connectionName?.toLowerCase().includes(nameFilter.toLowerCase()));
      }
      if (typeFilter) {
        return conns.filter((c) => c.connectionType?.includes(typeFilter));
      }
      if (groupFilter) {
        return conns.filter((c) => c.connectionTypeGroup?.includes(groupFilter));
      }
      return conns;
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve connection data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestCaseForConnection = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['connection', 'testCases', queryKey.connectionGuid],
    queryFn: () => connection.getTestCases(queryKey.connectionGuid),

    enabled: enabled,

    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetConnectionSummary = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['connectionSummary', queryKey],
    queryFn: () => connection.summary(queryKey),
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve connection summary');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

// Mutations
export const useCreateConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => connection.create(rawData),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: allConnectionsKey }); // invalidate the connection list (easier than updating state)

      // Show success snackbar
      enqueueSnackbar('Connection created successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to create the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useUpdateConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { connectionGuid, ...body } = rawData;
      return connection.update(body, connectionGuid);
    },

    onSuccess: (data, updatedConnection) => {
      queryClient.invalidateQueries({ queryKey: allConnectionsKey }); // invalidate the connection list (easier than updating state)

      // Show success snackbar
      enqueueSnackbar('Connection updated successfully', { variant: SNACKBAR_SUCCESS });

      // invalidate token store
      invalidatePowerBIBearerTokenStore(updatedConnection.connectionGuid);
      return data;
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDeleteConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (connectionGuid) => connection.remove(connectionGuid),

    onSuccess: (data, connectionGuid) => {
      // Remove the connection from the connections cache (faster than invalidating)
      queryClient.setQueryData(allConnectionsKey, (old) => old.filter((t) => t.connectionGuid !== connectionGuid));

      // Show success snackbar
      enqueueSnackbar('Connection deleted successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to delete the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useAuthorizeConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { connectionGuid, ...body } = rawData;
      return connection.authorizeConnection(body, connectionGuid);
    },

    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to connection the power bi workspace');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_WARNING });
    },
  });
};

export const useTestPowerBIConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => connection.testPowerBIConnection(createTestPowerBIConnectionBody(rawData)),

    onSettled: (data, error, variables) => {
      if (error) {
        let response = '';

        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data) {
              response = error.response.data.error;

              if (response.startsWith('Power BI connection test failed. Reason')) {
                response = response.replace('Power BI connection test failed. Reason: ', '');
              }
            }
          }
        }

        if (!response) {
          response = 'Error connecting to Power BI';
        }

        enqueueSnackbar(response, {
          variant: SNACKBAR_ERROR,
        });
      } else {
        // We should still check that the response is correct
        if (data.status === 200 && data.data.testSuccessful) {
          const connName = variables.connectionName ?? 'Power BI';

          if (data.data.warning) {
            enqueueSnackbar(`${connName} successful with warnings: ${data.data.warning}`, {
              variant: SNACKBAR_WARNING,
            });
          } else {
            enqueueSnackbar(`${connName} test successful`, {
              variant: SNACKBAR_SUCCESS,
            });
          }
        } else {
          enqueueSnackbar('Error connecting to Power BI', {
            variant: SNACKBAR_ERROR,
          });
        }
      }
    },
  });
};

export const useTestDatabaseConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { keyVaultConnection, dbDetails, connectionType, authenticationType, credentialMethod } = rawData;

      const connectionBody = createTestDatabaseConnectionBody({
        keyVaultConnection,
        dbDetails,
        connectionType,
        authenticationType,
        credentialMethod,
      });

      return connection.testDatabaseConnection(connectionBody);
    },

    onSettled: (data, error, variables) => {
      let response = '';
      let isError = true;

      // If the response is not an error
      if (!error) {
        // if the data returned is a success message
        if (data === 'Database connection test passed') {
          response = `${variables?.connectionName ?? 'Database'} connection successful`;
          isError = false;
          // If the test result is failed
        } else if (data.startsWith('Database connection test failed. Reason')) {
          response = data.replace('Database connection test failed. Reason: ', '');
        }
        // an error was thrown
      } else {
        if (error.response?.data?.error) {
          response = error.response?.data?.error;
        } else if (error.code === 'ECONNABORTED' || error.startsWith('timeout of')) {
          response = 'Timeout occured';
        } else {
          response = error;
        }

        if (!response) {
          response = 'Unknown error occurred.';
        }
      }
      enqueueSnackbar(response, { variant: isError ? SNACKBAR_ERROR : SNACKBAR_SUCCESS });
    },
  });
};

export const useTestKeyVaultConnection = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { keyVaultUrl, tenantId, servicePrincipalAppId, servicePrincipalSecret } = rawData;
      return connection.testKeyVaultConnection({
        keyVaultUrl,
        tenantId,
        servicePrincipalAppId,
        servicePrincipalSecret,
      });
    },

    onSettled: (data, error, variables) => {
      if (error) {
        let response = '';

        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data) {
              response = error.response.data.error;

              if (response.startsWith('Key vault connection test failed. Reason')) {
                response = response.replace('Key vault connection test failed. Reason: ', '');
              }
            }
          }
        }

        if (!response) {
          response = 'Error connecting to key vault';
        }

        enqueueSnackbar(response, {
          variant: SNACKBAR_ERROR,
        });
      } else {
        if (data.status === 200 && data.data.testSuccessful && !data.data.warning) {
          enqueueSnackbar(`${variables?.connectionName ?? 'Key Vault'} connection successful`, {
            variant: SNACKBAR_SUCCESS,
          });
        } else if (data.status === 200 && data.data.testSuccessful && !!data.data.warning) {
          enqueueSnackbar(data.data.warning, {
            variant: SNACKBAR_WARNING,
          });
        } else {
          enqueueSnackbar('Error connecting to key vault', {
            variant: SNACKBAR_ERROR,
          });
        }
      }
    },
  });
};

// Prefetch
export const prefetchConnectionList = () => {
  return queryClient.prefetchQuery(allConnectionsKey, () => connection.get());
};

export const prefetchConnectionSummary = (connectionGuid) => {
  return queryClient.prefetchQuery(['connectionSummary', connectionGuid], () => connection.summary({ connectionGuid }));
};

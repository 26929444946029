import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Link } from 'react-router-dom';

export default function MissingObjectWarningComponent(props) {
  const { isLoading, array, alertTitle, linkTo, linkText } = props;

  // Check if 'array' exists and has a length greater than 0
  if (isLoading || (array && array.length > 0)) {
    return null; // Return null to render nothing
  }

  return (
    <Alert severity="info">
      <AlertTitle>
        <b>{alertTitle}</b>
      </AlertTitle>
      <u>
        <Link to={linkTo}>{linkText}</Link>
      </u>
    </Alert>
  );
}

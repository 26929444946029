import React from 'react';
import { Link } from '@mui/material';

import Popover from '../Popover';

function HelpPopup(props) {
  const { handleClosePopup, popUpOpen } = props;
  return (
    <Popover handleClose={handleClosePopup} anchorEl={popUpOpen.anchorEl}>
      {popUpOpen.content}
      {popUpOpen.linkUrl && (
        <span>
          <br />
          <br />
          <Link href={popUpOpen.linkUrl} target="_blank">
            {popUpOpen.linkText ?? 'Click here for more information'}
          </Link>
        </span>
      )}
    </Popover>
  );
}
export default HelpPopup;

export const getStorageItem = (keyName) => {
  return localStorage.getItem(keyName);
};
export const getStorageObject = (keyName) => {
  var value = localStorage.getItem(keyName);
  return value && JSON.parse(value);
};
export const setStorageItem = (keyName, keyValue) => {
  return localStorage.setItem(keyName, keyValue);
};
export const setStorageObject = (keyName, keyValue) => {
  return localStorage.setItem(keyName, JSON.stringify(keyValue));
};

export const clearStorageItem = (keyName) => {
  return localStorage.removeItem(keyName);
};

export const clearStorage = () => {
  localStorage.clear();
};

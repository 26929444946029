import axios from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { signOut } from './microsoftAuthentication';
import { msalInstance } from '../index';

import appConfig from '../configs/appConfig';
import { loginRequest } from '../configs/authConfig';

const DEFAULT_TIMEOUT = 60000;

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const msalRequest = {
  ...loginRequest, // Could pass this into API when we have roles
};

// Function to get token from MSAL
async function getTokenFromMsal(msalInstance) {
  try {
    const account = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API

    const response = await msalInstance.acquireTokenSilent({
      ...msalRequest,
      account,
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // redirect
      // window.location.href = process.env.REACT_APP__PT_BASE_URL + '/';
    } else {
      throw error;
    }
  }
}

axios.interceptors.request.use(
  async function (config) {
    config.baseURL = appConfig.PT_SERVER_URL;
    config.headers = { ...headers };

    const token = await getTokenFromMsal(msalInstance);
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401) &&
      error.config.redirect === true
    ) {
      signOut();
    }

    return Promise.reject(error);
  },
);

const httpService = {
  get: (api, params, config) => {
    return axios.get(api, { ...config, params: params, timeout: config?.timeout || DEFAULT_TIMEOUT });
  },
  post: (api, data, config) => {
    return axios.post(api, data, { ...config, timeout: config?.timeout || DEFAULT_TIMEOUT });
  },
  put: (api, data, config) => {
    return axios.put(api, data, { ...config, timeout: config?.timeout || DEFAULT_TIMEOUT });
  },
  delete: (api, data, config) => {
    return axios.delete(api, { ...config, data: data, timeout: config?.timeout || DEFAULT_TIMEOUT });
  },
};

export default httpService;

import { Box } from '@mui/material';
import React from 'react';
import Search from '../Search';

function SearchFilter({ searchProps }) {
  return (
    <Box pb={2}>
      <Search {...searchProps} />
    </Box>
  );
}
export default SearchFilter;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TablePaginationActions from '../../../components/TablePaginationActions';
import TableSkeleton from '../../../components/TableSkeleton';

export default function TestCaseViewProperties(props) {
  const { testPlans, isLoading } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [testPlans]);

  const selectTestPlan = (testPlanGuid) => {
    navigate(`/testplans/${testPlanGuid}`);
  };

  return (
    <TableContainer>
      <Table className="list-table fixed" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" className="column">
              Test Plan Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            (testPlans && testPlans?.length > 0
              ? rowsPerPage > 0
                ? testPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : testPlans
              : []
            ).map((row) => (
              <TableRow className="pointer" onClick={() => selectTestPlan(row.testPlanGuid)} key={row.testPlanGuid}>
                <TableCell className="column">{row.testPlanName}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableSkeleton columns={7} rows={10} hasMenu />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={testPlans?.length ?? 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

import http from '../../services/http';
import { NOTIFICATIONS } from '../../constants/api';
import { validateResponse } from '../../utils/response';

export const get = (params) => http.get(NOTIFICATIONS, params).then((res) => res.data);

export const update = async (body, notificationMessageId) => {
  let url = NOTIFICATIONS;

  if (notificationMessageId) url + '/' + notificationMessageId;
  const response = await http.put(url, body);
  if (validateResponse(response)) {
    return response.data;
  }
};

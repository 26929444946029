import ConnectionIcon from '../assets/images/icons/connections.svg';
import TestCasesIcon from '../assets/images/icons/test-cases.svg';
import ReportsIcon from '../assets/images/icons/reports.svg';
import TestPlanIcon from '../assets/images/icons/test-plan.svg';
import LogoIcon from '../assets/images/icons/logo.svg';
import DashboardIcon from '../assets/images/icons/dashboard.svg';
import { TEST_CASES, TEST_PLANS, REPORTS, CONNECTIONS, DASHBOARD } from './routes';

const navigation = [
  {
    name: '',
    route: '/',
    icon: LogoIcon,
  },
  {
    name: 'Dashboard',
    route: DASHBOARD,
    icon: DashboardIcon,
  },
  {
    name: 'Test Plans',
    route: TEST_PLANS,
    icon: TestPlanIcon,
  },
  {
    name: 'Test Cases',
    route: TEST_CASES,
    icon: TestCasesIcon,
  },
  {
    name: 'Reports',
    route: REPORTS,
    icon: ReportsIcon,
  },
  {
    name: 'Connections',
    route: CONNECTIONS,
    icon: ConnectionIcon,
  },
];

export default navigation;

import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

import CloseIcon from '../../../assets/images/icons/close.svg';
import GettingStartedList from '../../../components/GettingStartedList';
import Image from '../../../components/Image';

export default function GettingStartedCheklist(props) {
  const { stepsList, hideGettingStartedChecklist } = props;
  const [mappedStepsList, setMappedStepsList] = useState([]);

  useEffect(() => {
    if (!stepsList || stepsList.length === 0) return;
    // Create a copy of the stepsList array
    const updatedStepsList = [...stepsList];

    // Find the index of the first object where IsComplete is false
    const index = updatedStepsList.findIndex((step) => !step.IsComplete);

    // If a matching object is found, update the open property
    if (index !== -1) {
      updatedStepsList[index].open = true;
    }

    // Update the state with the modified copy of the array
    setMappedStepsList(updatedStepsList);
    // eslint-disable-next-line
  }, [stepsList]);

  const handleOnClickGettingStartedChecklist = (id) => {
    let mapped = stepsList.map((step) => {
      return step.StepId === Number(id) ? { ...step, open: true } : { ...step, open: false };
    });
    setMappedStepsList(mapped);
  };

  return (
    <Box className="dashboard-container" flex={1} mx={4} px={4} py={1} flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <h2>Welcome to PowerTester</h2>
          Follow these steps to test your first visual.
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box onClick={hideGettingStartedChecklist} pt={2}>
            <Image className="close-icon pointer" src={CloseIcon} alt="Close Icon" />
          </Box>
        </Box>
      </Box>
      <Box pt={2}>
        <GettingStartedList
          gettingStartedList={mappedStepsList}
          onClickHandler={handleOnClickGettingStartedChecklist}
        />
      </Box>
    </Box>
  );
}

import React from 'react';
import { Button, Box, useTheme, Badge } from '@mui/material';

function Switch(props) {
  const { switchOptions, dotIndicatorOption, direction } = props;
  const theme = useTheme();

  const styles = {
    switch: {
      border: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      color: 'black',
      flexDirection: direction ?? 'row',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#FFEFC5',
    },
  };

  return (
    <Box sx={styles.switch}>
      {switchOptions.list.map((option) => (
        <React.Fragment key={option.label}>
          <Button
            startIcon={
              option.label === dotIndicatorOption && (
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  variant="dot"
                  color="primary"
                ></Badge>
              )
            }
            sx={{ minWidth: '125px' }}
            key={option.label}
            className={(option.label === switchOptions.activeSwitch ? 'yellow' : 'white') + ' square'}
            variant="contained"
            onClick={option.action}
          >
            {option.label}
          </Button>
        </React.Fragment>
      ))}
    </Box>
  );
}

export default Switch;

import { useQuery } from '@tanstack/react-query';

import * as tag from '../endpoints/tag';

// Queries
export const useGetTag = (enabled) => {
  return useQuery({
    queryKey: ['tags', 'all'],
    queryFn: () => tag.get(),
    enabled: enabled,
  });
};

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { formatDate, formatSecondsToHms } from '../../../utils/date';

export default function TestPlanTestCases(props) {
  const { testRuns, viewTestRun } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table className="list-table table-type-2 fixed" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row" className="column">
              Run Time
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              Duration
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              Pass rate
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              # of tests
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testRuns &&
            (rowsPerPage > 0 ? testRuns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : testRuns).map(
              (row) => (
                <TableRow className="pointer" key={row.testRunGuid} onClick={() => viewTestRun(row, 'TEST_RUN')}>
                  <TableCell className="column">{formatDate(row.runTime)}</TableCell>
                  <TableCell className="column">{formatSecondsToHms(row.runDuration)}</TableCell>
                  <TableCell className="column">
                    {row.passRate !== undefined ? _.round(row.passRate * 100) + '%' : '-'}
                  </TableCell>
                  <TableCell className="column">{row.noOfTests}</TableCell>
                </TableRow>
              ),
            )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={testRuns ? testRuns.length : 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

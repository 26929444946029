export const isJson = (str) => {
  try {
    if (!str) return;
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const nullIfEmpty = (inputString) => {
  return inputString === '' ? null : inputString;
};

export const convertToNumber = (str) => {
  var num = parseFloat(str);
  if (!isNaN(num)) {
    return num;
  } else {
    return null;
  }
};

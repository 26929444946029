import * as Yup from 'yup';
import errors from '../../constants/errors';
import { databaseSchema } from './createDatabaseSchema';
import { powerBISchema } from './createPowerBISchema';
import { keyVaultSchema } from './createKeyVaultSchema';

const connectionNameForm = (connectionGuid, connections) => {
  return Yup.object().shape({
    connectionName: Yup.string()
      .required(errors.CONNECTION_NAME.REQUIRED)
      .max(800, errors.CONNECTION_NAME.MAX_LENGTH(800))
      .test('unique', 'Connection name already exists', function (value) {
        // Iterate over the connections array and check if any connectionName matches the value
        // if the matching connection is the editting one then dw
        return !connections.some(
          (connection) => connection.connectionName === value && connection.connectionGuid != connectionGuid,
        );
      }),
    connectionType: Yup.string().nullable().required(errors.CONNECTION_TYPE_SELECTION.REQUIRED),
  });
};

const connectionDetailsForm = (connectionGuid) => {
  return Yup.object().shape({
    connectionType: Yup.object()
      .nullable()
      .required(errors.CONNECTION_TYPE_SELECTION.REQUIRED)
      .shape({
        connectionType: Yup.string().nullable().required(errors.CONNECTION_TYPE_SELECTION.REQUIRED),
      }),

    authenticationType: Yup.object()
      .nullable()
      .when('connectionType.connectionType', {
        is: (connType) => connType !== 'Key Vault',
        then: Yup.object()
          .nullable()
          .required(errors.AUTHENTICATION_TYPE_SELECTION.REQUIRED)
          .shape({
            authenticationType: Yup.string().nullable().required(errors.AUTHENTICATION_TYPE_SELECTION.REQUIRED),
          }),
      }),
    credentialMethod: Yup.string()
      .nullable()
      .when('connectionType.connectionType', {
        is: (connType) => connType !== 'Key Vault',
        then: Yup.string().nullable().required(errors.AUTHENTICATION_TYPE_SELECTION.REQUIRED),
      }),
    keyVaultConnection: Yup.object()
      .nullable()
      .when('credentialMethod', {
        is: 'Key Vault',
        then: Yup.object().nullable().required(errors.KV_CONNECTION.REQUIRED),
      }),
    dbDetails: Yup.object().when(['connectionType.connectionType'], {
      is: (connType) =>
        connType === 'Azure SQL Database' ||
        connType === 'Microsoft Fabric' ||
        connType === 'Postgres' ||
        connType === 'Snowflake',
      then: databaseSchema(),
    }),
    powerBIDetails: Yup.object().when('connectionType.connectionType', {
      is: 'Power BI',
      then: powerBISchema(connectionGuid),
    }),
    keyVaultDetails: Yup.object().when('connectionType.connectionType', {
      is: 'Key Vault',
      then: keyVaultSchema(),
    }),
  });
};

const CreateConnectionSchema = ({ connectionGuid }, connections) => {
  return [connectionNameForm(connectionGuid, connections), connectionDetailsForm(connectionGuid)];
};

export default CreateConnectionSchema;

import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CREATE_FORM, EDIT_FORM, VIEW_DETAILS } from '../../constants/sidedrawer';
import Header from '../../components/Header';
import SearchFilter from '../../components/SearchFilter';
import SideDrawer from '../../components/SideDrawer';
import withFormInteraction from '../../hocs/withFormInteraction';
import withSnackbar from '../../hocs/withSnackbar';
import { useGetConnections } from '../../api/queries/connection';
import { useGetReports, useDeleteReport } from '../../api/queries/report';
import { getSideDrawerHeading } from '../../utils/sidedrawer';
import CreateReport from './CreateReport';
import ReportView from './ReportView';
import ReportList from './ReportList';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useGetVisualSelectionTypes } from '../../api/queries/visualSelectionTypes';

function Reports(props) {
  const { formOpen, toggleForm, actionType, openSnackbar } = props;
  const { reportGuidUrl } = useParams();

  const [reportData, setReportData] = useState({});
  const [isReloading, setIsReloading] = useState(false);
  const [reportNameFilter, setReportNameFilter] = useState('');
  const [viewHeader, setViewHeader] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteReportGuid, setDeleteReportGuid] = useState(null);

  const navigate = useNavigate();

  const { data: visualSelectionTypes } = useGetVisualSelectionTypes(true);

  // Get data
  const { data: connections, isLoading: connectionsIsLoading } = useGetConnections(true, 'all', null, 'Power BI');
  const {
    data: reports,
    isLoading: reportsIsLoading,
    isError,
    refetch: refetchReports,
  } = useGetReports(true, 'all', reportNameFilter);

  const reload = () => {
    setIsReloading(true);
    refetchReports().then(() => {
      setIsReloading(false);
    });
  };

  // Updates
  const { mutate: deleteReportHook } = useDeleteReport();

  useEffect(() => {
    if (isError) {
      openSnackbar('Error returning connection data', SNACKBAR_ERROR);
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if (reportGuidUrl && reportsIsLoading === false) {
      const foundTestCase = reports.find((x) => x.reportGuid === reportGuidUrl.toUpperCase());

      if (foundTestCase) {
        setReportData(foundTestCase);
        toggleForm(VIEW_DETAILS);
      } else if (reportGuidUrl === 'add') {
        toggleForm(CREATE_FORM);
      } else {
        navigate('/reports');
      }
    }
    // eslint-disable-next-line
  }, [reports, reportsIsLoading]);

  const setFilterReports = (data) => {
    setReportNameFilter(data);
  };

  const resetReportData = () => {
    setReportData({});
  };

  const searchFilterProps = {
    searchProps: {
      placeholder: 'Search reports',
      list: reports,
      searchKey: 'reportName',
      setFilterListHook: setFilterReports,
      returnValue: true,
    },
  };

  const selectReport = async (index, newActionType) => {
    if (formOpen && actionType !== VIEW_DETAILS) {
      return;
    }
    setViewHeader(reports[index]?.reportName);
    setReportData(reports[index]);

    if (formOpen) {
      return;
    }

    toggleForm(newActionType);
  };

  const getSideDrawerContent = () => {
    switch (actionType) {
      case VIEW_DETAILS:
        return (
          <ReportView
            reportData={reportData}
            reportDataLoading={reportsIsLoading}
            toggleDeleteModal={toggleDeleteModal}
            visualSelectionTypes={visualSelectionTypes}
            connections={connections}
          />
        );
      case CREATE_FORM:
      case EDIT_FORM:
      default:
        return (
          <CreateReport
            actionType={actionType}
            toggleForm={toggleForm}
            reportData={reportData}
            resetReportData={resetReportData}
            openSnackbar={openSnackbar}
            connections={connections}
            connectionsIsLoading={connectionsIsLoading}
            reportDataLoading={reportsIsLoading}
          />
        );
    }
  };

  const openForm = async () => {
    resetReportData();
    toggleForm();
  };

  const toggleDeleteModal = (reportGuid) => {
    if (reportGuid) {
      setDeleteReportGuid({ reportGuid, closeForm: false });
    } else if (reportData?.reportGuid) {
      setDeleteReportGuid({ reportGuid: reportData?.reportGuid, closeForm: true });
    }
    setDeleteModalOpen(!deleteModalOpen);
  };

  const confirmDelete = () => {
    if (!deleteReportGuid?.reportGuid) {
      openSnackbar('Unknown Error deleting report', SNACKBAR_ERROR);
      return;
    }
    deleteReportHook(deleteReportGuid.reportGuid);
    toggleDeleteModal();
    if (deleteReportGuid.closeForm) toggleForm();
  };

  return (
    <Box className="page-details">
      <Header
        title="Reports"
        buttonText="Add Report"
        toggleForm={openForm}
        loading={reportsIsLoading}
        buttonDisabled={formOpen}
        helpText="Reports contain the Power BI report and visuals in scope for testing."
        refreshAction={reload}
      />
      <ConfirmationModal
        title="Delete Report"
        modalOpen={deleteModalOpen}
        confirm={confirmDelete}
        cancel={toggleDeleteModal}
        confirmationButtonText={'Delete'}
        msg={'Are you sure you want to delete?'}
      />
      <Box px={4} className="page-details__list-container" display="flex" pb={4} flexDirection="row">
        <Box className="page-details-container" pr={3} flex={1}>
          <SearchFilter {...searchFilterProps} />
          <ReportList
            selectReport={selectReport}
            reports={reports}
            loading={(reportsIsLoading || isReloading) && !isError}
            toggleDeleteModal={toggleDeleteModal}
            formOpen={formOpen}
            actionType={actionType}
          />
        </Box>
        <SideDrawer
          toggleForm={toggleForm}
          formOpen={formOpen}
          title={getSideDrawerHeading('REPORT', actionType, viewHeader ?? reportData.reportName)}
        >
          {getSideDrawerContent()}
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withSnackbar(withFormInteraction(Reports));

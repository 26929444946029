import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import Menu from '../../../components/Menu';
import TablePaginationActions from '../../../components/TablePaginationActions';
import TableRowMenu from '../../../components/TableRowMenu';
import TableSkeleton from '../../../components/TableSkeleton';
import { EDIT_FORM, VIEW_DETAILS } from '../../../constants/sidedrawer';
import { getRowIndex } from '../../../utils/TablePagination';

export default function ReportList(props) {
  const { reports, formOpen, loading, toggleDeleteModal } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleContextMenu = (event, index, id) => {
    if (formOpen) return;
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            top: event.clientY - 6,
            left: event.clientX + 2,
            index,
            id,
          }
        : null,
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [reports]);

  const selectReport = (index, id, actionType) => {
    props.selectReport(index, actionType);
  };

  const deleteReport = (index, id) => {
    toggleDeleteModal(id);
  };

  const rowClassName = () => {
    if (!formOpen) return 'pointer';

    if (formOpen && props.actionType === VIEW_DETAILS) return 'pointer';
  };

  const options = [
    { label: 'Edit', action: selectReport, actionType: EDIT_FORM },
    { label: 'Delete', action: deleteReport },
  ];

  return (
    <React.Fragment>
      {!!contextMenu?.top && (
        <Menu
          anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
          options={options}
          handleClose={() => setContextMenu(null)}
          index={contextMenu?.index}
          id={contextMenu?.id}
        />
      )}
      <TableContainer>
        <Table className="list-table fixed" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="column">Report Name</TableCell>
              <TableCell className="column"># Visuals</TableCell>
              {!formOpen ? (
                <>
                  <TableCell className="column"># Pages</TableCell>
                  <TableCell className="column menu-column"></TableCell>
                </>
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              (reports && reports?.length > 0
                ? rowsPerPage > 0
                  ? reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : reports
                : []
              ).map((row, index) => (
                <TableRow
                  key={row.reportGuid}
                  onClick={(event) => {
                    selectReport(getRowIndex(index, page, rowsPerPage), '', VIEW_DETAILS);
                    event.stopPropagation();
                  }}
                  onContextMenu={(event) =>
                    handleContextMenu(event, getRowIndex(index, page, rowsPerPage), row.reportGuid)
                  }
                  className={rowClassName()}
                >
                  <TableCell className="column" scope="row">
                    <Typography noWrap variant={'body1'}>
                      {row.reportName}
                    </Typography>
                  </TableCell>
                  <TableCell className="column">{row.visualCount ? row.visualCount.toLocaleString() : 0}</TableCell>
                  {!formOpen ? (
                    <>
                      <TableCell className="column">
                        {row.visualPageCount ? row.visualPageCount.toLocaleString() : 0}
                      </TableCell>
                      <TableCell className="column menu-column text-right">
                        <TableRowMenu
                          index={getRowIndex(index, page, rowsPerPage)}
                          id={row.reportGuid}
                          options={options}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))
            ) : (
              <TableSkeleton columns={3} rows={10} hasMenu />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={reports?.length ?? 0}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

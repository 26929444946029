import React from 'react';
import Step from './Step';

const GettingStartedList = (props) => {
  const { gettingStartedList, onClickHandler } = props;

  return (
    <div>
      {gettingStartedList &&
        gettingStartedList.length > 0 &&
        gettingStartedList.map((step, ix) => {
          const isLast = gettingStartedList.length - 1 === ix;

          return <Step step={step} key={step.StepId} onClickHandler={onClickHandler} isLast={isLast} />;
        })}
    </div>
  );
};

export default GettingStartedList;

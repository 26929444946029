import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import React, { useRef } from 'react';

export default function FreeSolo(props) {
  const { options, setFieldValue, name, selectedItem, inputLabel, freeSolo, label, disabled, loading } = props;
  const inputRef = useRef(null);

  // Function to handle tag creation
  const handleCreateTag = (e) => {
    if (!e?.target?.value) return null;

    const inputValue = e.target.value.trim();

    if (inputValue) {
      if (inputValue !== '') {
        setFieldValue(name, [...selectedItem, inputValue]);
        inputRef.current.value = ''; // Clear the input after creating the tag
      }
    }
  };

  const onChange = (e, a) => {
    setFieldValue(name, a);
  };

  return (
    <Autocomplete
      loading={loading}
      multiple
      clearOnBlur
      limitTags={5}
      disabled={disabled}
      className="full-width"
      id="tags-freesolo"
      name={name}
      options={options}
      getOptionLabel={(option) => option.tagValue}
      onChange={onChange}
      value={selectedItem.map((item) => item)}
      freeSolo={freeSolo}
      ref={inputRef} // Add this line to pass the ref
      onBlur={handleCreateTag}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <Chip key={index} variant="outlined" label={option[label] || option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} variant="outlined" label={inputLabel} style={props.textStyle} />}
    />
  );
}

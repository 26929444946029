import React from 'react';

import AreaChart from '../assets/images/visuals/area-chart.png';
import BarChart from '../assets/images/visuals/bar-chart.png';
import CardChart from '../assets/images/visuals/card.png';
import ComboChart from '../assets/images/visuals/combo-chart.png';
import DecompositionTree from '../assets/images/visuals/decomposition-tree.png';
import DonutChart from '../assets/images/visuals/donut-chart.png';
import Funnel from '../assets/images/visuals/funnel.png';
import Gauge from '../assets/images/visuals/gauge.png';
import LineChart from '../assets/images/visuals/line-chart.png';
import MapChart from '../assets/images/visuals/map.png';
import PieChart from '../assets/images/visuals/pie-chart.png';
import Ribbon from '../assets/images/visuals/ribbon.png';
import Scatter from '../assets/images/visuals/scatter.png';
import TableChart from '../assets/images/visuals/table.png';
import Treemap from '../assets/images/visuals/treemap.png';
import Default from '../assets/images/visuals/unknown.png';
import WaterfallChart from '../assets/images/visuals/waterfall.png';
import Slicer from '../assets/images/visuals/slicer.png';
import Textbox from '../assets/images/visuals/textbox.png';
import Button from '../assets/images/visuals/button.png';
import Image from '../assets/images/visuals/image-visual.png';

import ImageComponent from '../components/Image';

export const getVisualImage = (visualType) => {
  switch (visualType) {
    case 'actionButton':
      return <ImageComponent src={Button} />;
    case 'areaChart':
    case 'stackedAreaChart':
      return <ImageComponent src={AreaChart} />;
    case 'card':
    case 'multiRowCard':
    case 'kpi':
    case 'cardVisual':
      return <ImageComponent src={CardChart} />;
    case 'barChart':
    case 'clusteredBarChart':
    case 'clusteredColumnChart':
    case 'columnChart':
    case 'hundredPercentStackedBarChart':
    case 'hundredPercentStackedColumnChart':
      return <ImageComponent src={BarChart} />;
    case 'Decomposition Tree':
      return <ImageComponent src={DecompositionTree} />;
    case 'donutChart':
      return <ImageComponent src={DonutChart} />;
    case 'map':
    case 'filledMap':
    case 'azureMap':
    case 'shapeMap':
      return <ImageComponent src={MapChart} />;
    case 'funnel':
      return <ImageComponent src={Funnel} />;
    case 'gauge':
      return <ImageComponent src={Gauge} />;
    case 'lineChart':
      return <ImageComponent src={LineChart} />;
    case 'lineClusteredColumnComboChart':
    case 'lineStackedColumnComboChart':
      return <ImageComponent src={ComboChart} />;
    case 'pieChart':
      return <ImageComponent src={PieChart} />;
    case 'pivotTable':
    case 'tableEx':
      return <ImageComponent src={TableChart} />;
    case 'ribbonChart':
      return <ImageComponent src={Ribbon} />;
    case 'scatterChart':
      return <ImageComponent src={Scatter} />;
    case 'treemap':
      return <ImageComponent src={Treemap} />;
    case 'waterfallChart':
      return <ImageComponent src={WaterfallChart} />;
    case 'textbox':
      return <ImageComponent src={Textbox} />;
    case 'slicer':
      return <ImageComponent src={Slicer} />;
    case 'image':
      return <ImageComponent src={Image} />;
    default:
      return <ImageComponent src={Default} />;
  }
};

export const mapVisualTypeTitle = (title, titleSourceId) => {
  if (!title || !titleSourceId || titleSourceId !== 3) return title; // TyprId 3 == visual Type

  return getFriendlyVisualTypeName(title);
};

export const getFriendlyVisualTypeName = (visualType) => {
  switch (visualType) {
    case 'actionButton':
      return 'Button';
    case 'areaChart':
    case 'stackedAreaChart':
      return 'Area chart';
    case 'card':
    case 'multiRowCard':
    case 'kpi':
      return 'Card';
    case 'cardVisual':
      return 'Card (new)';
    case 'barChart':
    case 'clusteredBarChart':
    case 'clusteredColumnChart':
    case 'columnChart':
    case 'hundredPercentStackedBarChart':
    case 'hundredPercentStackedColumnChart':
      return 'Bar chart';
    case 'Decomposition Tree':
      return 'Decomposition Tree';
    case 'donutChart':
      return 'Donut chart';
    case 'map':
    case 'filledMap':
    case 'shapeMap':
    case 'azureMap':
      return 'Map';
    case 'funnel':
      return 'Funnel';
    case 'gauge':
      return 'Gauge';
    case 'lineChart':
      return 'Line chart';
    case 'lineClusteredColumnComboChart':
    case 'lineStackedColumnComboChart':
      return 'Combo chart';
    case 'pieChart':
      return 'Pie chart';
    case 'pivotTable':
    case 'tableEx':
      return 'Table';
    case 'ribbonChart':
      return 'Ribbon Chart';
    case 'scatterChart':
      return 'Scatter Chart';
    case 'treemap':
      return 'Treemap';
    case 'waterfallChart':
      return 'waterfallChart';
    case 'custom':
      return 'Custom';
    case 'textbox':
      return 'Text box';
    case 'image':
      return 'Image';
    default:
      return visualType;
  }
};

export const getVisualIdFromUrl = (urlInputString) => {
  if (!urlInputString) return null;
  try {
    const url = new URL(urlInputString);
    const searchParams = new URLSearchParams(url.search);
    const visualParam = searchParams.get('visual');
    if (visualParam) {
      return visualParam.toLowerCase();
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const mapAuthenticationTypes = (authTypes, connectionTypeId) => {
  if (!authTypes) {
    return [];
  }

  // Return all authentication types if no connection ID is specified
  if (!connectionTypeId) {
    return authTypes.map((item) => {
      const parsedIds = JSON.parse(item.supportedConnectionTypeIds);
      return { ...item, supportedConnectionTypeIds: parsedIds };
    });
  }

  // Filter the array for only authentication types that have a matching connection ID
  return authTypes
    .filter((item) => {
      const parsedIds = JSON.parse(item.supportedConnectionTypeIds);
      return parsedIds.some((obj) => obj.Id === connectionTypeId);
    })
    .map((item) => {
      const parsedIds = JSON.parse(item.supportedConnectionTypeIds);
      return { ...item, supportedConnectionTypeIds: parsedIds };
    });
};

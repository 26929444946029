import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useGetTag } from '../../../api/queries/tag';
import { useGetTestCase } from '../../../api/queries/testCase';
import { useCreateTestPlan, useUpdateTestPlan } from '../../../api/queries/testPlan';
import createTestPlanSchema from '../../../schema/createTestPlanSchema';
import { getIdList } from '../../../utils/array';
import { getResponseErrorMsg } from '../../../utils/response';
import { nullIfEmpty } from '../../../utils/string';
import { getDefaultTimezone } from '../../../utils/timezone';
import CreateTestPlanForm from './Form';

function CreateTestPlan(props) {
  const { resetPlanCaseData, actionType, executeTestPlan } = props;
  const LAST_FORM_NUMBER = 3;
  const initialValues = {
    testPlanName: '',
    cronSchedule: '',
    testPlanGuid: '',
    timezone: '',
    tests: [],
    tagLists: [],
    isActiveSchedule: false,
    notificationEmailStatus: ['Failed', 'Inconclusive', 'Passed'],
    notificationEmail: '',
    isActiveEmailSubscription: false,
  };
  const {
    testPlanGuid,
    testPlanName,
    cronSchedule,
    tags,
    isActiveSchedule,
    timezone,
    notificationEmailStatus,
    notificationEmail,
  } = props.testPlanData;
  const [formNumber, setFormNumber] = useState(1);
  const [isDetailsSubmitted, setDetailsSubmitted] = useState(false);
  const [isDetailsSubmitting, setDetailsSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseError, setResponseError] = useState('');
  const [testPlanToExecute, setTestPlanToExecute] = useState(null);

  const { data: testCasesForTestPlan } = useGetTestCase(!!testPlanGuid, {
    testPlanGuid: testPlanGuid,
    shouldIncludeTaggedTestCases: false,
  });

  const { data: tagLists } = useGetTag(true, 'all');
  const { data: testCases, isLoading: testCasesIsLoading } = useGetTestCase(true, 'all');
  const createMutation = useCreateTestPlan();
  const updateMutation = useUpdateTestPlan();

  const setEditTestPlanValues = () => {
    let value = initialValues;
    if (testPlanGuid && props.actionType === 'EDIT_FORM') {
      value = {
        testPlanGuid: testPlanGuid,
        testPlanName: testPlanName,
        cronSchedule: cronSchedule || '',
        tests: testCasesForTestPlan,
        tags: tags && tags.length ? JSON.parse(tags).map((tg) => ({ tagValue: tg.tag, tagId: tg.tagId })) : [],

        isActiveSchedule: isActiveSchedule,
        timezone: nullIfEmpty(timezone) ?? getDefaultTimezone(),
        notificationEmailStatus:
          notificationEmailStatus && notificationEmailStatus.length
            ? // convert object array to string array
              JSON.parse(notificationEmailStatus).map((item) => item.NotificationStatus)
            : ['Failed', 'Inconclusive', 'Passed'],
        notificationEmail: notificationEmail,
        isActiveEmailSubscription: !!notificationEmail,
      };
    }
    return value;
  };

  const [values, setValues] = useState(setEditTestPlanValues());

  useEffect(() => {
    setValues({ ...values, tests: testCasesForTestPlan });
    // eslint-disable-next-line
  }, [testCasesForTestPlan]);

  const submitForm = async (values) => {
    try {
      setDetailsSubmitting(true);
      const {
        testPlanName,
        cronSchedule,
        tests,
        tags,
        isActiveSchedule,
        timezone,
        notificationEmailStatus,
        notificationEmail,
      } = values;
      const testCaseGuidList = getIdList(tests, 'testCaseGuid') ?? [];
      const data = {
        testPlanName,
        testCaseGuids: testCaseGuidList,
        cronSchedule,
        tagIds: tags?.map((tag) => tag.tagId),
        isActiveSchedule,
        timezone,
        notificationEmailStatus,
        notificationEmail: notificationEmail?.replace(',', ';'),
      };
      if (testPlanGuid) {
        const formData = { testPlanGuid, ...data, testCaseGuids: testCaseGuidList };
        await updateMutation.mutateAsync({ ...formData });

        resetPlanCaseData();
        setTestPlanToExecute(testPlanGuid);
      } else {
        const resp = await createMutation.mutateAsync({ ...data });

        setTestPlanToExecute(resp.data.testPlanGuid);
      }
      setResponseStatus('success');
    } catch (error) {
      setResponseStatus('error');
      console.error(error);
      setResponseError(getResponseErrorMsg(error.response));
    } finally {
      setDetailsSubmitted(true);
      setDetailsSubmitting(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (formNumber === LAST_FORM_NUMBER) {
      submitForm(values);
      return;
    }
    actions.setTouched({});
    actions.setSubmitting(false);
    setFormNumber(formNumber + 1);
  };

  const handleBack = async () => {
    setFormNumber(formNumber - 1);
    setDetailsSubmitted(false);
    setResponseStatus('');
    setResponseError('');
  };

  const handleExecuteTestPlan = () => {
    executeTestPlan({ testPlanGuid: testPlanToExecute });
    setTestPlanToExecute(null);
  };

  return (
    <Formik
      validationSchema={createTestPlanSchema[formNumber - 1]}
      enableReinitialize
      initialValues={values}
      setValues={setValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <CreateTestPlanForm
          actionType={actionType}
          handleBack={handleBack}
          testCases={testCases}
          testCasesIsLoading={testCasesIsLoading}
          responseStatus={responseStatus}
          responseError={responseError}
          isDetailsSubmitted={isDetailsSubmitted}
          isDetailsSubmitting={isDetailsSubmitting}
          toggleForm={props.toggleForm}
          formNumber={formNumber}
          LAST_FORM_NUMBER={LAST_FORM_NUMBER}
          {...formikProps}
          tagList={tagLists}
          executeTestPlan={handleExecuteTestPlan}
        />
      )}
    </Formik>
  );
}

export default CreateTestPlan;

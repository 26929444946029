import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { mapConnections } from '../../api/mappings/connection';
import { useDeleteConnection, useGetConnections } from '../../api/queries/connection';
import { useGetConnectionTypes } from '../../api/queries/connectionType';
import Header from '../../components/Header';
import SearchFilter from '../../components/SearchFilter';
import SideDrawer from '../../components/SideDrawer';
import { CREATE_FORM, EDIT_FORM, VIEW_DETAILS } from '../../constants/sidedrawer';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import withFormInteraction from '../../hocs/withFormInteraction';
import withSnackbar from '../../hocs/withSnackbar';
import { getSideDrawerHeading } from '../../utils/sidedrawer';
import ConnectionList from './ConnectionList';
import ConnectionView from './ConnectionView';
import CreateConnection from './CreateConnection';
import ConfirmationModal from '../../components/ConfirmationModal';

function Connections(props) {
  const { formOpen, toggleForm, actionType, openSnackbar } = props;
  const { connectionGuidUrl } = useParams();

  const [connectionNameFilter, setConnectionNameFilter] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteConnectionGuid, setDeleteConnectionGuid] = useState(null);
  const [isReloading, setIsReloading] = useState(false);

  const navigate = useNavigate();

  // Get connection type data
  const {
    data: connectionTypes,
    isLoading: connectionTypesIsLoading,
    isError: connectionTypesIsError,
  } = useGetConnectionTypes();

  // Get connections
  const {
    data: connectionsData,
    isLoading: connectionsIsLoading,
    isError: connectionsIsError,
    refetch: refetchConnections,
  } = useGetConnections(true, 'all', connectionNameFilter);

  const reload = () => {
    setIsReloading(true);
    refetchConnections().then(() => {
      setIsReloading(false);
    });
  };

  const { data: keyVaultConnections } = useGetConnections(true, 'all', null, 'Key Vault');

  const { mutate: deleteConnectionHook } = useDeleteConnection();

  const isLoading = connectionTypesIsLoading || connectionsIsLoading;
  const isError = connectionTypesIsError || connectionsIsError;

  // join together (we use the caching of react-query so dont need to use state)
  const connections = mapConnections(connectionsData);

  useEffect(() => {
    if (isError) {
      openSnackbar('Error returning report data', SNACKBAR_ERROR);
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if (connectionGuidUrl && connectionsIsLoading === false) {
      const foundTestCase = connectionsData.find((x) => x.reportGuid === connectionGuidUrl.toUpperCase());

      if (foundTestCase) {
        setConnectionData(foundTestCase);
        toggleForm(VIEW_DETAILS);
      } else if (connectionGuidUrl === 'add') {
        toggleForm(CREATE_FORM);
      } else {
        navigate('/connection');
      }
    }
    // eslint-disable-next-line
  }, [connectionsData, connectionsIsLoading]);

  const [connectionData, setConnectionData] = useState({});

  const setFilterConnections = (data) => {
    setConnectionNameFilter(data);
  };

  const selectConnection = (index, newActionType) => {
    if (formOpen && actionType !== VIEW_DETAILS) {
      return;
    }
    setConnectionData(connections[index]);
    if (formOpen) {
      return;
    }
    toggleForm(newActionType);
  };

  const resetConnectionData = () => {
    setConnectionData({});
  };

  const searchFilterProps = {
    searchProps: {
      placeholder: 'Search connections',
      list: connections,
      searchKey: 'connectionName',
      setFilterListHook: setFilterConnections,
      returnValue: true,
    },
  };

  const getSideDrawerContent = () => {
    switch (actionType) {
      case VIEW_DETAILS:
        return (
          <ConnectionView
            connectionData={connectionData}
            toggleForm={toggleForm}
            toggleDeleteModal={toggleDeleteModal}
          />
        );
      case EDIT_FORM:
      case CREATE_FORM:
        return (
          <CreateConnection
            actionType={actionType}
            toggleForm={toggleForm}
            connectionData={connectionData}
            connections={connections}
            resetConnectionData={resetConnectionData}
            connectionTypes={connectionTypes}
          />
        );
      default:
        return null;
    }
  };

  const toggleDeleteModal = (connectionGuid) => {
    if (connectionGuid) {
      setDeleteConnectionGuid({ connectionGuid, closeForm: false });
    } else if (connectionData?.connectionGuid) {
      setDeleteConnectionGuid({ connectionGuid: connectionData?.connectionGuid, closeForm: true });
    }
    setDeleteModalOpen(!deleteModalOpen);
  };

  const confirmDelete = () => {
    if (!deleteConnectionGuid?.connectionGuid) {
      openSnackbar('Unknown Error deleting connection', SNACKBAR_ERROR);
      return;
    }
    deleteConnectionHook(deleteConnectionGuid.connectionGuid);
    toggleDeleteModal();
    if (deleteConnectionGuid.closeForm) toggleForm();
  };

  return (
    <Box className="page-details">
      <Header
        loading={isLoading}
        title="Connections"
        buttonText="Add Connection"
        toggleForm={() => {
          toggleForm();
          resetConnectionData();
        }}
        buttonDisabled={formOpen}
        helpText="Connections are much like connection strings, which define the connection information needed for PowerTester to connect to Power BI and external sources."
        refreshAction={reload}
      />
      <ConfirmationModal
        title="Delete Test case"
        modalOpen={deleteModalOpen}
        confirm={confirmDelete}
        cancel={toggleDeleteModal}
        confirmationButtonText={'Delete'}
        msg={'Are you sure you want to delete?'}
      />

      <Box px={4} className="page-details__list-container" display="flex" pb={4} flexDirection="row">
        <Box className="page-details-container" pr={3} flex={1}>
          <SearchFilter {...searchFilterProps} />
          <ConnectionList
            actionType={actionType}
            selectConnection={selectConnection}
            connections={connections ?? []}
            toggleDeleteModal={toggleDeleteModal}
            loading={(isLoading || isReloading) && !isError}
            formOpen={formOpen}
            keyVaultConnections={keyVaultConnections}
          />
        </Box>
        <SideDrawer
          toggleForm={toggleForm}
          formOpen={formOpen}
          title={getSideDrawerHeading('CONNECTION', actionType, connectionData['connectionName'])}
        >
          {getSideDrawerContent()}
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withSnackbar(withFormInteraction(Connections));

import http from '../../services/http';
import { POWERBI_REPORTS } from '../../constants/api';

// Note these API calls have to be POST as we need to include a body

// Get Report details
export const getPowerBIReports = (body) => http.post(POWERBI_REPORTS, body).then((res) => res.data);

// Get Page details
export const getPowerBIPages = (body, powerBIReportGuid) =>
  http.post(`${POWERBI_REPORTS}/${powerBIReportGuid}/page`, body).then((res) => res.data);

export const getPowerBIPage = (body, powerBIReportGuid, pageGuid) =>
  http.post(`${POWERBI_REPORTS}/${powerBIReportGuid}/page/${pageGuid}`, body).then((res) => res.data);

// Get Visual details
export const getPowerBIReportVisuals = (body, powerBIReportGuid) =>
  http.post(`${POWERBI_REPORTS}/${powerBIReportGuid}/visuals`, body).then((res) => res.data);

export const getPowerBIVisualQuery = (body, powerBIReportGuid, pageGuid, visualGuid) =>
  http
    .post(`${POWERBI_REPORTS}/${powerBIReportGuid}/page/${pageGuid}/visuals/${visualGuid}/query`, body)
    .then((res) => res.data);

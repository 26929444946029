import AzureSQLDatabaseIcon from '../assets/images/connectionTypes/azureSQLDatabase.png';
import StorageAccountIcon from '../assets/images/connectionTypes/storageAccount.png';
import Postgres from '../assets/images/connectionTypes/postgres.png';
import SynapseDedicatedPoolcon from '../assets/images/connectionTypes/synapse.png';
import SnowflakeIcon from '../assets/images/connectionTypes/snowflake.png';
import FabricSourceIcon from '../assets/images/connectionTypes/fabric.png';
import PowerBIIcon from '../assets/images/connectionTypes/powerBI.png';
import FabricPowerBIIcon from '../assets/images/connectionTypes/powerBI.png';

import GenericIcon from '../assets/images/connectionTypes/generic.png';

export const CONNECTION_TYPES = [
  {
    name: 'Azure SQL Database',
    icon: AzureSQLDatabaseIcon,
  },
  {
    name: 'Azure Storage Account',
    icon: StorageAccountIcon,
  },
  {
    name: 'Postgres',
    icon: Postgres,
  },
  {
    name: 'Synapse dedicated pool',
    icon: SynapseDedicatedPoolcon,
  },
  {
    name: 'Snowflake',
    icon: SnowflakeIcon,
  },
  {
    name: 'Microsoft Fabric',
    icon: FabricSourceIcon,
  },
  {
    name: 'Power BI',
    icon: PowerBIIcon,
  },
  {
    name: 'Power BI - Fabric',
    icon: FabricPowerBIIcon,
  },
];

export const CONNECTION_TYPE_GENERIC = {
  name: 'Generic',
  icon: GenericIcon,
};

import { Box, Stack, Link } from '@mui/material';
import React from 'react';

import FormTextField from '../../../../components/FormTextField';
import Autocomplete from '../../../../components/Autocomplete';
import FieldHelpText from '../../../../components/FieldHelpText';

function AltTextForm(props) {
  const {
    setFieldValue,
    setFieldTouched,
    reports,
    reportsIsLoading,
    helpText,
    report,
    errors,
    touched,
    page,
    altText,
    powerBIReportPages,
    powerBIPagesIsLoading,
  } = props;

  const onTextChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  return (
    <React.Fragment>
      <Stack spacing={1} mb={2}>
        <span>
          PowerTester can use visual alt-text to identify the visualisation, providing it is unique for every
          visualisation on that report page.
        </span>
        <Link href="https://wild-data-solutions.outseta.com/support/kb/articles/ZmNZ5pm2/alt-text" target="_blank">
          View more on Alt-Text in PowerTester
        </Link>
      </Stack>
      <Stack direction={'column'} spacing={4}>
        <Box className="form-field-container">
          <Autocomplete
            loading={reportsIsLoading}
            name="report"
            setFieldValue={setFieldValue}
            options={reports}
            label={'reportName'}
            value={'reportGuid'}
            selectedItem={report}
            inputLabel={'Select report'}
          />
          {errors.report && touched.report && (
            <Box color="error.main" className="form-field-error-text">
              {errors.report}
            </Box>
          )}
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            loading={powerBIPagesIsLoading}
            name="page"
            setFieldValue={setFieldValue}
            options={powerBIReportPages}
            label={'pageDisplayName'}
            value={'pageGuid'}
            selectedItem={page}
            inputLabel={'Select report page'}
          />
          {errors.page && touched.page && (
            <Box color="error.main" className="form-field-error-text">
              {errors.page}
            </Box>
          )}
        </Box>

        <FormTextField
          id="altText"
          label="Alt Text"
          name="altText"
          fullWidth
          multiline
          rows={4}
          value={altText}
          variant="outlined"
          onChange={(event) => {
            onTextChange('altText', event.target.value);
          }}
          error={touched.altText && Boolean(errors.altText)}
          InputProps={{
            endAdornment: (
              <FieldHelpText
                content={helpText.testCase.altText.HELP_TEXT}
                linkText={helpText.testCase.altText.LINK_TEXT}
                link={helpText.testCase.altText.LINK_URL}
              />
            ),
          }}
          touched={touched}
          errors={errors}
        />
      </Stack>
    </React.Fragment>
  );
}

export default AltTextForm;

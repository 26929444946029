import React, { useState, forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as ExternalLink } from '@mui/material';
import { Link } from 'react-router-dom';

import StatusBadge from '../../StatusBadge';
import { Box } from '@mui/material';

const Collapseable = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const { status, message, subtitle, linkText, linkTo, isExtenalLink } = props;

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const getLink = () => {
    if (!linkText || !linkTo) return;

    if (isExtenalLink) {
      return (
        <ExternalLink href={linkTo} className="link" target="_blank">
          {linkText}
        </ExternalLink>
      );
    } else {
      return (
        <Link to={linkTo} className="link">
          {linkText}
        </Link>
      );
    }
  };

  return (
    <SnackbarContent ref={ref} className="snackbar-root">
      <Card className={`card  ${status && 'status-' + status}`}>
        <CardActions classes={{ root: 'actionRoot' }}>
          <Typography variant="body2" className={'typography'}>
            {message}
          </Typography>
          <div className={'icons'}>
            <IconButton
              aria-label="Show more"
              size="small"
              className={`expand ${expanded && 'expandOpen'}`}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton size="small" className={'expand'} onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={'paper'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignContent={'flex-start'}
              mb={1}
            >
              {status && (
                <Box width="30px">
                  <StatusBadge status={status} showText={false} />
                </Box>
              )}
              <Typography variant="subtitle2" sx={{ color: '#000', display: 'block', fontWeight: '500' }}>
                {subtitle}
              </Typography>
            </Box>
            {getLink()}
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default Collapseable;

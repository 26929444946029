export const mapReportData = (reportData, visuals, connections) => {
  if (!reportData) {
    return;
  }
  // Find connection matching report Data
  let connectionData;
  if (connections && reportData.connectionGuid) {
    connectionData = connections.find((conn) => conn?.connectionGuid === reportData.connectionGuid);
  }

  const newReportData = {
    reportName: reportData.reportName,
    reportGuid: reportData.reportGuid,
    connection: connectionData,
    powerBIReport: {
      reportGuid: reportData.powerBIReportGuid,
    },
    visuals: visuals,
  };

  return newReportData;
};

export const mapReportVisuals = (visualData) => {
  if (!visualData) {
    return;
  }

  const visualArray = visualData.map((obj) => {
    return {
      visualGuid: obj.visualGuid,
      visualName: obj.visualName,
      customName: obj.customName,
      visualType: obj.visualType,
      pageDisplayName: obj.pageDisplayName,
      pageGuid: obj.pageGuid,
      pageOrder: obj.pageOrder,
      uniqueGuid: obj.uniqueGuid,
    };
  });

  return visualArray;
};

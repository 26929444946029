import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index';

import * as notification from '../endpoints/notification';

const allNotificationsKey = ['notifications', 'all'];

// Queries
export const useGetNotifications = (enabled) => {
  return useQuery({
    queryKey: allNotificationsKey,
    queryFn: () => notification.get(),
    enabled: enabled,
  });
};

// Mutations
export const useUpdateNotifications = () => {
  return useMutation({
    mutationFn: (rawData) => {
      const { notificationMessageId, ...body } = rawData;
      return notification.update(body, notificationMessageId);
    },

    onSuccess: (data, updatedNotification) => {
      const { notificationMessageId, notificationStatus } = updatedNotification;

      if (notificationMessageId) {
        queryClient.setQueryData(allNotificationsKey, (old) =>
          old.filter((t) => t.NotificationMessageId !== updatedNotification.notificationMessageId),
        );
        // filter state to remove all messages with that status, update count so that counts remaining
      } else {
        if (notificationStatus === 'ALL') {
          // If notificationStatus is "ALL", remove all notifications
          queryClient.setQueryData(allNotificationsKey, []);
        } else {
          queryClient.setQueryData(allNotificationsKey, (old) =>
            old.filter((t) => t.NotificationStatus.toUpperCase() !== notificationStatus),
          );
        }
      }
    },
  });
};

import http from '../../services/http';
import { TEST_PLANS } from '../../constants/api';

export const get = (params) => http.get(TEST_PLANS, params, { redirect: true }).then((res) => res.data);

export const getTestCases = (params, testPlanGuid) =>
  http.get(`${TEST_PLANS}/${testPlanGuid}/testcases`, params, { redirect: true }).then((res) => res.data);

export const summary = (params, testPlanGuid) =>
  http.get(`${TEST_PLANS}/${testPlanGuid}/summary`, params, { redirect: true }).then((res) => res.data);

export const create = (body) => http.post(TEST_PLANS, body);
export const execute = (body, testPlanGuid) => http.post(`${TEST_PLANS}/${testPlanGuid}/execute`, body);

export const update = (body, testPlanGuid) => http.put(`${TEST_PLANS}/${testPlanGuid}`, body);

export const remove = (testPlanGuid) => http.delete(`${TEST_PLANS}/${testPlanGuid}`);

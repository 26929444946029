import React, { useState } from 'react';
import { CREATE_FORM } from '../constants/sidedrawer';

const withFormInteraction = (WrappedComponent) => {
  function WithFormInteraction(props) {
    const [formOpen, setFormOpen] = useState(false);
    const [splitScreen, setSplitScreen] = useState(false);
    const [actionType, setActionType] = useState(CREATE_FORM);

    const [isFormClosing, setIsFormClosing] = useState(false);

    const toggleForm = (actionType, shouldOpen) => {
      if (shouldOpen === 'dontopen') {
        setFormOpen(false);
      } else if (shouldOpen === 'keepopen') {
        setFormOpen(true);
      } else {
        setFormOpen(!formOpen);

        setIsFormClosing(true); // Set isFormClosing to true when closing the form
        // After 500ms (0.5 seconds), set isFormClosing back to false
        setTimeout(() => {
          setIsFormClosing(false);
        }, 400);
      }
      setActionType(actionType || CREATE_FORM);
    };
    const toggleSplitScreen = (value) => {
      setSplitScreen(value);
    };
    return (
      <WrappedComponent
        actionType={actionType}
        formOpen={formOpen}
        toggleForm={toggleForm}
        isFormClosing={isFormClosing}
        {...props}
        splitScreen={splitScreen}
        toggleSplitScreen={toggleSplitScreen}
      />
    );
  }
  return WithFormInteraction;
};

export default withFormInteraction;

import React from 'react';
import { CheckCircle, Cancel, DataUsage, WarningAmber, Info } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';

export default function StatusBadge(props) {
  const { status, showText, chip } = props;

  const getIcon = () => {
    if (!status) return null;

    const colour = getIconColor(status);

    switch (status) {
      case 'Passed':
        return <CheckCircle color={colour} />;
      case 'Queued':
      case 'In progress':
        return <DataUsage className="rotate" color={colour} />;
      case 'Warning':
        return <WarningAmber color={colour} />;
      case 'info':
      case 'Info':
      case 'Information':
      case 'information':
        return <Info color={colour} />;
      case 'Failed':
      case 'Inconclusive':
      case 'Skipped':
      default:
        return <Cancel color={colour} />;
    }
  };

  const getIconColor = (status) => {
    switch (status) {
      case 'Failed':
        return 'error';
      case 'Passed':
        return 'success';
      case 'Queued':
      case 'In progress':
        return 'inProgress';
      case 'info':
      case 'Info':
      case 'Information':
      case 'information':
        return 'info';
      case 'Warning':
      case 'Inconclusive':
      case 'Skipped':
      default:
        return 'warning';
    }
  };

  if (chip) {
    return <Chip label={status} color={getIconColor(status)} />;
  }

  if (!showText) {
    return getIcon(status);
  }

  return (
    <Box className="status table" mr={2}>
      {getIcon(status)}
      {<span className="status text">{status}</span>}
    </Box>
  );
}

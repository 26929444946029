import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function DashboardCard(props) {
  const { route, value, description, first } = props;

  return (
    <Link to={route} className={`dashboard-chart ${first ? 'first' : ''}`}>
      <Box
        height="100%"
        width="100%"
        boxShadow={1}
        alignItems="center"
        justifyContent="center"
        px={4}
        py={1}
        display="flex"
        flexDirection="column"
      >
        <h1>{value}</h1>
        <h2>{description}</h2>
      </Box>
    </Link>
  );
}

export default DashboardCard;

import http from '../../services/http';
import { REPORTS } from '../../constants/api';

export const get = (params) => http.get(REPORTS, params, { redirect: true }).then((res) => res.data);
export const getVisuals = (reportGuid) =>
  http.get(`${REPORTS}/${reportGuid}/visuals`, null, { redirect: true }).then((res) => res.data);

export const getCombinedVisuals = (body, reportGuid) =>
  http.post(`${REPORTS}/${reportGuid}/combinedVisuals`, body, { redirect: true }).then((res) => res.data);

export const getTestCases = (reportGuid) =>
  http.get(`${REPORTS}/${reportGuid}/testcases`, null, { redirect: true }).then((res) => res.data);

export const create = (body) => http.post(REPORTS, body).then((res) => res.data);
export const update = (body, reportGuid) => http.put(`${REPORTS}/${reportGuid}`, body).then((res) => res.data);
export const updateVisual = (body, reportGuid, visualGuid) =>
  http.put(`${REPORTS}/${reportGuid}/visuals/${visualGuid}`, body);

export const remove = (reportGuid) => http.delete(`${REPORTS}/${reportGuid}`);

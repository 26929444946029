import http from '../../services/http';
import { CONNECTIONS, TEST_CONNECTION } from '../../constants/api';

export const get = (params) => http.get(CONNECTIONS, params, { redirect: true }).then((res) => res.data);
export const getTestCases = (connectionGuid) =>
  http.get(`${CONNECTIONS}/${connectionGuid}/testcases`, null, { redirect: true }).then((res) => res.data);

export const create = (body) => http.post(CONNECTIONS, body).then((res) => res.data);
export const update = (body, connectionGuid) =>
  http.put(`${CONNECTIONS}/${connectionGuid}`, body).then((res) => res.data);

export const remove = (connectionGuid) => http.delete(`${CONNECTIONS}/${connectionGuid}`);

export const authorizeConnection = (body, connectionGuid) =>
  http.post(`${CONNECTIONS}/${connectionGuid}/authorize`, body).then((res) => res.data);

export const summary = (connectionGuid) =>
  http.get(`${CONNECTIONS}/${connectionGuid}/summary`, null, { redirect: true }).then((res) => res.data);

// Test connections - note its in its own controller in API
export const testKeyVaultConnection = async (body) =>
  http.post(TEST_CONNECTION + '/key-vault', body, { timeout: 15000 });

export const testPowerBIConnection = async (body) => http.post(TEST_CONNECTION + '/power-bi', body, { timeout: 15000 });

export const testDatabaseConnection = async (body) =>
  http.post(TEST_CONNECTION + '/database', body, { timeout: 15000 }).then((res) => res.data);

import { Box } from '@mui/material';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import SearchFilter from '../../components/SearchFilter';
import SideDrawer from '../../components/SideDrawer';
import { VIEW_DETAILS } from '../../constants/sidedrawer';
import withFormInteraction from '../../hocs/withFormInteraction';
import withNotifiy from '../../hocs/withNotifiy';
import { getSideDrawerHeading } from '../../utils/sidedrawer';
import TestCaseView from './TestCaseView';
import TestRunList from './TestRunList';
import TestRunView from './TestRunView';
import { useGetTestRun, useGetTestRunSummary } from '../../api/queries/testRun';
import { SignalRContext } from '../../services/signalR';
import StatusBadge from '../../components/StatusBadge';
import { useGetTestCaseSummary } from '../../api/queries/testRun';

function TestRun(props) {
  const { formOpen, toggleForm, actionType, toggleSplitScreen, splitScreen } = props;
  const [testRunData, setTestRunData] = useState({});
  const [selectedTestRun, setSelectedTestRun] = useState(null);
  const [splitScreenData, setSplitScreenData] = useState({});
  const [testPlanNameFilter, setTestPlanNameFilter] = useState('');
  const [isReloading, setIsReloading] = useState(false);

  const formOpenRef = useRef(formOpen); // Initialize the useRef with the current value of formOpen

  const { testRunGuidUrl, testCaseGuidUrl } = useParams();
  const { startNotify, stopNotify } = useContext(SignalRContext);

  const navigate = useNavigate();

  // Capture the state of formOpen so that we can reference it in the reload function correctly
  useEffect(() => {
    formOpenRef.current = formOpen;
  }, [formOpen]);

  const {
    data: testRuns,
    isLoading: testRunsIsLoading,
    refetch: refetchTestRuns,
  } = useGetTestRun(true, 'all', testPlanNameFilter);

  const { data: testCaseSummary } = useGetTestCaseSummary(
    !!splitScreenData?.testCaseGuid && !!testRunData?.testRunGuid,
    {
      testRunGuid: testRunData?.testRunGuid,
      testCaseGuid: splitScreenData?.testCaseGuid,
    },
  );

  useEffect(() => {
    if (testRuns && testRuns.length > 0 && selectedTestRun) {
      setTestRunData(testRuns.find((x) => x.testRunGuid === selectedTestRun));
    }
  }, [selectedTestRun, testRuns]);

  useEffect(() => {
    reload(false);
  }, []);

  const {
    data: testRunSummary,
    isLoading: testRunSummaryIsLoading,
    refetch: refetchTestRunSummary,
  } = useGetTestRunSummary(!!testRunData?.testRunGuid, testRunData?.testRunGuid);

  const reload = (showProgress) => {
    if (showProgress) setIsReloading(true);

    const currentFormOpen = formOpenRef.current; // Access the current value of formOpen from the ref

    const promises = [refetchTestRuns()];

    // If form is open also refresh the test run summary
    if (currentFormOpen) {
      promises.push(refetchTestRunSummary());
    }

    Promise.all(promises)
      .then(() => {
        if (showProgress) setIsReloading(false);
      })
      .catch((error) => {
        // Handle any errors that might occur during the fetch
        if (showProgress) setIsReloading(false);
        console.error('Error fetching data:', error);
      });
  };

  const setFilterTestRuns = (data) => {
    setTestPlanNameFilter(data);
  };

  useEffect(() => {
    if (testRunGuidUrl) {
      if (!formOpen) toggleForm(VIEW_DETAILS);
    }
    // eslint-disable-next-line
  }, [testRunGuidUrl]);

  const notificationAction = (msg, source) => {
    if (!msg) return;
    if (source !== 'testRun') return;

    reload(false);
  };

  useEffect(() => {
    startNotify('StartTestRun', notificationAction, 'testRun');
    startNotify('FinishTestRun', notificationAction, 'testRun');
    startNotify('FinishTestCase', notificationAction, 'testRun');

    return () => {
      stopNotify('StartTestRun');
      stopNotify('FinishTestRun');
      stopNotify('FinishTestCase');
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (testRunGuidUrl && testRunsIsLoading === false) {
      setSelectedTestRun(testRunGuidUrl.toUpperCase());
    }
    // eslint-disable-next-line
  }, [testRuns, testRunsIsLoading, testRunGuidUrl]);

  const searchFilterProps = {
    searchProps: {
      placeholder: 'Search test runs',
      list: testRuns,
      searchKey: 'TestPlanName',
      setFilterListHook: setFilterTestRuns,
      returnValue: true,
    },
  };

  const togglePage = (page) => {
    if (page !== '/testruns') navigate(page);
  };

  const switchOptions = {
    list: [
      { label: 'Test Plans', action: () => togglePage('/testplans') },
      { label: 'Test Runs', action: () => togglePage('/testruns') },
    ],
    activeSwitch: 'Test Runs',
  };

  const resetTestRunData = () => {
    setTestRunData({});
    setSelectedTestRun(null);
  };

  const selectTestRun = (testRunGuid, newActionType) => {
    if (formOpen && actionType !== VIEW_DETAILS) {
      return;
    }
    setSelectedTestRun(testRunGuid);

    if (formOpen) {
      return;
    }

    toggleForm(newActionType);
  };

  const resetSplitScreenData = () => {
    setSplitScreenData({});
  };

  const openSplitScreen = (obj) => {
    toggleSplitScreen(true);
    setSplitScreenData(obj);
  };

  const getSubTitle = (page) => {
    if (actionType === VIEW_DETAILS) {
      if (page === 'testRun') {
        if (!testRunData || !testRunData?.status) return;
        return <StatusBadge status={testRunData.status} showText={true} />;
      } else if (page === 'testCase') {
        if (!testCaseSummary || !testCaseSummary.resultOutcomeDescription) return;
        return <StatusBadge status={testCaseSummary.resultOutcomeDescription} showText={true} />;
      }
    }
  };

  return (
    <Box className="page-details">
      <Header
        title={'Test Runs'}
        buttonText="Add Test Plan"
        toggleForm={toggleForm}
        switchOptions={switchOptions}
        buttonDisabled
        refreshAction={() => reload(true)}
      />
      <Box px={4} className={'page-details__list-container '} display="flex" pb={4} flexDirection="row">
        <Box className={'page-details-container ' + (splitScreen ? 'split-screen-open' : '')} pr={3} flex={1}>
          <SearchFilter {...searchFilterProps} />
          <TestRunList
            smallScreen={formOpen}
            testRuns={testRuns}
            selectTestRun={selectTestRun}
            isLoading={(testRunsIsLoading || isReloading) ?? true}
          />
        </Box>
        <SideDrawer
          toggleForm={() => {
            toggleForm();
            resetTestRunData();
          }}
          formOpen={formOpen}
          title={getSideDrawerHeading(
            'TEST_PLAN',
            actionType,
            testRunData?.testPlanName || testRunSummary?.testPlanName,
          )}
          subtitle={getSubTitle('testRun')}
          isSplitScreen={splitScreen}
        >
          <TestRunView
            viewTestCase={openSplitScreen}
            testRunGuid={testRunGuidUrl ? testRunGuidUrl : testRunData?.testRunGuid}
            testRunSummary={testRunSummary}
            testCaseGuid={testCaseGuidUrl}
            isLoading={testRunSummaryIsLoading}
          />
        </SideDrawer>

        <SideDrawer
          toggleForm={() => {
            resetSplitScreenData();
            toggleSplitScreen(false);
          }}
          formOpen={splitScreen}
          title={splitScreenData?.testCaseName}
          subtitle={getSubTitle('testCase')}
        >
          <TestCaseView
            testCaseGuid={splitScreenData?.testCaseGuid}
            testRunGuid={testRunData?.testRunGuid}
            testRunDate={splitScreenData?.TestRunDate}
          />
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withNotifiy(withFormInteraction(TestRun));

import React from 'react';
import Cron, { HEADER } from 'react-cron-generator';

function CronSchedule(props) {
  const { value, onChange, showResultText, showResultCron } = props;

  const options = {
    headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.DAILY, HEADER.HOURLY, HEADER.CUSTOM],
  };

  return (
    <div className="cron-parent">
      <Cron
        onChange={onChange}
        value={value}
        showResultText={showResultText ?? false}
        showResultCron={showResultCron ?? false}
        options={options}
      />
    </div>
  );
}

export default CronSchedule;

import { Box, CircularProgress, Link, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpIcon from '@mui/icons-material/Help';

import { UNSUPPORTED_VISUALS } from '../../../../constants/visuals';
import CheckboxTreeView from '../../../../components/CheckboxTreeView';
import { getChildByGuid } from '../../../../utils/treeview';
import { useGetPowerBIReportVisuals } from '../../../../api/queries/powerBI';
import { mapVisualTypeTitle } from '../../../../utils/visual';

function VisualsForm(props) {
  const { visuals, errors, touched, powerBIReport, setFieldValue, reportName, connection } = props;

  const [powerBIVisualsData, setPowerBIVisualsData] = useState([]);
  const [powerBIVisualsDataFlatterned, setPowerBIVisualsDataFlatterned] = useState([]);
  const [powerBIVisualsExpanded, setPowerBIVisualsExpanded] = useState([]);
  const [defaultSelectAll, setDefaultSelectAll] = useState(true);
  const [selectedVisuals, setSelectedVisuals] = useState();

  const { data: powerBIReportVisuals, isLoading: powerBIReportVisualsIsLoading } = useGetPowerBIReportVisuals(
    !!powerBIReport,
    false, // transform
    connection,
    powerBIReport.reportGuid,
    true, // isRefresh - always refresh the display names on edit
  );

  useEffect(() => {
    if (powerBIReport) {
      if (visuals && visuals.length > 0) {
        setDefaultSelectAll(false);
      }
    }

    // eslint-disable-next-line
  }, [powerBIReport]);

  const selectVisuals = (visuals) => {
    if (!visuals || visuals?.length === 0) return;
    const visualObj = visuals.map((vis) => {
      return {
        uniqueGuid: vis.guid,
        visualGuid: vis.visualGuid,
        visualName: vis.name,
        customName: vis.customName,
        visualType: vis.visualType,
        pageDisplayName: vis.pageDisplayName,
        pageGuid: vis.pageGuid,
        pageOrder: vis.pageOrder,
        objType: vis.objType,
      };
    });

    setFieldValue('visuals', visualObj);
  };

  const getTooltip = (visual) => {
    if (UNSUPPORTED_VISUALS.includes(visual.visualType)) {
      return (
        <Tooltip title="Visual type unsupported">
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      );
    }

    if (!visual.title) {
      return (
        <Tooltip title="This visual has no custom title so it's ID was used instead">
          <Link
            href={'https://wild-data-solutions.outseta.com/support/kb/articles/B9lrR7m8/no-visual-title'}
            target="_blank"
          >
            <HelpIcon />
          </Link>
        </Tooltip>
      );
    }
  };

  const transformPowerBIVisuals = (rawData) => {
    // Transform the data into the tree map expected format
    if (!powerBIReport || !rawData) return null;

    let id = 0;

    let expandedIds = [];

    const transformedObject = {
      id: String(id),
      name: reportName,
      guid: rawData.reportGuid,
      uniqueGuid: rawData.reportGuid,
      children: [],
      objType: 'report',
    };

    expandedIds.push(String(id));
    rawData.pages.forEach((page) => {
      id++;
      const pageObject = {
        id: String(id),
        name: page.pageDisplayName,
        guid: page.pageGuid,
        uniqueGuid: page.pageGuid,
        children: [],
        objType: 'page',
      };

      expandedIds.push(String(id));
      transformedObject.children.push(pageObject);

      page.visuals.forEach((visual) => {
        id++;

        const visualObject = {
          id: String(id),
          name: mapVisualTypeTitle(visual.title, visual.titleSourceId) || visual.visualGuid,
          guid: visual.uniqueGuid,
          visualGuid: visual.visualGuid,
          uniqueGuid: visual.uniqueGuid,
          nameId: !visual.title,
          visualType: visual.visualType,
          objType: 'visual',
          pageDisplayName: page.pageDisplayName,
          pageGuid: page.pageGuid,
          pageOrder: page.pageOrder,
          disabled: UNSUPPORTED_VISUALS.includes(visual.visualType),
          tooltip: getTooltip(visual),
          customName: '',
        };

        pageObject.children.push(visualObject);
      });
    });

    setPowerBIVisualsExpanded(expandedIds);
    return transformedObject;
  };

  useEffect(() => {
    setPowerBIVisualsData(transformPowerBIVisuals(powerBIReportVisuals));

    // eslint-disable-next-line
  }, [powerBIReportVisuals]);

  useEffect(() => {
    setPowerBIVisualsDataFlatterned(getChildByGuid(powerBIVisualsData, powerBIVisualsData?.guid));

    // eslint-disable-next-line
  }, [powerBIVisualsData]);

  useEffect(() => {
    if (visuals && visuals.length > 0 && powerBIVisualsDataFlatterned && powerBIVisualsDataFlatterned.length > 0) {
      const selectedVisuals = powerBIVisualsDataFlatterned.filter((obj1) =>
        visuals.some((obj2) => obj1.uniqueGuid === obj2.uniqueGuid),
      );
      setSelectedVisuals(selectedVisuals);
    }

    // eslint-disable-next-line
  }, [powerBIVisualsDataFlatterned]);

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" mb={2}>
        <span>
          Select the visuals in the report that are in scope for testing.
          <br />
          PowerTester will show the report title when available, or the visual Id when a custom tile has not been added.
        </span>
        <br />
        <Link
          href="https://wild-data-solutions.outseta.com/support/kb/articles/rQVPYkW6/powertester-visuals-guide"
          target="_blank"
        >
          Read our visuals article here
        </Link>
      </Box>

      {powerBIReport && powerBIReport.reportGuid && (
        <Box mb={2} className="form-field-container">
          {powerBIReportVisualsIsLoading ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <CheckboxTreeView
                options={powerBIVisualsData}
                optionsFlattened={powerBIVisualsDataFlatterned}
                defaultExpanded={powerBIVisualsExpanded}
                onSelect={selectVisuals}
                selectedItems={selectedVisuals}
                defaultSelectAll={defaultSelectAll}
              />
              {errors.visuals && touched.visuals && (
                <Box color="error.main" className="form-field-error-text">
                  {errors.visuals}
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

export default VisualsForm;

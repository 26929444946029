import { Box } from '@mui/material';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

function BarChart(props) {
  const { chartData, chartOptions, error, noData } = props;

  return (
    <React.Fragment>
      {!error && !noData ? (
        <div
          style={{
            position: 'relative',
            margin: 'auto',
            width: props.width,
            height: props.height,
          }}
        >
          <Bar data={chartData} options={chartOptions} />
        </div>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" width={props.width} height={props.height}>
          <h2>No data to display</h2>
        </Box>
      )}
    </React.Fragment>
  );
}

export default BarChart;

import { Box, Stack, Collapse, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';

import FormTextField from '../../../../../components/FormTextField';
import SwitchContainer from '../../../../../components/SwitchContainer';
import CheckboxContainer from '../../../../../components/CheckboxContainer';

const FabricDatabaseForm = (props) => {
  const {
    authenticationType,
    dbDetails,

    errors,
    touched,
    setFieldTouched,
    setFieldValue,
  } = props;

  const [advancedOptions, setAdvancedOptions] = useState(Boolean(dbDetails?.connectionString)); // set advancedOptions to true if connection string exists
  const [useLocalGateway, setUselocalGateway] = useState(false);
  const [isConnectionStringPopulated, setIsConnectionStringPopulated] = useState(Boolean(dbDetails?.connectionString));

  useEffect(() => {
    if (dbDetails?.connectionString) {
      setAdvancedOptions(true);
    }

    // eslint-disable-next-line
  }, []);

  const onConnectionstringChange = (name, value) => {
    setIsConnectionStringPopulated(value !== '');
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const onTextChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const embeddedForm = () => {
    switch (authenticationType?.authenticationType) {
      case 'Service principal':
        return SPEmbeddedForm();
      case 'Master User OAuth':
        return SPEmbeddedForm();
      default:
        return null;
    }
  };

  const SPEmbeddedForm = () => {
    return (
      <Stack direction="column" spacing={2}>
        <FormTextField
          disabled={isConnectionStringPopulated}
          className="text-field"
          label="SQL Endpoint connection string"
          variant="outlined"
          fullWidth
          id="serverName"
          name="dbDetails.serverName"
          value={dbDetails?.serverName || ''}
          onChange={(event) => {
            onTextChange('dbDetails.serverName', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="dbDetails"
          size="small"
          dynamicErrorField
          helpText={{
            content: 'Microsft Fabric SQL Endpoint connection string.',
            linkText:
              'Click here for more information view this KB article including where to find the connection string',
            link: 'https://wild-data-solutions.outseta.com/support/kb/articles/496Dd0mX/microsoft-fabric-connections',
          }}
        />
        <FormTextField
          disabled={isConnectionStringPopulated}
          className="text-field"
          label="Database name"
          variant="outlined"
          fullWidth
          id="database"
          name="database"
          value={dbDetails?.database || ''}
          onChange={(event) => {
            onTextChange('dbDetails.database', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="dbDetails"
          size="small"
          dynamicErrorField
        />

        <FormTextField
          disabled={isConnectionStringPopulated}
          className="text-field"
          label="Service Principal App Id"
          variant="outlined"
          fullWidth
          id="servicePrincipalAppId"
          name="servicePrincipalAppId"
          value={dbDetails?.servicePrincipalAppId || ''}
          onChange={(event) => {
            onTextChange('dbDetails.servicePrincipalAppId', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="dbDetails"
          size="small"
          dynamicErrorField
        />
        <FormTextField
          disabled={isConnectionStringPopulated}
          type="password"
          className="text-field"
          label="Service Principal Secret"
          variant="outlined"
          fullWidth
          id="servicePrincipalSecret"
          name="servicePrincipalSecret"
          value={dbDetails?.servicePrincipalSecret || ''}
          onChange={(event) => {
            onTextChange('dbDetails.servicePrincipalSecret', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="dbDetails"
          size="small"
          dynamicErrorField
        />
        <Box mt={3} mb={5} className="form-field-container">
          <SwitchContainer
            label="Show advanced options"
            initialState={advancedOptions}
            handleSwitch={advancedOptionsSwitch}
          />

          <Collapse in={advancedOptions} timeout="auto" unmountOnExit>
            <Stack direction="column" alignItems="flex-end" spacing={2}>
              <TextField
                disabled={useLocalGateway}
                className="text-field"
                label="Connection String"
                variant="outlined"
                fullWidth
                id="connectionString"
                name="dbDetails.connectionString"
                value={dbDetails?.connectionString || ''}
                onChange={(event) => {
                  onConnectionstringChange('dbDetails.connectionString', event.target.value);
                }}
                error={touched.dbDetails?.connectionString && Boolean(errors.dbDetails?.connectionString)}
                size="small"
              />
              {errors.dbDetails?.connectionString && touched.dbDetails?.connectionString && (
                <Box color="error.main" className="form-field-error-text">
                  {errors.dbDetails?.connectionString}
                </Box>
              )}
              <CheckboxContainer label="Use local gateway" initialState={false} handleChecked={localGatewaySwitch} />
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    );
  };

  const advancedOptionsSwitch = (stateOfSwitch) => {
    setAdvancedOptions(stateOfSwitch);
  };

  const localGatewaySwitch = (stateOfSwitch) => {
    setUselocalGateway(stateOfSwitch);
    if (stateOfSwitch) {
      onConnectionstringChange('dbDetails.connectionString', 'PowerTesterProvider=onpremsql');
    } else {
      onConnectionstringChange('dbDetails.connectionString', '');
    }
  };

  return <React.Fragment>{embeddedForm()}</React.Fragment>;
};

export default FabricDatabaseForm;

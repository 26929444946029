import { useFormik } from 'formik';
import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import { getResponseErrorMsg } from '../../../../utils/response';
import { useCreateNewUserInvite } from '../../../../api/queries/setting';
import ResponseCard from '../../../../components/ResponseCard';
import { Box, Divider, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StepperComponent from '../../../../components/Stepper';
import moment from 'moment';
import appConfig from '../../../../configs/appConfig';

function InviteNewUser(props) {
  const { toggleForm } = props;
  const [formNumber, setFormNumber] = useState(1);
  const [isDetailsSubmitted, setDetailsSubmitted] = useState(false);
  const [isDetailsSubmitting, setDetailsSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseError, setResponseError] = useState('');
  const [responseValues, setResponseValues] = useState({ linkUrl: '', accessCode: '' });

  const formik = useFormik({
    initialValues: {
      role: 'Owner',
    },
    // validationSchema: createTestCaseSchema,
    onSubmit: (values, formikBag) => handleSubmit(values, formikBag), // Pass formikBag to handleSubmit
  });

  const createInviteMutation = useCreateNewUserInvite();

  const handleSubmit = async (values, formikBag) => {
    try {
      setDetailsSubmitting(true);

      const resp = await createInviteMutation.mutateAsync({ ...values });

      setResponseValues(resp);
      formikBag.resetForm(); // Reset the form after successful submission
      setResponseStatus('success');
      setFormNumber(formNumber + 1);
    } catch (error) {
      setResponseStatus('error');
      console.error(error);
      setResponseError(getResponseErrorMsg(error.response));
    } finally {
      setDetailsSubmitted(true);
      setDetailsSubmitting(false);
    }
  };

  const getResponseCard = () => {
    const result = [
      { key: 'Link Url', value: appConfig.BASE_UTL + '/invites/' + responseValues.linkUrl },
      { key: 'Access Code', value: responseValues.accessCode },
      { key: 'Expires at', value: moment.utc(responseValues.expiryDateTime).local().format('lll') },
    ];
    return (
      <ResponseCard
        title={!responseError ? 'Invite created' : responseError}
        values={result}
        responseType={responseStatus}
        toggleForm={toggleForm}
      />
    );
  };

  const steps = ['Created invite', 'Share invite'];

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Box display="flex" pr={4} py={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <StepperComponent steps={steps} activeStep={formNumber - 1} />

        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
          {!isDetailsSubmitted && (
            <LoadingButton
              type="submit"
              className="yellow form-button"
              variant="contained"
              loading={isDetailsSubmitting}
            >
              Create
            </LoadingButton>
          )}
        </Box>
      </Box>
      <Divider />
      {!isDetailsSubmitted ? (
        <React.Fragment>
          <Box className="form-field-container" px={4} py={2}>
            <Autocomplete
              disabled
              id="user-role-select"
              options={['Owner', 'Member', 'Viewer']}
              value={formik.values.role}
              onChange={(event, value) => {
                formik.setFieldValue('role', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Role"
                  variant="outlined"
                  error={formik.errors.role && formik.touched.role}
                  helperText="Role based access control not yet implemented"
                />
              )}
            />
            {formik.errors.role && formik.touched.role && (
              <Box color="error.main" className="form-field-error-text dynamic">
                {formik.errors.role}
              </Box>
            )}
          </Box>
        </React.Fragment>
      ) : (
        getResponseCard()
      )}
    </form>
  );
}

export default InviteNewUser;

import React from 'react';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { convertToCSV, exportToFile } from '../../utils/fileDownload';

const ExportCSVButton = (props) => {
  const { data, filename, buttonDisabled } = props;

  const handleExport = () => {
    const csv = convertToCSV(data);

    exportToFile('csv', csv, filename);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleExport}
      startIcon={<FileDownloadIcon />}
      disabled={buttonDisabled}
    >
      Export to CSV
    </Button>
  );
};

export default ExportCSVButton;

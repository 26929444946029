import React, { useState, useEffect } from 'react';
import { Box, Badge, Menu, MenuItem, Tooltip, Avatar, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { Link as ExternalLink } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PersonIcon from '@mui/icons-material/Person';

import { msalInstance } from '../../index';
import Navigation from '../../constants/navigation';
import Image from '../Image';
import { signOut } from '../../services/microsoftAuthentication';
import Tour from '../../services/tour';
import HelpIcon from '../../assets/images/icons/help.svg';
import NotificationIcon from '../../assets/images/icons/notification.svg';
import { logHelpTelemetry } from '../../services/telemetry';

function NavigationDrawer(props) {
  const { openNotification } = props;
  const [routeBase, setRouteBase] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [smallMode, setSmallMode] = useState(false);
  const [avatarInitials, setAvatarInitials] = useState('');
  const location = useLocation();

  const account = msalInstance.getActiveAccount();

  const smallWindow = useMediaQuery('(max-width:828px)');

  const open = Boolean(anchorEl);

  const updateRouteName = () => {
    if (!location.pathname) return; // dont think this can happen but just in case
    const route = location.pathname.replace('/testruns', '/testplans');
    const parts = route.split('/');
    let base = parts.length > 1 ? parts[1] : '';

    if (base === '') {
      base = 'dashboard';
    }

    setRouteBase('/' + base);
  };

  useEffect(() => {
    if (smallWindow) {
      setSmallMode(true);
    } else {
      setSmallMode(false);
    }
  }, [smallWindow]);

  useEffect(() => {
    updateRouteName(location);
  }, [location]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const name = account?.name;

    if (name == null) {
      setAvatarInitials('?');
      return;
    }

    const word_names = name.split(' ');

    let initials = '';

    if (word_names.length === 1) {
      initials = word_names[0].charAt(0); // Return the first letter of the single word
    } else {
      // Extract the first letter from the first two words
      initials = `${word_names[0][0]}${word_names[1][0]}`;
    }

    if (name) setAvatarInitials(initials);
  }, [account]);

  const getUserAccountTooltip = () => {
    return (
      <span>
        Name: {account?.idTokenClaims?.name} <br />
        Email: {account?.idTokenClaims?.emails?.[0]} <br />
        User Id: {account?.idTokenClaims?.extension_UserAccountGuid_DB} <br />
      </span>
    );
  };

  return (
    <React.Fragment>
      <menu className="full-height">
        <Box
          className={`navigation-drawer-container ${smallMode && 'smallMode'} full-height`}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box>
            <ul>
              {Navigation.map((navItem) => {
                return (
                  <li
                    key={navItem.name}
                    className={`navigation-list__item text-center pointer ${
                      routeBase === navItem.route ? 'navigation-list__item--active' : ''
                    }`}
                  >
                    <Tooltip title={smallMode && navItem.name} placement="right" arrow>
                      <Link
                        to={navItem.route}
                        className={navItem.route !== '/' ? 'navigation-list__item__link top' : ''}
                      >
                        <Box>
                          <Image
                            className={`navigation-list__item__image nav_${navItem.name
                              .replace(' ', '_')
                              .toLowerCase()} ${smallMode && 'smallMode'} `}
                            src={navItem.icon}
                            altText={'nav icon'}
                          />
                          <Box className="navigation-list__item--title">{!smallMode && navItem.name}</Box>
                        </Box>
                      </Link>
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
          </Box>

          <Box className="navigation-list__item text-center" pb={3}>
            <Tour />
            {/* Notifications */}
            <Tooltip title={smallMode && 'Notifications'} placement="right" arrow>
              <Box className="navigation-list__item__link pointer bottom" onClick={openNotification}>
                <Box>
                  <Badge color="primary" badgeContent={props.notificationCount} max={10}>
                    <Image
                      className="navigation-list__item__image--small bottom"
                      src={NotificationIcon}
                      altText={'Notifications icon'}
                    />
                  </Badge>
                  {!smallMode && <p className="text-center">Notifications</p>}
                </Box>
              </Box>
            </Tooltip>

            {/* Help */}
            <Tooltip title={smallMode && 'Get help'} placement="right" arrow>
              <ExternalLink
                target="_blank"
                href="https://wild-data-solutions.outseta.com/support/kb"
                rel="noopener noreferrer"
                className="navigation-list__item__link"
              >
                <Box onClick={logHelpTelemetry}>
                  <Image className="navigation-list__item__image--small" src={HelpIcon} altText={'get help'} />
                  {!smallMode && <p className="text-center">Get help</p>}
                </Box>
              </ExternalLink>
            </Tooltip>

            {/* Avatar/Settings */}
            <Tooltip title={getUserAccountTooltip()} placement="right" arrow>
              <Box
                className="navigation-list__item__link pointer bottom"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <Avatar
                  sx={{
                    bgcolor: '#15161E',
                    border: '0.1px solid lightgray',
                    color: '#fff',
                    width: smallMode ? '20px !important;' : '30px;',
                    height: smallMode ? '20px !important;' : '30px;',
                    fontSize: '12px',
                  }}
                >
                  {!smallMode && !!avatarInitials ? <span>{avatarInitials}</span> : <PersonIcon fontSize="small" />}
                </Avatar>
              </Box>
            </Tooltip>

            <Tooltip title={smallMode && 'Expand'} placement="right" arrow>
              <Box className="navigation-list__item__link pointer bottom" onClick={() => setSmallMode(!smallMode)}>
                {smallMode ? (
                  <KeyboardDoubleArrowRightIcon color="grey" />
                ) : (
                  <KeyboardDoubleArrowLeftIcon color="grey" />
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </menu>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 100,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 15,
              left: -5,
              width: 20,
              height: 20,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        {/* <Link to={'/settings/general'} className={'navigation-list__item__link'}>
          <MenuItem onClick={handleClose}>General</MenuItem>
        </Link> */}
        {/* <Link to={'/settings/users'} className={'navigation-list__item__link'}>
          <MenuItem onClick={handleClose}>Manage users</MenuItem>
        </Link> */}

        <MenuItem onClick={signOut}>Logout</MenuItem>
        {/* <Link to={'/settings/notifications'} className={'navigation-list__item__link'}>
          <MenuItem onClick={handleClose}>Notifications</MenuItem>
        </Link> */}
      </Menu>
    </React.Fragment>
  );
}
export default NavigationDrawer;

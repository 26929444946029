import http from '../../services/http';
import {
  DASHBOARD_SUMMARY,
  DASHBOARD_CHECKLIST,
  DASHBOARD_FAILEDTESTS,
  DASHBOARD_TESTRUNS,
  DASHBOARD_FAILEDTESTSTREND,
  DASHBOARD_TESTDURATIONTREND,
  DASHBOARD_PASSRATE,
} from '../../constants/api';

export const getSummary = () => http.get(DASHBOARD_SUMMARY).then((res) => res.data);

export const getChecklist = () => http.get(DASHBOARD_CHECKLIST).then((res) => res.data);

export const getFailedTests = (params) => http.get(DASHBOARD_FAILEDTESTS, params).then((res) => res.data);
export const getTestRuns = (params) => http.get(DASHBOARD_TESTRUNS, params).then((res) => res.data);

export const getDashboardFailedTestTrend = (params) =>
  http.get(DASHBOARD_FAILEDTESTSTREND, params).then((res) => res.data);
export const getDashboardTestDurationTrend = (params) =>
  http.get(DASHBOARD_TESTDURATIONTREND, params).then((res) => res.data);
export const getDashboardPassRate = (params) => http.get(DASHBOARD_PASSRATE, params).then((res) => res.data);

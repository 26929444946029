import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import TableSkeleton from '../../../components/TableSkeleton';

export default function ReportTestCases(props) {
  const { testCases, isLoading } = props;
  const navigate = useNavigate();

  const selectTestCase = (testCaseGuid) => {
    navigate(`/testcases/${testCaseGuid}`);
  };

  return (
    <TableContainer>
      <Table className="list-table table-type-2 fixed" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row" className="column">
              Page
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              Test Case Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            testCases &&
            testCases.map((row) => (
              <TableRow key={row.testCaseGuid} onClick={() => selectTestCase(row.testCaseGuid)} className="pointer">
                <TableCell className="column">{row.pageDisplayName}</TableCell>
                <TableCell className="column">{row.testCaseName}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableSkeleton columns={2} rows={10} hasMenu />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { formatSmallMilliseconds } from '../../utils/date';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

function DurationHorizontalBarChart(props) {
  const { totalDuration, powerBIDuration, sourceDuration } = props;

  const durationChartOptions = {
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },

      datalabels: {
        formatter: (val) => {
          return formatSmallMilliseconds(val);
        },
        borderRadius: 25,
        borderWidth: 3,
        color: 'black',
        font: {
          weight: 'bold',
        },
        padding: 6,

        tooltip: {
          enabled: false,
        },

        align: 'end',
        anchor: 'end',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: false,
        display: false,
        // Remove gridlines on x-axis
        grid: {
          display: false,
        },
      },
      y: {
        stacked: false,
        display: true,
        // Remove gridlines on x-axis
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        right: 40,
      },
    },
  };

  const durationData = {
    labels: ['Total Duration', 'Power BI Duration', 'Source Duration'],
    datasets: [
      {
        label: 'Duration in MS',
        backgroundColor: '#FF7227',
        borderWidth: 0,
        hoverBackgroundColor: '#ff8c4e',
        data: [totalDuration, powerBIDuration, sourceDuration],
        barThickness: 25, // You can change the number as needed
      },
    ],
  };

  return (
    <div style={{ width: '100%' }}>
      <Bar data={durationData} options={durationChartOptions} />
    </div>
  );
}

export default DurationHorizontalBarChart;

import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../index';
import { useSnackbar } from 'notistack';

import * as testRun from '../endpoints/testRun';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { mapTestRunSummary } from '../mappings/testRun';

// Queries
export const useGetTestRun = (enabled, queryKey, nameFilter) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testRun', queryKey],
    queryFn: () => {
      if (queryKey !== 'all') {
        return testRun.get(queryKey.testRunGuid);
      } else {
        return testRun.get();
      }
    },
    enabled: enabled,
    select: (testRuns) => {
      if (!nameFilter) return testRuns;
      return testRuns.filter((p) => p.testPlanName.toLowerCase()?.includes(nameFilter));
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test run data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestRunSummary = (enabled, testRunGuid) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testRunSummary', testRunGuid],
    queryFn: () => testRun.summary(testRunGuid),
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test plan data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
    select: (data) => {
      return mapTestRunSummary(data);
    },
  });
};

export const useGetTestCaseSummary = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: [
      'testrun',
      'testCaseSummary',
      { testRunGuid: queryKey.testRunGuid },
      { testCaseGuid: queryKey.testCaseGuid },
    ],
    queryFn: () => {
      const { testRunGuid, testCaseGuid } = queryKey;
      return testRun.getTestCase(testRunGuid, testCaseGuid);
    },
    enabled: enabled,

    select: (data) => {
      if (!data?.[0]) return {};
      if (data[0]?.resultOutcomeDescription == 'In progress' || data[0]?.resultOutcomeDescription == 'Queued') {
        queryClient.invalidateQueries([
          'testrun',
          'testCaseSummary',
          { testRunGuid: queryKey.testRunGuid },
          { testCaseGuid: queryKey.testCaseGuid },
        ]); // invalidate the query if its in progress so it always refetces
      }
      return data[0];
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

// Prefetch
export const prefetchTestRunList = () => {
  return queryClient.prefetchQuery(['testRun', 'all'], () => testRun.get());
};
export const prefetchTestRunSummary = (params) => {
  return queryClient.prefetchQuery(['testRunSummary', params.testRunGuid], () => testRun.summary(params));
};

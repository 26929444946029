import React, { useState } from 'react';
import { Stack, Button, Box, IconButton } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '../Menu';
import { Icon } from '../Icon';

const FormButtons = (props) => {
  const [anchorPosition, setAnchorPosition] = useState(null); // State to store the menu position

  const { buttons, contextMenuOptions } = props;

  const handleClick = (event) => {
    if (event.currentTarget) {
      const boundingRect = event.currentTarget.getBoundingClientRect(); // Get the position of the anchor element
      setAnchorPosition({ top: boundingRect.top + 38, left: boundingRect.left + 25 });
      event.stopPropagation();
    }
  };

  const handleClose = (event) => {
    setAnchorPosition(null); // Clear the menu position when closing
    event && event.stopPropagation();
  };

  return (
    <Box pt={1} display="flex" justifyContent="flex-end" flexDirection="row">
      <Stack direction="row" spacing={2}>
        {buttons.map((button) => (
          <Button
            key={button.buttonName}
            className="yellow"
            variant="contained"
            onClick={button.action}
            startIcon={<Icon icon={button.buttonIcon} />}
          >
            {button.buttonName}
          </Button>
        ))}
        {contextMenuOptions && (
          <div>
            <IconButton
              className="icon-button"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              anchorPosition={anchorPosition}
              options={contextMenuOptions}
              handleClose={handleClose}
              origin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          </div>
        )}
      </Stack>
    </Box>
  );
};

export default FormButtons;

import { isJson } from './string';
import { errorCodes } from '../constants/errors';
const SQL_JSON_KEY = 'JSON_F52E2B61-18A1-11d1-B105-00805F49916B';
const SQL_ERROR_RESPONSE = 'Error executing procedure: Error:';

export const formatResponse = (response) => {
  if (response.status === 200 && response.data && response.data.length > 0 && response.data[0][SQL_JSON_KEY]) {
    const data = response.data[0][SQL_JSON_KEY];
    return isJson(data) ? JSON.parse(data) : data;
  } else if (response.data && response.data[SQL_JSON_KEY]) {
    const data = response.data[SQL_JSON_KEY];
    return isJson(data) ? JSON.parse(data) : data;
  } else if (response.data) {
    return response.data;
  }
};

export const getErrorMessage = (errorCode) => {
  if (errorCodes['SQL_ERRORS'][errorCode]) {
    return errorCodes['SQL_ERRORS'][errorCode];
  }
};

export const validateResponse = (response) => {
  if (response.status === 200 && response.data && response.data.length > 0) {
    return true;
  }
};

export const convertEAVToObject = (data) => {
  // Convert an EAV design to a object
  // Example EAV: [{PropertyName:Property1, ValueName:Value1},{PropertyName:Property2, ValueName:Value2}]
  // Converts to: {Property1:Value1, Property2:Value2}

  let returnObj = {};
  if (!Array.isArray(data)) return {};

  // for each value in array
  data.forEach((x) => {
    // Get the name of the first property
    let prop = Object.values(x)[0];

    // Get the value of the first property
    let value = Object.values(x)[1];
    returnObj[prop] = value;
  });

  return returnObj;
};

export const parseSQLError = (response) => {
  if (response?.data && response.data?.length > 0) {
    if (response.data.startsWith(SQL_ERROR_RESPONSE)) {
      const errorMsgArray = response.data.replace(SQL_ERROR_RESPONSE, '').split(',');

      return errorMsgArray.reduce((obj, currentValue) => {
        currentValue = currentValue.trimStart();
        obj[currentValue.substring('0', currentValue.indexOf('='))] = currentValue.substring(
          currentValue.indexOf('=') + 1,
        );
        return obj;
      }, {});
    } else {
      return response.data;
    }
  }
};

export const getResponseErrorMsg = (response, fallbackMessage) => {
  // Get the error message obj from the resonse
  const error = parseSQLError(response);

  // If it returned an error obj
  if (error) {
    // Lookup the error against the friendly list
    const errorMsg = getErrorMessage(error.ErrorNumber);

    // Return friendly error
    if (errorMsg) return errorMsg;

    // Return raw error
    if (error.ErrorDesc) return error.ErrorDesc;
  }
  // return fallback or generic error
  return fallbackMessage ?? 'An error occured. Please contact support';
};

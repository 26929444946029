import { Stack, Typography } from '@mui/material';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Image from '../Image';
import { CONNECTION_TYPES, CONNECTION_TYPE_GENERIC } from '../../constants/connectionTypes';
import OverflowTooltip from '../OverflowTooltip';

const TestCaseResultMetadataSystemView = ({ name, type }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const getIconForConnectionType = (connectionTypeName) => {
    const connectionType = CONNECTION_TYPES.find((type) => type.name === connectionTypeName);
    return connectionType ? connectionType.icon : CONNECTION_TYPE_GENERIC.icon;
  };

  const connectionIcon = getIconForConnectionType(type);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
        <OverflowTooltip variant="h6" text={name} maxWidth={!isLarge ? 128 : '100%'} />

        <Image src={connectionIcon} altText={'Database Image'} />
        <span style={{ textAlign: 'center' }}>
          <Typography variant="subtitle3">{type}</Typography>
        </span>
      </Stack>
    </div>
  );
};

export default TestCaseResultMetadataSystemView;

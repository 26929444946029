import { highlight, languages } from 'prismjs/components/prism-core';
import React from 'react';
import Editor from 'react-simple-code-editor';

import 'prismjs/components/prism-clike'; // must import after prism-core
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';

const Code = (props) => {
  const { placeholder, value, onValueChange, className, id, name, autoOpen, disabled } = props;

  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split('\n')
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n');

  return (
    <Editor
      disabled={disabled}
      autoFocus={autoOpen}
      placeholder={placeholder}
      value={value}
      onValueChange={onValueChange}
      id={id}
      highlight={(code) => hightlightWithLineNumbers(code, languages.sql)}
      padding={10}
      textareaId="codeArea"
      className={className}
      style={{
        fontFamily: '"Consolas", "monaco", monospace !important',
        fontSize: 12,
        outline: 0,
      }}
      name={name}
    />
  );
};

export default Code;

import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index';
import { useSnackbar } from 'notistack';

import * as setting from '../endpoints/setting';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';

// Queries
export const useGetUsers = (enabled, userNameFilter) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['setting', 'users'],
    queryFn: () => setting.getUsers(),
    enabled: enabled,
    select: (users) => {
      if (!userNameFilter) return users;
      return users.filter((c) => c.userPrincipal?.toLowerCase().includes(userNameFilter.toLowerCase()));
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve users');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetUserInvites = (enabled) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['setting', 'users', 'invites'],
    queryFn: () => setting.getUserInvites(),
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve invites');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

// Mutations
export const useUpdateUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => setting.updateUser(rawData),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['setting', 'users'] }); // invalidate the user list (easier than updating state)

      // Show success snackbar
      enqueueSnackbar('User updated', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to create the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useCreateNewUserInvite = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => setting.createInvite(rawData),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['setting', 'users', 'invites'] }); // invalidate the user list (easier than updating state)
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to create the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDeactiveInvite = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => setting.deactivateInvite(rawData),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['setting', 'users', 'invites'] }); // invalidate the user list (easier than updating state)
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to create the connection');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

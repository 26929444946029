import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import FieldHelpText from '../FieldHelpText';

export default function RowRadioButtonsGroup(props) {
  const { title, options, value, onChange, tooltip, disabled, helpText } = props;
  const [selectedValue, setSelectedValue] = React.useState(value);

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onChange(value);
  };

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Tooltip title={tooltip}>
      <FormControl disabled={disabled} sx={{ margin: 0 }}>
        {title && (
          <FormLabel>
            <Box display="flex" flexDirection={'row'} alignItems="center">
              {title}
              {helpText && helpText.content && (
                <FieldHelpText content={helpText.content} linkText={helpText.linkText} link={helpText.link} small />
              )}
            </Box>
          </FormLabel>
        )}
        <RadioGroup row value={selectedValue} onChange={handleRadioChange}>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option.label ?? option.value}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Tooltip>
  );
}

import { useQuery } from '@tanstack/react-query';

import { useSnackbar } from 'notistack';

import * as connectionType from '../endpoints/connectionType';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';

// Queries
export const useGetConnectionTypes = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['connectionTypes'],
    queryFn: () => connectionType.get(),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve connection data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';

export default function TabComponent(props) {
  const { options, selectedTab, handleChange, small } = props;
  const [value, setValue] = React.useState(selectedTab);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    handleChange(newValue);
  };
  return (
    <Box px={small ? 0 : 4}>
      <Tabs
        className="tabs-container"
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="disabled tabs example"
      >
        {options &&
          options.map((tabOption) => (
            <Tab
              className={`tabs-container__tab font-600 ${small ? 'small' : ''}`}
              key={tabOption.name}
              label={tabOption.name}
              value={tabOption.value}
            />
          ))}
      </Tabs>
    </Box>
  );
}

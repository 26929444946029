import { clearStorage } from './storage';
import Cookies from 'universal-cookie';
import { msalInstance } from '../index';
import { loginRequest } from '../configs/authConfig';

const cookies = new Cookies();

export const signOut = () => {
  // this function needs to be moved somewhere else

  cookies.remove('hideBanner');
  clearStorage('token');
  clearStorage('outsetaToken');
  msalInstance.logoutRedirect();
};

export const signIn = () => {
  msalInstance
    .loginRedirect({
      ...loginRequest,
    })
    .catch((error) => console.error(error));
};

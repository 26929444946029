import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { queryClient } from '../../index';
import * as powerBI from '../endpoints/powerBI';
import * as token from '../endpoints/token';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { mapVisualTypeTitle } from '../../utils/visual';

// Queries
export const useGetPowerBIBearerToken = (connectionGuid, isServicePrincipalAuthentication, isKeyVault) => {
  return useQuery({
    queryKey: ['powerBIToken', connectionGuid],
    queryFn: () => token.getPowerBIBearerToken({ connectionGuid, isServicePrincipalAuthentication, isKeyVault }),
    staleTime: 45 * (60 * 1000), // 45 mins
  });
};

export const useGetPowerBIReports = (enabled, connection, workspaceId) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['PBIReports', connection?.connectionGuid],
    queryFn: async () => {
      const bearerToken = await queryClient.fetchQuery({
        queryKey: ['powerBIToken', connection?.connectionGuid],
        queryFn: () =>
          token.getPowerBIBearerToken(
            connection?.connectionGuid,
            connection?.authenticationType === 'Service principal',
            connection?.isLinkedToKeyVault,
          ),
      });

      if (!bearerToken) {
        throw new Response('', {
          status: 403,
          statusText: 'Bearer Token missing',
        });
      }
      return powerBI.getPowerBIReports({ workspaceId, bearerToken });
    },
    enabled: enabled,

    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve report data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetPowerBIPages = (
  enabled,
  connection,
  workspaceId,
  powerBIReportGuid,
  pageGuid,
  includeReportData,
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['PBIReportPages', powerBIReportGuid, pageGuid],
    queryFn: async () => {
      const bearerToken = await queryClient.fetchQuery({
        queryKey: ['powerBIToken', connection?.connectionGuid],
        queryFn: () =>
          token.getPowerBIBearerToken(
            connection?.connectionGuid,
            connection?.authenticationType === 'Service principal',
            connection?.isLinkedToKeyVault,
          ),
      });

      if (!bearerToken) {
        throw new Error({
          status: 403,
          statusText: 'Bearer Token missing',
        });
      }
      // TODO: Split these into their own queries eventually
      if (pageGuid)
        return powerBI.getPowerBIPage({ workspaceId, bearerToken, includeReportData }, powerBIReportGuid, pageGuid);

      return powerBI.getPowerBIPages({ workspaceId, bearerToken, includeReportData }, powerBIReportGuid);
    },
    enabled: enabled,
    retry: 2,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve power bi page data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetPowerBIReportVisuals = (
  enabled,
  shouldTransformOutput,
  connection,
  powerBIReportGuid,
  isRefresh = false,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const isServicePrincipalAuthentication = connection?.authenticationType === 'Service principal';

  return useQuery({
    queryKey: ['PBIReportVisuals', powerBIReportGuid],
    queryFn: async () => {
      const bearerToken = await queryClient.fetchQuery({
        queryKey: ['powerBIToken', connection?.connectionGuid],
        queryFn: () =>
          token.getPowerBIBearerToken(
            connection?.connectionGuid,
            isServicePrincipalAuthentication,
            connection?.isLinkedToKeyVault,
          ),
      });

      if (!bearerToken) {
        throw new Response('', {
          status: 403,
          statusText: 'Bearer Token missing',
        });
      }

      return powerBI.getPowerBIReportVisuals(
        {
          bearerToken,
          isRefresh,
          isServicePrincipalAuthentication,
          workspaceId: connection?.workspaceId,
        },
        powerBIReportGuid,
      );
    },
    enabled: enabled,
    select: (data) => {
      if (!shouldTransformOutput) return data;
      const pages = data?.pages;

      if (!pages || pages?.length === 0) return [];

      let pbiVisuals = [];
      pages.forEach((page) => {
        if (page.visuals && page.visuals.length > 0) {
          page.visuals.forEach((visual) => {
            const visualObject = {
              visualName: mapVisualTypeTitle(visual.title, visual.titleSourceId) || visual.visualGuid,
              visualGuid: visual.visualGuid,
              uniqueGuid: visual.uniqueGuid,
              visualType: visual.visualType,
              pageDisplayName: page.pageDisplayName,
              pageGuid: page.pageGuid,
              pageOrder: page.pageOrder,
              customName: '',
              inScope: false,
            };
            pbiVisuals.push(visualObject);
          });
        }
      });

      return pbiVisuals;
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve report visuals');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetPowerBIReportVisualQuery = (
  enabled,
  connection,
  powerBIReportGuid,
  visualGuid,
  pageGuid,
  isRefresh = false,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const isServicePrincipalAuthentication = connection?.authenticationType === 'Service principal';
  return useQuery({
    queryKey: ['PBIReportVisualsQuery', powerBIReportGuid, pageGuid, visualGuid],
    queryFn: async () => {
      const bearerToken = await queryClient.fetchQuery({
        queryKey: ['powerBIToken', connection?.connectionGuid],
        queryFn: () =>
          token.getPowerBIBearerToken(
            connection?.connectionGuid,
            isServicePrincipalAuthentication,
            connection?.isLinkedToKeyVault,
          ),
      });

      if (!bearerToken) {
        throw new Response('', {
          status: 403,
          statusText: 'Bearer Token missing',
        });
      }
      return powerBI.getPowerBIVisualQuery({
        powerBIReportGuid,
        bearerToken,
        isRefresh,
        isServicePrincipalAuthentication,
        workspaceId: connection?.workspaceId,
        visualGuid,
        pageGuid,
      });
    },
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve report visual query');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

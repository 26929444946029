import { Box, Drawer, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import CloseIcon from '../../assets/images/icons/close.svg';
import Image from '../../components/Image';
import OverflowTooltip from '../OverflowTooltip';

function SideDrawer(props) {
  const { formOpen, title, toggleForm, children, subtitle, isSplitScreen, hideScroll } = props;

  const useDrawer = useMediaQuery('(max-width:1280px)');

  const getClassName = () => {
    if (useDrawer) return 'side-drawer-container-overlay';
    const className = 'side-drawer-container';
    if (formOpen) {
      return `${className} show`;
    }
    return 'side-drawer-container';
  };

  const getSideDrawContent = () => {
    return (
      <Box
        className={getClassName()}
        ml={formOpen && !isSplitScreen && !useDrawer ? 3 : 0}
        height="100%"
        boxShadow={formOpen ? 1 : 0}
      >
        <Box py={4} border={1} borderColor="#CECFD5" borderRadius="2px" height="100%">
          <Box px={4}>
            <Box display="flex" justifyContent="space-between">
              <Box className="page-title" sx={{ maxWidth: '90%' }}>
                <OverflowTooltip text={title} variant="h2" />
              </Box>
              <Box display="flex" flexDirection="row" alignItems={'center'}>
                <Tooltip title="Close blade">
                  <Box onClick={toggleForm} sx={{ minWidth: '40px', marginLeft: '5px' }}>
                    <Image className="close-icon pointer" src={CloseIcon} alt="Close Icon" />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            <Box className="page-subtitle">{subtitle}</Box>
          </Box>

          {formOpen && children && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '80vh',
                overflowY: hideScroll ? 'hidden' : 'auto',
                overflowX: 'hidden',
              }}
            >
              {React.cloneElement(children, { toggleForm: toggleForm })}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // Use conditional rendering to either wrap getContent() in a Drawer or return getContent() directly
  return useDrawer ? (
    <Drawer anchor={'right'} open={formOpen} onClose={toggleForm}>
      {getSideDrawContent()}
    </Drawer>
  ) : (
    getSideDrawContent()
  );
}
export default SideDrawer;

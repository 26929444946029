import { nullIf } from '../utils/obj';

export const exportConnection = (connectionData) => {
  const {
    connectionName,
    connectionGuid,
    authenticationType,
    connectionType,
    keyVaultConnection,
    credentialMethod,
    dbDetails,
    powerBIDetails,
    keyVaultDetails,
  } = connectionData;

  let keyVaultConnectionExport = {};

  if (keyVaultConnection?.connectionGuid) {
    keyVaultConnectionExport.connectionGuid = keyVaultConnection.connectionGuid;
    keyVaultConnectionExport.connectionName = keyVaultConnection.connectionName;
  }

  let dbDetailsExport = {};

  if (dbDetails?.username) {
    dbDetailsExport.serverName = dbDetails.serverName;
    dbDetailsExport.database = dbDetails.database;
    dbDetailsExport.username = dbDetails.username;
    dbDetailsExport.password = '********';
    dbDetailsExport.port = dbDetails.port;
    dbDetailsExport.isLocalGateway = dbDetails.isLocalGateway;
  }

  if (dbDetails?.servicePrincipalAppId) {
    dbDetailsExport.serverName = dbDetails.serverName;
    dbDetailsExport.database = dbDetails.database;
    dbDetailsExport.servicePrincipalAppId = dbDetails.servicePrincipalAppId;
    dbDetailsExport.servicePrincipalSecret = '********';
    dbDetailsExport.isLocalGateway = dbDetails.isLocalGateway;
  }
  if (dbDetails?.port) {
    dbDetailsExport.port = dbDetails.port;
  }

  if (dbDetails?.connectionStringKeyVault) {
    dbDetailsExport.connectionStringSecretName = dbDetails.connectionStringKeyVault;
  }

  if (dbDetails?.connectionString) {
    dbDetailsExport.connectionString = dbDetails.connectionString;
  }

  let powerBIDetailsExport = {};

  if (powerBIDetails?.workspaceId) {
    powerBIDetailsExport.workspaceId = powerBIDetails.workspaceId;
  }

  if (powerBIDetails?.appId) {
    powerBIDetailsExport.workspaceId = powerBIDetails.appId;
  }

  if (powerBIDetails?.servicePrincipalAppId) {
    powerBIDetailsExport.servicePrincipalAppId = powerBIDetails.servicePrincipalAppId;
    powerBIDetailsExport.servicePrincipalSecret = '********';
  }

  if (powerBIDetails?.servicePrincipalAppIdKeyVault) {
    powerBIDetailsExport.servicePrincipalAppIdSecretName = powerBIDetails.servicePrincipalAppIdKeyVault;
    powerBIDetailsExport.servicePrincipalSecretSecretName = powerBIDetails.servicePrincipalSecretKeyVault;
  }

  if (powerBIDetails?.tenantId) {
    powerBIDetailsExport.tenantId = powerBIDetails.tenantId;
  }

  let keyVaultDetailsExport = null;

  if (keyVaultDetails?.keyVaultUrl) {
    keyVaultDetailsExport.keyVaultUrl = keyVaultDetails.keyVaultUrl;
    keyVaultDetailsExport.tenantId = keyVaultDetails.tenantId;
    keyVaultDetailsExport.servicePrincipalAppId = keyVaultDetails.servicePrincipalAppId;
    keyVaultDetailsExport.servicePrincipalSecret = '********';
  }

  keyVaultConnectionExport = nullIf(keyVaultConnectionExport);
  dbDetailsExport = nullIf(dbDetailsExport);
  powerBIDetailsExport = nullIf(powerBIDetailsExport);
  keyVaultDetailsExport = nullIf(keyVaultDetailsExport);

  return {
    connectionName,
    connectionGuid,
    authenticationType: authenticationType.authenticationType,
    connectionType: connectionType.connectionType,
    credentialMethod,
    ...(keyVaultConnectionExport !== null && { keyVaultConnectionExport }),
    connectionDetails: dbDetailsExport ?? powerBIDetailsExport ?? keyVaultDetailsExport,
  };
};

import * as Yup from 'yup';
import errors from '../constants/errors';

const createTestCaseSchema = Yup.object().shape({
  testCaseName: Yup.string().required(errors.TEST_CASE.REQUIRED).max(255, errors.TEST_CASE_NAME.MAX_LENGTH(255)),
  connection: Yup.string()
    .nullable()
    .required(errors.CONNECTION_SELECTION.REQUIRED)
    .max(1000, errors.CONNECTION_NAME.MAX_LENGTH(1000)),
  sourceQuery: Yup.string().required(errors.SQL_QUERY.REQUIRED).max(8000, errors.REPORT_NAME.MAX_LENGTH(8000)),
});

export default createTestCaseSchema;

import { TableRow, TableCell, Skeleton } from '@mui/material';
import React from 'react';

export default function TableSkeleton(props) {
  const { rows, columns, hasMenu } = props;

  if (!Number.isInteger(rows) || !Number.isInteger(columns) || rows <= 0 || columns <= 0) {
    return null; // or some error message
  }

  return (
    <React.Fragment>
      {/* foreach row */}
      {Array.from({ length: rows }, (item, rowIndex) => (
        <TableRow key={rowIndex}>
          {/* foreach column */}
          {Array.from({ length: columns }, (item, columnIndex) => (
            <TableCell
              className="column"
              style={{ padding: '10px' }}
              key={columnIndex}
              colSpan={hasMenu && columnIndex === columns - 1 ? 2 : 1} // if there is a menu then the last column should span 2
            >
              <Skeleton variant="text" height="20px" key={columnIndex} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </React.Fragment>
  );
}

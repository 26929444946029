import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useGetConnectionSummary, useGetTestCaseForConnection } from '../../../api/queries/connection';

import TabComponent from '../../../components/Tabs';
import ConnectionTestCases from './ConnectionTestCases';
import Summary from './Summary';
import FormButtons from '../../../components/FormButtons';
import { EDIT_FORM } from '../../../constants/sidedrawer';
import { exportToFile } from '../../../utils/fileDownload';
import { exportConnection } from '../../../export/connection';

export default function TestCaseView(props) {
  const DEFAULT_SELECTED_TAB = 'SUMMARY';

  const { connectionData } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);

  // Get connection summary
  const { data: connectionSummary, isLoading: connectionSummaryIsLoading } = useGetConnectionSummary(
    selectedTab === 'SUMMARY',
    connectionData.connectionGuid,
  );
  const { data: testCases, isLoading: testCasesIsLoading } = useGetTestCaseForConnection(selectedTab === 'TEST_CASES', {
    connectionGuid: connectionData.connectionGuid,
  });

  const handleTabChange = async (tabName) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    switch (selectedTab) {
      case 'SUMMARY':
        break;
      case 'TEST_CASES':
        break;
      default:
        return;
    }
    // eslint-disable-next-line
  }, [selectedTab, connectionData]);

  const tabOptions = [
    { name: 'Summary', value: 'SUMMARY' },
    { name: 'Test Cases', value: 'TEST_CASES' },
  ];

  const tabViews = {
    SUMMARY: () => {
      return <Summary connectionSummary={connectionSummary} isLoading={connectionSummaryIsLoading} />;
    },
    TEST_CASES: () => {
      return <ConnectionTestCases testCases={testCases} isLoading={testCasesIsLoading} />;
    },
  };

  const onDelete = () => {
    props.toggleDeleteModal();
  };

  const onEditForm = () => {
    props.toggleForm(EDIT_FORM, 'keepopen');
  };

  const onExport = () => {
    exportToFile('json', exportConnection(connectionData), connectionData.connectionName);
  };

  const buttonsArray = [
    {
      buttonName: 'Edit',
      buttonIcon: 'edit',
      action: onEditForm,
    },
  ];

  const contextMenuArray = [
    {
      label: 'Delete',
      icon: 'delete',
      action: onDelete,
    },
    {
      label: 'Export',
      icon: 'export',
      action: onExport,
    },
  ];

  return (
    <Box>
      <Box display="flex" px={4} pb={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <span className="sub-header"> </span>
      </Box>
      <TabComponent handleChange={handleTabChange} selectedTab={DEFAULT_SELECTED_TAB} options={tabOptions} />
      <Divider />
      <Box px={4} mt={3}>
        {tabViews[selectedTab]()}

        <FormButtons buttons={buttonsArray} contextMenuOptions={contextMenuArray} />
      </Box>
    </Box>
  );
}

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useAuthorizeConnection, useCreateConnection, useUpdateConnection } from '../../../api/queries/connection';
import CreateConnectionSchema from '../../../schema/connection/createConnectionSchema';
import { connectionStringBuilder } from '../../../utils/connection';
import { getResponseErrorMsg } from '../../../utils/response';
import CreateConnectionForm from './Form';

function CreateConnection(props) {
  const LAST_FORM_NUMBER = 3;
  const [formNumber, setFormNumber] = useState(1);
  const { resetConnectionData, actionType, connectionData, connectionTypes, connections } = props;
  const { connectionGuid } = props.connectionData;
  let initValues = {
    connectionName: '',
    keyVaultConnection: null,
    connectionType: null,
    credentialMethod: 'Embedded', // default to embedded rather than KV
    authenticationType: null,
    dbDetails: {
      serverName: '',
      database: '',
      port: '',

      username: '',
      password: '',
      servicePrincipalAppId: '',
      servicePrincipalSecret: '',
      connectionString: '',
      connectionStringKeyVault: null,

      // snowflake
      snowflakeIdentifier: '',
      warehouse: '',
      role: '',
    },
    powerBIDetails: {
      workspaceId: '',

      tenantId: '',
      servicePrincipalAppId: '',
      servicePrincipalSecret: '',
      tenantIdKeyVault: '',
      servicePrincipalAppIdKeyVault: '',
      servicePrincipalSecretKeyVault: '',

      refreshToken: '',
    },
    keyVaultDetails: {
      keyVaultUrl: '',
      tenantId: '',
      servicePrincipalAppId: '',
      servicePrincipalSecret: '',
    },
  };
  const [values, setValues] = useState(initValues);
  const [isDetailsSubmitted, setDetailsSubmitted] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseError, setResponseError] = useState('');

  const { mutate: authorizeConnection } = useAuthorizeConnection();
  const updateMutation = useUpdateConnection();
  const createMutation = useCreateConnection();

  const submitForm = async (values) => {
    try {
      const data = {
        connectionGuid,
        connectionName: values.connectionName,
        connectionTypeId: values.connectionType.connectionTypeId,
        isLinkedToKeyVault: values.credentialMethod === 'Key Vault' ? true : false,
        authenticationTypeId: values.authenticationType?.authenticationTypeId,
      };

      if (values.credentialMethod === 'Key Vault') {
        data.linkedKeyVaultConnectionGuid = values.keyVaultConnection?.connectionGuid;
      }

      switch (values.connectionType.connectionType) {
        case 'Azure SQL Database':
        case 'Microsoft Fabric':
        case 'Postgres':
        case 'Snowflake':
          if (values.credentialMethod === 'Key Vault') {
            data.dbDetails = {
              connectionStringKeyVault: values.dbDetails.connectionStringKeyVault,
              isGeneratedConnectionString: false,
            };
          } else {
            data.dbDetails = {
              connectionString: connectionStringBuilder(
                values.dbDetails,
                values.connectionType?.connectionType,
                values.authenticationType?.authenticationType,
              ),
              isGeneratedConnectionString: values.dbDetails.connectionString ? false : true,
            };
          }

          break;
        case 'Power BI':
        case 'Power BI - Fabric':
          if (values.credentialMethod === 'Key Vault') {
            data.powerBIDetails = {
              workspaceId: values.powerBIDetails.workspaceId,
              tenantIdKeyVault: values.powerBIDetails.tenantIdKeyVault,
              servicePrincipalAppIdKeyVault: values.powerBIDetails.servicePrincipalAppIdKeyVault,
              servicePrincipalSecretKeyVault: values.powerBIDetails.servicePrincipalSecretKeyVault,
            };
          } else {
            data.powerBIDetails = {
              workspaceId: values.powerBIDetails.workspaceId,
              tenantId: values.powerBIDetails.tenantId,
              servicePrincipalAppId: values.powerBIDetails.servicePrincipalAppId,
              servicePrincipalSecret: values.powerBIDetails.servicePrincipalSecret,
            };
          }

          break;

        case 'Key Vault':
          data.keyVaultDetails = {
            keyVaultUrl: values.keyVaultDetails.keyVaultUrl,
            tenantId: values.keyVaultDetails.tenantId,
            servicePrincipalAppId: values.keyVaultDetails.servicePrincipalAppId,
            servicePrincipalSecret: values.keyVaultDetails.servicePrincipalSecret,
          };
          break;
        default:
          throw new Error('Unknown Connection Type');
      }

      const refreshToken = values.powerBIDetails?.refreshToken;

      if (connectionGuid) {
        // Update Connection
        await updateMutation.mutateAsync({ ...data });
        if (refreshToken) {
          authorizeConnection({ connectionGuid, refreshToken });
        }
      } else {
        // New Connection
        const response = await createMutation.mutateAsync({ ...data });
        const connectionGuid = response?.connectionGuid;
        if (refreshToken) {
          authorizeConnection({ connectionGuid, refreshToken });
        }
      }
      resetConnectionData();
      setResponseStatus('success');
    } catch (error) {
      console.error(error);
      setResponseStatus('error');
      setResponseError(getResponseErrorMsg(error.response));
    } finally {
      setDetailsSubmitted(true);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (formNumber === LAST_FORM_NUMBER) {
      submitForm(values);
      return;
    }
    actions.setTouched({});
    actions.setSubmitting(false);
    setFormNumber(formNumber + 1);
  };

  const handleBack = async () => {
    setFormNumber(formNumber - 1);
    setDetailsSubmitted(false);
    setResponseStatus('');
    setResponseError('');
  };

  useEffect(() => {
    if (connectionGuid) {
      const values = connectionData;
      setValues(values);
    }
    // eslint-disable-next-line
  }, [connectionData]);

  return (
    <Formik
      validationSchema={CreateConnectionSchema({ connectionGuid }, connections)[formNumber - 1]}
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <CreateConnectionForm
          isDetailsSubmitting={updateMutation.isLoading || createMutation.isLoading}
          LAST_FORM_NUMBER={LAST_FORM_NUMBER}
          handleBack={handleBack}
          responseStatus={responseStatus}
          responseError={responseError}
          isDetailsSubmitted={isDetailsSubmitted}
          toggleForm={props.toggleForm}
          {...formikProps}
          actionType={actionType}
          formNumber={formNumber}
          connectionGuid={connectionGuid}
          connectionTypes={connectionTypes}
        />
      )}
    </Formik>
  );
}

export default CreateConnection;

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Link as ExternalLink,
  Stack,
  TextField,
  Typography,
  Autocomplete as AutocompleteNative,
  Checkbox,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import React from 'react';

import Autocomplete from '../../../components/Autocomplete';
import CronExpression from '../../../components/CronExpression';
import CronScheduler from '../../../components/CronScheduler';
import FreeSolo from '../../../components/FreeSolo';
import MissingObjectWarningComponent from '../../../components/MissingObjectWarning';
import ResponseCard from '../../../components/ResponseCard';
import StepperComponent from '../../../components/Stepper';
import SwitchContainer from '../../../components/SwitchContainer';
import { cronExpressionToHumanReadable } from '../../../utils/cron';
import { getSideDrawerSubHeading } from '../../../utils/sidedrawer';
import TimezoneSelector from '../../../components/TimeZoneSelector';

function CreateTestPlanForm(props) {
  const {
    values: {
      testPlanName,
      cronSchedule,
      tests,
      tags,
      isActiveSchedule,
      timezone,
      notificationEmail,
      notificationEmailStatus,
      isActiveEmailSubscription,
    },
    responseStatus,
    responseError,
    errors,
    touched,
    handleSubmit,
    handleBack,
    handleChange,
    setFieldTouched,
    isDetailsSubmitted,
    isDetailsSubmitting,
    formNumber,
    setFieldValue,
    testCases,
    testCasesIsLoading,
    toggleForm,
    actionType,
    tagList,
    executeTestPlan,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const getResponseCard = () => {
    const result = [
      { key: 'Name', value: testPlanName },
      { key: 'Schedule', value: cronExpressionToHumanReadable(cronSchedule) },
    ];
    return (
      <Box>
        <ResponseCard
          title={!responseError ? getSideDrawerSubHeading('TEST_PLAN', actionType) : responseError}
          values={result}
          responseType={responseStatus}
          toggleForm={toggleForm}
          toggleButtonText={'Execute now'}
          onClickHandler={executeTestPlan}
          buttonIcon={<RocketLaunchIcon />}
        />
      </Box>
    );
  };

  const getNextButtonText = () => {
    if (formNumber === 3) {
      return 'Save';
    }
    return 'Next';
  };

  const steps = ['Test Plan details', 'Select tags', 'Select test cases'];

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box display="flex" pr={4} py={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <StepperComponent steps={steps} activeStep={formNumber - 1} />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ minWidth: '150px' }}
        >
          <Stack direction="row" spacing={1}>
            {formNumber !== 1 && (!isDetailsSubmitted || responseError) && (
              <Button className="grey form-button" variant="contained" onClick={handleBack}>
                Back
              </Button>
            )}
            {!isDetailsSubmitted && (
              <LoadingButton
                type="submit"
                className="yellow form-button"
                variant="contained"
                loading={isDetailsSubmitting}
              >
                {getNextButtonText()}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box px={4} mt={3}>
        {!isDetailsSubmitted && (
          <React.Fragment>
            {formNumber === 1 && (
              <Box>
                <Stack spacing={2} direction={'column'} mb={2}>
                  <p>
                    Test plans are a collection of test cases that can be executed together. Each time a test plan
                    executes it creates a test run.
                    <br />
                    <ExternalLink
                      href="https://wild-data-solutions.outseta.com/support/kb/articles/rm0AKJQX/creating-and-executing-test-plans"
                      target="_blank"
                      rel="noopener"
                    >
                      Test Plans getting started guide
                    </ExternalLink>
                  </p>
                  <Divider>Test Plan info</Divider>
                </Stack>
                <Stack spacing={2} direction={'column'} mb={2}>
                  <Box className="form-field-container">
                    <TextField
                      className="text-field"
                      label="Test Plan Name"
                      variant="filled"
                      fullWidth
                      id="testPlanName"
                      name="testPlanName"
                      value={testPlanName}
                      onChange={change.bind(null, 'testPlanName')}
                      error={touched.testPlanName && Boolean(errors.testPlanName)}
                    />
                    {errors.testPlanName && touched.testPlanName && (
                      <Box color="error.main" className="form-field-error-text">
                        {errors.testPlanName}
                      </Box>
                    )}
                  </Box>
                  <MissingObjectWarningComponent
                    isLoading={testCasesIsLoading}
                    array={testCases}
                    alertTitle="No test cases detected."
                    linkTo="/testcases/add"
                    linkText="Click to go to new test case screen."
                  />
                  {/* Create Schedule Settings */}
                  <Box className="form-field-container">
                    <SwitchContainer
                      label="Create schedule"
                      initialState={isActiveSchedule}
                      handleSwitch={(e) => setFieldValue('isActiveSchedule', e)}
                    />
                    <Box mt={2} className="form-field-container">
                      <Collapse in={isActiveSchedule} timeout="auto" unmountOnExit>
                        <Stack spacing={2} direction={'column'} mt={1}>
                          <Box>
                            <TimezoneSelector
                              errors={errors.timezone}
                              touched={touched.timezone}
                              onValueChange={setFieldValue}
                              name="timezone"
                              initialTimezone={timezone}
                            />
                          </Box>
                          <CronScheduler value={cronSchedule} onChange={(e) => setFieldValue('cronSchedule', e)} />
                          <CronExpression expression={cronSchedule} timezone={timezone} />
                        </Stack>
                      </Collapse>
                    </Box>
                  </Box>

                  {/* Notification Settings */}
                  <Box>
                    <SwitchContainer
                      label="Send Email Notification"
                      initialState={isActiveEmailSubscription}
                      handleSwitch={(e) => setFieldValue('isActiveEmailSubscription', e)}
                    />
                    <Box mt={2}>
                      <Collapse in={isActiveEmailSubscription} timeout="auto" unmountOnExit>
                        <Stack spacing={4} direction={'column'}>
                          <Box>
                            <Typography gutterBottom variant="body1">
                              {'Email recipients (semicolon seperated):'}
                            </Typography>
                            <Box className="form-field-container">
                              <TextField
                                className="text-field"
                                label="Enter Email Address"
                                variant="filled"
                                fullWidth
                                id="notificationEmail"
                                name="notificationEmail"
                                value={notificationEmail}
                                onChange={change.bind(null, 'notificationEmail')}
                                error={touched.testPlanName && Boolean(errors.notificationEmail)}
                              />
                              {errors.notificationEmail && touched.notificationEmail && (
                                <Box color="error.main" className="form-field-error-text">
                                  {errors.notificationEmail}
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box>
                            <AutocompleteNative
                              multiple
                              disableCloseOnSelect
                              name="tests"
                              options={['Failed', 'Inconclusive', 'Passed']}
                              value={notificationEmailStatus}
                              onChange={(e, a) => setFieldValue('notificationEmailStatus', a)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  error={Boolean(errors.notificationEmailStatus) && touched.notificationEmailStatus}
                                  {...params}
                                  label="Test plan notification status"
                                />
                              )}
                            />
                            {errors.notificationEmailStatus && touched.notificationEmailStatus && (
                              <Box color="error.main" className="form-field-error-text">
                                {errors.notificationEmailStatus}
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      </Collapse>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            )}
            {formNumber === 2 && (
              <Stack spacing={2} direction={'column'}>
                <Typography>Select tags to create a dynamic test plan.</Typography>
                <Box className="form-field-container">
                  <FreeSolo
                    multiple={true}
                    name="tags"
                    setFieldValue={setFieldValue}
                    options={tagList}
                    label={'tagValue'}
                    value={'tagId'}
                    selectedItem={tags ?? []}
                    inputLabel={'Search tags'}
                  />
                  {errors.tags && touched.tags && (
                    <Box color="error.main" className="form-field-error-text">
                      {errors.tags}
                    </Box>
                  )}
                </Box>
              </Stack>
            )}
            {formNumber === 3 && (
              <Stack spacing={2} direction={'column'}>
                <Typography>Select test cases to include in the test plan.</Typography>
                <Box className="form-field-container">
                  <Autocomplete
                    multiple={true}
                    groupBy={'reportName'}
                    name="tests"
                    setFieldValue={setFieldValue}
                    options={testCases}
                    label={'testCaseName'}
                    value={'testCaseGuid'}
                    selectedItem={tests}
                    inputLabel={'Search Test Cases'}
                  />
                  {errors.testCaseGuids && touched.testCaseGuids && (
                    <Box color="error.main" className="form-field-error-text">
                      {errors.testCaseGuids}
                    </Box>
                  )}
                </Box>
              </Stack>
            )}
          </React.Fragment>
        )}
        {isDetailsSubmitted && getResponseCard()}
      </Box>
    </form>
  );
}
export default CreateTestPlanForm;

import http from '../../services/http';
import { TEST_RUNS } from '../../constants/api';

// if testrunGuid is not null then add to url
export const get = (testRunGuid) =>
  http.get(`${TEST_RUNS}${testRunGuid ? `/${testRunGuid}` : ''}`, null).then((res) => res.data);

export const summary = (testRunGuid) => http.get(`${TEST_RUNS}/${testRunGuid}/summary`, null).then((res) => res.data);
export const getTestCase = (testRunGuid, testCaseGuid) =>
  http.get(`${TEST_RUNS}/${testRunGuid}/testcases/${testCaseGuid}`, null).then((res) => res.data);

import { Box } from '@mui/material';
import React from 'react';

import LOGO from '../../../assets/images/logo-login.png';
import Image from '../../../components/Image';

function LogIn() {
  return (
    <Box className="login-page" display="flex">
      <Box className="container" display="flex" flexDirection="column" justifyContent="flex-start">
        <Box className="login-form">
          <Box mb={4} mr={6} ml={6} mt={-8.5}>
            <Image src={LOGO} altText={'PowerTester Logo'} className="login-logo" />
          </Box>
          <Box mb={4} className="login-label" mr={6} ml={6}>
            <span>Logout successful</span>
          </Box>
          <span>Please close thie session window</span>
        </Box>
      </Box>
    </Box>
  );
}

export default LogIn;

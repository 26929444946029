import { Box } from '@mui/material';
import React from 'react';
import LOGO from '../../../assets/images/logo-login.png';
import Image from '../../../components/Image';

function Maintenance() {
  return (
    <Box className="maintenance-page" display="flex">
      <Box className="container" display="flex" flexDirection="column" justifyContent="flex-start">
        <Box className="maintenance-form">
          <Box mb={10} mr={6} ml={6} mt={-8.5}>
            <Image src={LOGO} altText={'PowerTester Logo'} className="maintenance-logo" />
          </Box>
          <Box mb={4} className="maintenance-label" mr={6} ml={6}>
            <span>PowerTester is currently down for scheduled Maintenance.</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Maintenance;

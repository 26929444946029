import { Box, CircularProgress } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import {
  useDashboardGetFailedTests,
  useDashboardGetTestRuns,
  useGetChecklist,
  useGetDashboardFailedTestTrend,
  useGetDashboardPassRate,
  useGetDashboardTestDurationTrend,
  useGetSummary,
} from '../../api/queries/dashboard';
import BarChart from '../../components/BarChart';
import DashboardCard from '../../components/DashboardCard';
import Header from '../../components/Header';
import LineChart from '../../components/LineChart';
import navigation from '../../constants/navigation';
import withSnackbar from '../../hocs/withSnackbar';
import FailedTestsList from './FailedTestsList';
import GettingStartedCheklist from './GettingStartedChecklist';
import RecentTestRunsList from './RecentTestRunsList';

function Dashboard(props) {
  const { openSnackbar, closeSnackbar } = props;

  const [showGettingStartedChecklist, setShowGettingStartedChecklist] = useState(false);
  const [gettingStartedCheckListCheckIsLoading, setGettingStartedCheckListCheckIsLoading] = useState(true);
  const cookies = new Cookies();
  const cookieExpiry = '2032-10-10'; // temp, set to 10 years from today

  useEffect(() => {
    const checklistCookie = cookies.get(`hideGettingStartedChecklist`);

    if (checklistCookie) {
      setGettingStartedCheckListCheckIsLoading(false);
      setShowGettingStartedChecklist(false);
    } else {
      setGettingStartedCheckListCheckIsLoading(false);
      setShowGettingStartedChecklist(true);
    }

    // eslint-disable-next-line
  }, []);

  const { data: summary, isLoading: summaryIsLoading } = useGetSummary();
  const {
    data: testRuns,
    isLoading: testRunsIsLoading,
    isError: testRunsIsError,
  } = useDashboardGetTestRuns({ resultLimit: 10 });
  const {
    data: failedTests,
    isLoading: failedTestsIsLoading,
    isError: failedTestsIsError,
  } = useDashboardGetFailedTests({ resultLimit: 10 });
  const {
    data: passRate,
    isLoading: passRateIsLoading,
    isError: passRateIsError,
  } = useGetDashboardPassRate({ periodDays: 7 });
  const {
    data: failedTestTrend,
    isLoading: failedTestTrendIsLoading,
    isError: failedTestTrendIsError,
  } = useGetDashboardFailedTestTrend({ periodDays: 7 });
  const {
    data: testDurationTrend,
    isLoading: testDurationTrendIsLoading,
    isError: testDurationTrendIsError,
  } = useGetDashboardTestDurationTrend({ periodDays: 7 });

  const { data: stepsList } = useGetChecklist(showGettingStartedChecklist);

  const isLoading =
    summaryIsLoading ||
    testRunsIsLoading ||
    failedTestsIsLoading ||
    passRateIsLoading ||
    failedTestTrendIsLoading ||
    testDurationTrendIsLoading ||
    gettingStartedCheckListCheckIsLoading;

  const navigate = useNavigate();

  const navigateTo = (route) => {
    navigate(route);
  };

  const undoHideGettingStartedChecklist = () => {
    setShowGettingStartedChecklist(true);
    closeSnackbar();
    cookies.remove('hideGettingStartedChecklist');
  };

  const hideGettingStartedChecklist = () => {
    setShowGettingStartedChecklist(false);
    cookies.set('hideGettingStartedChecklist', 'true', { path: '/', expires: new Date(Date.parse(cookieExpiry)) });

    openSnackbar("Won't show Getting started checklist again", 'info', 'UNDO', undoHideGettingStartedChecklist);
  };

  const passRateData = {
    labels: ['Pass Rate'],
    datasets: [
      {
        label: 'Pass',
        backgroundColor: '#27AE60',
        borderWidth: 0,
        hoverBackgroundColor: '#67c68f',
        data: [passRate?.[0]?.PassPercentage],
      },
      {
        label: 'Fail',
        backgroundColor: '#EB5757',
        borderWidth: 0,
        hoverBackgroundColor: '#ef7878',
        data: [passRate?.[0]?.FailPercentage],
      },
      {
        label: 'Inconclusive',
        backgroundColor: '#ff9100',
        borderWidth: 0,
        hoverBackgroundColor: '#ffb24d',
        data: [passRate?.[0]?.InconclusivePercentage],
      },
    ],
  };

  const passRateOptions = {
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        backgroundColor: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return Number(value) === 0 ? 'transparent' : context.dataset.backgroundColor;
        },
        formatter: (val) => {
          const formattedValue = (Number(val) * 100).toFixed(1);
          return Number(val) > 0 ? `${formattedValue}%` : ''; // Show label only if value is greater than 0
        },
        borderRadius: 25,
        borderWidth: 3,
        color: 'black',
        font: {
          weight: 'bold',
        },
        padding: 6,
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
  };

  const failedTestsTrendData = {
    labels: failedTestTrend?.map((a) => a.RunDate),
    datasets: [
      {
        label: 'Failed tests',
        fill: true,
        data: failedTestTrend?.map((a) => a.TestCount),
        borderColor: '#EB5757',
        tension: 0.3,
      },
    ],
  };

  const testDurationTrendData = {
    labels: testDurationTrend?.map((a) => a.RunDate),
    datasets: [
      {
        label: 'Failed tests',
        fill: true,
        data: testDurationTrend?.map((a) => a.TotalDurationSeconds),
        borderColor: '#EB5757',
        tension: 0.3,
      },
    ],
  };

  const lineChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <Box className="dashboard-page">
      <Header title="Dashboard" />

      {isLoading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ width: '100%', height: '500px' }}
          flexGrow={1}
        >
          <Box
            sx={{ width: '100%', height: '100%' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <CircularProgress sx={{ marginBottom: '25px' }} />
            Loading Dashboard
          </Box>
        </Box>
      ) : (
        <React.Fragment>
          {showGettingStartedChecklist ? (
            <GettingStartedCheklist stepsList={stepsList} hideGettingStartedChecklist={hideGettingStartedChecklist} />
          ) : (
            <>
              <Box
                className="dashboard-container no-border no-background"
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                width="95%"
                flexDirection="row"
                mt={showGettingStartedChecklist ? 2 : 0}
              >
                {summary &&
                  summary?.map((sumItem, i) => {
                    const route = navigation.find((x) => x.name === sumItem.RouteName);
                    return (
                      <DashboardCard
                        first={i === 0}
                        route={route?.route}
                        value={sumItem.Count}
                        description={sumItem.Description}
                        key={i}
                      />
                    );
                  })}
              </Box>
              <Box
                className="dashboard-container no-border no-background"
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                width="95%"
                flexDirection="row"
                mt={2}
              >
                <Box className="dashboard-object small first" px={2} py={2}>
                  <h2>Last 7 days Pass rate</h2>
                  <Box pt={2}>
                    {!passRateIsLoading ? (
                      <BarChart
                        chartData={passRateData}
                        width="95%"
                        height="200px"
                        chartOptions={passRateOptions}
                        error={passRateIsError}
                        noData={!passRate?.[0].Total > 0}
                      />
                    ) : (
                      <Skeleton variant="rectangular" height={100} />
                    )}
                  </Box>
                </Box>
                <Box className="dashboard-object small" px={2} py={2}>
                  <h2>Failed tests trend</h2>
                  <Box pt={2}>
                    {!failedTestTrendIsLoading ? (
                      <LineChart
                        chartData={failedTestsTrendData}
                        width="95%"
                        height="200px"
                        chartOptions={lineChartOptions}
                        error={failedTestTrendIsError}
                        noData={!failedTestTrend?.length > 0}
                      />
                    ) : (
                      <Skeleton variant="rectangular" height={100} />
                    )}
                  </Box>
                </Box>
                <Box className="dashboard-object small" px={2} py={2}>
                  <h2>Test duration trend</h2>
                  <Box pt={2}>
                    {!testDurationTrendIsLoading ? (
                      <LineChart
                        chartData={testDurationTrendData}
                        width="95%"
                        height="200px"
                        chartOptions={lineChartOptions}
                        error={testDurationTrendIsError}
                        noData={!testDurationTrend?.length > 0}
                      />
                    ) : (
                      <Skeleton variant="rectangular" height={100} />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                className="dashboard-container no-border no-background"
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                width="95%"
                flexDirection="row"
                mt={2}
              >
                <Box className="dashboard-object first" px={4} py={2}>
                  <h2>Recent Failed Tests</h2>
                  <Box pt={2}>
                    {!failedTestsIsLoading ? (
                      <FailedTestsList
                        failedTestList={failedTests}
                        limit={4}
                        onClick={navigateTo}
                        error={failedTestsIsError}
                        noData={!failedTests?.length > 0}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
                <Box className="dashboard-object" px={4} py={2}>
                  <h2>Recent Test Runs</h2>
                  <Box pt={2}>
                    {!testRunsIsLoading ? (
                      <RecentTestRunsList
                        recentTestRuns={testRuns}
                        limit={4}
                        onClick={navigateTo}
                        error={testRunsIsError}
                        noData={!testRuns?.length > 0}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}

export default withSnackbar(Dashboard);

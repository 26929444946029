export const mapTestRunSummary = (testRuns) => {
  if (!testRuns) return;
  const groups = {};

  testRuns.forEach((result) => {
    const planKey = `${result.testPlanName}-${result.testRunGuid}-${result.RunId}`;

    if (!groups[planKey]) {
      groups[planKey] = {
        testPlanName: result.testPlanName,
        testRunGuid: result.testRunGuid,
        runId: result.runId,
        totalNumberOfTests: result.totalNumberOfTests,
        passedTest: result.passedTest,
        failedTest: result.failedTest,
        inconclusiveTest: result.inconclusiveTest,
        durationInMs: result.durationInMs,
        passPercentage: result.passPercentage,
        testCases: [],
        result: result.result,
      };
    }

    groups[planKey].testCases.push({
      testCaseGuid: result.testCaseGuid,
      testCaseName: result.testCaseName,
      reportName: result.reportName,
      duration: result.duration,
      result: result.result,
    });
  });

  const output = Object.values(groups);

  return output[0];
};

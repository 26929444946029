import { nullIfEmpty, convertToNumber } from './string';

export const connectionStringBuilder = (connectionDetails, type, authType, credentialMethod) => {
  if (!type || !connectionDetails || connectionDetails === '') return null;

  if (credentialMethod === 'Key Vault') return null;

  if (connectionDetails.connectionString) return connectionDetails.connectionString;

  const userName = nullIfEmpty(connectionDetails.username) ?? connectionDetails.servicePrincipalAppId;
  const password = nullIfEmpty(connectionDetails.password) ?? connectionDetails.servicePrincipalSecret;
  const port = connectionDetails.port ? ',' + connectionDetails.port : '';
  const SPAuth = authType === 'Service principal' ? 'Authentication=Active Directory Service Principal;' : '';

  //https://learn.microsoft.com/en-us/sql/connect/ado-net/sql/azure-active-directory-authentication?view=sql-server-ver16
  const connectionFormats = {
    'Azure SQL Database': `Server=${connectionDetails.serverName}${port};${SPAuth}Initial Catalog=${connectionDetails.database};User Id=${userName};Password=${password}`,
    Snowflake: `jdbc:snowflake://${connectionDetails.snowflakeIdentifier}.snowflakecomputing.com/?user=${connectionDetails.username}&password=${connectionDetails.password}&db=${connectionDetails.database}&warehouse=${connectionDetails.warehouse}&role=${connectionDetails.role}`,
    'Microsoft Fabric': `Server=${connectionDetails.serverName}; Authentication=Active Directory Service Principal; Encrypt=True; Database=${connectionDetails.database}; User Id=${connectionDetails.servicePrincipalAppId}; Password=${connectionDetails.servicePrincipalSecret}`,
    'Synapse pool': `Server=${connectionDetails.serverName}${port};Initial Catalog=${connectionDetails.database};User Id=${connectionDetails.username};Password=${connectionDetails.password}`,
    Postgres: `Server=${connectionDetails.serverName};${port};Database=${connectionDetails.database};User Id=${connectionDetails.username};Password=${connectionDetails.password};Ssl Mode=Require;PowerTesterProvider=postgres`,
  };

  if (!connectionFormats[type]) {
    return null;
  }

  return connectionFormats[type];
};

export const extractConnectionStringField = (connectionString, type, authType) => {
  if (!connectionString || connectionString === '') {
    return null;
  }

  const keyValuePairs = connectionString.split(';');
  const fields = {};

  keyValuePairs.forEach((keyValuePair) => {
    const [key, value] = keyValuePair.split('=');
    if (key && value) {
      fields[key.trim()] = value.trim();
    }
  });

  switch (type) {
    case 'Microsoft Fabric': {
      // Fabric only has SP and no port
      const serverParts = fields['Server']?.split(',');
      const serverName = serverParts ? serverParts[0] : '';

      return {
        serverName,
        databaseName: fields['Database'] || '',
        servicePrincipalAppId: fields['User Id'] || '',
        servicePrincipalSecret: fields['Password'] || '',
      };
    }
    case 'Azure SQL Database':
    case 'Synapse pool': {
      // Extract server name and port number
      const serverParts = fields['Server']?.split(',');
      const serverName = serverParts ? serverParts[0] : '';
      const port = serverParts?.length > 1 ? convertToNumber(serverParts[1]) : null;

      if (authType === 'Service principal') {
        return {
          serverName,
          port,
          databaseName: fields['Initial Catalog'] || '',
          servicePrincipalAppId: fields['User Id'] || '',
          servicePrincipalSecret: fields['Password'] || '',
        };
      } else {
        return {
          serverName,
          port,
          databaseName: fields['Initial Catalog'] || '',
          username: fields['User Id'] || '',
          password: fields['Password'] || '',
        };
      }
    }
    case 'Postgres': {
      return {
        serverName: fields['Server'] || '',
        port: convertToNumber(fields['Port']) || null,
        databaseName: fields['Database'] || '',
        username: fields['User Id'] || '',
        password: fields['Password'] || '',
      };
    }
    default: {
      return null;
    }
  }
};

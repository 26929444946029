import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SyncIcon from '@mui/icons-material/Sync';
import IconButton from '@mui/material/IconButton';
import React, { useState, useEffect } from 'react';
import { Tooltip, Box } from '@mui/material';
import {
  useTestPowerBIConnection,
  useTestDatabaseConnection,
  useTestKeyVaultConnection,
} from '../../api/queries/connection';

export default function TableRowTestConnection(props) {
  const { connection } = props;
  const [testingInProgress, setTestingInProgress] = useState(false); // State to store the menu position

  const { mutate: testPowerBIConnection, isLoading: testingPowerBIInProcess } = useTestPowerBIConnection();
  const { mutate: testDatabaseConnection, isLoading: testingDatabaseInProcess } = useTestDatabaseConnection();
  const { mutate: testKeyVaultConnection, isLoading: testingKeyVaultInProcess } = useTestKeyVaultConnection();

  useEffect(() => {
    setTestingInProgress(testingKeyVaultInProcess || testingDatabaseInProcess || testingPowerBIInProcess);
  }, [testingPowerBIInProcess, testingDatabaseInProcess, testingPowerBIInProcess]);

  const handleClick = (event) => {
    event.stopPropagation();
    testConnection(connection.connectionType);
  };

  const testConnection = () => {
    const {
      connectionType: { connectionType },
      keyVaultConnection,
      dbDetails,
      authenticationType,
      credentialMethod,
      keyVaultDetails,
      connectionName,
    } = connection;

    switch (connection.connectionType.connectionType) {
      case 'Azure SQL Database':
      case 'Microsoft Fabric':
      case 'Postgres':
      case 'Snowflake':
        return testDatabaseConnection({
          connectionName,
          keyVaultConnection,
          dbDetails,
          connectionType,
          authenticationType,
          credentialMethod,
        });
      case 'Power BI':
      case 'Power BI - Fabric':
        return testPowerBIConnection(connection);
      case 'Key Vault':
        return testKeyVaultConnection({ ...keyVaultDetails, connectionName });
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="center" alignItems="center">
        <div className="menu" style={{ visibility: testingInProgress && 'visible' }}>
          <Tooltip title={testingInProgress ? 'Testing' : 'Test Connection'} arrow>
            <div>
              {!testingInProgress ? (
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  size="small"
                  className="icon-button"
                >
                  <CloudSyncIcon fontSize="small" color="black" />
                </IconButton>
              ) : (
                <SyncIcon fontSize="small" color="black" className="rotate" />
              )}
            </div>
          </Tooltip>
        </div>
      </Box>
    </React.Fragment>
  );
}

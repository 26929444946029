import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';
import React, { useEffect, useRef, useState } from 'react';

import { useGetPowerBIReportVisualQuery } from '../../../api/queries/powerBI';
import { useCopilotGenerateSQL } from '../../../api/queries/copilot';
import AIIcon from '../../../assets/images/icons/ai.svg';
import Autocomplete from '../../../components/Autocomplete';
import Code from '../../../components/Code';
import CopilotSQLConfirmModal from '../../../components/CopilotSQLModal';
import Editable from '../../../components/Editable';
import FreeSolo from '../../../components/FreeSolo';
import Image from '../../../components/Image';
import { getStorageItem, setStorageItem } from '../../../services/storage';
import { findObjectByProperty } from '../../../utils/array';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { EDIT_FORM } from '../../../constants/sidedrawer';
import FormButtons from '../../../components/FormButtons';
import { exportToFile } from '../../../utils/fileDownload';

export default function TestCaseViewProperties(props) {
  const classes = {
    buttonProgress: {
      color: grey[900],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  };
  const {
    toggleForm,
    setEditMode,
    setReadMode,
    values,
    setValues,
    submitForm,
    resetForm,
    saving,
    reports,
    tags,
    visuals,
    visualSelectionTypes,
    reportPages,
    responseStatus,
    reportPageLoading,
    connections,
    databaseConnections,
    connectionsIsLoading,
    powerBIReportPagesIsError,
    deleteTestCase,
    executeQuickRun,
    onRenameAction,
  } = props;
  const inputRef = useRef();

  const [isEditable, setIsEditable] = useState(false);
  const [reportConnection, setReportConnection] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!saving && responseStatus === 'success') {
      setIsEditable(false);
    }

    // eslint-disable-next-line
  }, [saving, responseStatus]);

  useEffect(() => {
    if (props.actionType === EDIT_FORM) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }

    // eslint-disable-next-line
  }, [props.actionType]);

  useEffect(() => {
    if (connections && connections.length > 0 && values?.report?.connectionGuid) {
      setReportConnection(findObjectByProperty(connections, 'connectionGuid', values.report.connectionGuid));
    }

    // eslint-disable-next-line
  }, [connections, values]);

  //  setConnection(findObjectByProperty(connections, 'connectionGuid', reportData.connectionGuid));

  useEffect(() => {
    setSQLGenerated(false);
  }, [values.visualSelectionType, values.visual]);

  const [getPowerBIQuery, setGetPowerBIQuery] = useState(false);
  const [openSQLCopilotDialog, setOpenSQLCopilotDialog] = useState(false);
  const [generateSQLFromCopilot, setGenerateSQLFromCopilot] = useState(false);
  const [sqlGenerated, setSQLGenerated] = useState(false);
  const { data: powerBIVisualQuery, isFetching: powerBIVisualQueryIsFetching } = useGetPowerBIReportVisualQuery(
    getPowerBIQuery && !!values.visual.visualGuid && !!reportConnection?.connectionGuid, // enabled
    reportConnection,
    values.report?.powerBIReportGuid,
    values.visual?.visualGuid,
    values.visual?.pageGuid,
    false, // refresh
  );

  const { data: copilotSQL, isFetching: copilotSQLIsFetching } = useCopilotGenerateSQL(
    !!powerBIVisualQuery && generateSQLFromCopilot, // enabled
    powerBIVisualQuery,
    values.visual?.visualGuid,
  );

  const copilotIsFetching = powerBIVisualQueryIsFetching || copilotSQLIsFetching;

  useEffect(() => {
    if (!copilotSQLIsFetching && copilotSQL && copilotSQL.response) {
      setFieldValue('sourceQuery', copilotSQL.response);
      setGenerateSQLFromCopilot(false);
      setSQLGenerated(true);
    }
  }, [copilotSQLIsFetching]);

  const setFieldValue = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const editForm = () => {
    setEditMode();
  };

  const saveForm = async () => {
    setReadMode();
    submitForm();
  };

  const cancelForm = () => {
    setReadMode();
    resetForm();
  };

  const generateSQL = (hideModal) => {
    if (hideModal) {
      setStorageItem('hideCopilotModal', true);
    }
    setOpenSQLCopilotDialog(false);
    setGenerateSQLFromCopilot(true);
    setGetPowerBIQuery(false);
  };
  const generateSQLClick = () => {
    if (copilotSQLIsFetching || sqlGenerated || !isEditable) return;

    if (getStorageItem('hideCopilotModal')) {
      generateSQL();
    } else {
      setOpenSQLCopilotDialog(true);
    }

    setGetPowerBIQuery(true);
  };

  const handleAltTextReportDropDownChange = (report, value) => {
    setValues({
      ...values,
      altText: '',
      visual: null,
      page: null,
      report: value,
    });
  };

  const handleVisualSelectReportDropDownChange = (report, value) => {
    setValues({
      ...values,
      altText: '',
      page: null,
      visual: null,
      report: value,
    });
    // getReportVisuals(value?.reportGuid);
  };

  function renderAltTextSelection() {
    if (values.visualSelectionType && values.visualSelectionType?.selectionType === 'Alt-text') {
      return (
        <React.Fragment>
          <Box key="Report" className="text-left">
            <b>{'Report'}</b>
            <Editable
              isEditable={isEditable}
              text={values.report?.reportName}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <Autocomplete
                autoOpen={true}
                multiple={false}
                name="report"
                setFieldValue={handleAltTextReportDropDownChange}
                options={reports}
                label={'reportName'}
                value={'reportGuid'}
                selectedItem={values.report}
                textStyle={{ backgroundColor: 'white' }}
                applyStyle={true}
              />
            </Editable>
          </Box>
          <Box pt={2} key="Page Name">
            <b>{'Page Name'}</b>
            <Editable
              isEditable={isEditable}
              text={values.page?.pageDisplayName}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <Autocomplete
                autoOpen={true}
                multiple={false}
                name="page"
                setFieldValue={setFieldValue}
                options={reportPages}
                label={'pageDisplayName'}
                value={'pageGuid'}
                selectedItem={values.page}
                textStyle={{ backgroundColor: 'white' }}
                applyStyle={true}
              />
            </Editable>
          </Box>
          <Box pt={2} key="Alt Text">
            <b>{'Alt Text'}</b>
            <Editable
              isEditable={isEditable}
              text={values.altText}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <input
                ref={inputRef}
                type="text"
                name="altText"
                value={values.altText}
                className="info-container textBox editable editting"
                onChange={handleChange}
              />
            </Editable>
          </Box>
        </React.Fragment>
      );
    }
  }

  function renderReportVisualSelection() {
    if (values.visualSelectionType && values.visualSelectionType?.selectionType === 'Report Visual Selection') {
      return (
        <React.Fragment>
          <Box key="Report" className="text-left">
            <b>{'Report'}</b>
            <Editable
              isEditable={isEditable}
              text={values.report?.reportName}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <Autocomplete
                autoOpen={true}
                multiple={false}
                name="report"
                setFieldValue={handleVisualSelectReportDropDownChange}
                options={reports}
                label={'reportName'}
                value={'reportGuid'}
                selectedItem={values.report}
                textStyle={{ backgroundColor: 'white' }}
                applyStyle={true}
              />
            </Editable>
          </Box>

          <Box pt={2} key="Visual">
            <b>{'Visual'}</b>
            <Editable
              isEditable={isEditable}
              text={values.visual?.visualName}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <Autocomplete
                autoOpen={true}
                multiple={false}
                disabled={!values.report?.reportGuid}
                groupBy="pageDisplayName"
                name="visual"
                setFieldValue={setFieldValue}
                options={visuals}
                label={'visualDisplayName'}
                value={'uniqueGuid'}
                selectedItem={values.visual}
                textStyle={{ backgroundColor: 'white' }}
                applyStyle={true}
              />
            </Editable>
          </Box>
        </React.Fragment>
      );
    }
  }

  function renderUrlSelection() {
    if (values.visualSelectionType && values.visualSelectionType?.selectionType === 'Visual URL') {
      return (
        <Box pt={2} key="Visual Url">
          <b>{'Visual Url'}</b>
          <Editable
            isEditable={isEditable}
            text={values.reportVisualUrl}
            childRef={inputRef}
            type="input"
            className={`info-container textBox ${isEditable && 'editable'}`}
          >
            <input
              ref={inputRef}
              type="text"
              name="reportVisualUrl"
              value={values.reportVisualUrl}
              className="info-container textBox editable editting"
              onChange={handleChange}
            />
          </Editable>
          {reportPageLoading && <LinearProgress />}
          {powerBIReportPagesIsError && (
            <Box color="error.main" className={`error-text`}>
              <i>{'Error retrieving power bi details from URL'}</i>
            </Box>
          )}
        </Box>
      );
    }
  }

  const onExecuteQuickRun = () => {
    executeQuickRun({ testCaseGuid: values.testCaseGuid });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onDeleteTestCase = () => {
    toggleModal();
  };

  const confirmDelete = () => {
    deleteTestCase(values.testCaseGuid);
    toggleModal();
    toggleForm();
  };

  const renameTestCase = () => {
    onRenameAction({ testCaseGuid: values.testCaseGuid, testCaseName: values.testCaseName });
  };

  const exportTestCase = () => {
    exportToFile('json', values, values.testCaseName);
  };

  const contextMenuArray = [
    {
      label: 'Delete',
      icon: 'delete',
      action: onDeleteTestCase,
    },
    {
      label: 'Export',
      icon: 'export',
      action: exportTestCase,
    },
    {
      label: 'Rename',
      icon: 'rename',
      action: renameTestCase,
    },
  ];

  const buttonsArray = [
    {
      buttonName: 'Execute',
      buttonIcon: 'rocket',
      action: onExecuteQuickRun,
    },
    {
      buttonName: 'Edit',
      buttonIcon: 'edit',
      action: editForm,
    },
  ];

  return (
    <React.Fragment>
      <ConfirmationModal
        title="Delete TestCase"
        modalOpen={modalOpen}
        confirm={confirmDelete}
        cancel={toggleModal}
        confirmationButtonText={'Delete'}
        msg={'Are you sure you want to delete?'}
      />

      <CopilotSQLConfirmModal
        modalOpen={openSQLCopilotDialog}
        cancel={() => setOpenSQLCopilotDialog(false)}
        confirm={generateSQL}
      />
      <Box>
        <Box
          className="info-container"
          px={4}
          pt={2}
          pb={1}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box key="VisualSelectionTypes" className="text-left">
            <b>{'Visual selection type'}</b>
            <Editable
              isEditable={isEditable}
              text={values.visualSelectionType?.selectionType}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <Autocomplete
                autoOpen={true}
                multiple={false}
                name="visualSelectionType"
                setFieldValue={setFieldValue}
                options={visualSelectionTypes}
                label={'selectionType'}
                value={'visualSelectionTypeId'}
                selectedItem={values.visualSelectionType}
                textStyle={{ backgroundColor: 'white' }}
                applyStyle={true}
              />
            </Editable>
          </Box>
        </Box>
        <Box
          className="info-container"
          px={4}
          py={2}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          {renderReportVisualSelection()}
          {renderAltTextSelection()}
          {renderUrlSelection()}
        </Box>
        <Box
          className="info-container"
          px={4}
          py={2}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box key="Connection">
            <b>{'Connection'}</b>
            <Editable
              isEditable={isEditable}
              text={values.connection?.connectionName}
              childRef={inputRef}
              type="input"
              className={`info-container textBox ${isEditable && 'editable'}`}
            >
              <Autocomplete
                loading={connectionsIsLoading}
                autoOpen={true}
                multiple={false}
                name="connection"
                setFieldValue={setFieldValue}
                options={databaseConnections}
                label={'connectionName'}
                value={'connectionGuid'}
                selectedItem={values.connection}
                textStyle={{ backgroundColor: 'white' }}
                applyStyle={true}
              />
            </Editable>
          </Box>
          <Box pt={2} key="SQL">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: '36px', maxWidth: '400px' }}
            >
              <b>{'SQL'}</b>
              {values.visualSelectionType &&
                values.visualSelectionType?.selectionType === 'Report Visual Selection' && (
                  <Tooltip
                    title={
                      sqlGenerated
                        ? 'SQL already generated'
                        : copilotIsFetching
                        ? 'SQL Generating please wait'
                        : !isEditable
                        ? 'Edit form to enable'
                        : 'Use PowerTester co-pilot to generate AI'
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems={'center'}
                      className={copilotIsFetching || sqlGenerated || !isEditable ? '' : 'pointer underline'}
                      onClick={generateSQLClick}
                      sx={{ color: copilotIsFetching || sqlGenerated || !isEditable ? 'grey' : 'black' }}
                    >
                      <Box sx={{ width: '40px' }}>
                        <Image src={AIIcon} altText={'Auto generate SQL'} />
                      </Box>
                      <span>Generate SQL</span>
                    </Box>
                  </Tooltip>
                )}
            </Box>

            <Editable
              disabled={copilotIsFetching || sqlGenerated}
              isEditable={isEditable && !copilotIsFetching}
              text={values.sourceQuery}
              childRef={inputRef}
              type="editor"
              className={`info-container textBox ${isEditable && 'editable'} maxHeight`}
            >
              <Code
                autoOpen
                disabled={copilotIsFetching}
                placeholder="Enter SQL Query..."
                value={values.sourceQuery}
                onValueChange={(e) => setFieldValue('sourceQuery', e)}
                name="sourceQuery"
                id="sourceQuery"
                className="editor"
              />
            </Editable>
            {copilotIsFetching && <LinearProgress />}
          </Box>
        </Box>
        <Box
          className="info-container"
          px={4}
          py={2}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <b>{'Tags'}</b>
          <FreeSolo
            multiple={true}
            disabled={!isEditable}
            name="tag"
            setFieldValue={setFieldValue}
            options={tags}
            label={'tagValue'}
            value={'tagId'}
            freeSolo={true}
            selectedItem={values.tag}
            textStyle={{ backgroundColor: `${isEditable ? 'white' : 'inherit'}` }}
          />
        </Box>
        <Box p={1} display="flex" justifyContent="flex-end" flexDirection="row">
          {!isEditable && <FormButtons buttons={buttonsArray} contextMenuOptions={contextMenuArray} />}
          {isEditable && (
            <React.Fragment>
              <Box mr={2}>
                <Button className="yellow" variant="contained" onMouseDown={cancelForm} disabled={saving}>
                  <Box display="flex" alignItems="center">
                    <ClearIcon />
                    <Box ml={2}>Cancel</Box>
                  </Box>
                </Button>
              </Box>
              <Button
                className="yellow"
                variant="contained"
                onMouseDown={saveForm}
                disabled={saving || reportPageLoading}
              >
                <Box display="flex" alignItems="center">
                  <SaveIcon />
                  <Box ml={2}>Save</Box>
                  {saving && <CircularProgress size={20} ml={2} sx={classes.buttonProgress} />}
                </Box>
              </Button>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

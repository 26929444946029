import React from 'react';

function Image(props) {
  return (
    <div className={'image-container ' + (props.className ? props.className : '')}>
      <img src={props.src} alt={props.altText} />
    </div>
  );
}
export default Image;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

import App from './App';
import './styles/main.scss';
import theme from './styles/theme.js';
import SignalRProvider from './services/signalR';
import { msalConfig } from './configs/authConfig.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 2 * (60 * 1000), // 2 mins
    },
  },
});

const setUserAccountDetails = (account) => {
  msalInstance.setActiveAccount(account);
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.

  setUserAccountDetails(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    setUserAccountDetails(event.payload.account);
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
    <SignalRProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <ThemeProvider theme={theme}>
        <App instance={msalInstance} />
      </ThemeProvider>
    </SignalRProvider>
  </QueryClientProvider>,
);

export const getChildByGuid = (node, guid) => {
  if (!node || !guid) return;
  let array = [];

  function getAllChild(nodes) {
    if (nodes === null) return [];
    array.push(nodes);
    if (Array.isArray(nodes.children)) {
      nodes.children.forEach((node) => {
        array = [...array, ...getAllChild(node)];
        array = array.filter((v, i) => array.indexOf(v) === i);
      });
    }
    return array;
  }

  function getNodeByGuid(nodes, guid) {
    if (nodes.guid === guid) {
      return nodes;
    } else if (Array.isArray(nodes.children)) {
      let result = null;
      nodes.children.forEach((node) => {
        if (getNodeByGuid(node, guid)) {
          result = getNodeByGuid(node, guid);
        }
      });
      return result;
    }

    return null;
  }

  return getAllChild(getNodeByGuid(node, guid));
};

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as moment from 'moment';

import Menu from '../../../../../components/Menu';
import TablePaginationActions from '../../../../../components/TablePaginationActions';
import TableRowMenu from '../../../../../components/TableRowMenu';
import TableSkeleton from '../../../../../components/TableSkeleton';
import TextChipCopy from '../../../../../components/TextChipCopy';
import { getRowIndex } from '../../../../../utils/TablePagination';
import appConfig from '../../../../../configs/appConfig';

export default function InviteList(props) {
  const { invites, loading, deleteInvite } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event, index, id, row) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            top: event.clientY - 6,
            left: event.clientX + 2,
            index,
            id,
            row,
          }
        : null,
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [invites]);

  const options = [{ label: 'Delete', action: deleteInvite }];

  const isRowExpired = (expirationDateUTC) => {
    if (!expirationDateUTC) return false;

    const currentUTC = new Date().toISOString();

    if (expirationDateUTC < currentUTC) return true;

    return false;
  };

  return (
    <React.Fragment>
      {!!contextMenu?.top && (
        <Menu
          anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
          options={options}
          handleClose={() => setContextMenu(null)}
          index={contextMenu?.index}
          id={contextMenu?.id}
          row={contextMenu?.row}
        />
      )}
      <TableContainer>
        <Table className="list-table fixed" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="column">Link</TableCell>
              <TableCell className="column">Access Code</TableCell>
              <TableCell className="column">Role</TableCell>
              <TableCell className="column">Expires</TableCell>
              <TableCell className="column menu-column"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              invites && invites?.length > 0 ? (
                invites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const expired = isRowExpired(row.expiresAtUTC);

                  return (
                    <TableRow
                      key={row.linkUrl}
                      onContextMenu={(event) =>
                        handleContextMenu(event, getRowIndex(index, page, rowsPerPage), row.linkUrl)
                      }
                    >
                      <TableCell className="column break-all" component="td" scope="row">
                        {!expired ? (
                          <TextChipCopy>
                            <Typography noWrap variant={'body1'}>
                              {appConfig.BASE_UTL + '/invites/' + row.linkUrl}
                            </Typography>
                          </TextChipCopy>
                        ) : (
                          <Typography noWrap variant={'body1Expired'}>
                            {appConfig.BASE_UTL + '/invites/' + row.linkUrl}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell className="column break-all" component="td" scope="row">
                        {!expired ? (
                          <TextChipCopy textToCopy={row.secureCode}>
                            <Typography noWrap variant={'body1'}>
                              {row.secureCode.slice(0, 3)}-{row.secureCode.slice(3)}
                            </Typography>
                          </TextChipCopy>
                        ) : (
                          <Typography noWrap variant={'body1Expired'}>
                            {row.secureCode.slice(0, 3)}-{row.secureCode.slice(3)}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell className="column break-all" component="td" scope="row">
                        <Typography variant={expired ? 'body1Expired' : 'body1'}>{row.role}</Typography>
                      </TableCell>
                      <TableCell className="column break-all" component="td" scope="row">
                        <Typography variant={expired ? 'body1Expired' : 'body1'}>
                          {moment.utc(row.expiresAtUTC).local().format('lll')}
                        </Typography>
                      </TableCell>

                      <React.Fragment>
                        <TableCell className="column menu-column">
                          <TableRowMenu
                            index={getRowIndex(index, page, rowsPerPage)}
                            id={row.linkUrl}
                            options={options}
                            row={row}
                          />
                        </TableCell>
                      </React.Fragment>
                    </TableRow>
                  );
                })
              ) : (
                []
              )
            ) : (
              <TableSkeleton columns={5} rows={10} hasMenu />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={invites?.length ?? 0}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

import { TITLES } from '../constants/sidedrawer';

export const getSideDrawerHeading = (pageType, actionType, title) => {
  if (TITLES[pageType]['heading'][actionType]) {
    return TITLES[pageType]['heading'][actionType];
  }
  return title;
};

export const getSideDrawerSubHeading = (pageType, actionType, confirmation) => {
  if (confirmation) {
    if (TITLES[pageType]['confirmation'][actionType]) {
      return TITLES[pageType]['confirmation'][actionType];
    }
  }

  if (TITLES[pageType]['subHeading'][actionType]) {
    return TITLES[pageType]['subHeading'][actionType];
  }
};

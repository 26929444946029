import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Container, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import CheckIcon from '../../assets/images/icons/check.svg';
import Image from '../Image';
import { grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';

function ResponseCard(props) {
  const { responseType, toggleButtonText, onClickHandler, errorDetails, buttonIcon } = props;
  const [disabled, setDisabled] = React.useState(false);

  const handleButtonClick = () => {
    setDisabled(true);
    onClickHandler();
  };

  const getIcon = (buttonIcon) => {
    if (buttonIcon) return buttonIcon;
  };

  return (
    <>
      {responseType === 'pre-submit' && (
        <Box>
          <Box p={2} mb={4} className="response-container">
            <Box className="header-wrapper" p={3}>
              <Box mb={3} display="flex" justifyContent="center" alignItems="center" className="icon-container success">
                <AddIcon sx={{ color: grey[50] }} fontSize="large" />
              </Box>
              <Container maxWidth="sm">
                <h4 className="heading text-center">{props.title}</h4>
              </Container>
            </Box>
            <Box p={3} pt={4}>
              <Table>
                <TableBody>
                  {props.values.map((result) => (
                    <TableRow key={result.key}>
                      <TableCell align="left">{result.key}</TableCell>
                      <TableCell align="right" className="value-text">
                        {result.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            {toggleButtonText && (
              <Box display="flex" justifyContent="right">
                <Button
                  className="yellow form-button"
                  variant="contained"
                  onClick={handleButtonClick}
                  disabled={disabled}
                  startIcon={getIcon(buttonIcon)}
                >
                  {toggleButtonText}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {responseType === 'success' && (
        <Box>
          <Box p={2} mb={4} className="response-container">
            <Box className="header-wrapper" p={3}>
              <Box mb={3} display="flex" justifyContent="center" alignItems="center" className="icon-container success">
                <Image src={CheckIcon} alt="Success" />
              </Box>
              <Container maxWidth="sm">
                <h4 className="heading text-center">{props.title}</h4>
              </Container>
            </Box>
            <Box p={3} pt={4}>
              <Table>
                <TableBody>
                  {props.values.map((result) => (
                    <TableRow key={result.key}>
                      <TableCell align="left">{result.key}</TableCell>
                      <TableCell align="right" className="value-text">
                        {result.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            {toggleButtonText && (
              <Box display="flex" justifyContent="right">
                <Button
                  className="yellow form-button"
                  variant="contained"
                  onClick={handleButtonClick}
                  disabled={disabled}
                  startIcon={getIcon(buttonIcon)}
                >
                  {toggleButtonText}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {responseType === 'error' && (
        <Box>
          <Box p={2} mb={4} className="response-container failed">
            <Box className="header-wrapper" p={3}>
              <Box mb={3} display="flex" justifyContent="center" alignItems="center" className="icon-container failed">
                <ErrorIcon style={{ fontSize: 80 }} />
              </Box>
              <Box maxWidth="sm" mb={3}>
                <h4 className="heading text-center">{props.title}</h4>
              </Box>
              {errorDetails && (
                <span>
                  <h5>Error details:</h5>
                  {errorDetails}
                </span>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
export default ResponseCard;

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React from 'react';

import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TestPlanSummary(props) {
  const { graphData } = props;

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const data = {
    labels: [...graphData.labels],
    datasets: [
      {
        label: 'Pass',
        backgroundColor: '#27AE60',
        borderWidth: 0,
        hoverBackgroundColor: '#67c68f',
        data: [...graphData.passData],
      },
      {
        label: 'Fail',
        backgroundColor: '#EB5757',
        borderWidth: 0,
        hoverBackgroundColor: '#ef7878',
        data: [...graphData.failData],
      },
      {
        label: 'Inconclusive',
        backgroundColor: '#ff9100',
        borderWidth: 0,
        hoverBackgroundColor: '#ff9100',
        data: [...graphData.inconclusiveData],
      },
    ],
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: 'relative',
          margin: 'auto',
          width: '90%',
          height: '300px',
          background: '#F6F8F9',
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </React.Fragment>
  );
}

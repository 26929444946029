export const exportReport = (reportData, visuals) => {
  const { reportGuid, reportName, powerBIReportGuid, connectionGuid, workspaceId, AppId } = reportData;

  let extractedVisuals = [];
  if (visuals && visuals.length > 0) {
    extractedVisuals = visuals.map((visual) => ({
      visualGuid: visual.visualGuid,
      visualName: visual.visualName,
      customName: visual.customName,
      visualType: visual.visualType,
      pageDisplayName: visual.pageDisplayName,
      pageGuid: visual.pageGuid,
      inScope: visual.inScope,
    }));
  }

  return {
    reportGuid,
    reportName,
    powerBIReportGuid,
    connectionGuid,
    workspaceId,
    AppId,
    visuals: extractedVisuals,
  };
};

import { ListItemIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { Icon } from '../Icon';

export default function LongMenu(props) {
  const { options, handleClose, anchorPosition, origin, row } = props;

  return (
    <Menu
      id="long-menu"
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition} // Use the saved anchorPosition instead of anchorEl
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: origin?.vertical ?? 'top',
        horizontal: origin?.horizontal ?? 'left',
      }}
      keepMounted
      open={!!anchorPosition}
      onClose={handleClose}
    >
      {options.map((option) => (
        <MenuItem
          onClick={(event) => {
            option.action(props?.index, props?.id, option?.actionType, props?.row);
            handleClose();
            event.stopPropagation();
          }}
          key={option.label || option.getOptionLabel(row)}
          selected={option.label === 'Pyxis'}
          disabled={option.disabled || (option.disabledCriteria && (option.disabledCriteria(row) ? true : false))}
        >
          {option.icon && (
            <ListItemIcon>
              <Icon icon={option.icon} fontSize="small" />
            </ListItemIcon>
          )}
          <React.Fragment>{option.label || option.getOptionLabel(row)}</React.Fragment>
        </MenuItem>
      ))}
    </Menu>
  );
}

import * as Yup from 'yup';
import errors from '../../constants/errors';
import validations from '../../constants/validations';

const keyVaultSPAuthentication = () => {
  return Yup.object().shape({
    workspaceId: Yup.string()
      .required(errors.WORKSPACE_ID.REQUIRED)
      .matches(validations.GUID, errors.WORKSPACE_ID.CRITERION),
    tenantIdKeyVault: Yup.string().nullable().required(errors.TENANT_ID_KV.REQUIRED),
    servicePrincipalAppIdKeyVault: Yup.string().nullable().required(errors.SP_APP_ID_KV.REQUIRED),
    servicePrincipalSecretKeyVault: Yup.string().nullable().required(errors.SP_APP_SECRET_KV.REQUIRED),
  });
};

const embeddedSPAuthentication = () => {
  return Yup.object().shape({
    workspaceId: Yup.string()
      .required(errors.WORKSPACE_ID.REQUIRED)
      .matches(validations.GUID, errors.TENANT_ID.CRITERION),
    tenantId: Yup.string()
      .nullable()
      .required(errors.TENANT_ID_KV.REQUIRED)
      .matches(validations.GUID, errors.WORKSPACE_ID.CRITERION),
    servicePrincipalAppId: Yup.string().nullable().required(errors.SP_APP_ID.REQUIRED),
    servicePrincipalSecret: Yup.string().nullable().required(errors.SP_APP_SECRET.REQUIRED),
  });
};

const masterUserAuthentication = (connectionGuid) => {
  return Yup.object().shape({
    refreshToken: !connectionGuid && Yup.string().required(errors.REFRESH_TOKEN.REQUIRED),
    workspaceId: Yup.string()
      .required(errors.WORKSPACE_ID.REQUIRED)
      .matches(validations.GUID, errors.WORKSPACE_ID.CRITERION),
  });
};

export const powerBISchema = (connectionGuid) => {
  return Yup.object().when('authenticationType.authenticationType', {
    is: 'Master User OAuth',
    then: Yup.object().when('credentialMethod', {
      is: 'Embedded',
      then: masterUserAuthentication(connectionGuid),
    }),
    otherwise: Yup.object().when('authenticationType.authenticationType', {
      is: 'Service principal',
      then: Yup.object().when('credentialMethod', {
        is: 'Embedded',
        then: embeddedSPAuthentication(),
        otherwise: keyVaultSPAuthentication(),
      }),
    }),
  });
};

import { findObjectByProperty } from '../../utils/array';
import { connectionStringBuilder, extractConnectionStringField } from '../../utils/connection';
import { addPropertyIfNotNull } from '../../utils/obj';

export const mapConnections = (connectionData) => {
  if (!connectionData) {
    return;
  }

  const connectionArray = connectionData.map((obj) => {
    const connectionFields = extractConnectionStringField(
      obj.connectionString,
      obj.connectionType,
      obj.authenticationType,
    );
    let kvConnection = null;

    if (obj.linkedKeyVaultConnectionGuid) {
      // Search for connections for the key vault conneciton
      kvConnection = findObjectByProperty(connectionData, 'connectionGuid', obj.linkedKeyVaultConnectionGuid);
    }

    return {
      connectionName: obj.connectionName,
      connectionGuid: obj.connectionGuid,
      authenticationType: {
        authenticationTypeId: obj.authenticationTypeId,
        authenticationType: obj.authenticationType,
      },
      connectionType: { connectionTypeId: obj.connectionTypeId, connectionType: obj.connectionType },

      keyVaultConnection: kvConnection,
      credentialMethod: obj.isLinkedToKeyVault === false ? 'Embedded' : 'Key Vault',
      dbDetails: {
        serverName: obj.isGeneratedConnectionString ? connectionFields?.serverName : '',
        database: obj.isGeneratedConnectionString ? connectionFields?.databaseName : '',
        username: obj.isGeneratedConnectionString ? connectionFields?.username : '',
        password: obj.isGeneratedConnectionString ? connectionFields?.password : '',
        port: obj.isGeneratedConnectionString ? connectionFields?.port : '',
        servicePrincipalAppId: obj.isGeneratedConnectionString ? connectionFields?.servicePrincipalAppId : '',
        servicePrincipalSecret: obj.isGeneratedConnectionString ? connectionFields?.servicePrincipalSecret : '',
        connectionString: obj.isGeneratedConnectionString ? '' : obj.connectionString,
        isLocalGateway: obj.isLocalGateway,
        isGeneratedConnectionString: obj.isGeneratedConnectionString,

        connectionStringKeyVault: obj.connectionStringKeyVault,
      },
      powerBIDetails: {
        workspaceId: obj.workspaceId,
        appId: obj.appId,
        keyVaultConnection: kvConnection,
        refreshToken: obj.refreshToken,

        tenantId: obj.tenantId,
        servicePrincipalAppId: obj.servicePrincipalAppId,
        servicePrincipalSecret: obj.servicePrincipalSecret,
        tenantIdKeyVault: obj.tenantIdKeyVault,
        servicePrincipalAppIdKeyVault: obj.servicePrincipalAppIdKeyVault,
        servicePrincipalSecretKeyVault: obj.servicePrincipalSecretKeyVault,
      },
      keyVaultDetails: {
        keyVaultUrl: obj.keyVaultUrl,
        tenantId: obj.tenantId,
        servicePrincipalAppId: obj.servicePrincipalAppId,
        servicePrincipalSecret: obj.servicePrincipalSecret,
      },
    };
  });

  return connectionArray;
};

export const mapSimpleConnections = (connectionData) => {
  if (!connectionData) {
    return;
  }

  const connectionArray = connectionData.map((obj) => {
    return {
      connectionName: obj.connectionName,
      connectionGuid: obj.connectionGuid,
      powerBIDetails: {
        workspaceId: obj.workspaceId,
        appId: obj.appId,
        refreshToken: obj.refreshToken,
      },
    };
  });

  return connectionArray;
};

export const mapUpdateConnection = (connectionData, newConnectionGuid) => {
  if (!connectionData) return;

  // explode the object
  const {
    powerBIDetails: { appId, workspaceId, refreshToken } = {},
    connectionName,
    connectionType,
    connectionDetails: { connectionString, isGeneratedConnectionString, isLocalGateway } = {},
  } = connectionData;

  const connectionGuid = newConnectionGuid ?? connectionData.connectionGuid;

  // return values some are optional and therefore might not exist
  return {
    ...(appId && { appId }),
    connectionGuid,
    connectionName,
    connectionType,
    ...(connectionString && { connectionString }),
    ...(isGeneratedConnectionString && { isGeneratedConnectionString }),
    ...(isLocalGateway && { isLocalGateway }),
    ...(workspaceId && { workspaceId }),
    ...(refreshToken && { refreshToken }),
  };
};

export const createTestPowerBIConnectionBody = (data) => {
  const { connectionGuid, powerBIDetails, keyVaultConnection } = data;
  let servicePrincipalDetails = {};
  let keyVaultConnectionDetails = {};
  let refreshToken = powerBIDetails.refreshToken; // Assign the refreshToken

  if (!refreshToken) {
    refreshToken = undefined; // Set refreshToken to undefined if it doesn't exist
    if (keyVaultConnection) {
      // Service principal details
      addPropertyIfNotNull(powerBIDetails, servicePrincipalDetails, 'tenantIdKeyVault', 'tenantIdKeyVault');
      addPropertyIfNotNull(
        powerBIDetails,
        servicePrincipalDetails,
        'servicePrincipalAppIdKeyVault',
        'servicePrincipalAppIdKeyVault',
      );
      addPropertyIfNotNull(
        powerBIDetails,
        servicePrincipalDetails,
        'servicePrincipalSecretKeyVault',
        'servicePrincipalSecretKeyVault',
      );

      // Key vault details
      addPropertyIfNotNull(keyVaultConnection, keyVaultConnectionDetails, 'keyVaultUrl', 'keyVaultUrl');
      addPropertyIfNotNull(keyVaultConnection, keyVaultConnectionDetails, 'tenantId', 'tenantId');
      addPropertyIfNotNull(
        keyVaultConnection,
        keyVaultConnectionDetails,
        'servicePrincipalAppId',
        'servicePrincipalAppId',
      );
      addPropertyIfNotNull(
        keyVaultConnection,
        keyVaultConnectionDetails,
        'servicePrincipalSecret',
        'servicePrincipalSecret',
      );
    } else {
      addPropertyIfNotNull(powerBIDetails, servicePrincipalDetails, 'tenantId', 'tenantId');
      addPropertyIfNotNull(powerBIDetails, servicePrincipalDetails, 'servicePrincipalAppId', 'servicePrincipalAppId');
      addPropertyIfNotNull(powerBIDetails, servicePrincipalDetails, 'servicePrincipalSecret', 'servicePrincipalSecret');
    }
  }

  return {
    workspaceId: powerBIDetails.workspaceId,
    ...(connectionGuid && { connectionGuid }), // Include connectionGuid if it exists
    ...(refreshToken && { refreshToken }), // Include refreshToken if it exists
    ...(Object.keys(servicePrincipalDetails).length !== 0 && { servicePrincipalDetails }), // if not empty then include
    ...(Object.keys(keyVaultConnectionDetails).length !== 0 && { keyVaultConnectionDetails }), // if not empty then include
  };
};

export const createTestDatabaseConnectionBody = ({
  keyVaultConnection,
  dbDetails,
  connectionType,
  authenticationType,
  credentialMethod,
}) => {
  // Check if keyVaultConnection is available and if connectionStringKeyVault is provided
  if (keyVaultConnection && dbDetails.connectionStringKeyVault) {
    const { tenantId, servicePrincipalAppId, servicePrincipalSecret, keyVaultUrl } = keyVaultConnection;

    return {
      keyVaultConnectionDetails: {
        tenantId,
        servicePrincipalAppId,
        servicePrincipalSecret,
        keyVaultUrl,
      },
      connectionStringKeyVault: dbDetails.connectionStringKeyVault,
    };
  }

  // Check if connectionString is provided directly
  if (dbDetails.connectionString) {
    return { connectionString: dbDetails.connectionString };
  }

  // If no direct connection string is provided, build it using connectionStringBuilder
  return {
    connectionString: connectionStringBuilder(
      dbDetails,
      connectionType,
      authenticationType.authenticationType,
      credentialMethod,
    ),
  };
};

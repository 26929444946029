import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import React from 'react';

import withSnackbar from '../../../../../hocs/withSnackbar';
import FormTextField from '../../../../../components/FormTextField';
import { useTestKeyVaultConnection } from '../../../../../api/queries/connection';

function KeyVaultForm(props) {
  const { errors, touched, setFieldTouched, setFieldValue, keyVaultDetails, connectionName } = props;

  const onTextChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const { mutate: testConnection, isLoading: testingInProcess } = useTestKeyVaultConnection();

  // If all fields are populated
  const formPopulated =
    !!keyVaultDetails.keyVaultUrl &&
    !!keyVaultDetails.tenantId &&
    !!keyVaultDetails.servicePrincipalAppId &&
    !!keyVaultDetails.servicePrincipalSecret;

  return (
    <React.Fragment>
      <Stack direction="column" spacing={3}>
        <Box display="flex" flexDirection="row" justifyContent={'flex-end'} alignItems="center">
          <LoadingButton
            className={`yellow form-button ${formPopulated ? '' : 'disabled'}`}
            variant="contained"
            loading={testingInProcess}
            disabled={!formPopulated}
            onClick={() => testConnection({ ...keyVaultDetails, connectionName })}
          >
            Test Connection
          </LoadingButton>
        </Box>

        <FormTextField
          className="text-field"
          label="key Vault Url"
          variant="outlined"
          fullWidth
          id="keyVaultUrl"
          name="keyVaultDetails.keyVaultUrl"
          value={keyVaultDetails?.keyVaultUrl || ''}
          onChange={(event) => {
            onTextChange('keyVaultDetails.keyVaultUrl', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="keyVaultDetails"
        />

        <FormTextField
          className="text-field"
          label="Tenant Id"
          variant="outlined"
          fullWidth
          id="tenantId"
          name="keyVaultDetails.tenantId"
          value={keyVaultDetails?.tenantId || ''}
          onChange={(event) => {
            onTextChange('keyVaultDetails.tenantId', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="keyVaultDetails"
        />

        <FormTextField
          className="text-field"
          label="Service Principal App Id"
          variant="outlined"
          fullWidth
          id="servicePrincipalAppId"
          name="keyVaultDetails.servicePrincipalAppId"
          value={keyVaultDetails?.servicePrincipalAppId || ''}
          onChange={(event) => {
            onTextChange('keyVaultDetails.servicePrincipalAppId', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="keyVaultDetails"
        />

        <FormTextField
          className="text-field"
          type="password"
          label="Service Principal Secret"
          variant="outlined"
          fullWidth
          id="servicePrincipalSecret"
          name="keyVaultDetails.servicePrincipalSecret"
          value={keyVaultDetails?.servicePrincipalSecret || ''}
          onChange={(event) => {
            onTextChange('keyVaultDetails.servicePrincipalSecret', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="keyVaultDetails"
        />
      </Stack>
    </React.Fragment>
  );
}

export default withSnackbar(KeyVaultForm);

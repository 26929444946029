import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '../../assets/images/icons/search.svg';
import { filterListByName } from '../../utils/array';
import Image from '../Image';

function Search(props) {
  const { placeholder, list, setFilterListHook, searchKey, returnValue } = props;

  const onChange = (value) => {
    if (returnValue) return setFilterListHook(value);
    filterListByName(list, searchKey, value, setFilterListHook);
  };

  return (
    <div className="search-container">
      <Image src={SearchIcon} altText={'Search Icon'} />
      <DebounceInput
        minLength={1}
        debounceTimeout={300}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}
export default Search;

import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import InviteList from './InviteList';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useDeactiveInvite, useGetUserInvites } from '../../../../api/queries/setting';
import { SNACKBAR_ERROR } from '../../../../constants/snackbar';
import { findObjectByProperty } from '../../../../utils/array';
import Header from '../../../../components/Header';

function ManageInvites(props) {
  const { openSnackbar } = props;
  const [modalProps, setModalProps] = useState({ open: false, title: '', user: {} });
  const [isReloading, setIsReloading] = useState(false);

  const navigate = useNavigate();

  // Get data
  const { data: userInvites, isLoading: userInvitesIsLoading, refetch: refetchUserInvites } = useGetUserInvites(true);

  // Updates
  const { mutate: deactivateInvite } = useDeactiveInvite();

  const reload = (showProgress) => {
    if (showProgress) setIsReloading(true);

    const promises = [refetchUserInvites()];

    Promise.all(promises)
      .then(() => {
        if (showProgress) setIsReloading(false);
      })
      .catch((error) => {
        // Handle any errors that might occur during the fetch
        if (showProgress) setIsReloading(false);
        console.error('Error fetching data:', error);
      });
  };

  const toggleModal = (userPrincipal) => {
    if (userPrincipal) {
      const userToUpdate = findObjectByProperty(userInvites, 'userPrincipal', userPrincipal);

      setModalProps({
        open: true,
        title: `Are you sure you want to ${userToUpdate.status === 'Active' ? 'deactivate' : 'activate'}?`,
        user: {
          userPrincipal: userToUpdate.userPrincipal,
          role: userToUpdate.role,
          isActive: userToUpdate.status === 'Active' ? false : true,
        },
      });
    } else {
      setModalProps({ open: false, title: '', user: {} });
    }
  };

  const confirmDeactivate = () => {
    if (!modalProps?.user?.userPrincipal) {
      openSnackbar('Unknown Error deleting user', SNACKBAR_ERROR);
      return;
    }
    deactivateInvite({
      linkUrl: modalProps?.user.userPrincipal,
    });
    toggleModal();
  };

  const togglePage = (page) => {
    if (page !== '/settings/invites') navigate(page);
  };

  const switchOptions = {
    list: [
      { label: 'Users', action: () => togglePage('/settings/users') },
      { label: 'Invites', action: () => togglePage('/settings/invites') },
    ],
    activeSwitch: 'Invites',
  };

  return (
    <Box className="page-details" sx={{ flexGrow: 1 }}>
      <Header
        title="Settings | Manage User Invites"
        switchOptions={switchOptions}
        buttonDisabled={true}
        refreshAction={() => reload(true)}
        headerVariantOverride={'h3'}
        buttonText="Invite new user"
      />
      <ConfirmationModal
        title="Update user"
        modalOpen={modalProps.open}
        confirm={confirmDeactivate}
        cancel={() => toggleModal()}
        msg={modalProps.title}
      />
      <Box px={4} className="page-details__list-container" display="flex" pb={4} flexDirection="row">
        <Box className="page-details-container" pr={3} flex={1}>
          <InviteList invites={userInvites} loading={isReloading || userInvitesIsLoading} toggleModal={toggleModal} />
        </Box>
      </Box>
    </Box>
  );
}

export default ManageInvites;

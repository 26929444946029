import http from '../../services/http';
import { ANNOUNCEMENTS } from '../../constants/api';
import { validateResponse } from '../../utils/response';

export const getAnnouncements = async () => {
  try {
    const response = await http.get(ANNOUNCEMENTS);
    if (validateResponse(response)) {
      return response.data;
    } else {
      return [];
    }
    // data = convertEAVToObject(formatResponse(response));
  } catch (e) {
    return [];
  }
};

import { HubConnectionBuilder } from '@microsoft/signalr';
import React, { createContext, useEffect, useRef, useState } from 'react';

const SignalRContext = createContext();

const SignalRProvider = ({ children }) => {
  const signalRUrl = 'https://pt-services-deva22a.azurewebsites.net/api/';
  const [connection, setConnection] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const pendingCallbacksRef = useRef([]);

  const startNotify = (methodName, actionOnMsg, source) => {
    if (isConnected && connection) {
      connection.off(methodName);

      connection.on(methodName, (message) => {
        actionOnMsg(message, source);
      });
    } else {
      // If the connection is not ready, add the callback to the pendingCallbacksRef
      pendingCallbacksRef.current.push({ methodName, actionOnMsg });
    }
  };

  const stopNotify = (methodName) => {
    if (isConnected && connection) {
      connection.off(methodName);
    }
  };

  useEffect(() => {
    const connect = new HubConnectionBuilder().withUrl(signalRUrl).withAutomaticReconnect().build();
    setConnection(connect);

    connect
      .start()
      .then(() => {
        setIsConnected(true);
      })
      .catch((error) => console.error('Error connecting to SignalR:', error));
  }, []);

  useEffect(() => {
    // Handle any pending callbacks once the connection is established
    if (isConnected && connection) {
      pendingCallbacksRef.current.forEach(({ methodName, actionOnMsg }) => {
        connection.on(methodName, (message) => {
          actionOnMsg(message);
        });
      });
      // Clear pending callbacks as they are now handled
      pendingCallbacksRef.current = [];
    }
  }, [isConnected, connection]);

  return <SignalRContext.Provider value={{ connection, startNotify, stopNotify }}>{children}</SignalRContext.Provider>;
};

export default SignalRProvider;
export { SignalRContext };

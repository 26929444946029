import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import * as authenticationType from '../endpoints/authenticationType';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { mapAuthenticationTypes } from '../mappings/authenticationType';

// Queries

export const useGetAuthenticationTypes = (connectionTypeIdFilter) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['authenticationtypes'],
    queryFn: () => authenticationType.get(),
    staleTime: 60 * (60 * 1000), // 60 mins
    select: (authTypes) => {
      if (connectionTypeIdFilter) {
        return mapAuthenticationTypes(authTypes, connectionTypeIdFilter);
      }
      return mapAuthenticationTypes(authTypes);
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve connection data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

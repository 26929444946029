import _ from 'lodash';
import React from 'react';
import Info from '../../../components/Info';
import { formatMilliseconds } from '../../../utils/date';

export default function TestPlanSummaryInfo(props) {
  const { summary } = props;

  const getSummaryInfo = () => [
    { label: '# of Test Cases', value: summary?.totalNumberOfTests || 0 },
    { label: 'avg Pass', value: isNaN(summary?.avgPass) ? '-' : _.round(summary?.avgPass * 100) + '%' },
    { label: 'avg duration', value: formatMilliseconds(summary?.avgDuration) || 0 },
  ];
  return <Info data={getSummaryInfo()} />;
}

import { FormControlLabel, Switch } from '@mui/material';
import React, { useState } from 'react';

function SwitchContainer({ handleSwitch, label, initialState }) {
  const [state, setState] = useState(initialState);

  const handleChange = (event) => {
    setState(event.target.checked);
    handleSwitch(event.target.checked);
  };

  return (
    <React.Fragment>
      {' '}
      <FormControlLabel
        control={
          <Switch
            checked={state}
            onChange={handleChange}
            value={state}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
        label={label}
        labelPlacement="start"
        sx={{ marginLeft: 0, '& .MuiFormControlLabel-label': { marginLeft: 0 } }}
      />{' '}
    </React.Fragment>
  );
}

export default SwitchContainer;

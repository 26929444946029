import * as cronValidator from 'cron-expression-validator';
import cronstrue from 'cronstrue';

export const isValidCron = (value) => {
  if (!value) {
    return true;
  }
  return cronValidator.isValidCronExpression(value, { error: true });
};

export const cronExpressionToHumanReadable = (value) => {
  try {
    if (!value) {
      return '';
    }

    const result = cronstrue.toString(value);
    return result;
  } catch (error) {
    console.error(error);
  }
};

import React from 'react';
import DatabaseForm from './DatabaseForm';
import PowerBIForm from './PowerBIForm';
import KeyVaultConnections from './KeyVaultConnections';

function ConnectionDetailsForm(props) {
  const {
    values: {
      connectionName,
      dbDetails,
      powerBIDetails,
      keyVaultDetails,
      connectionType,
      authenticationType,
      credentialMethod,
      keyVaultConnection,
    },

    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    connectionGuid,
  } = props;

  function renderConnectionForm() {
    switch (connectionType.connectionType) {
      case 'Azure SQL Database':
      case 'Microsoft Fabric':
      case 'Postgres':
      case 'Snowflake':
        return (
          <DatabaseForm
            connectionName={connectionName}
            dbDetails={dbDetails}
            credentialMethod={credentialMethod}
            connectionType={connectionType}
            authenticationType={authenticationType}
            keyVaultConnection={keyVaultConnection}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        );
      case 'Power BI':
      case 'Power BI - Fabric':
        return (
          <PowerBIForm
            connectionName={connectionName}
            connectionGuid={connectionGuid}
            powerBIDetails={powerBIDetails}
            credentialMethod={credentialMethod}
            connectionType={connectionType}
            authenticationType={authenticationType}
            keyVaultConnection={keyVaultConnection}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        );
      case 'Key Vault':
        return (
          <KeyVaultConnections
            connectionName={connectionName}
            authenticationType={authenticationType}
            keyVaultDetails={keyVaultDetails}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        );
      default:
        return null;
    }
  }

  return <React.Fragment>{connectionType && connectionType.connectionType && renderConnectionForm()}</React.Fragment>;
}

export default ConnectionDetailsForm;

import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import Info from '../../../components/Info';
import { formatMilliseconds } from '../../../utils/date';
import { isJson } from '../../../utils/string';
import MismatchRecordsTable from '../../../components/MismatchRecordsTable';
import JsonViewer from '../../../components/JsonViewer';
import TestCaseResultMetadataView from '../../../components/TestCaseResultMetdataView';

export default function TestRunSummary(props) {
  const { summary } = props;
  const getSummaryInfo = () => [
    { label: 'Result', value: summary.resultOutcomeDescription },
    { label: 'Duration', value: formatMilliseconds(summary.durationInMSeconds) },
  ];

  const getErrorWindow = () => {
    // If the summary or detail is null return
    if (!summary.resultDetails || !summary.resultOutcomeDescription) return;
    // if the test was successfull retur
    if (summary.resultOutcomeDescription === 'Passed') return;

    // If the detail aint json return
    if (!isJson(summary.resultDetails)) return;

    try {
      const parsedErrorDetails = JSON.parse(summary.resultDetails);

      if (
        (parsedErrorDetails.results &&
          parsedErrorDetails.results[0]?.Expected &&
          parsedErrorDetails.results[0]?.Actual) ||
        (parsedErrorDetails[0]?.Expected && parsedErrorDetails[0]?.Actual)
      ) {
        if (isJson(summary.columnArray)) {
          const parsedColumnsArray = JSON.parse(summary.columnArray);

          parsedErrorDetails.columns = parsedColumnsArray;
        }

        if (!parsedErrorDetails.results) {
          parsedErrorDetails.results = parsedErrorDetails;
        }

        // TMP: this needs to be changed to use a typ
        return (
          <Box
            sx={{
              // flexGrow: 1,
              overflowY: 'auto', // Add a scrollbar when content overflows
              overflowX: 'hidden', // Add a scrollbar when content overflows
            }}
          >
            <Box sx={{ height: 'calc(100vh - 390px)' }}>
              <MismatchRecordsTable data={parsedErrorDetails} rawData={summary.resultDetails} />
            </Box>
          </Box>
        );
      }
    } catch (error) {
      console.warn('Cannot parse error results, JSON fallback');
    }

    return <JsonViewer data={summary.resultDetails} />;
  };

  const getSuccessWindow = () => {
    // Only show the success if the test passed and the extended metadata is true
    if (summary?.resultOutcomeDescription !== 'Passed' || summary?.extendedMetadata !== true) return;

    return <TestCaseResultMetadataView testResult={summary} />;
  };

  return summary?.resultOutcomeDescription ? (
    <React.Fragment>
      <Info data={getSummaryInfo()} />
      <Box mt={1}></Box>
      {getErrorWindow()}
      {getSuccessWindow()}
    </React.Fragment>
  ) : (
    <CircularProgress />
  );
}

import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import './Editable.css';

const Editable = ({ isEditable, text, type, placeholder, children, childRef, className, disabled, ...props }) => {
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  const handleKeyDown = (event, type) => {
    const { key } = event;
    const keys = ['Escape', 'Tab'];
    const enterKey = 'Enter';
    const allKeys = [...keys, enterKey];
    if (
      (type === 'textarea' && keys.indexOf(key) > -1) ||
      (type !== 'textarea' && type !== 'editor' && allKeys.indexOf(key) > -1)
    ) {
      setEditing(false);
    }
  };

  const handleOnClick = () => {
    if ((isEditable === true || isEditable === undefined) && !disabled) {
      setEditing(true);
    }
  };

  return (
    <section {...props}>
      {isEditing ? (
        <div
          style={{ backgroundColor: 'white' }}
          onBlur={() => setEditing(false)}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <div
          className={className}
          onClick={handleOnClick}
          style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <Typography noWrap>{text || placeholder || ''}</Typography>
        </div>
      )}
    </section>
  );
};

export default Editable;

import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Typography, CircularProgress, TextField, IconButton, Collapse, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import VisualCard from '../../../components/VisualCard';
import VisualDetailsModal from '../../../components/VisualDetailsModal';
import QuickCreateTestCaseModal from '../../../components/QuickCreateTestCaseModal';
import { UNSUPPORTED_VISUALS } from '../../../constants/visuals';

export default function Summary(props) {
  const { report, visuals, updateVisual, visualUpdating, visualSelectionTypes } = props;
  const [groupedVisuals, setGroupedVisuals] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [quickCreateModalOpen, setQuickCreateModalOpen] = useState(false);
  const [detailsVisual, setDetailsVisual] = useState({});
  const [showUnsupportedVisuals, setShowUnsupportedVisuals] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // isLoading state
  const [searchOpen, setSearchOpen] = useState(false); // Search input visibility state
  const [searchQuery, setSearchQuery] = useState(''); // Search query state
  const [reportVisualSelectionTypeId, setReportVisualSelectionTypeId] = useState(-1); // Search query state

  useEffect(() => {
    if (!visualSelectionTypes) return;
    if (visualSelectionTypes.length === 0) return;

    const firstReportVisualSelection = visualSelectionTypes.find(
      (obj) => obj.selectionType === 'Report Visual Selection',
    );

    if (!firstReportVisualSelection) {
      throw new Error("No object found with selectionType 'Report Visual Selection'");
    }
    setReportVisualSelectionTypeId(firstReportVisualSelection.visualSelectionTypeId);

    // eslint-disable-next-line
  }, [visualSelectionTypes]);

  useEffect(() => {
    setIsLoading(true);

    if (visuals === null) return;

    if (groupedVisuals == null) {
      // // Wait a small delay so that the component can load - means smoother UI
      setTimeout(() => {
        setGroupedVisuals(groupVisuals());
        setIsLoading(false); // Set isLoading to false when the operation is done
      }, 500);
    } else {
      setGroupedVisuals(groupVisuals());
      setIsLoading(false); // Set isLoading to false when the operation is done
    }

    // eslint-disable-next-line
  }, [visuals]);

  // Group visuals by pageDisplayName
  const groupVisuals = () => {
    if (!visuals || visuals.length === 0) {
      return {};
    }

    if (Object.keys(visuals).length == 0) {
      return {};
    }

    const grouped = visuals.reduce((acc, visual) => {
      const key = `${visual.pageOrder}-${visual.pageDisplayName}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(visual);
      return acc;
    }, {});

    const orderedGrouped = {};
    // Reorder by pageOrder
    Object.keys(grouped)
      .sort((a, b) => {
        const pageOrderA = Number(a.split('-')[0]);
        const pageOrderB = Number(b.split('-')[0]);
        return pageOrderA - pageOrderB;
      })
      .forEach((key) => {
        orderedGrouped[key] = grouped[key];
      });

    // setVisualsGroupingIsLoading(false);
    return orderedGrouped;
  };

  const inScopeCheck = (checkboxState, visual) => {
    updateVisual({ ...visual, inScope: checkboxState });
  };

  const isVisualUpdating = (uniqueGuid) => {
    return visualUpdating.includes(uniqueGuid);
  };

  const openEditModal = (visual) => {
    setDetailsVisual(visual);
    setDetailsModalOpen(true);
  };

  const openQuickAddModal = (visual) => {
    setDetailsVisual(visual);
    setQuickCreateModalOpen(true);
  };

  const closeModal = () => {
    setDetailsModalOpen(false);
    setQuickCreateModalOpen(false);
  };

  const confirmEdit = (customName) => {
    setDetailsModalOpen(false);
    updateVisual({ ...detailsVisual, customName: customName });
  };

  const handleSearchIconClick = () => {
    setSearchOpen(!searchOpen);
    if (!searchOpen) {
      setSearchQuery(''); // Clear search query when opening the search input
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // If its isLoading show cirular progress bar
  if (isLoading) {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <CircularProgress />
        <Box ml={2}>
          <i>Loading visuals from Power BI</i>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto', // Add a scrollbar when content overflows
        overflowX: 'hidden', // Add a scrollbar when content overflows
      }}
    >
      <Box
        pr={4}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center', // Center the content vertically
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Tooltip title="Show unsupported visuals">
            <IconButton onClick={() => setShowUnsupportedVisuals(!showUnsupportedVisuals)}>
              {showUnsupportedVisuals ? <FilterAltOffIcon /> : <FilterAltIcon />}
            </IconButton>
          </Tooltip>
          <Typography>{'Show unsupported visuals'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <IconButton onClick={handleSearchIconClick}>
            <SearchIcon />
          </IconButton>
          <Collapse orientation="horizontal" in={searchOpen}>
            <TextField size="small" placeholder="Search visual name, type or guid" onChange={handleSearchInputChange} />
          </Collapse>
        </Box>
      </Box>
      {groupedVisuals ? ( // Render grouped visuals if available
        Object.entries(groupedVisuals).map(([key, visuals]) => {
          const pageDisplayName = key.split('-')[1];
          return (
            <div key={key} style={{ marginLeft: '3px' }}>
              <h3>{pageDisplayName}</h3>
              <Grid container spacing={2} pt={1} pb={3}>
                {visuals.map((vis, index) => {
                  const supportedVisual = !UNSUPPORTED_VISUALS.includes(vis.visualType);

                  if (!showUnsupportedVisuals && !supportedVisual) {
                    return null;
                  }

                  // const filteredVisuals = visuals.filter((vis) => vis.visualName.toLowerCase().includes(searchQuery));
                  if (searchQuery !== '') {
                    if (
                      !vis.visualName.toLowerCase().includes(searchQuery) &&
                      !vis.visualGuid.toLowerCase().includes(searchQuery) &&
                      !vis.visualType.toLowerCase().includes(searchQuery)
                    ) {
                      return null;
                    }
                  }

                  return (
                    <Grid key={index}>
                      <VisualCard
                        onCheck={inScopeCheck}
                        visual={vis}
                        isUpdating={isVisualUpdating(vis.uniqueGuid)}
                        openEditModal={openEditModal}
                        openQuickAddModal={openQuickAddModal}
                        visualTypeSupported={supportedVisual}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })
      ) : (
        <Typography>No visuals to display</Typography>
      )}
      <VisualDetailsModal
        title={detailsVisual.customName || detailsVisual.visualName}
        modalOpen={detailsModalOpen}
        confirm={confirmEdit}
        cancel={closeModal}
        canUndo={true}
        undoTitle={'Restore original name'}
        visualGuid={detailsVisual.visualGuid}
        customName={detailsVisual.customName}
        visualName={detailsVisual.visualName}
        visualType={detailsVisual.visualType}
      />

      <QuickCreateTestCaseModal
        modalOpen={quickCreateModalOpen}
        cancel={closeModal}
        visual={detailsVisual}
        reportGuid={report.reportGuid}
        powerBIReportGuid={report.powerBIReportGuid}
        reportName={report.reportName}
        reportVisualSelectionTypeId={reportVisualSelectionTypeId}
      />
    </Box>
  );
}

import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { mapUpdateTestCase } from '../../../api/mappings/testcase';
import { useGetPowerBIPages } from '../../../api/queries/powerBI';
import { useGetReportVisuals, useGetReports } from '../../../api/queries/report';
import { useGetTestPlanForTestCase, useUpdateTestCase } from '../../../api/queries/testCase';
import TabComponent from '../../../components/Tabs';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../constants/snackbar';
import updateTestCaseSchema from '../../../schema/updateTestCaseSchema';
import { getResponseErrorMsg } from '../../../utils/response';
import Summary from './Summary';
import TestPlans from './TestPlans';
import { useGetConnections } from '../../../api/queries/connection';
import { filterListByProperty, findObjectByPropertyCI } from '../../../utils/array';
import { enqueueSnackbar } from 'notistack';

export default function TestCaseView(props) {
  const DEFAULT_SELECTED_TAB = 'SUMMARY';

  const {
    actionType,
    testCaseData,
    openSnackbar,
    tags,
    visualSelectionTypes,
    deleteTestCase,
    executeQuickRun,
    toggleForm,
    setEditMode,
    setReadMode,
    onRenameAction,
  } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);

  const [saving, setSaving] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [reportUrlValues, setReportUrlValues] = useState({});
  const [databaseConnections, setDatabaseConnections] = useState([]);
  const [reportConnection, setReportConnection] = useState([]);

  const initData = {
    report: {},
    page: {},
    visualSelectionType: {},
    visual: {},
    altText: '',
    sourceQuery: '',
    tag: [],
    reportVisualUrl: '',
  };
  const [values, setValues] = useState(initData);
  const [initialValues, setInitialValues] = useState(initData);

  const { data: reports } = useGetReports(true, 'all', '');
  const { data: visuals } = useGetReportVisuals(!!values?.report?.reportGuid, values?.report?.reportGuid, '');

  const { data: connections, isLoading: connectionsIsLoading } = useGetConnections(true, 'all', null, null, null);

  useEffect(() => {
    if (!connectionsIsLoading && connections && connections.length > 0) {
      setDatabaseConnections(filterListByProperty(connections, 'connectionTypeGroup', 'Database'));
    }

    if (!connectionsIsLoading && connections && connections.length > 0 && !!values.report.connectionGuid) {
      const reportConnection = findObjectByPropertyCI(connections, 'connectionGuid', values.report.connectionGuid);

      if (reportConnection) {
        setReportConnection(reportConnection);
      }
    }
    // eslint-disable-next-line
  }, [connections, values.report]);

  const {
    data: powerBIReportPages,
    isLoading: powerBIReportPagesIsLoading,
    isFetching: powerBIReportPagesIsFetching,
    isError: powerBIReportPagesIsError,
    isSuccess: powerBIReportPagesIsSuccess,
  } = useGetPowerBIPages(
    !!reportUrlValues?.powerBIReportGuid && !!reportConnection?.connectionGuid, // enabled
    reportConnection,
    reportUrlValues.workspaceId,
    reportUrlValues.powerBIReportGuid,
    reportUrlValues.pageGuid,
    reportUrlValues.includeReportData,
  );

  const { data: testPlans, isLoading: testPlansIsLoading } = useGetTestPlanForTestCase(
    selectedTab === 'TEST_PLANS' && !!testCaseData.testCaseGuid,
    {
      testCaseGuid: testCaseData?.testCaseGuid,
    },
  );

  const updateMutation = useUpdateTestCase();
  const resetData = () => {
    setValues(initData);
    setInitialValues(initData);
  };

  useEffect(() => {
    if (!!reportUrlValues?.powerBIReportGuid && !!reportConnection?.connectionGuid && powerBIReportPagesIsSuccess) {
      enqueueSnackbar('Report details retrieved from URL', { variant: SNACKBAR_SUCCESS });
    }

    // eslint-disable-next-line
  }, [powerBIReportPagesIsSuccess]);

  useEffect(() => {
    if (!values?.visualSelectionType?.selectionType) return;

    switch (values.visualSelectionType.selectionType) {
      case 'Alt-text': {
        // set page value in page obj
        setValues({
          ...values,
          visual: null,
          visualUrl: '',
        });
        break;
      }
      case 'Report Visual Selection':
        setValues({
          ...values,
          altText: '',
          visualUrl: '',
        });
        break;
      case 'Visual URL':
        setValues({
          ...values,
          altText: '',
          visual: null,
          page: null,
        });
        break;
      default:
        return;
    }

    // eslint-disable-next-line
  }, [values.visualSelectionType]);

  useEffect(() => {
    // If the page is returned
    if (
      values.reportVisualUrl &&
      !powerBIReportPagesIsLoading &&
      powerBIReportPages &&
      powerBIReportPages.length > 0 &&
      powerBIReportPagesIsSuccess
    ) {
      // set page value in page obj

      setValues({
        ...values,
        page: powerBIReportPages[0],
      });
    }

    // eslint-disable-next-line
  }, [powerBIReportPages, powerBIReportPagesIsLoading]);

  const getPowerBIDetailsFromUrl = (visualUrl) => {
    try {
      if (!visualUrl) return;

      const url = new URL(visualUrl);
      if (!url) return;

      const workspaceId = url.pathname.split('/')[2];
      const powerBIReportGuid = url.pathname.split('/')[4];
      const pageGuid = url.pathname.split('/')[5];
      const visualGuid = url.searchParams.get('visual');

      if ((workspaceId, powerBIReportGuid, pageGuid, visualGuid)) {
        setValues({
          ...values,
          visualGuid: visualGuid,
        });
        return { workspaceId, powerBIReportGuid, pageGuid, visualGuid };
      } else {
        openSnackbar('Cannot parse Url', SNACKBAR_ERROR);
      }
    } catch (error) {
      console.error(error);
      openSnackbar('Cannot parse Url', SNACKBAR_ERROR);
    }
  };

  useEffect(() => {
    if (values.reportVisualUrl) {
      setValues({
        ...values,
        page: null,
      });
      const reportUrl = getPowerBIDetailsFromUrl(values.reportVisualUrl);
      if (!reportUrl) {
        return;
      }

      const workspaceId = reportUrl.workspaceId;
      const powerBIReportGuid = reportUrl.powerBIReportGuid;
      const pageGuid = reportUrl.pageGuid;
      const includeReportData = true;

      if (reports && reports.length > 0) {
        // Find the report in the report list
        const reportFromList = findObjectByPropertyCI(reports, 'powerBIReportGuid', powerBIReportGuid);

        if (reportFromList) {
          setReportUrlValues({ workspaceId, powerBIReportGuid, pageGuid, includeReportData });
        } else {
          enqueueSnackbar('Cannot retrieve report data, ensure you have created the report in PowerTester.', {
            variant: SNACKBAR_ERROR,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [values.reportVisualUrl]);

  const getSummaryData = async () => {
    const data = {
      testCaseName: testCaseData.testCaseName ?? '',
      testCaseGuid: testCaseData.testCaseGuid ?? '',
      connection:
        {
          connectionGuid: testCaseData.connectionGuid,
          connectionName: testCaseData.connectionName,
          isLinkedToKeyVault: testCaseData.isLinkedToKeyVault,
          authenticationType: testCaseData.authenticationType,
        } ?? {},
      report:
        {
          reportGuid: testCaseData.reportGuid,
          reportName: testCaseData.reportName,
          connectionGuid: testCaseData.reportConnectionGuid,
          powerBIReportGuid: testCaseData.powerBIReportGuid,
        } ?? {},
      page:
        {
          pageDisplayName: testCaseData.pageDisplayName,
          pageGuid: testCaseData.pageGuid,
          pageOrder: testCaseData.pageOrder,
        } ?? {},
      altText: testCaseData.altText ?? '',
      visual:
        {
          visualName: testCaseData.visualName,
          visualGuid: testCaseData.visualGuid,
          uniqueGuid: testCaseData.uniqueGuid,
          pageDisplayName: testCaseData.pageDisplayName,
          pageGuid: testCaseData.pageGuid,
          pageOrder: testCaseData.pageOrder,
        } ?? {},
      reportVisualUrl: testCaseData.reportVisualUrl ?? '',
      sourceQuery: testCaseData.sourceQuery ?? '',
      visualSelectionType: {
        selectionType: testCaseData.selectionType,
        visualSelectionTypeId: testCaseData.visualSelectionTypeId,
      },
      tag: testCaseData.tagIds && testCaseData.tagIds.length > 0 ? JSON.parse(testCaseData.tagIds) : [],
    };

    setValues(data);
    setInitialValues(data);
  };

  const handleTabChange = async (tabName) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    if (!testCaseData) {
      resetData();
    }
    switch (selectedTab) {
      case 'SUMMARY':
        getSummaryData();
        break;
      default:
        return;
    }
    // eslint-disable-next-line
  }, [selectedTab, testCaseData]);

  const tabOptions = [
    { name: 'Summary', value: 'SUMMARY' },
    { name: 'Test Plans', value: 'TEST_PLANS' },
  ];

  const submitForm = async () => {
    try {
      setSaving(true);

      // Validate the form before submitting
      await updateTestCaseSchema.validate(values, { abortEarly: true });

      const data = mapUpdateTestCase(values);
      await updateMutation.mutateAsync(data);

      setInitialValues(values);
      setResponseStatus('success');
    } catch (error) {
      console.error(error);
      let fallbackMessage = 'Unable to update test case';
      if (error.errors && error.errors.length >= 0) {
        fallbackMessage = error.errors[0];
      }
      const errorMsg = getResponseErrorMsg(error.response, fallbackMessage);
      openSnackbar(errorMsg, SNACKBAR_ERROR);
      setResponseStatus('failure');
    }
    setSaving(false);
  };

  const resetForm = () => {
    setValues(initialValues);
  };

  const getTabViews = () => {
    return tabViews[selectedTab]();
  };

  const tabViews = {
    SUMMARY: () => {
      return (
        <Summary
          toggleForm={toggleForm}
          actionType={actionType}
          setEditMode={setEditMode}
          setReadMode={setReadMode}
          values={values}
          setValues={setValues}
          submitForm={submitForm}
          resetForm={resetForm}
          saving={saving}
          reports={reports}
          visuals={visuals}
          powerBIReportPages={powerBIReportPages}
          tags={tags ?? []}
          visualSelectionTypes={visualSelectionTypes}
          responseStatus={responseStatus}
          reportPageLoading={powerBIReportPagesIsLoading && powerBIReportPagesIsFetching}
          connections={connections}
          databaseConnections={databaseConnections}
          connectionsIsLoading={connectionsIsLoading}
          powerBIReportPagesIsError={powerBIReportPagesIsError}
          deleteTestCase={deleteTestCase}
          executeQuickRun={executeQuickRun}
          onRenameAction={onRenameAction}
        />
      );
    },
    TEST_PLANS: () => {
      return <TestPlans testPlans={testPlans} isLoading={testPlansIsLoading} />;
    },
    HISTORY: () => {
      return <></>;
    },
  };
  return (
    <Box>
      <Box display="flex" px={4} pb={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <span className="sub-header"> </span>
      </Box>
      <TabComponent handleChange={handleTabChange} selectedTab={DEFAULT_SELECTED_TAB} options={tabOptions} />
      <Divider />
      <Box px={4} mt={3}>
        {getTabViews()}
      </Box>
    </Box>
  );
}

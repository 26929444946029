import * as Yup from 'yup';
import errors from '../constants/errors';

function validatePowerBISharedVisualUrl(url) {
  if (!url) {
    return false;
  }
  if (!url.startsWith('https://app.powerbi.com/') && !url.startsWith('https://app.fabric.microsoft.com/')) {
    return false;
  }
  if (!url.includes('/reports/')) {
    return false;
  }
  if (!url.includes('/ReportSection')) {
    return false;
  }
  return true;
}

const UpdateTestCaseSchema = Yup.object().shape({
  testCaseName: Yup.string().required(errors.TEST_CASE.REQUIRED).max(255, errors.TEST_CASE_NAME.MAX_LENGTH(255)),

  sourceQuery: Yup.string().required(errors.SQL_QUERY.REQUIRED).max(8000, errors.REPORT_NAME.MAX_LENGTH(8000)),

  visualSelectionType: Yup.object()
    .nullable()
    .required(errors.VISUAL_SELECTION_TYPE.REQUIRED)
    .shape({
      selectionType: Yup.string().required(errors.VISUAL_SELECTION_TYPE.REQUIRED),
    }),
  report: Yup.string()
    .nullable()
    .when('visualSelectionType.selectionType', {
      is: 'Alt-text',
      then: Yup.string().required(errors.REPORT_NAME.REQUIRED),
      otherwise: Yup.string().when('visualSelectionType.selectionType', {
        is: 'Report Visual Selection',
        then: Yup.string().required(errors.REPORT_NAME.REQUIRED),
      }),
    }),
  page: Yup.string()
    .nullable()
    .when('visualSelectionType.selectionType', {
      is: 'Alt-text',
      then: Yup.string().nullable().required(errors.PAGE_NAME.REQUIRED),
      otherwise: Yup.string().when('visualSelectionType.selectionType', {
        is: 'Visual URL',
        then: Yup.string().nullable().required('Cannot retrieve visual details from Url, please check Url is correct'),
      }),
    }),
  altText: Yup.string().when('visualSelectionType.selectionType', {
    is: 'Alt-text',
    then: Yup.string().required(errors.ALT_TEXT.REQUIRED).max(2000, errors.REPORT_NAME.MAX_LENGTH(2000)),
  }),
  visual: Yup.string()
    .nullable()
    .when('visualSelectionType.selectionType', {
      is: 'Report Visual Selection',
      then: Yup.string().nullable().required(errors.VISUAL.REQUIRED),
    }),
  reportVisualUrl: Yup.string().when('visualSelectionType.selectionType', {
    is: 'Visual URL',
    then: Yup.string()
      .url('Please enter a valid URL')
      .test('powerbi-shared-visual-url', 'Please enter a valid Power BI shared visual URL', (value) =>
        validatePowerBISharedVisualUrl(value),
      )
      .required(errors.VISUAL_URL.REQUIRED)
      .max(2000, errors.VISUAL_URL.MAX_LENGTH(2000)),
  }),
});
export default UpdateTestCaseSchema;

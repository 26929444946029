import { useQuery, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { queryClient } from '../../index';
import * as report from '../endpoints/report';
import * as token from '../endpoints/token';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';

const allReportsKey = ['reports', 'all'];

// Queries
export const useGetReports = (enabled, queryKey, nameFilter) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['reports', queryKey],
    queryFn: () => {
      if (queryKey !== 'all') {
        return report.get(queryKey);
      } else {
        return report.get();
      }
    },
    enabled: enabled,
    select: (reports) => {
      if (!nameFilter) return reports;
      return reports.filter((c) => c.reportName?.toLowerCase().includes(nameFilter.toLowerCase()));
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve report data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetReportVisuals = (enabled, reportGuid) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['reportVisuals', reportGuid],
    queryFn: () => report.getVisuals(reportGuid),

    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve report visuals');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetReportCombinedVisuals = (enabled, connection, reportGuid, powerBIReportGuid) => {
  const { enqueueSnackbar } = useSnackbar();

  const isServicePrincipalAuthentication = connection?.authenticationType === 'Service principal';
  return useQuery({
    queryKey: ['combinedReportVisuals', reportGuid],
    queryFn: async () => {
      const bearerToken = await queryClient.fetchQuery({
        queryKey: ['powerBIToken', connection?.connectionGuid],
        queryFn: () =>
          token.getPowerBIBearerToken(
            connection?.connectionGuid,
            isServicePrincipalAuthentication,
            connection?.isLinkedToKeyVault,
          ),
      });

      if (!bearerToken) {
        throw new Response('', {
          status: 403,
          statusText: 'Bearer Token missing',
        });
      }

      return report.getCombinedVisuals(
        {
          bearerToken,
          isServicePrincipalAuthentication,
          workspaceId: connection?.workspaceId,
          powerBIReportGuid,
        },
        reportGuid,
      );
    },

    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve report visuals');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestCasesForReport = (enabled, reportGuid) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['report', 'testCase', reportGuid],
    queryFn: () => report.getTestCases(reportGuid),

    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test cases');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

// Mutations
export const useCreateReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => report.create(rawData),

    onSuccess: (data) => {
      if (!data) return;

      queryClient.invalidateQueries({ queryKey: allReportsKey }); // invalidate the report list (easier than updating state)

      // Show success snackbar
      enqueueSnackbar('Report created successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the report');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useUpdateReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { reportGuid, ...body } = rawData;
      return report.update(body, reportGuid);
    },

    onSuccess: (data, updatedReport) => {
      if (!data) return;
      queryClient.invalidateQueries({ queryKey: allReportsKey }); // invalidate the report list (easier than updating state)
      queryClient.invalidateQueries({ queryKey: ['reportVisuals', updatedReport.reportGuid] }); // invalidate the report visual list

      // Show success snackbar
      enqueueSnackbar('Report updated successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the report');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useUpdateReportVisual = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { reportGuid, visualGuid, ...body } = rawData;
      return report.updateVisual(body, reportGuid, visualGuid);
    },

    onSuccess: (resp, updatedVisual) => {
      // Update the report visual in state

      if (!resp || !updatedVisual) return;

      const reportGuid = updatedVisual.reportGuid;
      const queryKey = ['combinedReportVisuals', reportGuid];

      // Ge the current list
      const currentList = queryClient.getQueryData(queryKey);

      if (!currentList || currentList.length === 0) return;
      // Update the correct visual in the list
      var updatedList = currentList.map((item) => {
        // If the visualGuid matches, update the inScope property
        if (item.uniqueGuid === updatedVisual.uniqueGuid) {
          return { ...item, inScope: updatedVisual.inScope, customName: updatedVisual.customName };
        }
        // Otherwise, return the item unchanged
        return item;
      });
      queryClient.setQueryData(queryKey, updatedList); // invalidate the report visual
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the report visual');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDeleteReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (reportGuid) => report.remove(reportGuid),

    onSuccess: (data, reportGuid) => {
      // Remove the report from the reports cache (faster than invalidating)
      queryClient.setQueryData(allReportsKey, (old) => old.filter((t) => t.reportGuid !== reportGuid));

      // Show success snackbar
      enqueueSnackbar('Report deleted successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to delete the report');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

//prefetch
export const prefetchReportList = () => {
  return queryClient.prefetchQuery(allReportsKey, () => report.get());
};

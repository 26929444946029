import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import * as keyVault from '../endpoints/keyVault';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';

// Queries
export const useGetKeyVaultSecrets = (enabled, keyVaultConnection) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['keyVaultSecrets', keyVaultConnection?.connectionGuid],
    queryFn: () => {
      return keyVault.getSecrets({
        keyVaultUrl: keyVaultConnection.keyVaultUrl,
        tenantId: keyVaultConnection.tenantId,
        servicePrincipalAppId: keyVaultConnection.servicePrincipalAppId,
        servicePrincipalSecret: keyVaultConnection.servicePrincipalSecret,
      });
    },
    enabled: enabled,
    staleTime: 0, // dont cache
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve key vault secrets');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import TablePaginationActions from '../../../components/TablePaginationActions';
import { VIEW_DETAILS } from '../../../constants/sidedrawer';
import { formatDate, formatSecondsToHms } from '../../../utils/date';
import TableSkeleton from '../../../components/TableSkeleton';
import StatusBadge from '../../../components/StatusBadge';

export default function TestRunList(props) {
  const { testRuns, selectTestRun, smallScreen, isLoading } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [testRuns]);

  return (
    <TableContainer>
      <Table className="list-table fixed" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" className="column">
              Test Plan Name
            </TableCell>
            <TableCell component="th" className="column">
              Run time
            </TableCell>
            {!smallScreen ? (
              <>
                <TableCell component="th" className="column">
                  # Of Tests
                </TableCell>
                <TableCell component="th" className="column">
                  Pass %
                </TableCell>
                <TableCell component="th" className="column">
                  Status
                </TableCell>
                <TableCell component="th" className="column">
                  Duration
                </TableCell>
              </>
            ) : (
              <>
                <TableCell component="th" className="column">
                  Pass %
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            (testRuns && testRuns?.length > 0
              ? rowsPerPage > 0
                ? testRuns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : testRuns
              : []
            ).map((row) => (
              <TableRow
                className="pointer"
                onClick={() => selectTestRun(row.testRunGuid, VIEW_DETAILS)}
                key={row.testRunGuid}
              >
                <TableCell className="column">{row.testPlanName}</TableCell>
                <TableCell className="column">{formatDate(row.runTime)}</TableCell>
                {!smallScreen ? (
                  <>
                    <TableCell className="column">{row.noOfTests || '0'}</TableCell>
                    <TableCell className="column">
                      {(row.passRate && _.round(row.passRate * 100) + '%') || '0%'}
                    </TableCell>
                    <TableCell className="column">
                      <StatusBadge status={row.status} showText />
                    </TableCell>
                    <TableCell className="column">{formatSecondsToHms(row.runDuration) || ''}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell className="column">
                      {(row.passRate && _.round(row.passRate * 100) + '%') || '0%'}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))
          ) : (
            <TableSkeleton columns={6} rows={10} hasMenu />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={testRuns?.length ?? 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

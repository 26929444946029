import { Box, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  useGetReportCombinedVisuals,
  useGetTestCasesForReport,
  useUpdateReportVisual,
} from '../../../api/queries/report';

import TabComponent from '../../../components/Tabs';
import { GENERIC_ERRORS } from '../../../constants/errors';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../constants/snackbar';
import withSnackbar from '../../../hocs/withSnackbar';
import { getResponseErrorMsg } from '../../../utils/response';
import ReportTestCases from './ReportTestCases';
import Visuals from './Visuals';
import { filterListByProperty, findObjectByProperty } from '../../../utils/array';
import { EDIT_FORM } from '../../../constants/sidedrawer';
import FormButtons from '../../../components/FormButtons';
import { exportToFile } from '../../../utils/fileDownload';
import { exportReport } from '../../../export/report';

function ReportView(props) {
  const DEFAULT_SELECTED_TAB = 'VISUALS';
  const { reportData, openSnackbar, reportDataLoading, visualSelectionTypes, connections } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);
  const [connection, setConnection] = useState({});

  const [visualUpdating, setVisualUpdating] = useState([]);

  // const { data: connections } = useGetConnections(true, 'all', null, 'Power BI');

  const {
    data: visuals,
    isLoading: visualsIsLoading,
    isError: visualsIsError,
    isFetched: visualsFetched,
  } = useGetReportCombinedVisuals(
    !!reportData.powerBIReportGuid && !!connection?.connectionGuid,
    connection,
    reportData.reportGuid,
    reportData.powerBIReportGuid,
  );

  useEffect(() => {
    if (!reportDataLoading && reportData && reportData?.powerBIReportGuid) {
      setConnection(findObjectByProperty(connections, 'connectionGuid', reportData.connectionGuid));
    }
    // eslint-disable-next-line
  }, [reportData, reportDataLoading, connections]);

  useEffect(() => {
    if (!visualsIsLoading && !visualsIsError && visuals.length > 0) {
      backgroundUpdateVisuals(filterListByProperty(visuals, 'visualToUpdate', true));
    }
    // eslint-disable-next-line
  }, [visuals, visualsIsLoading, visualsIsError]);

  const { data: testCases, isLoading: testCasesIsLoading } = useGetTestCasesForReport(
    selectedTab === 'TEST_CASES',
    reportData.reportGuid,
  );

  const updateReporVisualMutation = useUpdateReportVisual();

  const backgroundUpdateVisuals = async (visualsToUpdate) => {
    if (visualsToUpdate.length === 0) return;

    visualsToUpdate.forEach((vis) => {
      const data = {
        visualGuid: vis.visualGuid,
        visualName: vis.visualName,
        customName: vis.customName,
        visualType: vis.visualType ?? 'Unknown Visual Type',
        pageDisplayName: vis.pageDisplayName,
        pageGuid: vis.pageGuid,
        pageOrder: vis.pageOrder,
        inScope: vis.inScope,
        reportGuid: reportData?.reportGuid,
        uniqueGuid: vis.uniqueGuid,
      };
      updateReporVisualMutation.mutateAsync(data);
    });
  };

  const handleTabChange = async (tabName) => {
    setSelectedTab(tabName);
  };

  const tabOptions = [
    { name: 'Visuals', value: 'VISUALS' },
    { name: 'Test Cases', value: 'TEST_CASES' },
  ];

  const getTabViews = () => {
    if (visualsIsLoading && selectedTab === 'VISUALS') {
      return (
        <Box display={'flex'} alignItems={'center'}>
          <CircularProgress />
          <Box ml={2}>
            <i>Loading visuals from Power BI</i>
          </Box>
        </Box>
      );
    }
    return tabViews[selectedTab]();
  };

  const tabViews = {
    VISUALS: () => {
      return (
        <Visuals
          report={reportData}
          visuals={visuals}
          updateVisual={updateVisual}
          dataLoaded={!visualsIsLoading && !visualsIsError}
          visualUpdating={visualUpdating}
          visualSelectionTypes={visualSelectionTypes}
        />
      );
    },
    TEST_CASES: () => {
      return <ReportTestCases testCases={testCases} isLoading={testCasesIsLoading} />;
    },
  };

  const updateVisual = async (values) => {
    try {
      setVisualUpdating((prevUpdating) => [...prevUpdating, values.uniqueGuid]);
      const data = {
        visualGuid: values.visualGuid,
        visualName: values.visualName,
        customName: values.customName,
        visualType: values.visualType,
        pageDisplayName: values.pageDisplayName,
        pageGuid: values.pageGuid,
        pageOrder: values.pageOrder,
        inScope: values.inScope,
        reportGuid: reportData?.reportGuid,
        uniqueGuid: values.uniqueGuid,
      };

      // Update report visual
      await updateReporVisualMutation.mutateAsync(data);

      openSnackbar('Visual updated', SNACKBAR_SUCCESS, null, null, 2000);
    } catch (error) {
      console.error(error);
      openSnackbar(getResponseErrorMsg(error.response, GENERIC_ERRORS.REPORT_VISUAL.UPDATE), SNACKBAR_ERROR);
    } finally {
      setVisualUpdating((prevUpdating) => prevUpdating.filter((id) => id !== values.uniqueGuid));
    }
  };

  const onDelete = () => {
    props.toggleDeleteModal();
  };

  const onEditForm = () => {
    props.toggleForm(EDIT_FORM, 'keepopen');
  };

  const onExport = () => {
    exportToFile('json', exportReport(reportData, visuals), reportData.reportName);
  };

  const buttonsArray = [
    {
      buttonName: 'Edit',
      buttonIcon: 'edit',
      action: onEditForm,
    },
  ];

  const contextMenuArray = [
    {
      label: 'Delete',
      icon: 'delete',
      action: onDelete,
    },
    {
      label: 'Export',
      icon: 'export',
      action: onExport,
      disabled: !visualsFetched && !visualsFetched,
    },
  ];

  return (
    <React.Fragment>
      <TabComponent handleChange={handleTabChange} selectedTab={DEFAULT_SELECTED_TAB} options={tabOptions} />
      <Divider />
      <Box
        pl={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {getTabViews()}
        <Box
          pt={3}
          pr={4}
          pb={3}
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            height: 70,
          }}
        >
          <FormButtons buttons={buttonsArray} contextMenuOptions={contextMenuArray} />
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default withSnackbar(ReportView);

import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import * as copilot from '../endpoints/copilot';
import { SNACKBAR_WARNING } from '../../constants/snackbar';

// Queries
export const useCopilotGenerateSQL = (enabled, powerBIQuery, visualGuid) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['generatedSQL', visualGuid],
    staleTime: 0, // instant staleness
    queryFn: () => {
      const powerBIQueryJSON = JSON.stringify({ powerBIQuery: JSON.stringify(powerBIQuery) });

      return copilot.generateSQL({ powerBIQuery: powerBIQueryJSON });
    },
    enabled: enabled,
    onError: () => {
      const errorMsg = 'Unable to generate SQL';
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_WARNING });
    },
  });
};

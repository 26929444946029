import React from 'react';

function withNotifiy(WrappedComponent) {
  function WithNotifiy(props) {
    return (
      <React.Fragment>
        <WrappedComponent {...props} />
      </React.Fragment>
    );
  }
  return WithNotifiy;
}

export default withNotifiy;

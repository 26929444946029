import React, { useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Autocomplete from '../../components/Autocomplete';
import { useGetConnections } from '../../api/queries/connection';
import Code from '../Code';
import createTestCaseSchema from '../../schema/quickCreateTestCaseSchema';
import { mapQuickCreateTestCaseData } from '../../api/mappings/testcase';
import { useCreateTestCase } from '../../api/queries/testCase';
import { getResponseErrorMsg } from '../../utils/response';
import withSnackbar from '../../hocs/withSnackbar';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { LoadingButton } from '@mui/lab';
import ResponseCard from '../ResponseCard';

function QuickCreateTestCaseModal(props) {
  const {
    modalOpen,
    cancel,
    visual,
    reportGuid,
    powerBIReportGuid,
    reportName,
    reportVisualSelectionTypeId,
    openSnackbar,
  } = props;

  const [isDetailsSubmitted, setDetailsSubmitted] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [isDetailsSubmitting, setDetailsSubmitting] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [testCaseName, setTestCaseName] = useState('');

  const createMutation = useCreateTestCase();

  const { data: connections, isLoading: connectionsIsLoading } = useGetConnections(
    modalOpen,
    'all',
    null,
    null,
    'Database',
  );

  const handleCancel = () => {
    setDetailsSubmitted(false);
    setDetailsSubmitting(false);
    setResponseStatus('');
    setResponseError('');
    setTestCaseName('');

    cancel();
  };

  const handleSubmit = async (values, formikBag) => {
    try {
      setDetailsSubmitting(true);

      const data = mapQuickCreateTestCaseData(
        values.testCaseName,
        values.sourceQuery,
        values.connection.connectionGuid,
        visual,
        reportVisualSelectionTypeId,
        reportGuid,
        powerBIReportGuid,
        reportName,
      );

      await createMutation.mutateAsync({ ...data });
      setTestCaseName(values.testCaseName);
      formikBag.resetForm(); // Reset the form after successful submission
      setResponseStatus('success');
    } catch (error) {
      console.error(error);
      setResponseStatus('error');
      setResponseError(getResponseErrorMsg(error.response));
      openSnackbar('Error creating test case', SNACKBAR_ERROR);
    } finally {
      setDetailsSubmitted(true);
      setDetailsSubmitting(false);
    }
  };

  const createQuickCreateForm = () => {
    const formik = useFormik({
      initialValues: {
        testCaseName: '',
        sourceQuery: '',
        connection: null,
      },
      validationSchema: createTestCaseSchema,
      onSubmit: (values, formikBag) => handleSubmit(values, formikBag), // Pass formikBag to handleSubmit
    });

    return (
      <div>
        <form onSubmit={formik.handleSubmit}>
          {!isDetailsSubmitted && (
            <React.Fragment>
              <Stack direction="column" spacing={3} mb={3} maxWidth={'400px'}>
                <Box className="form-field-container">
                  <TextField
                    label="Test Case Name"
                    variant="filled"
                    fullWidth
                    id="testCaseName"
                    name="testCaseName"
                    value={formik.values.testCaseName}
                    onChange={formik.handleChange}
                    error={formik.touched.testCaseName && Boolean(formik.errors.testCaseName)}
                  />
                  {Boolean(formik.errors.testCaseName) && formik.touched.testCaseName && (
                    <Box color="error.main" className={`form-field-error-text`}>
                      {formik.errors.testCaseName}
                    </Box>
                  )}
                </Box>
                <Box mb={3} className="form-field-container">
                  <Autocomplete
                    loading={connectionsIsLoading}
                    multiple={false}
                    name="connection"
                    setFieldValue={formik.setFieldValue}
                    options={connections}
                    label={'connectionName'}
                    value={'connectionGuid'}
                    inputLabel={'Selection connection'}
                    error={formik.touched.connection && Boolean(formik.errors.connection)}
                  />
                  {Boolean(formik.errors.connection) && formik.touched.connection && (
                    <Box color="error.main" className="form-field-error-text">
                      {formik.errors.connection}
                    </Box>
                  )}
                </Box>
                <Box mb={5} className="form-field-container">
                  <Code
                    disabled={false}
                    placeholder="Enter SQL Query..."
                    value={formik.values.sourceQuery}
                    onValueChange={(v) => {
                      formik.setFieldValue('sourceQuery', v);
                    }}
                    id="sourceQuery"
                    className={`editor large ${
                      formik.errors.sourceQuery && formik.touched.sourceQuery ? ' error' : ''
                    }`}
                  />

                  {Boolean(formik.errors.sourceQuery) && formik.touched.sourceQuery && (
                    <Box color="error.main" className="form-field-error-text">
                      {formik.errors.sourceQuery}
                    </Box>
                  )}
                </Box>
              </Stack>
              <Stack display={'flex'} justifyContent={'flex-end'} direction={'row'} spacing={3} mt={1}>
                <Button onClick={handleCancel} color="primary">
                  Cancel
                </Button>
                <LoadingButton
                  disabled={Boolean(props.buttonDisabled)}
                  className="yellow"
                  variant="contained"
                  type="submit"
                  loading={isDetailsSubmitting}
                >
                  Create
                </LoadingButton>
              </Stack>
            </React.Fragment>
          )}
          {isDetailsSubmitted && responseForm()}
        </form>
      </div>
    );
  };

  const responseForm = () => {
    const result = [{ key: 'Name', value: testCaseName }];
    return (
      <ResponseCard
        title={!responseError ? 'Test Case Added' : 'An error occured'}
        values={result}
        responseType={responseStatus || 'pre-submit'}
        errorDetails={responseError}
      />
    );
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isDetailsSubmitted ? 'Test Case Created' : 'Quick Create Test Case'}
      </DialogTitle>
      <DialogContent style={{ width: '400px', padding: '10px', minHeight: isDetailsSubmitted ? '150px' : '450px' }}>
        {createQuickCreateForm()}
      </DialogContent>
    </Dialog>
  );
}

export default withSnackbar(QuickCreateTestCaseModal);

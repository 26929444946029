import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import * as dashboard from '../endpoints/dashboard';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';

// Queries
export const useGetSummary = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['dashboardSummary'],
    queryFn: () => dashboard.getSummary(),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetChecklist = (enabled) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['checklist'],
    queryFn: () => dashboard.getChecklist(),
    staleTime: 60 * (60 * 1000), // 60 mins
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDashboardGetFailedTests = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['dashboardFailedTests'],
    queryFn: () => dashboard.getFailedTests(params),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDashboardGetTestRuns = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['dashboardTestRuns'],
    queryFn: () => dashboard.getTestRuns(params),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetDashboardFailedTestTrend = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['dashboardFailedTestTrend'],
    queryFn: () => dashboard.getDashboardFailedTestTrend(params),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetDashboardTestDurationTrend = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['dashboardTestDurationTrend'],
    queryFn: () => dashboard.getDashboardTestDurationTrend(params),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetDashboardPassRate = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['dashboardPassRate'],
    queryFn: () => dashboard.getDashboardPassRate(params),
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve dashboard data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { formatDate } from '../../../utils/date';

export default function RecentTestRunsList(props) {
  const { recentTestRuns, limit, onClick, error, noData } = props;

  return (
    <React.Fragment>
      {!error && !noData ? (
        <TableContainer>
          <Table className="list-table fixed" aria-label="simple table" mt={1}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" className="column">
                  Test Plan Name
                </TableCell>
                <TableCell component="th" scope="row" className="column">
                  Run Time
                </TableCell>
                <TableCell component="th" scope="row" className="column">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentTestRuns.slice(0, limit - 1).map((row, index) => (
                <TableRow className="pointer" key={index} onClick={() => onClick('/testruns/' + row.testRunGuid)}>
                  <TableCell className="column">{row.testPlanName}</TableCell>
                  <TableCell className="column">{formatDate(row.startDateTime, '')}</TableCell>
                  <TableCell className="column">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box height="200px" display="flex" alignItems="center" justifyContent="center">
          <h2>No data to display</h2>
        </Box>
      )}
    </React.Fragment>
  );
}

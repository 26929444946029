import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Cookies from 'universal-cookie';

import { useGetAnnouncements } from '../../api/queries/announcement';
import { isObjEmpty } from '../../utils/obj';

function Banner(props) {
  const { hyperlinkText, hyperlinkUrl, showBanner, closeBanner } = props;
  const theme = useTheme();
  const cookies = new Cookies();

  const { data: announcement } = useGetAnnouncements(true);

  const styles = {
    bannerContainer: {
      display: 'flex',
      backgroundColor: `${theme.palette.info.main}`,
      color: `${theme.palette.white.default}`,
      height: 30,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    bannerItem: {
      width: '100px',
    },
  };

  const onCloseBanner = () => {
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999); // set to the end of the current day

    cookies.set('hideBanner', true, { expires: endOfToday });

    closeBanner();
  };

  if (!announcement?.[0]) return;

  if (isObjEmpty(announcement[0])) {
    console.log('announce is empty exiting');
    return;
  }

  console.log('announce', announcement[0]);

  return (
    <React.Fragment>
      {showBanner && (
        <Slide in={true} timeout={1000}>
          <Box sx={styles.bannerContainer}>
            <Box sx={styles.bannerItem}></Box>
            <Box>
              {announcement[0]}
              {hyperlinkUrl && (
                <Box ml="3px">
                  <u>
                    <a
                      target="_blank"
                      href={hyperlinkUrl}
                      rel="noopener noreferrer"
                      className="navigation-list__item__link"
                    >
                      {hyperlinkText}
                    </a>
                  </u>
                </Box>
              )}
            </Box>
            <Box sx={styles.bannerItem}>
              <IconButton aria-label="delete" sx={{ color: `${theme.palette.white.default}` }} onClick={onCloseBanner}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Slide>
      )}
    </React.Fragment>
  );
}
export default Banner;

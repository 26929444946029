import React, { useState } from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpPopup from '../HelpPopup';

function FieldHelpText(props) {
  const { content, linkText, link, sqlOverride, small } = props;
  const [popUpOpen, setPopUpOpen] = useState({ content: '', anchorEl: false, linkText: '', linkUrl: '' });

  const handleOpenPopup = (content, linkText, linkUrl) => (event) => {
    setPopUpOpen({ content: content, anchorEl: event.currentTarget, linkText: linkText, linkUrl: linkUrl });
  };

  const handleClosePopup = () => {
    setPopUpOpen({ anchorEl: null });
  };

  return (
    <React.Fragment>
      <Box
        className={`form-field-help-icon ${sqlOverride ? ' sql-button' : ''}  ${small ? ' small' : ''}`}
        alignItems="flex-start"
      >
        <IconButton onClick={handleOpenPopup(content, linkText, link)} className="iconContainer help" size={'large'}>
          <HelpOutlineIcon />
        </IconButton>
      </Box>
      <HelpPopup handleClosePopup={handleClosePopup} popUpOpen={popUpOpen} />
    </React.Fragment>
  );
}
export default FieldHelpText;

import React from 'react';
import TestCaseResultMetadataSystemView from '../TestCaseResultMetadataSystemView';
import { Divider, Stack, Typography } from '@mui/material';
import StatusBadge from '../StatusBadge';
import DurationHorizontalBarChart from '../DurationHorizontalBarChart';

const TestCaseResultMetdataView = (props) => {
  const { testResult } = props;
  if (!testResult?.sourceRowCount) return;

  const systemComparision = () => {
    return (
      <div className="test-result-container">
        <div className="test-result-box">
          <TestCaseResultMetadataSystemView name={testResult.connectionName} type={testResult.connectionType} />
        </div>
        <div className="test-result-box center-div">
          <Divider sx={{ borderStyle: 'dashed' }} flexItem>
            <StatusBadge onlyIcon status="Passed" />
          </Divider>
          <span>{testResult.sourceRowCount} rows checked</span>
        </div>
        <div className="test-result-box">
          <TestCaseResultMetadataSystemView
            name={testResult.powerBiConnectionName}
            type={testResult.powerBiConnectionType}
          />
        </div>
      </div>
    );
  };

  const durationBreakdown = () => {
    return (
      <div>
        <Typography variant="h5">Duration Breakdown</Typography>
        <DurationHorizontalBarChart
          totalDuration={testResult.durationInMSeconds}
          sourceDuration={testResult.sourceDurationInMSeconds}
          powerBIDuration={testResult.powerBIDurationInMSeconds}
        />
      </div>
    );
  };

  return (
    <Stack spacing={2}>
      {systemComparision()}
      <Divider />
      {durationBreakdown()}
    </Stack>
  );
};

export default TestCaseResultMetdataView;

import * as Yup from 'yup';
import errors from '../constants/errors';

const CreateTestCaseSchema = [
  Yup.object().shape({
    testPlanName: Yup.string().required(errors.TEST_PLAN_NAME.REQUIRED).max(255, errors.TEST_PLAN_NAME.MAX_LENGTH(255)),
    cronSchedule: Yup.string().test('Cron Test', errors.SCHEDULE.REQUIRED, (value) => errors.SCHEDULE.VALIDATE(value)),
    isActiveSchedule: Yup.boolean().required(''),
    timezone: Yup.string()
      .nullable()
      .when('isActiveSchedule', {
        is: true,
        then: Yup.string().required(errors.TIMEZONE.REQUIRED),
        otherwise: Yup.string(),
      }),

    notificationEmail: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}([;,]\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
        'Invalid email address format',
      )
      .nullable(),
    notificationEmailStatus: Yup.array()
      .when('notificationEmail', {
        is: (notificationEmail) => notificationEmail && notificationEmail.length > 0,
        then: Yup.array().min(1, 'At least one must be selected').of(Yup.string()),
      })
      .nullable(),
  }),

  Yup.object().shape({
    tags: Yup.array().of(Yup.string()),
  }),
  Yup.object().shape({
    testCaseGuidss: Yup.array().of(Yup.string()),
  }),
];

export default CreateTestCaseSchema;

import { Box, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useGetTestCaseHistory } from '../../../../api/queries/testCase';
import TabComponent from '../../../../components/Tabs';
import { subtractDate } from '../../../../utils/date';
import History from './History';
import Properties from './Properties';
import TestCaseSummary from '../../../testruns/TestCaseView/Summary';

export default function TestCaseView(props) {
  const DEFAULT_SELECTED_TAB = 'SUMMARY';
  const { testCaseGuid, testRunDate, summary, testCasesIsLoading } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);
  const [age, setAge] = React.useState(7);

  const { data: history, isLoading: historyIsLoading } = useGetTestCaseHistory(selectedTab === 'HISTORY', {
    testCaseGuid: testCaseGuid,
    fromDate: subtractDate(age, 'days', true, testRunDate),
  });

  const handleDateChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange = async (tabValue) => {
    setSelectedTab(tabValue);
  };

  const tabViews = {
    SUMMARY: () => {
      return <TestCaseSummary summary={summary} />;
    },
    HISTORY: () => {
      return <History handleDateChange={handleDateChange} graphData={history} age={age} />;
    },
    PROPERTIES: () => {
      return <Properties properties={summary} />;
    },
  };

  useEffect(() => {
    handleChange(selectedTab);
    // eslint-disable-next-line
  }, [testCaseGuid]);

  const getTabViews = () => {
    switch (selectedTab) {
      case 'SUMMARY':
      case 'PROPERTIES':
        if (testCasesIsLoading) {
          return <CircularProgress />;
        }
        break;
      case 'HISTORY':
        if (historyIsLoading) {
          return <CircularProgress />;
        }
        break;
    }

    return tabViews[selectedTab]();
  };

  const tabOptions = [
    { name: 'Summary', value: 'SUMMARY' },
    { name: 'History', value: 'HISTORY' },
    { name: 'Properties', value: 'PROPERTIES' },
    // { name: 'Notes', value: 'NOTES' },
  ];
  return (
    <Box>
      <Box display="flex" px={4} pb={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <span className="sub-header"> </span>
      </Box>
      <TabComponent handleChange={handleChange} selectedTab={selectedTab} options={tabOptions} />
      <Divider />
      <Box px={4} mt={3}>
        {getTabViews()}
      </Box>
    </Box>
  );
}

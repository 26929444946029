import React from 'react';
import { Box } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Image from '../Image';
import MicrosoftImage from '../../assets/images/providers/microsoft.png';
const providerImages = {
  microsoft: MicrosoftImage,
};

function AuthProviderLoginButton({ name, buttontext, success }) {
  return (
    <Box
      className="login-provider-button pointer"
      boxShadow={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" mr={2}>
        <Image src={providerImages[name]} altText={name + ' Image'} className="provider-image" />
        <p>{buttontext}</p>
      </Box>
      {success && <VerifiedUserIcon color="success" fontSize="small" className="success-image" />}
    </Box>
  );
}

export default AuthProviderLoginButton;

import { EventType } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { b2cPolicies, protectedResources } from './configs/authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';

import { ProtectedRoute } from './components/ProtectedRoute';
import Connections from './containers/connections';
import Dashboard from './containers/dashboard';
import Error from './containers/global/Error';
import Logout from './containers/global/Logout';
import Maintenance from './containers/global/Maintenance';
import Reports from './containers/reports';
import SettingsInvite from './containers/settings/ManageUsers/Invites';
import SettingsUser from './containers/settings/ManageUsers';
import TestCases from './containers/testcases';
import TestCasesQuickRuns from './containers/testcases/testCaseQuickRuns';
import TestPlans from './containers/testplans';
import TestRuns from './containers/testruns';
// import InviteUser from './containers/users/InviteUser';

function Routes() {
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn),
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...protectedResources.powertester.scopes.read, ...protectedResources.powertester.scopes.write],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }

        if (event.error && event.error.errorMessage.includes('AADB2C90091')) {
          instance.logoutRedirect(); // TODO this should go to base url
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route errorElement={<Error />} element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/testplans" element={<TestPlans />}>
            <Route path=":testPlanGuidUrl" element={<TestPlans />} />
          </Route>
          <Route path="/testruns" element={<TestRuns />}>
            <Route path=":testRunGuidUrl" element={<TestRuns />} />
            <Route path=":testRunGuidUrl/testcases/:testCaseGuidUrl" element={<TestRuns />} />
          </Route>
          <Route path="/testcases" element={<TestCases />}>
            <Route path=":testCaseGuidUrl" element={<TestCases />} />
          </Route>
          <Route path="/testcases/runs" element={<TestCasesQuickRuns />}>
            <Route path=":testRunGuidUrl" element={<TestCasesQuickRuns />} />
          </Route>
          <Route path="/reports" element={<Reports />}>
            <Route path=":reportGuidUrl" element={<Reports />} />
          </Route>
          <Route path="/connections" element={<Connections />}>
            <Route path=":connectionGuidUrl" element={<Connections />} />
          </Route>
          <Route path="/settings/users" element={<SettingsUser />} />
          <Route path="/settings/invites" element={<SettingsInvite />} />
        </Route>

        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/logout" element={<Logout />} />
        {/* <Route path="/invite/:inviteCode" element={<InviteUser />} /> */}
      </React.Fragment>,
    ),
  );

  return <RouterProvider router={router} />;
}

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Routes />
    </MsalProvider>
  );
};

export default App;

export const EDIT_FORM = 'EDIT_FORM';
export const VIEW_DETAILS = 'VIEW_DETAILS';
export const CREATE_FORM = 'CREATE_FORM';

export const TITLES = {
  TEST_PLAN: {
    heading: {
      [CREATE_FORM]: 'New Test Plan',
      [EDIT_FORM]: 'Update Test Plan',
    },
    subHeading: {
      [CREATE_FORM]: 'Test Plan Added',
      [EDIT_FORM]: 'Test Plan Updated',
    },
  },
  TEST_CASE: {
    heading: {
      [CREATE_FORM]: 'New Test Case',
    },
    subHeading: {
      [CREATE_FORM]: 'Test Case Added',
      [EDIT_FORM]: 'Test Case Updated',
    },
  },
  REPORT: {
    heading: {
      [CREATE_FORM]: 'New Report',
      [EDIT_FORM]: 'Update Report',
    },
    subHeading: {
      [CREATE_FORM]: 'Report Added',
      [EDIT_FORM]: 'Report Updated',
    },
  },
  CONNECTION: {
    heading: {
      [CREATE_FORM]: 'New Connection',
      [EDIT_FORM]: 'Update Connection',
    },
    subHeading: {
      [CREATE_FORM]: 'Connection Added',
      [EDIT_FORM]: 'Connection Updated',
    },
    confirmation: {
      [CREATE_FORM]: 'Add new connection?',
      [EDIT_FORM]: 'Update connection?',
    },
  },
};

export default { EDIT_FORM, VIEW_DETAILS, CREATE_FORM };

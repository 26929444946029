import { Box, TextField } from '@mui/material';
import React from 'react';
import FieldHelpText from '../FieldHelpText';

export default function FormTextField(props) {
  const {
    label,
    variant,
    fullWidth,
    id,
    name,
    value,
    onChange,
    errors,
    touched,
    disabled,
    errorProperty,
    type,
    InputProps,
    multiline,
    rows,
    size,
    helpText,

    dynamicErrorField,
  } = props;

  let errorReference, touchedReference;

  if (errorProperty && errors && errors[errorProperty]) {
    errorReference = errors[errorProperty][id];
  } else {
    errorReference = errors[id];
  }
  if (errorProperty && touched && touched[errorProperty]) {
    touchedReference = touched[errorProperty][id];
  } else {
    touchedReference = touched[id];
  }

  return (
    <Box className="form-field-container">
      <Box display={'flex'} flexDirection={'row'}>
        <TextField
          InputProps={InputProps}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          type={type}
          className="text-field"
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          error={touchedReference && Boolean(errorReference)}
          size={size ?? 'normal'}
        />
        {helpText && helpText.content && (
          <FieldHelpText
            content={helpText.content}
            linkText={helpText.linkText}
            link={helpText.link}
            small={size === 'small'}
          />
        )}
      </Box>
      {errorReference && touchedReference && (
        <Box color="error.main" className={`form-field-error-text${dynamicErrorField ? ' dynamic' : ''}`}>
          {errorReference}
        </Box>
      )}
    </Box>
  );
}

import * as Yup from 'yup';
import errors from '../constants/errors';

const CreateReportSchema = [
  // Form 1 - Report
  Yup.object().shape({
    connection: Yup.string()
      .nullable()
      .required(errors.CONNECTION_SELECTION.REQUIRED)
      .max(1000, errors.CONNECTION_NAME.MAX_LENGTH(1000)),

    powerBIReport: Yup.string()
      .nullable()
      .required(errors.REPORT_SELECTION.REQUIRED)
      .max(1000, errors.REPORT_NAME.MAX_LENGTH(1000)),
  }),

  // Form 3 - Visual selection
  Yup.object().shape({
    visuals: Yup.array().min(1, errors.VISUALS_SELECTION.MIN).required(1, errors.VISUALS_SELECTION.MIN),
  }),
];
export default CreateReportSchema;

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

export default function TestPlanTestCases(props) {
  const { testCases, selectTestCase } = props;
  return (
    <TableContainer>
      <Table className="list-table table-type-2 fixed" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row" className="column">
              Test Case Name
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              Report Name
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              Duration
            </TableCell>
            <TableCell component="th" scope="row" className="column">
              Result
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testCases &&
            testCases.map((row) => (
              <TableRow key={row.testCaseGuid} onClick={() => selectTestCase(row.testCaseGuid)} className="pointer">
                <TableCell className="column">{row.testCaseName}</TableCell>
                <TableCell className="column">{row.reportName}</TableCell>
                <TableCell className="column">{row.testCaseDuration}</TableCell>
                <TableCell className="column">{row.testCaseStatus}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

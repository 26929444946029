import { useQuery } from '@tanstack/react-query';

import { queryClient } from '../../index';
import * as announcement from '../endpoints/announcement';

// Queries
export const useGetAnnouncements = (enabled) => {
  return useQuery({
    queryKey: ['banner'],
    queryFn: () => announcement.getAnnouncements(),
    enabled: enabled,
    staleTime: 60 * (60 * 1000), // 60 mins
  });
};

export const prefetchBanner = () => {
  return queryClient.prefetchQuery(['announcement'], () => announcement.getAnnouncements());
};

import { Box, Stack, Divider } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

import { useGetAuthenticationTypes } from '../../../../../api/queries/authenticationType';
import { useGetConnections, useTestDatabaseConnection } from '../../../../../api/queries/connection';
import RowRadioButtonsGroup from '../../../../../components/RowRadioButtonsGroup';
import Autocomplete from '../../../../../components/Autocomplete';
import { useGetKeyVaultSecrets } from '../../../../../api/queries/keyVault';
import BaseDatabaseForm from './baseDatabase';
import FabricDatabaseForm from './fabricDatabase';

function SQLDatabaseForm(props) {
  const {
    connectionName,
    dbDetails,
    credentialMethod,
    keyVaultConnection,
    connectionType,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    authenticationType,
  } = props;

  const { data: authenticationTypes, isLoading: authenticationTypesIsLoading } = useGetAuthenticationTypes(
    connectionType?.connectionTypeId,
  );

  // Get KV connections
  const { data: keyVaultConnections, isLoading: keyVaultConnectionsIsLoading } = useGetConnections(
    true,
    'all',
    null,
    'Key Vault',
  );

  // Get key Vault Secrets
  const { data: keyVaultSecrets, isLoading: keyVaultSecretsIsLoading } = useGetKeyVaultSecrets(
    !!keyVaultConnection?.connectionGuid,
    keyVaultConnection,
  );

  const { mutate: testConnection, isLoading: testingInProcess } = useTestDatabaseConnection();

  useEffect(() => {
    if (authenticationTypes && authenticationTypes.length === 1) {
      const name = 'authenticationType';
      const value = authenticationTypes[0];
      setFieldValue(name, value);
      setFieldTouched(name, true, false);
    }

    // eslint-disable-next-line
  }, [authenticationTypes, authenticationType]);

  const onTextChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const keyVaultForm = () => {
    return (
      <React.Fragment>
        <Stack direction="column" spacing={3}>
          <Box className="form-field-container">
            <Autocomplete
              multiple={false}
              loading={keyVaultConnectionsIsLoading}
              name="keyVaultConnection"
              setFieldValue={setFieldValue}
              options={keyVaultConnections}
              label={'connectionName'}
              value={'connectionGuid'}
              selectedItem={keyVaultConnection}
              inputLabel={'Selection key vault connection'}
              error={touched.keyVaultConnection && Boolean(errors?.keyVaultConnection)}
            />
            {errors?.keyVaultConnection && touched.keyVaultConnection && (
              <Box color="error.main" className="form-field-error-text">
                {errors?.keyVaultConnection}
              </Box>
            )}
          </Box>
          <Box className="form-field-container" mt={2}>
            <Autocomplete
              multiple={false}
              loading={keyVaultSecretsIsLoading}
              name="dbDetails.connectionStringKeyVault"
              setFieldValue={setFieldValue}
              options={keyVaultSecrets}
              selectedItem={dbDetails.connectionStringKeyVault || null}
              inputLabel={'Select secret containing connection string'}
              error={
                touched?.dbDetails?.connectionStringKeyVault && Boolean(errors?.dbDetails?.connectionStringKeyVault)
              }
            />
            {errors?.dbDetails?.connectionStringKeyVault && touched?.dbDetails?.connectionStringKeyVault && (
              <Box color="error.main" className="form-field-error-text">
                {errors?.dbDetails?.connectionStringKeyVault}
              </Box>
            )}
          </Box>
        </Stack>
      </React.Fragment>
    );
  };

  const testButtonIsDisabled = () => {
    // Check if dbDetails or authenticationType is falsy, return true ie disabled
    if (!dbDetails || !authenticationType) return true;

    const {
      connectionString,
      serverName,
      database,
      servicePrincipalAppId,
      servicePrincipalSecret,
      connectionStringKeyVault,
    } = dbDetails;

    if (credentialMethod === 'Embedded') {
      if (!connectionString && (!serverName || !database)) {
        // Check if connectionString is missing and either serverName or database is missing
        return true;
      }

      // Check if authenticationType is 'SQL Authenticaiton' and serverName or database is missing
      if (
        authenticationType?.authenticationType === 'SQL Authenticaiton' &&
        (!serverName || !database) &&
        !connectionString
      ) {
        return true;
      }

      // Check if authenticationType is 'Service principal' and any of the required properties is missing
      if (
        authenticationType?.authenticationType === 'Service principal' &&
        (!serverName || !database || !servicePrincipalAppId || !servicePrincipalSecret) &&
        !connectionString
      ) {
        return true;
      }
    }
    // Check if authenticationType is 'SQL Authenticaiton' and serverName or database is missing
    else if (credentialMethod === 'Key Vault' && (!keyVaultConnection || !connectionStringKeyVault)) {
      return true;
    }
    // All conditions passed, return false ie not disabled
    return false;
  };

  const getFormType = () => {
    if (!dbDetails) return;
    if (credentialMethod === 'Key Vault') return keyVaultForm();
    /* eslint-disable no-fallthrough */
    switch (connectionType.connectionType) {
      case 'Snowflake':
      case 'Microsoft Fabric':
        return (
          <FabricDatabaseForm
            dbDetails={dbDetails}
            credentialMethod={credentialMethod}
            authenticationType={authenticationType}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        );
      case 'Azure SQL Database':
      case 'Postgres':
      default:
        return (
          <BaseDatabaseForm
            dbDetails={dbDetails}
            credentialMethod={credentialMethod}
            authenticationType={authenticationType}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        );
    }
    /* eslint-enable no-fallthrough */
  };

  return (
    <React.Fragment>
      <Stack direction={'column'} spacing={2}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: '38px' }}
        >
          <h4>Select how to connect to data source</h4>
          {dbDetails && authenticationType && credentialMethod && (
            <LoadingButton
              className="yellow form-button"
              variant="contained"
              loading={testingInProcess}
              disabled={testButtonIsDisabled()}
              onClick={() =>
                testConnection({
                  keyVaultConnection,
                  dbDetails,
                  connectionType: connectionType?.connectionType,
                  authenticationType,
                  credentialMethod,
                  connectionName,
                })
              }
            >
              Test
            </LoadingButton>
          )}
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            preselectOne
            disabled={!connectionType}
            loading={authenticationTypesIsLoading}
            multiple={false}
            name="authenticationType"
            setFieldValue={setFieldValue}
            options={authenticationTypes}
            label={'authenticationType'}
            value={'authenticationTypeId'}
            selectedItem={authenticationType?.authenticationTypeId || null}
            inputLabel={'Select authentication type'}
            error={touched.authenticationType && Boolean(errors.authenticationType)}
            helpText={{
              content: 'Authentication method determines how PowerTester connects to the data source.',
            }}
          />
          {errors.authenticationType && touched.authenticationType && (
            <Box color="error.main" className="form-field-error-text">
              {errors.authenticationType}
            </Box>
          )}
        </Box>
        <RowRadioButtonsGroup
          disabled={!authenticationType}
          title={'Select credential method'}
          options={[{ value: 'Embedded' }, { value: 'Key Vault' }]}
          value={credentialMethod}
          onChange={(value) => {
            onTextChange('credentialMethod', value);
          }}
          helpText={{
            content:
              'You can store credentials for connections in an Azure Key Vault. PowerTester retrieves the credentials when executing an test case that uses the connection but does not store any credential information when using a Key Vault.',
          }}
        />
        <Divider>Connection details</Divider>

        {errors.credentialMethod && touched.credentialMethod && (
          <Box color="error.main" className="form-field-error-text">
            {errors.credentialMethod}
          </Box>
        )}
        {getFormType()}
      </Stack>
    </React.Fragment>
  );
}

export default SQLDatabaseForm;

import { Box } from '@mui/material';
import React from 'react';
import LOGO from '../../../assets/images/logo-login.png';
import Image from '../../../components/Image';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

function Error() {
  const error = useRouteError();

  const getError = () => {
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return (
          <>
            <Box mb={4} className="http-status-header" mr={6} ml={6}>
              <span>404</span>
            </Box>
            <Box mb={4} className="error-label" mr={6} ml={6}>
              <span>Sorry, page not found</span>
            </Box>
          </>
        );
      }

      if (error.status === 401) {
        return (
          <>
            <Box mb={4} className="http-status-header" mr={6} ml={6}>
              <span>401</span>
            </Box>
            <Box mb={4} className="error-label" mr={6} ml={6}>
              <span>Unauthorised</span>
            </Box>
          </>
        );
      }

      if (error.status === 503) {
        return (
          <>
            <Box mb={4} className="http-status-header" mr={6} ml={6}>
              <span>503</span>
            </Box>
            <Box mb={4} className="error-label" mr={6} ml={6}>
              <span>PowerTester is currently not available, please try again later</span>
            </Box>
          </>
        );
      }

      if (error.status === 418) {
        return <div>🫖</div>;
      }
    }

    return (
      <>
        <Box mb={4} className="error-header" mr={6} ml={6}>
          <span>Unexpected Error!</span>
        </Box>
        <Box mb={4} className="error-label" mr={6} ml={6}>
          <span>
            An unexpected error occured. <br />
            Please try that again, or contact support
          </span>
        </Box>
      </>
    );
  };

  return (
    <Box className="error-page" display="flex">
      <Box className="container" display="flex" flexDirection="column" justifyContent="flex-start">
        <Box className="error-form">
          <Box mb={10} mr={6} ml={6} mt={-8.5}>
            <Image src={LOGO} altText={'PowerTester Logo'} className="error-logo" />
          </Box>
          {getError()}
        </Box>
      </Box>
    </Box>
  );
}

export default Error;

export const addPropertyIfNotNull = (source, target, sourceProp, targetProp) => {
  // Check if source and target are objects
  if (typeof source !== 'object' || source === null) {
    throw new Error('Source object is not valid.');
  }

  if (typeof target !== 'object' || target === null) {
    throw new Error('Target object is not valid.');
  }

  // Check if source property exists
  if (!(sourceProp in source)) {
    throw new Error(`Source property '${sourceProp}' does not exist in the source object.`);
  }

  // // Check if target property exists
  // if (!(targetProp in target)) {
  //   throw new Error(`Target property '${targetProp}' does not exist in the target object.`);
  // }

  // Check if source property value is not null or undefined
  if (source[sourceProp] !== null && source[sourceProp] !== undefined) {
    target[targetProp] = source[sourceProp];
  }
};

export const isObjEmpty = (obj) => {
  if (!obj) return true;

  if (typeof obj !== 'object') return null;

  if (Object.prototype.hasOwnProperty.call(obj, '')) return true;

  return Object.keys(obj).length === 0;
};

export const nullIf = (obj) => {
  if (obj === null) {
    return null;
  }
  return Object.keys(obj).length === 0 ? null : obj;
};

import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  Divider,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import withSnackbar from '../../../../hocs/withSnackbar';
import TablePaginationActions from '../../../../components/TablePaginationActions';
import ControlledSearch from '../../../../components/ControlledSearch';
import RenameModal from '../../../../components/RenameModal';
import { getFriendlyVisualTypeName, getVisualIdFromUrl } from '../../../../utils/visual';

function VisualDetailsForm(props) {
  const { visuals, reportName, setFieldValue, setFieldError, errors, touched } = props;
  const [page, setPage] = useState(0);
  const [visualsFiltered, setVisualsFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [visualToRename, setVisualToRename] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [placeHolder, setPlaceHolder] = useState('');

  useEffect(() => {
    filterObjectsForVisualsOnly(visuals);

    // eslint-disable-next-line
  }, [visuals]);

  const filterObjectsForVisualsOnly = () => {
    if (visuals && visuals.length > 0) {
      const filteredArray = visuals.filter((obj) => obj.objType === 'visual' || !obj.objType);
      setVisualsFiltered(filteredArray);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    filterObjectsForVisualsOnly(visuals);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const filterListByUrl = (searchValue) => {
    setSearchValue(searchValue);
    if (!searchValue) {
      filterObjectsForVisualsOnly(visuals);
      return;
    }
    const visualId = getVisualIdFromUrl(searchValue);

    if (visualId) {
      const filteredArray = visuals.filter((obj) => obj.objType === 'visual' && obj.visualGuid === visualId);
      setVisualsFiltered(filteredArray);
    } else {
      setFieldError('visualUrl', 'Invalid URL. Please enter a Power BI report URL with a visual parameter');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const confirmRename = (newVisualName) => {
    // Find the visual with the guid
    const indexToUpdate = visuals.findIndex((item) => item.visualGuid === visualToRename);

    if (indexToUpdate !== -1) {
      // update the visual with the new custom name
      const updatedItem = { ...visuals[indexToUpdate], customName: newVisualName };
      // Update copy of array with new visual
      const updatedItems = [...visuals.slice(0, indexToUpdate), updatedItem, ...visuals.slice(indexToUpdate + 1)];

      // Update visuals array with new item
      setFieldValue('visuals', updatedItems);
    }
    toggleModal();
    clearSearch();
  };

  const renameVisual = (visualGuid, customName) => {
    toggleModal();
    setVisualToRename(visualGuid);

    if (customName) {
      setPlaceHolder(customName);
    } else {
      setPlaceHolder('');
    }
  };

  const getRenameMenu = (visualGuid, customName) => {
    return (
      <Box className="menu">
        <Tooltip title="Rename visual" arrow>
          <IconButton
            sx={{ padding: '2px!important' }}
            size="small"
            onClick={() => {
              renameVisual(visualGuid, customName);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const getName = (customName, name) => {
    if (customName) {
      return (
        <>
          <i>{customName}</i>
          <Tooltip title="Custom name">
            <DriveFileRenameOutlineIcon fontSize="small" />
          </Tooltip>
        </>
      );
    } else {
      return name;
    }
  };

  return (
    <React.Fragment>
      <RenameModal
        title="Rename visual"
        modalOpen={modalOpen}
        confirmRename={confirmRename}
        cancelRename={toggleModal}
        descriptionText={
          <span>
            Give the visual a new friendly name to make it easier to track in PowerTester <br />
            This does not impact any Power BI reports
          </span>
        }
        canUndo={true}
        undoTitle={'Restore original name'}
        placeholderText={placeHolder}
        objectType={'Visual'}
      />
      <Stack direction="column" spacing={2}>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <span>Didn't see your visual on the previous page? Search for it using the Visual URL here.</span>
          <Link
            href="https://wild-data-solutions.outseta.com/support/kb/articles/EWBjv1Wr/how-to-find-visual-url"
            target="_blank"
          >
            Where to get the visual Url
          </Link>
        </Box>
        <Box>
          <ControlledSearch value={searchValue} placeholder={'Enter visual share Url'} onChange={filterListByUrl} />
          {errors.powerBIReport && touched.powerBIReport && (
            <Box color="error.main" className="form-field-error-text">
              {errors.powerBIReport}
            </Box>
          )}
        </Box>

        <Divider>{reportName}</Divider>

        <TableContainer style={{ maxHeight: '400px' }}>
          <Table stickyHeader className="list-table fixed" aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="column small">Page</TableCell>
                <TableCell className="column small">Visual name</TableCell>
                <TableCell className="column small" sx={{ width: '100px!important' }}>
                  Type
                </TableCell>
                <TableCell
                  className="column small menu-column"
                  sx={{ width: '50px!important', padding: 0 }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visualsFiltered &&
                (rowsPerPage > 0
                  ? visualsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : visuals
                ).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="column small">{row.pageDisplayName}</TableCell>
                    <TableCell className="column small">
                      <Typography sx={{ wordWrap: 'break-word' }}>{getName(row.customName, row.visualName)}</Typography>
                    </TableCell>
                    <TableCell className="column small">
                      <Typography sx={{ wordWrap: 'break-word' }}>
                        {getFriendlyVisualTypeName(row.visualType)}
                      </Typography>
                    </TableCell>
                    <TableCell className="column small menu-column" sx={{ width: '50px!important', padding: 0 }}>
                      {getRenameMenu(row.visualGuid, row.customName)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {visualsFiltered && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={visualsFiltered.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Stack>
    </React.Fragment>
  );
}

export default withSnackbar(VisualDetailsForm);

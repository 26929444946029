export const TEST_RUNS = '/api/testRun';
export const TEST_PLANS = '/api/testPlan';
export const TEST_CASES = '/api/testCase';

export const REPORTS = '/api/report';
export const CONNECTIONS = '/api/connection';

export const VISUAL_SELECTION_TYPES = 'api/visualselectiontype';
export const CONNECTION_TYPES = '/api/connectiontype';
export const AUTHENTICATION_TYPES = '/api/authenticationtype';

export const TEST_CONNECTION = 'api/test/connection';
export const CONFIG = '/api/config';
export const ANNOUNCEMENTS = '/api/announcement';
export const NOTIFICATIONS = '/api/notification';

export const SETTINGS = '/api/setting';
export const USERS = '/api/user';

export const TAGS = '/api/tag';

export const LOG_HELP_TELEMETRY = '/api/telemetry/help';

export const DASHBOARD_SUMMARY = '/api/dashboard/summary';
export const DASHBOARD_CHECKLIST = '/api/dashboard/gettingstartedsteps';
export const DASHBOARD_FAILEDTESTS = '/api/dashboard/failedtestcases';
export const DASHBOARD_TESTRUNS = '/api/dashboard/recenttestruns';
export const DASHBOARD_FAILEDTESTSTREND = '/api/dashboard/failedtesttrend';
export const DASHBOARD_TESTDURATIONTREND = '/api/dashboard/testdurationtrend';
export const DASHBOARD_PASSRATE = '/api/dashboard/passrate';

export const TOKEN = '/api/token';
export const POWERBI_REPORTS = 'api/pbi/reports';
export const COPILOT_SQL = 'api/copilot/sqlgenerate';
export const GET_KEY_VAULT_SECRETS = 'api/key-vault/secrets';

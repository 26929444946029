import { Box } from '@mui/material';
import React from 'react';

export default function Info(props) {
  const { data } = props;
  return (
    <Box>
      {data && data.length > 0 && (
        <Box className="info-container" p={2} display="flex" justifyContent="space-between">
          {data.map((option) => (
            <Box key={option.label} className="text-center">
              <h2>{option.value}</h2>
              {/* <Typography variant={'h2'}>{option.value}</Typography> */}
              <p className="bar">{option.label}</p>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

import { Formik } from 'formik';
import React, { useState } from 'react';

import { mapCreateTestCaseData } from '../../../api/mappings/testcase';
import { useCreateTestCase } from '../../../api/queries/testCase';
import { SNACKBAR_ERROR } from '../../../constants/snackbar';
import createTestCaseSchema from '../../../schema/createTestCaseSchema';
import { getResponseErrorMsg } from '../../../utils/response';
import CreateTestCaseForm from './Form';

function CreateTestCase(props) {
  const LAST_FORM_NUMBER = 4;
  const [formNumber, setFormNumber] = useState(1);
  const { actionType, tags, visualSelectionTypes, tagsLoading, openSnackbar } = props;

  const initValues = {
    testCaseName: '',
    report: null,
    sourceQuery: '',
    page: null,
    altText: '',
    tags: [],
    visualSelectionType: null,
    visualUrl: '',
    visual: null,
    connection: null,
  };
  const [values, setValues] = useState(initValues);
  const [isDetailsSubmitted, setDetailsSubmitted] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');

  const [popUpOpen, setPopUpOpen] = useState({ content: '', anchorEl: false, linkText: '', linkUrl: '' });
  const [responseError, setResponseError] = useState('');
  const [isDetailsSubmitting, setDetailsSubmitting] = useState(false);

  const createMutation = useCreateTestCase();

  const handleOpenPopup = (content, linkText, linkUrl) => (event) => {
    setPopUpOpen({ content: content, anchorEl: event.currentTarget, linkText: linkText, linkUrl: linkUrl });
  };

  const handleClosePopup = () => {
    setPopUpOpen({ anchorEl: null });
  };

  const submitForm = async (values) => {
    try {
      setDetailsSubmitting(true);
      const data = mapCreateTestCaseData(values);

      await createMutation.mutateAsync({ ...data });

      setResponseStatus('success');
    } catch (error) {
      console.error(error);
      setResponseStatus('error');
      setResponseError(getResponseErrorMsg(error.response));
      openSnackbar('Error creating test case', SNACKBAR_ERROR);
    } finally {
      setDetailsSubmitted(true);
      setDetailsSubmitting(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (formNumber === LAST_FORM_NUMBER) {
      submitForm(values);
      return;
    }
    actions.setTouched({});
    actions.setSubmitting(false);
    setFormNumber(formNumber + 1);
  };

  const handleBack = async () => {
    setFormNumber(formNumber - 1);
    setDetailsSubmitted(false);
    setResponseStatus('');
    setResponseError('');
  };

  return (
    <Formik
      validationSchema={createTestCaseSchema[formNumber - 1]}
      initialValues={values}
      setValues={setValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <CreateTestCaseForm
          visualSelectionTypes={visualSelectionTypes}
          responseStatus={responseStatus}
          responseError={responseError}
          isDetailsSubmitted={isDetailsSubmitted}
          isDetailsSubmitting={isDetailsSubmitting}
          toggleForm={props.toggleForm}
          formNumber={formNumber}
          LAST_FORM_NUMBER={LAST_FORM_NUMBER}
          {...formikProps}
          actionType={actionType}
          tagList={tags}
          handleOpenPopup={handleOpenPopup}
          handleClosePopup={handleClosePopup}
          handleBack={handleBack}
          popUpOpen={popUpOpen}
          tagsLoading={tagsLoading}
          setErrors={formikProps.setErrors}
        />
      )}
    </Formik>
  );
}
export default CreateTestCase;

import { CheckCircle } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import Menu from '../../../components/Menu';
import TablePaginationActions from '../../../components/TablePaginationActions';
import TableRowMenu from '../../../components/TableRowMenu';
import TableSkeleton from '../../../components/TableSkeleton';
import { EDIT_FORM, VIEW_DETAILS } from '../../../constants/sidedrawer';
import { getRowIndex } from '../../../utils/TablePagination';
import { formatDate, formatSecondsToHms } from '../../../utils/date';

export default function TestPlanList(props) {
  const { testPlans, formOpen, isLoading, toggleDeleteModal } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event, index, id) => {
    if (formOpen) return;
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            top: event.clientY - 6,
            left: event.clientX + 2,
            index,
            id,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [testPlans]);

  const executeTestPlan = (index, guid) => {
    props.executeTestPlan({ testPlanGuid: guid });
  };

  const selectTestPlan = (index, id, actionType) => {
    props.selectTestPlan(index, actionType);
  };

  const rowClassName = () => {
    if (!formOpen) return 'pointer';

    if (formOpen && props.actionType === VIEW_DETAILS) return 'pointer';
  };

  const deleteTestPlan = (index, id) => {
    toggleDeleteModal(id);
  };

  const options = [
    { label: 'Edit', action: selectTestPlan, actionType: EDIT_FORM },
    { label: 'Delete', action: deleteTestPlan },
    { label: 'Execute Now', action: executeTestPlan },
  ];

  const renderTestPlanHistory = (history) => {
    if (!history) return <></>;
    const lastRunStatuses = _.map(_.split(_.trim(history, '[{}]'), ','), (onestatus) => {
      return _.trim(onestatus, '"{}').slice(9);
    });
    return lastRunStatuses
      ? lastRunStatuses.map((status, index) => {
          if (status === 'Passed') {
            return (
              <Tooltip title="Passed" arrow key={index}>
                <Box key={index} color="success.main" className="status small" mr={2}>
                  <CheckCircle />
                </Box>
              </Tooltip>
            );
          } else if (status === 'Failed') {
            return (
              <Tooltip title="Failed" arrow key={index}>
                <Box key={index} color="error.main" className="status small" mr={2}>
                  <CancelIcon />
                </Box>
              </Tooltip>
            );
          } else if (status === 'In progress' || status === 'Queued') {
            return (
              <Tooltip title="In progress" arrow key={index}>
                <Box key={index} color="#2d9cdb" className="status small" mr={2}>
                  <InfoIcon />
                </Box>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="Inconclusive" arrow key={index}>
                <Box key={index} color="#ff9100" className="status small" mr={2}>
                  <CancelIcon />
                </Box>
              </Tooltip>
            );
          }
        })
      : '-';
  };
  return (
    <React.Fragment>
      {!!contextMenu?.top && (
        <Menu
          anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
          options={options}
          handleClose={() => setContextMenu(null)}
          index={contextMenu?.index}
          id={contextMenu?.id}
        />
      )}
      <TableContainer>
        <TableContainer>
          <Table className="list-table fixed test-plan-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" className="column font-600">
                  Name
                </TableCell>
                <TableCell component="th" className="column font-600">
                  Last Run
                </TableCell>
                {!formOpen ? (
                  <>
                    <TableCell component="th" className="column font-600">
                      Last Run Duration
                    </TableCell>
                    <TableCell component="th" className="column font-600">
                      History
                    </TableCell>
                    <TableCell component="th" className="column menu-column"></TableCell>
                  </>
                ) : (
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                (testPlans && testPlans?.length > 0
                  ? rowsPerPage > 0
                    ? testPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : testPlans
                  : []
                ).map((row, index) => (
                  <TableRow
                    onClick={(event) => {
                      selectTestPlan(getRowIndex(index, page, rowsPerPage), '', VIEW_DETAILS);
                      event.stopPropagation();
                    }}
                    key={row.testPlanGuid}
                    className={rowClassName()}
                    onContextMenu={(event) =>
                      handleContextMenu(event, getRowIndex(index, page, rowsPerPage), row.testPlanGuid)
                    }
                  >
                    <TableCell className="column font-600">
                      <Typography noWrap variant={'body1'}>
                        {row.testPlanName}
                      </Typography>
                    </TableCell>

                    <TableCell className="column">{formatDate(row.lastRunDateTime, <i>No test runs</i>)}</TableCell>
                    {!formOpen ? (
                      <>
                        <TableCell className="column">{formatSecondsToHms(row.lastRunDuration) || ''}</TableCell>
                        <TableCell className="column ">
                          <Box display="flex">{renderTestPlanHistory(row.history)}</Box>
                        </TableCell>
                        <TableCell className="column menu-column text-right">
                          <TableRowMenu
                            index={getRowIndex(index, page, rowsPerPage)}
                            id={row.testPlanGuid}
                            options={options}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableSkeleton columns={4} rows={10} hasMenu />
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={testPlans ? testPlans.length : 0}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </TableContainer>
    </React.Fragment>
  );
}

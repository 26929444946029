import { Box, Divider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetTestCaseForTestPlan } from '../../../api/queries/testPlan';
import TabComponent from '../../../components/Tabs';
import { subtractDate } from '../../../utils/date';
import Summary from './Summary';
import TestPlanTestCases from './TestPlanTestCases';
import TestPlanTestRuns from './TestPlanTestRuns';
import { useGetTestPlanSummary } from '../../../api/queries/testPlan';
import { mapFormatedSummaryGraphData, mapFormattedSummaryData } from '../../../api/mappings/testPlan';
import { useGetTestRun } from '../../../api/queries/testRun';
import { EDIT_FORM } from '../../../constants/sidedrawer';
import FormButtons from '../../../components/FormButtons';
import { exportToFile } from '../../../utils/fileDownload';
import { exportTestPlan } from '../../../export/testPlan';

export default function TestPlanView(props) {
  const DEFAULT_SELECTED_TAB = 'SUMMARY';
  const { testPlanData, viewTestRun, toggleDeleteModal, executeTestPlan } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);
  const [age, setAge] = useState(7);
  const [testPlanForExport, setTestPlanForExport] = useState(null);
  const navigate = useNavigate();

  const fromDate = subtractDate(age, 'days', false);

  const { data: testPlanSummary, isLoading: testPlanSummaryIsLoading } = useGetTestPlanSummary(true, {
    testPlanGuid: testPlanData.testPlanGuid,
    fromDate: subtractDate(30, 'days', false),
  });

  const { data: testCasesForExport, isFetched: testCasesForExportFetched } = useGetTestCaseForTestPlan(
    !!testPlanData.testPlanGuid && testPlanData.testPlanGuid === testPlanForExport,
    {
      testPlanGuid: testPlanData.testPlanGuid,
      shouldIncludeTaggedTestCases: false,
    },
  );

  const { data: testCases } = useGetTestCaseForTestPlan(selectedTab === 'TEST_CASES', {
    testPlanGuid: testPlanData.testPlanGuid,
  });

  const summary = mapFormattedSummaryData(testPlanSummary, fromDate);
  const graphData = mapFormatedSummaryGraphData(testPlanSummary, fromDate);

  const handleDateChange = (event) => {
    setAge(event.target.value);
  };

  const { data: testRuns, refetch: refetchTestRuns } = useGetTestRun(
    selectedTab === 'TEST_RUNS' || !testPlanSummaryIsLoading,
    {
      testPlanGuid: testPlanData.testPlanGuid,
    },
  );
  useEffect(() => {
    if (testCasesForExportFetched && testPlanForExport) {
      exportToFile('json', exportTestPlan(testPlanData, testCasesForExport), testPlanData.testPlanName);
      setTestPlanForExport(null);
    }
  }, [testCasesForExportFetched, testPlanForExport]);

  const handleTabChange = async (tabName) => {
    setSelectedTab(tabName);
  };

  const selectTestCase = (testCaseGuid) => {
    navigate(`/testcases/${testCaseGuid}`);
  };

  const tabOptions = [
    { name: 'Summary', value: 'SUMMARY' },
    { name: 'Test Runs', value: 'TEST_RUNS' },
    { name: 'Test Cases', value: 'TEST_CASES' },
  ];

  useEffect(() => {
    if (selectedTab === 'TEST_RUNS') refetchTestRuns();
  }, [selectedTab]);

  const onDelete = () => {
    toggleDeleteModal();
  };

  const onEditForm = () => {
    props.onEdit(EDIT_FORM, 'keepopen');
  };

  const onExecuteTestPlan = () => {
    executeTestPlan({ testPlanGuid: testPlanData.testPlanGuid });
  };

  const onExport = () => {
    setTestPlanForExport(testPlanData.testPlanGuid);
  };

  const buttonsArray = [
    {
      buttonName: 'Execute',
      buttonIcon: 'rocket',
      action: onExecuteTestPlan,
    },
    {
      buttonName: 'Edit',
      buttonIcon: 'edit',
      action: onEditForm,
    },
  ];

  const contextMenuArray = [
    {
      label: 'Delete',
      icon: 'delete',
      action: onDelete,
    },
    {
      label: 'Export',
      icon: 'export',
      action: onExport,
    },
  ];

  const tabViews = {
    SUMMARY: () => {
      return (
        <Summary
          handleDateChange={handleDateChange}
          graphData={graphData}
          summary={summary}
          isLoading={testPlanSummaryIsLoading}
          age={age}
        />
      );
    },
    TEST_RUNS: () => {
      return <TestPlanTestRuns testRuns={testRuns} viewTestRun={viewTestRun} />;
    },
    TEST_CASES: () => {
      return <TestPlanTestCases testCases={testCases} selectTestCase={selectTestCase} />;
    },
  };
  return (
    <Box>
      <Box display="flex" px={4} pb={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <span className="sub-header"> </span>
      </Box>
      <TabComponent handleChange={handleTabChange} selectedTab={DEFAULT_SELECTED_TAB} options={tabOptions} />
      <Divider />
      <Box px={4} mt={3}>
        {tabViews[selectedTab]()}
      </Box>
      <Box px={4}>
        <FormButtons buttons={buttonsArray} contextMenuOptions={contextMenuArray} />
      </Box>
    </Box>
  );
}

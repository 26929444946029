export const filterListByName = (inputArray, key, value, setListHook) => {
  if (value) {
    const filteredData = inputArray.filter((element) => {
      return element[key] && element[key].toLowerCase().includes(value.toLowerCase());
    });
    setListHook(filteredData);
    return;
  }
  setListHook(inputArray);
};

export const getIdList = (array, keyName) => {
  if (array && array?.length > 0) {
    return array.map((e) => e[keyName]);
  }
  return;
};

// returns list where property is equal to value
export const filterListByProperty = (list, property, value) => {
  return list.filter((obj) => obj[property] === value);
};

// returns first in list where property is equal to value
export const findObjectByProperty = (list, property, value) => {
  return list.find((obj) => obj[property] === value);
};

// returns first in list where property is equal to value
export const findObjectByPropertyCI = (list, property, value) => {
  const valueLower = value.toLowerCase();
  return list.find((obj) => obj[property].toLowerCase() === valueLower);
};

export const countDistinctPropertyValues = (arr, prop) => {
  const distinctValues = {};
  arr.forEach((obj) => {
    distinctValues[obj[prop]] = true;
  });
  return Object.keys(distinctValues).length;
};

import React from 'react';
import { JsonViewer } from '@textea/json-viewer';
import { isJson } from '../../utils/string';

const JsonTestViewer = (props) => {
  const { data, options, style } = props;

  const defaultOptions = {
    displayDataTypes: false,
    name: false,
    displaySize: false,
  };

  const defaultStyle = {
    maxHeight: '800px',
    overflowY: 'auto',
  };

  if (!isJson(data)) return <div>Error displaying JSON</div>;

  return (
    <JsonViewer
      {...(options ?? defaultOptions)}
      value={JSON.parse(data)}
      style={style ?? defaultStyle}
      rootName={false}
      collapseStringsAfterLength={250}
      displayDataTypes={false}
      displaySize={false}
    />
  );
};

export default JsonTestViewer;

import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '../../assets/images/icons/search.svg';
import Image from '../Image';

function ControlledSearch(props) {
  const { placeholder, value } = props;

  return (
    <div className="search-container">
      <Image src={SearchIcon} altText={'Search Icon'} />
      <DebounceInput
        minLength={3}
        debounceTimeout={500}
        placeholder={placeholder}
        onChange={(event) => props.onChange(event.target.value)}
        value={value}
      />
    </div>
  );
}
export default ControlledSearch;

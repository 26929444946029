import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import { CREATE_FORM } from '../../constants/sidedrawer';
import Switch from '../Switch';
import FieldHelpText from '../FieldHelpText';

function Header(props) {
  const { switchOptions, loading, helpText, refreshAction, dotIndicatorOption } = props;

  const smallWindow = useMediaQuery('(max-width:1024px)');
  const extraSmallWindow = useMediaQuery('(max-width:800px)');

  const actionButtons = () => {
    return (
      <React.Fragment>
        <Box ml={4} display={'flex'} flexDirection={'Row'}>
          {helpText && <FieldHelpText content={helpText} />}
          {refreshAction && (
            <IconButton onClick={refreshAction} className="iconContainer help" size={'large'}>
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Box
      className="header"
      display="flex"
      px={4}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display={'flex'} flexDirection={'Row'} alignItems="center">
        <Typography variant={props.headerVariantOverride ?? 'h1'}>{props.title}</Typography>
        {!extraSmallWindow && actionButtons()}
      </Box>
      <Box display="flex" alignItems="center">
        {switchOptions && (
          <Box mr={2}>
            <Switch
              switchOptions={switchOptions}
              dotIndicatorOption={dotIndicatorOption}
              direction={extraSmallWindow ? 'column' : 'row'}
            />
          </Box>
        )}
        {(props.toggleForm || props.buttonText) && (
          <LoadingButton
            disabled={Boolean(props.buttonDisabled)}
            className="yellow"
            variant="contained"
            onClick={() => props.toggleForm(CREATE_FORM)}
            loading={loading}
            loadingPosition={!smallWindow ? 'start' : 'center'} // Conditionally set loadingPosition
            startIcon={!smallWindow && <AddIcon />}
          >
            {props.buttonText}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}
export default Header;

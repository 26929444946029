import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';

import Menu from '../Menu';

export default function TableRowMenu(props) {
  const [anchorPosition, setAnchorPosition] = useState(null); // State to store the menu position
  const { options, index, id, row } = props;

  const handleClick = (event) => {
    if (event.currentTarget) {
      const boundingRect = event.currentTarget.getBoundingClientRect(); // Get the position of the anchor element
      setAnchorPosition({ top: boundingRect.top, left: boundingRect.left });
      event.stopPropagation();
    }
  };

  const handleClose = (event) => {
    setAnchorPosition(null); // Clear the menu position when closing
    event && event.stopPropagation();
  };

  return (
    <div className="menu" style={{ paddingRight: '4px' }}>
      <IconButton
        className="icon-button"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorPosition={anchorPosition}
        options={options}
        handleClose={handleClose}
        index={index}
        id={id}
        row={row}
      />
    </div>
  );
}

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Autocomplete,
  CircularProgress,
  TextField,
  Checkbox,
  IconButton,
} from '@mui/material';
import { useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FullScreenPopup from '../FullScreenPopup';
import TableViewIcon from '@mui/icons-material/TableView';
import JsonViewer from '../JsonViewer';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MismatchRecordsTable = (props) => {
  const { data, rawData } = props;
  const [hoveredCell, setHoveredCell] = useState(null);
  const [columns, setColumns] = useState(null);
  const [expectedData, setExpectedData] = useState(null);
  const [actualData, setActualData] = useState(null);
  const [tablePopupOpen, setTablePopupOpen] = useState(false);
  const [displayAsCode, setDisplayAsCode] = useState(false);

  useEffect(() => {
    if (!data || !data?.results) return;

    const expectedResults = [];
    const actualResults = [];

    // Iterate through the results array
    data.results.slice(0, 5).forEach((result) => {
      // Extract and push the Expected data
      expectedResults.push(result.Expected);

      // Extract and push the Actual data
      actualResults.push(result.Actual);
    });

    // Set the state variables
    setExpectedData(expectedResults);
    setActualData(actualResults);

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!data || !expectedData) return;

    // Check if there is an explicit "Columns" array in the data
    if (data.columns) {
      const columnsArray = data.columns.map((columnName) => ({
        columnName,
        show: true,
      }));

      setColumns(columnsArray);
      return;
    } else {
      // If no "Columns" array, count the number of strings in the first "Expected" result
      const firstExpectedResult = expectedData[0];

      const columnsArrayFromExpectedRecords = Array.from({ length: firstExpectedResult.length }, (_, index) => ({
        columnName: `${index + 1}`,
        show: true,
      }));
      setColumns(columnsArrayFromExpectedRecords);
    }

    // eslint-disable-next-line
  }, [data, expectedData]);

  // Function to check if two cells match
  const doCellsMatch = (expectedCell, actualCell) => expectedCell === actualCell;

  // Function to handle cell hover
  const handleCellHover = (rowIndex, cellIndex) => {
    setHoveredCell({ rowIndex, cellIndex });
  };

  const handleColumnChange = (e, newSelectedColumns) => {
    // Update show to false for missing columns
    const updatedColumns = columns.map((column) => ({
      ...column,
      show: newSelectedColumns.includes(column.columnName),
    }));

    setColumns(updatedColumns);
  };
  const renderTable = (dataArray, title) => (
    <React.Fragment>
      <h2>{title}</h2>
      <Box sx={{ overflow: 'auto' }}>
        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => column.show === true)
                  .map((column, index) => (
                    <TableCell
                      key={index}
                      className={hoveredCell && hoveredCell.cellIndex === index ? 'highlightedAgencent' : ''}
                    >
                      <strong>{`${column.columnName}`}</strong>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataArray.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map(
                    (cell, cellIndex) =>
                      // Check if the current column should be shown
                      columns.filter((column) => column.show === true).includes(columns[cellIndex]) && (
                        <TableCell
                          key={cellIndex}
                          className={`row ${
                            doCellsMatch(expectedData[rowIndex][cellIndex], actualData[rowIndex][cellIndex])
                              ? 'tableCell'
                              : 'mismatchCell'
                          }
                    ${
                      hoveredCell && hoveredCell.rowIndex === rowIndex && hoveredCell.cellIndex === cellIndex
                        ? 'highlightedCell'
                        : ''
                    }
                    ${
                      hoveredCell && (hoveredCell.rowIndex === rowIndex || hoveredCell.cellIndex === cellIndex)
                        ? 'highlightedAgencent'
                        : ''
                    }`}
                          onMouseEnter={() => handleCellHover(rowIndex, cellIndex)}
                          onMouseLeave={() => setHoveredCell(null)}
                        >
                          {cell}
                        </TableCell>
                      ),
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );

  if (!columns) {
    return <CircularProgress />;
  }

  const renderMismatchTables = (popup) => {
    return (
      <React.Fragment>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Box flexGrow={1}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              onChange={handleColumnChange}
              disablePortal
              id="pacolumnge-combo-box"
              options={columns.map((columns) => columns.columnName)}
              getOptionLabel={(option) => option}
              value={columns.filter((column) => column.show === true).map((columns) => columns.columnName)}
              renderTags={() => null}
              renderInput={(params) => <TextField {...params} label="Select Columns" />}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
            />
          </Box>
          {!popup && (
            <Box marginLeft={2}>
              <IconButton onClick={() => setTablePopupOpen(true)}>
                <OpenInFullIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDisplayAsCode(true);
                }}
              >
                <DataObjectIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box mt={2} mb={4}>
          {renderTable(expectedData, 'Expected Results - Sample')}
        </Box>

        <Box>{renderTable(actualData, 'Actual Results - Sample')}</Box>
      </React.Fragment>
    );
  };

  const renderCodeDisplay = () => {
    return (
      <React.Fragment>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
          <IconButton
            onClick={() => {
              setDisplayAsCode(false);
            }}
          >
            <TableViewIcon />
          </IconButton>
        </Box>
        <JsonViewer data={rawData} />
      </React.Fragment>
    );
  };

  return (
    <div>
      {displayAsCode ? renderCodeDisplay(false) : renderMismatchTables(false)}

      <FullScreenPopup isOpen={tablePopupOpen} handleClose={() => setTablePopupOpen(false)} title={'Test Case Result'}>
        <Box mt={2}>{renderMismatchTables(true)}</Box>
      </FullScreenPopup>
    </div>
  );
};

export default MismatchRecordsTable;

import { Box } from '@mui/material';
import React from 'react';
import CheckIcon from '../../../assets/images/icons/check.svg';
import Image from '../../Image';

const Step = (props) => {
  const { step, onClickHandler, isLast } = props;

  const handleClick = (e) => {
    e.preventDefault();
    onClickHandler(e.currentTarget.id);
  };

  return (
    <Box
      className={'step ' + (step.open === true ? 'open' : 'pointer') + (isLast === true ? ' isLast' : '')}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      ml={1}
      id={step.StepId}
      onClick={handleClick}
    >
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box
          minWidth={'24px'}
          minHeight={'24px'}
          display="flex"
          className={'icon-container ' + (step.IsComplete === true ? 'complete' : 'todo')}
          justifyContent="center"
          alignItems="center"
        >
          {step.IsComplete === true ? <Image src={CheckIcon} alt="Check" /> : step.StepId}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box ml={2}>
            <h3> {step.Step}</h3>
          </Box>

          {step.open === true ? (
            <Box ml={2} className="detail">
              {step.Detail}
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      {/* {step.complete === false ? 
            <Button id={step.id} className="step-button yellow" variant="contained" mr={15} onClick={handleButtonClick}>
                <Box  display="flex" alignItems="center">
                    <Box>Done</Box>
                </Box>
            </Button>
            : <></>
            } */}
    </Box>
  );
};

export default Step;

import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';

export default function TestCaseViewProperties(props) {
  const { properties } = props;

  const getAltTextProperties = () => {
    if (properties && properties.selectionType !== 'Alt-text') return;

    return (
      <Box pt={4} key="Alt Text" className="text-bottom">
        <b>{'Alt Text'}</b>
        <p>{properties.altText}</p>
      </Box>
    );
  };

  const getVisualSelectProperties = () => {
    if (properties && properties.selectionType !== 'Report Visual Selection') return;

    return (
      <Box pt={4} key="Visual Name" className="text-bottom">
        <b>{'Visual Name'}</b>
        <p>{properties.visualName}</p>
      </Box>
    );
  };

  const getVisualUrlProperties = () => {
    if (properties && properties.selectionType !== 'Visual URL') return;

    return (
      <Box pt={4} key="Visual URL" className="text-bottom" maxWidth="450px">
        <b>{'Visual URL'}</b>
        <p>
          <Tooltip title={properties.reportVisualUrl}>
            <Typography noWrap>{properties.reportVisualUrl}</Typography>
          </Tooltip>
        </p>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box>
        <Box className="info-container" p={4} display="flex" justifyContent="space-between" flexDirection="column">
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box key="Report" className="text-left">
              <b>{'Report'}</b>
              <p>{properties.reportName}</p>
            </Box>
            <Box key="Page Name" className="text-right">
              <b>{'Page Name'}</b>
              <p>{properties.pageDisplayName}</p>
            </Box>
          </Box>
          {getAltTextProperties()}
          {getVisualSelectProperties()}
          {getVisualUrlProperties()}
        </Box>
        <Box className="info-container" p={4} display="flex" justifyContent="space-between" flexDirection="column">
          <Box key="Connection" className="text-left">
            <b>{'Connection'}</b>
            <p>{properties.connectionName}</p>
          </Box>
          <Box pt={4} key="SQL" className="text-left">
            <b>{'SQL'}</b>
            <p>{properties.sourceQuery}</p>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

function ConfirmationModal(props) {
  const { modalOpen, cancel, confirm, title, msg, confirmationButtonText } = props;
  return (
    <Dialog
      open={modalOpen}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ width: '400px' }}>
        <DialogContentText id="alert-dialog-description">{msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          Cancel
        </Button>
        <Button onClick={confirm} color="primary" autoFocus>
          {confirmationButtonText || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;

import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';
import { useGetTag } from '../../api/queries/tag';
import { useDeleteTestCase, useGetTestCase, useRenameTestCase, useExecuteTestCase } from '../../api/queries/testCase';
import { useGetVisualSelectionTypes } from '../../api/queries/visualSelectionTypes';

import Header from '../../components/Header';
import RenameModal from '../../components/RenameModal';
import SearchFilter from '../../components/SearchFilter';
import SideDrawer from '../../components/SideDrawer';
import { CREATE_FORM, EDIT_FORM, VIEW_DETAILS } from '../../constants/sidedrawer';
import withFormInteraction from '../../hocs/withFormInteraction';
import withSnackbar from '../../hocs/withSnackbar';
import { SignalRContext } from '../../services/signalR';
import { getSideDrawerHeading } from '../../utils/sidedrawer';
import CreateTestCase from './CreateTestCase';
import TestCaseView from './TestCaseView';
import TestCasesList from './TestCasesList';

function TestCases(props) {
  const { formOpen, toggleForm, isFormClosing, actionType, openSnackbar } = props;
  const { testCaseGuidUrl } = useParams();

  const [testCaseData, setTestCaseData] = useState({});
  const [testCaseNameFilter, setTestCaseNameFilter] = useState('');
  const [dotInidcator, setDotInidcator] = useState('');
  const [isReloading, setIsReloading] = useState(false);
  const [renameTestCaseData, setRenameTestCaseData] = useState(null);
  const [savingRename, setSavingRename] = useState(false);

  const { startNotify, stopNotify } = useContext(SignalRContext);

  const navigate = useNavigate();

  const {
    data: testCases,
    isLoading: testCasesIsLoading,
    isError: testCasesIsError,
    refetch: refetchTestCases,
  } = useGetTestCase(true, 'all', testCaseNameFilter);

  const { data: visualSelectionTypes } = useGetVisualSelectionTypes(true);
  const { data: tags, isLoading: tagsIsLoading } = useGetTag(true, 'all');

  const reload = () => {
    setIsReloading(true);
    refetchTestCases().then(() => {
      setIsReloading(false);
    });
  };

  // Updates
  const { mutate: deleteTestCaseHook } = useDeleteTestCase();
  const { mutate: executeQuickRun } = useExecuteTestCase();

  const renameTestCase = useRenameTestCase();

  const setFilterTestCases = (data) => {
    setTestCaseNameFilter(data);
  };

  const searchFilterProps = {
    searchProps: {
      placeholder: 'Search test cases',
      list: testCases,
      searchKey: 'testCaseName',
      setFilterListHook: setFilterTestCases,
      returnValue: true,
    },
  };

  useEffect(() => {
    if (testCaseGuidUrl && testCasesIsLoading === false) {
      const foundTestCase = testCases.find((x) => x.testCaseGuid === testCaseGuidUrl.toUpperCase());

      if (foundTestCase) {
        setTestCaseData(foundTestCase);
        toggleForm(VIEW_DETAILS);
      } else if (testCaseGuidUrl === 'add') {
        toggleForm(CREATE_FORM);
      } else {
        navigate('/testcases');
      }
    }
    // eslint-disable-next-line
  }, [testCases, testCasesIsLoading]);

  const onTestCaseResult = (msg, source) => {
    if (!msg) return;
    if (source !== 'testCase') return;

    refetchTestCases();

    const status = msg.Outcome;

    const snackBarMessage = `Test Case Executed`;
    const linkTo = '/testcases/runs';

    let subtitle;

    switch (status) {
      case 'Skipped':
      case 'Inconclusive':
        subtitle = 'Test case could not run';
        break;
      case 'Failed':
        subtitle = 'Test case result error';
        break;
      case 'Passed':
        subtitle = 'Test case run passed';
        break;
      default:
        'Unknown result';
    }

    enqueueSnackbar(snackBarMessage, {
      subtitle: subtitle,
      variant: 'collapseable',
      linkText: `Browse Test cases for progress information`,
      linkTo: linkTo,
      status: status,
      isExtenalLink: false,
    });
  };

  useEffect(() => {
    startNotify('FinishTestCase', onTestCaseResult, 'testCase');
    startNotify('StartTestRun', refetchTestCases, 'testCase');

    // The cleanup function will be called when the component unmounts or when the dependency array changes
    return () => {
      stopNotify('FinishTestCase');
      stopNotify('StartTestRun');
    };
    // eslint-disable-next-line
  }, []);

  const resetTestCaseData = () => {
    setTestCaseData({});
  };

  const selectTestCase = (index, newActionType) => {
    if (formOpen && actionType !== VIEW_DETAILS) {
      return;
    }
    setTestCaseData(testCases[index]);

    if (formOpen) {
      if (actionType === VIEW_DETAILS && newActionType === EDIT_FORM) {
        toggleForm(newActionType, 'keepopen');
      }
      return;
    }

    toggleForm(newActionType);
  };

  const getSideDrawerContent = () => {
    const commonProps = {
      tags,
      visualSelectionTypes,
    };

    switch (actionType) {
      case VIEW_DETAILS:
      case EDIT_FORM:
        return (
          <TestCaseView
            actionType={actionType}
            toggleForm={toggleForm}
            setEditMode={() => toggleForm(EDIT_FORM, 'keepopen')}
            setReadMode={() => toggleForm(VIEW_DETAILS, 'keepopen')}
            testCaseData={testCaseData}
            openSnackbar={openSnackbar}
            deleteTestCase={deleteTestCaseHook}
            executeQuickRun={onExecuteQuickRun}
            onRenameAction={onRenameAction}
            {...commonProps}
          />
        );
      case CREATE_FORM:
        return (
          <CreateTestCase
            actionType={actionType}
            toggleForm={toggleForm}
            tagsLoading={tagsIsLoading}
            openSnackbar={openSnackbar}
            {...commonProps}
          />
        );
      default:
        throw new Error('Unknown action type');
    }
  };

  const togglePage = (page) => {
    if (page !== '/testcases') navigate(page);
  };

  const switchOptions = {
    list: [
      { label: 'Test Cases', action: () => togglePage('/testcases') },
      { label: 'Quick Runs', action: () => togglePage('/testcases/runs') },
    ],
    activeSwitch: 'Test Cases',
  };

  const onExecuteQuickRun = (data) => {
    executeQuickRun(data);
    setDotInidcator('Quick Runs');
  };

  const onRenameAction = (testCaseGuid, testCaseName) => {
    if (testCaseData?.testCaseGuid) {
      setRenameTestCaseData({
        open: true,
        testCaseGuid: testCaseData?.testCaseGuid,
        testCaseName: testCaseData?.testCaseName,
      });
      return;
    }

    setRenameTestCaseData({ open: true, testCaseGuid, testCaseName });
  };

  const confirmRename = async (newName) => {
    setSavingRename(true);
    const renameBody = { testCaseGuid: renameTestCaseData.testCaseGuid, testCaseName: newName };
    await renameTestCase.mutateAsync(renameBody);
    setRenameTestCaseData(null);
    if (testCaseData?.testCaseGuid) {
      setTestCaseData((prevTestCaseData) => ({
        ...prevTestCaseData, // Spread the existing properties
        testCaseName: newName, // Update the specific property
      }));
    }
    setSavingRename(false);
  };

  return (
    <Box className="page-details">
      <Header
        title="Test Cases"
        buttonText="Add Test Case"
        toggleForm={() => {
          toggleForm();
          resetTestCaseData();
        }}
        switchOptions={switchOptions}
        buttonDisabled={formOpen}
        helpText="Test cases contain the report visualisation and the source query to test each visual."
        refreshAction={reload}
        dotIndicatorOption={dotInidcator}
      />

      <RenameModal
        title="Rename Test Case"
        modalOpen={!!renameTestCaseData?.open}
        confirmRename={confirmRename}
        cancelRename={() => {
          setSavingRename(false);
          setRenameTestCaseData(null);
        }}
        descriptionText={null}
        canUndo={true}
        undoTitle={'Undo'}
        placeholderText={renameTestCaseData?.testCaseName ?? ''}
        objectType={'Report'}
        showSaving={savingRename}
      />

      <Box px={4} className="page-details__list-container" display="flex" pb={4} flexDirection="row">
        <Box className="page-details-container" pr={3} flex={1}>
          <SearchFilter {...searchFilterProps} />
          <TestCasesList
            formOpen={formOpen}
            isFormClosing={isFormClosing}
            testCases={testCases}
            actionType={actionType}
            deleteTestCase={deleteTestCaseHook}
            executeQuickRun={onExecuteQuickRun}
            onRenameAction={onRenameAction}
            selectTestCase={selectTestCase}
            loading={(testCasesIsLoading || isReloading) ?? true}
            error={testCasesIsError}
          />
        </Box>
        <SideDrawer
          toggleForm={() => {
            toggleForm();
            resetTestCaseData();
          }}
          formOpen={formOpen}
          title={getSideDrawerHeading('TEST_CASE', actionType, testCaseData?.testCaseName)}
          onRenameAction={onRenameAction}
        >
          {getSideDrawerContent()}
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withSnackbar(withFormInteraction(TestCases));

import { Box, LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import LOGO from '../../assets/images/logo-login.png';
import Image from '../Image';

import {
  useDashboardGetFailedTests,
  useDashboardGetTestRuns,
  useGetDashboardFailedTestTrend,
  useGetDashboardPassRate,
  useGetDashboardTestDurationTrend,
  useGetSummary,
} from '../../api/queries/dashboard';

import { useGetReports } from '../../api/queries/report';
import { useGetVisualSelectionTypes } from '../../api/queries/visualSelectionTypes';
import { useGetConnections } from '../../api/queries/connection';
import { useGetConnectionTypes } from '../../api/queries/connectionType';
import { useGetTestPlan } from '../../api/queries/testPlan';
import { useGetTestCase } from '../../api/queries/testCase';
import { useGetTag } from '../../api/queries/tag';
import { useGetNotifications } from '../../api/queries/notifications';
import { useGetAnnouncements } from '../../api/queries/announcement';

function PageLoadingBox(props) {
  const { setAppIsLoading } = props;

  // Dashboard
  const { isLoading: dashboardSummaryIsLoading } = useGetSummary();
  const { isLoading: dashboardTestRunsIsLoading } = useDashboardGetTestRuns({ resultLimit: 10 });
  const { isLoading: dashboardFailedTestsIsLoading } = useDashboardGetFailedTests({ resultLimit: 10 });
  const { isLoading: dashboardPassRateIsLoading } = useGetDashboardPassRate({ periodDays: 7 });
  const { isLoading: dashboardFailedTestTrendIsLoading } = useGetDashboardFailedTestTrend({ periodDays: 7 });
  const { isLoading: dashboardTestDurationTrendIsLoading } = useGetDashboardTestDurationTrend({ periodDays: 7 });

  // Comms
  const { isLoading: announcementssIsLoading } = useGetAnnouncements(true);
  const { isLoading: notificationsIsLoading } = useGetNotifications(true);

  // Test plan

  const { isLoading: testPlansIsLoading } = useGetTestPlan(true, 'all', null);
  // Test Case
  const { isLoading: testCasesIsLoading } = useGetTestCase(true, 'all', null);

  const { isLoading: tagsIsLoading } = useGetTag(true, 'all');
  // Report
  const { isLoading: visualSelectionIsLoading } = useGetVisualSelectionTypes(true);
  const { isLoading: reportsIsLoading } = useGetReports(true, 'all', null);
  // Get connections
  const { isLoading: connectionTypesIsLoading } = useGetConnectionTypes();
  const { isLoading: connectionsIsLoading } = useGetConnections(true, 'all', null);

  const isLoading =
    dashboardSummaryIsLoading ||
    dashboardTestRunsIsLoading ||
    dashboardFailedTestsIsLoading ||
    dashboardPassRateIsLoading ||
    dashboardFailedTestTrendIsLoading ||
    dashboardTestDurationTrendIsLoading ||
    testPlansIsLoading ||
    testCasesIsLoading ||
    tagsIsLoading ||
    visualSelectionIsLoading ||
    reportsIsLoading ||
    connectionTypesIsLoading ||
    connectionsIsLoading ||
    announcementssIsLoading ||
    notificationsIsLoading;

  useEffect(() => {
    if (!isLoading) {
      setAppIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <Box className="loading-page" display="flex">
      <Box className="container" display="flex" flexDirection="column" justifyContent="flex-start">
        <Box className="loading-form">
          <Box mb={3} mr={6} ml={6} mt={-8.5}>
            <Image src={LOGO} altText={'PowerTester Logo'} className="loading-logo" />
          </Box>
          <Box mb={4} className="loading-label" mr={6} ml={6}>
            <span>PowerTester Loading...</span>
          </Box>
          <Box sx={{ width: '80%', alignSelf: 'center' }}>
            <LinearProgress />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PageLoadingBox;

import Cookies from 'universal-cookie';

import http from '../../services/http';
import { TOKEN } from '../../constants/api';
import { validateToken } from '../../services/token';

const cookies = new Cookies();

// Note these API calls have to be POST as we need to include a body

// Get token
export const getPowerBIBearerToken = async (connectionGuid, isServicePrincipalAuthentication, isKeyVault) => {
  if (!connectionGuid) throw new Error('getPowerBIBearerToken expects connection guid');

  const powerBIToken = cookies.get(`powerBIToken-${connectionGuid.toLowerCase()}`);

  if (powerBIToken && validateToken(powerBIToken)) {
    return powerBIToken;
  }

  const oneHourFromNow = new Date(Date.now() + 60 * 60 * 1000); // set to one hour from now
  let response;
  if (connectionGuid) {
    response = await http.post(`${TOKEN}/bearer`, { connectionGuid, isServicePrincipalAuthentication, isKeyVault });
  } else {
    return null;
  }

  if (response.data) {
    cookies.set(`powerBIToken-${connectionGuid.toLowerCase()}`, response.data, { expires: oneHourFromNow });

    return response.data;
  }
};

export const getPowerBIRefreshToken = (body) => http.post(`${TOKEN}/refresh`, body).then((res) => res.data);

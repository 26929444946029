import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { Box, List, ListItem, ListItemText, IconButton, ListItemIcon, Typography, Tooltip } from '@mui/material/';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { FixedSizeList } from 'react-window';
import NotificationIconLarge from '../../assets/images/icons/notification_large.svg';
import FormCloseIcon from '../../assets/images/icons/close.svg';

import TabComponent from '../Tabs';
import { MESSAGES } from '../../constants/messages';

import { useUpdateNotifications } from '../../api/queries/notifications';
import Image from '../Image';

const NotificationPanel = (props) => {
  const DEFAULT_SELECTED_TAB = 'ALL';

  const { notifications, anchorEl, onClose } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);
  const handleTabChange = async (tabName) => {
    setSelectedTab(tabName);
  };

  const { mutate: updateNotificationHook } = useUpdateNotifications();
  const markNotificationAsRead = async (notificationStatus, notificationMessageId) => {
    if (!notifications) return;
    if (notifications.length === 0) return;

    updateNotificationHook({ notificationStatus, notificationMessageId });
  };

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const getDayLabel = (date) => {
    const dateTimeStamp = date.setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const yesterday = new Date(today - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0);
    if (dateTimeStamp === today) {
      return 'Today';
    } else if (dateTimeStamp === yesterday) {
      return 'Yesterday';
    } else {
      return date.toDateString(); // or use a date formatting library
    }
  };

  const getTimeLabel = (dayLabel, date) => {
    if (dayLabel === 'Today') {
      const now = new Date();
      const elapsedMilliseconds = now - date;

      // Calculate elapsed minutes and hours
      const elapsedMinutes = Math.floor(elapsedMilliseconds / 1000 / 60);
      const elapsedHours = Math.floor(elapsedMinutes / 60);

      if (elapsedMinutes < 60) {
        return `${elapsedMinutes} mins ago`;
      } else {
        return `${elapsedHours} hr ago`;
      }
    }

    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const filterNotifications = (notificationStatus) => {
    if (!notifications) return [];
    if (!notificationStatus || notificationStatus === 'ALL') return notifications;
    return notifications.filter((n) => n.NotificationStatus?.toUpperCase() === notificationStatus);
  };

  const tabOptions = [
    { name: 'All', value: 'ALL' },
    { name: 'Success', value: 'SUCCESS' },
    { name: 'Warning', value: 'WARNING' },
    { name: 'Error', value: 'ERROR' },
  ];

  const getNotificationMessage = (messageType, notification) => {
    if (!notification || !notification?.NotificationBody) return;
    const messageTemplate = MESSAGES[messageType];

    switch (messageType) {
      case 'FINISH_TEST_RUN': {
        const body = JSON.parse(notification.NotificationBody);
        if (!body?.TestPlanName) return;
        return messageTemplate.replace('{0}', body.TestPlanName).replace('{1}', notification.NotificationBodyStatus);
      }
      default:
        return notification.body;
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case 'Success':
        return <CheckCircleIcon color="success" />;
      case 'Error':
        return <ErrorIcon color="error" />;
      default:
      case 'Warning':
        return <WarningIcon sx={{ color: '#0288D1' }} />;
    }
  };

  const styles = {
    root: {
      width: '100%',
      height: '400px',
      maxWidth: 300,
      backgroundColor: 'white',
    },
    listItemRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: 'center',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
      paddingLeft: '0px',
    },
    statusIcon: {
      minWidth: '20px !important',
      width: '20px !important',
      alignSelf: 'flex-start',
      marginRight: '5px',
      marginTop: '10px',
    },
    closeIcon: {
      marginRight: '8px!important',
      padding: '0 !important',
      alignSelf: 'flex-start',
    },
  };

  const navigateTo = (route) => {
    if (!route) return;
    onClose();

    navigate(route);
  };

  const bodyClick = (messageType, notificationBody) => {
    try {
      if (!notificationBody) return;

      switch (messageType) {
        case 'FINISH_TEST_RUN': {
          const body = JSON.parse(notificationBody);
          if (!body?.TestRunGuid) return;
          return navigateTo('/testruns/' + body.TestRunGuid);
        }
        default:
          return null;
      }
    } catch (e) {
      return;
    }
  };

  const filteredList = filterNotifications(selectedTab);

  const notificationListItem = (notification) => {
    const dayLabel = getDayLabel(new Date(notification.CreatedDateTime));
    return (
      <ListItem
        sx={styles.listItemRoot}
        key={notification.NotificationMessageId}
        secondaryAction={
          <Tooltip title={'Mark as read'}>
            <IconButton
              sx={styles.closeIcon}
              onClick={() => markNotificationAsRead(null, notification.NotificationMessageId)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <ListItemIcon sx={styles.statusIcon}>{getIcon(notification.NotificationStatus)}</ListItemIcon>
        <ListItemText
          onClick={() => bodyClick(notification.NotificationTypeCode, notification.NotificationBody)}
          sx={{
            '&:hover ': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
          primaryTypographyProps={{
            fontSize: 12,
            fontWeight: 500,
            // hover states
          }}
          primary={getNotificationMessage(notification.NotificationTypeCode, notification)}
          secondary={`${dayLabel} ${getTimeLabel(dayLabel, new Date(notification.CreatedDateTime))}`}
        />
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <Popover
        id={open ? 'notification-popover' : undefined}
        open={open}
        onClose={() => {
          onClose();
          setSelectedTab(DEFAULT_SELECTED_TAB);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 120,
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '400px', height: '600px', overflow: 'auto' }} p={2}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <h3>Notifications</h3>

            <Tooltip title="Close">
              <Box onClick={() => onClose()} sx={{ minWidth: '40px', marginLeft: '5px' }}>
                <Image className="close-icon pointer" src={FormCloseIcon} alt="Close Icon" />
              </Box>
            </Tooltip>
          </Box>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <TabComponent
              handleChange={handleTabChange}
              selectedTab={DEFAULT_SELECTED_TAB}
              options={tabOptions}
              small
            />
            <span
              onClick={() => markNotificationAsRead(selectedTab, null)}
              style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: 500, cursor: 'pointer' }}
            >
              Mark All as read
            </span>
          </Box>

          <Box sx={{ width: '100%', overflow: 'auto' }}>
            <List>
              <div className={styles.root}>
                {/* If there are no notifications show the placeholder text */}
                {filteredList?.length == 0 ? (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ height: '400px' }}
                  >
                    <Image src={NotificationIconLarge} altText={'Notification Image'} />
                    <Typography mt={2}>{"You're all caught up!"}</Typography>
                    <Typography>{'No new notifications.'}</Typography>
                  </Box>
                ) : (
                  <FixedSizeList height={450} itemSize={60} itemData={filteredList} itemCount={filteredList?.length}>
                    {({ data, index, style }) => {
                      return (
                        <div key={index} style={style}>
                          {notificationListItem(data[index])}
                        </div>
                      );
                    }}
                  </FixedSizeList>
                )}
              </div>
            </List>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default NotificationPanel;

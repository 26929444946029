import { LoadingButton } from '@mui/lab';
import { Box, Divider, Link, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';

import AuthProviderLoginButton from '../../../../../components/AuthProviderLoginButton';
import FieldHelpText from '../../../../../components/FieldHelpText';
import { powerBiOauthUrl } from '../../../../../configs/msal';
import helpText from '../../../../../constants/helpText';
import withSnackbar from '../../../../../hocs/withSnackbar';
import OauthPopup from '../../../../../libs/OauthPopup';
import { useGetAuthenticationTypes } from '../../../../../api/queries/authenticationType';
import {
  useAuthorizeConnection,
  useGetConnections,
  useTestPowerBIConnection,
} from '../../../../../api/queries/connection';
import { useGetPowerBIRefreshToken } from '../../../../../api/queries/token';
import RowRadioButtonsGroup from '../../../../../components/RowRadioButtonsGroup';
import Autocomplete from '../../../../../components/Autocomplete';
import FormTextField from '../../../../../components/FormTextField';
import { useGetKeyVaultSecrets } from '../../../../../api/queries/keyVault';

function PowerBIForm(props) {
  const {
    connectionName,
    connectionGuid,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    openSnackbar,
    powerBIDetails,
    authenticationType,
    credentialMethod,
    keyVaultConnection,
    connectionType,
  } = props;

  const disableCredMethod = !authenticationType || authenticationType?.authenticationType === 'Master User OAuth';

  useEffect(() => {
    // When the conncetion type changes reset the auth type
    if (authenticationType && authenticationType.authenticationType === 'Master User OAuth') {
      setFieldValue('credentialMethod', 'Embedded');
    }
    // eslint-disable-next-line
  }, [authenticationType]);

  // Get KV connections
  const { data: keyVaultConnections, isLoading: keyVaultConnectionsIsLoading } = useGetConnections(
    true,
    'all',
    null,
    'Key Vault',
  );

  // Get key Vault Secrets
  const { data: keyVaultSecrets, isLoading: keyVaultSecretsIsLoading } = useGetKeyVaultSecrets(
    !!keyVaultConnection?.connectionGuid,
    keyVaultConnection,
  );

  const { mutate: testConnection, isLoading: testingInProcess } = useTestPowerBIConnection();
  const { mutate: authorizeConnection } = useAuthorizeConnection();

  const onTextChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  // Get connections
  const { data: authenticationTypes, isLoading: authenticationTypesIsLoading } = useGetAuthenticationTypes(
    connectionType?.connectionTypeId,
  );

  const createRefreshTokenMutation = useGetPowerBIRefreshToken();

  useEffect(() => {
    if (authenticationTypes && authenticationTypes.length === 1) {
      const name = 'authenticationType';
      const value = authenticationTypes[0];
      setFieldValue(name, value);
      setFieldTouched(name, true, false);
    }

    // eslint-disable-next-line
  }, [authenticationTypes, authenticationType]);

  const onAuthoriseRedirect = async (code) => {
    try {
      const resp = await createRefreshTokenMutation.mutateAsync(code);
      const { refresh_token, error, error_description } = resp;
      if (error || createRefreshTokenMutation.isError) {
        console.error(error);
        let msg = 'Error authorising';

        if (error_description) {
          msg += ` :${error_description}`;
        }
        openSnackbar(msg, 'error', undefined, undefined, 5000);
        return;
      }

      if (connectionGuid) {
        authorizeConnection({ connectionGuid, refreshToken: refresh_token });
      }
      setFieldValue('powerBIDetails.refreshToken', refresh_token);
      openSnackbar('Connection authorised', 'success');
    } catch (error) {
      console.error(error);
      openSnackbar('Error authorising', 'error');
    }
  };

  const openOauthPopup = () => {
    setFieldTouched('refreshToken', true, false);
  };

  const SPEmbedded = () => {
    return (
      <React.Fragment>
        <FormTextField
          className="text-field"
          label="Tenant Id"
          variant="outlined"
          fullWidth
          id="tenantId"
          name="powerBIDetails.tenantId"
          value={powerBIDetails?.tenantId || ''}
          onChange={(event) => {
            onTextChange('powerBIDetails.tenantId', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="powerBIDetails"
        />

        <FormTextField
          className="text-field"
          label="Service Principal App Id"
          variant="outlined"
          fullWidth
          id="servicePrincipalAppId"
          name="powerBIDetails.servicePrincipalAppId"
          value={powerBIDetails?.servicePrincipalAppId || ''}
          onChange={(event) => {
            onTextChange('powerBIDetails.servicePrincipalAppId', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="powerBIDetails"
        />

        <FormTextField
          className="text-field"
          type="password"
          label="Service Principal Secret"
          variant="outlined"
          fullWidth
          id="servicePrincipalSecret"
          name="powerBIDetails.servicePrincipalSecret"
          value={powerBIDetails?.servicePrincipalSecret || ''}
          onChange={(event) => {
            onTextChange('powerBIDetails.servicePrincipalSecret', event.target.value);
          }}
          touched={touched}
          errors={errors}
          errorProperty="powerBIDetails"
        />
      </React.Fragment>
    );
  };

  const SPKV = () => {
    return (
      <React.Fragment>
        <Box className="form-field-container">
          <Autocomplete
            multiple={false}
            loading={keyVaultConnectionsIsLoading}
            name="keyVaultConnection"
            setFieldValue={setFieldValue}
            options={keyVaultConnections}
            label={'connectionName'}
            value={'connectionGuid'}
            selectedItem={powerBIDetails.keyVaultConnection}
            inputLabel={'Selection key vault connection'}
            error={touched.keyVaultConnection && Boolean(errors.keyVaultConnection)}
          />
          {errors.keyVaultConnection && touched.keyVaultConnection && (
            <Box color="error.main" className="form-field-error-text">
              {errors.keyVaultConnection}
            </Box>
          )}
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            multiple={false}
            loading={keyVaultSecretsIsLoading}
            name="powerBIDetails.tenantIdKeyVault"
            setFieldValue={setFieldValue}
            options={keyVaultSecrets}
            selectedItem={powerBIDetails.tenantIdKeyVault}
            inputLabel={'Select secret containing tenant Id'}
            error={touched?.powerBIDetails?.tenantIdKeyVault && Boolean(errors?.powerBIDetails?.tenantIdKeyVault)}
          />
          {errors?.powerBIDetails?.tenantIdKeyVault && touched?.powerBIDetails?.tenantIdKeyVault && (
            <Box color="error.main" className="form-field-error-text">
              {errors?.powerBIDetails?.tenantIdKeyVault}
            </Box>
          )}
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            multiple={false}
            loading={keyVaultSecretsIsLoading}
            name="powerBIDetails.servicePrincipalAppIdKeyVault"
            setFieldValue={setFieldValue}
            options={keyVaultSecrets}
            selectedItem={powerBIDetails.servicePrincipalAppIdKeyVault}
            inputLabel={'Select secret containing Service principal Id'}
            error={
              touched?.powerBIDetails?.servicePrincipalAppIdKeyVault &&
              Boolean(errors?.powerBIDetails?.servicePrincipalAppIdKeyVault)
            }
          />
          {errors?.powerBIDetails?.servicePrincipalAppIdKeyVault &&
            touched?.powerBIDetails?.servicePrincipalAppIdKeyVault && (
              <Box color="error.main" className="form-field-error-text">
                {errors?.powerBIDetails?.servicePrincipalAppIdKeyVault}
              </Box>
            )}
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            multiple={false}
            loading={keyVaultSecretsIsLoading}
            name="powerBIDetails.servicePrincipalSecretKeyVault"
            setFieldValue={setFieldValue}
            options={keyVaultSecrets}
            selectedItem={powerBIDetails.servicePrincipalSecretKeyVault}
            inputLabel={'Select secret containing Service principal secret'}
            error={
              touched?.powerBIDetails?.servicePrincipalSecretKeyVault &&
              Boolean(errors?.powerBIDetails?.servicePrincipalSecretKeyVault)
            }
          />
          {errors?.powerBIDetails?.servicePrincipalSecretKeyVault &&
            touched?.powerBIDetails?.servicePrincipalSecretKeyVaultservicePrincipalSecretKeyVault && (
              <Box color="error.main" className="form-field-error-text">
                {errors?.powerBIDetails?.servicePrincipalSecretKeyVault}
              </Box>
            )}
        </Box>
      </React.Fragment>
    );
  };

  const MasterUserEmbedded = () => {
    return (
      <React.Fragment>
        <div className="primary" onClick={openOauthPopup}>
          <OauthPopup url={powerBiOauthUrl} onCode={onAuthoriseRedirect}>
            <AuthProviderLoginButton
              name="microsoft"
              buttontext="Power Bi authentication"
              success={powerBIDetails.refreshToken}
            />
          </OauthPopup>
          {errors.powerBIDetails?.refreshToken && touched.powerBIDetails?.refreshToken && (
            <Box color="error.main" className="form-field-error-text">
              {errors.powerBIDetails?.refreshToken}
            </Box>
          )}
        </div>
      </React.Fragment>
    );
  };

  const testButtonIsDisabled = () => {
    // Check if workspaceId is falsy, return true ie disabled
    if (!powerBIDetails) return true;

    const {
      workspaceId,
      refreshToken,
      tenantId,
      servicePrincipalAppId,
      servicePrincipalSecret,
      tenantIdKeyVault,
      servicePrincipalAppIdKeyVault,
      servicePrincipalSecretKeyVault,
    } = powerBIDetails;

    // Check if workspaceId is missing
    if (!workspaceId) {
      return true;
    }
    // Check if authenticationType is 'Master User OAuth' and connectionGuid is null (so that its a new connection) and refreshtoken is null
    if (authenticationType?.authenticationType === 'Master User OAuth' && !connectionGuid && !refreshToken) {
      return true;
    }

    // Check if authenticationType is 'Service principal' and any of the required properties is missing
    if (authenticationType?.authenticationType === 'Service principal') {
      if (credentialMethod === 'Embedded' && (!tenantId || !servicePrincipalAppId || !servicePrincipalSecret)) {
        return true;
      }
      if (
        credentialMethod === 'Key Vault' &&
        (!tenantIdKeyVault || !servicePrincipalAppIdKeyVault || !servicePrincipalSecretKeyVault)
      ) {
        return true;
      }
    }

    // All conditions passed, return false ie not disabled
    return false;
  };

  return (
    <React.Fragment>
      <Stack direction="column" spacing={2}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: '38px' }}
        >
          <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <h4>Select how to connect to Power BI reports</h4>
            <Link
              href="https://wild-data-solutions.outseta.com/support/kb/articles/OW4pqRWg/what-data-in-my-power-bi-reports-can-powertester-access"
              target="_blank"
            >
              Can you read my Power BI reports?
            </Link>
          </Box>
          <LoadingButton
            className="yellow form-button"
            variant="contained"
            loading={testingInProcess}
            disabled={testButtonIsDisabled()}
            onClick={() => {
              testConnection({
                connectionGuid,
                powerBIDetails,
                keyVaultConnection,
                connectionName,
              });
            }}
          >
            Test
          </LoadingButton>
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            preselectOne
            loading={authenticationTypesIsLoading}
            multiple={false}
            name="authenticationType"
            setFieldValue={setFieldValue}
            options={authenticationTypes}
            label={'authenticationType'}
            value={'authenticationTypeId'}
            selectedItem={authenticationType?.authenticationTypeId || null}
            inputLabel={'Select authentication type'}
            error={touched.authenticationType && Boolean(errors.authenticationType)}
            helpText={{
              content: 'Authentication method determines how PowerTester authenticates to the Power BI workspace.',
            }}
          />
          {errors.authenticationType && touched.authenticationType && (
            <Box color="error.main" className="form-field-error-text">
              {errors.authenticationType}
            </Box>
          )}
        </Box>
        <RowRadioButtonsGroup
          tooltip={disableCredMethod && 'Key Vault not supported for Master user authentication'}
          disabled={disableCredMethod}
          title={'Select credential method'}
          options={[{ value: 'Embedded' }, { value: 'Key Vault' }]}
          value={credentialMethod}
          onChange={(value) => {
            onTextChange('credentialMethod', value);
          }}
          helpText={{
            content:
              'You can store credentials for connections in an Azure Key Vault. PowerTester retrieves the credentials when executing an test case that uses the connection; but does not store any credential.',
          }}
        />
        <Divider>Connection details</Divider>
      </Stack>
      <Stack direction={'column'} spacing={3} mt={2}>
        <Box className="form-field-container">
          <TextField
            disabled={false}
            className="text-field"
            label="Workspace Id"
            variant="filled"
            fullWidth
            id="workspaceId"
            name="workspaceId"
            value={powerBIDetails?.workspaceId || ''}
            onChange={(event) => {
              onTextChange('powerBIDetails.workspaceId', event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <FieldHelpText
                  content={helpText.connection.workspaceId.HELP_TEXT}
                  linkText={helpText.connection.workspaceId.LINK_TEXT}
                  link={helpText.connection.workspaceId.LINK_URL}
                />
              ),
            }}
            error={touched.powerBIDetails?.workspaceId && Boolean(errors.powerBIDetails?.workspaceId)}
          />
          {errors.powerBIDetails?.workspaceId && touched.powerBIDetails?.workspaceId && (
            <Box color="error.main" className="form-field-error-text">
              {errors.powerBIDetails?.workspaceId}
            </Box>
          )}
        </Box>
        {powerBIDetails &&
          credentialMethod === 'Embedded' &&
          authenticationType?.authenticationType === 'Master User OAuth' &&
          MasterUserEmbedded()}
        {powerBIDetails &&
          credentialMethod === 'Embedded' &&
          authenticationType?.authenticationType === 'Service principal' &&
          SPEmbedded()}
        {powerBIDetails &&
          credentialMethod === 'Key Vault' &&
          authenticationType?.authenticationType === 'Service principal' &&
          SPKV()}
      </Stack>
    </React.Fragment>
  );
}

export default withSnackbar(PowerBIForm);

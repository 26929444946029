import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

function withSnackbar(WrappedComponent) {
  function WithSnackbar(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false); // State to track loading state

    const openSnackbar = (message, type, actionMsg, onClickAction, timeout) => {
      const actionButton =
        actionMsg && onClickAction ? (
          <LoadingButton
            key="snackBAr"
            color="inherit"
            onClick={() => {
              handleActionClick(onClickAction);
            }}
            loading={loading} // Pass loading state to the LoadingButton
            size="small"
          >
            {actionMsg}
          </LoadingButton>
        ) : null;

      enqueueSnackbar(message, {
        variant: type ?? 'default', // Adjust variant based on the type
        action: [actionButton],
        autoHideDuration: timeout ?? 3000,
      });
    };

    const handleActionClick = async (onClickAction) => {
      setLoading(true); // Set loading state to true

      try {
        await onClickAction(); // Perform the action

        // If the action is successful, you can hide the Snackbar after a delay
        // closeSnackbar();
      } catch (error) {
        // Handle any error that occurred during the action
        console.error(error);
      } finally {
        setLoading(false); // Set loading state back to false
      }
    };

    return <WrappedComponent openSnackbar={openSnackbar} {...props} />;
  }
  return WithSnackbar;
}

export default withSnackbar;

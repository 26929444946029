import { TextField, Stack, Box, IconButton, Tooltip, CircularProgress, Backdrop } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState, useEffect } from 'react';
import UndoIcon from '@mui/icons-material/Undo';

function RenameModal(props) {
  const {
    modalOpen,
    cancelRename,
    objectType,
    confirmRename,
    title,
    descriptionText,
    canUndo,
    undoTitle,
    placeholderText,
    showSaving,
  } = props;
  const [newName, setNewName] = useState('');

  useEffect(() => {
    if (placeholderText) {
      setNewName(placeholderText);
    }
    // eslint-disable-next-line
  }, [modalOpen, placeholderText]);

  const submitForm = () => {
    confirmRename(newName);
    if (!showSaving) setNewName('');
  };

  const cancelForm = () => {
    setNewName('');
    cancelRename();
  };

  const onUndo = () => {
    setNewName('');
  };

  return (
    <Dialog
      open={modalOpen ?? false}
      onClose={cancelForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <React.Fragment>
        <DialogContent style={{ width: '600px' }}>
          <Stack direction="column" spacing={2}>
            <Backdrop
              sx={{ color: (theme) => theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showSaving ?? false}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <DialogContentText id="alert-dialog-description">{descriptionText}</DialogContentText>
            <Box className="form-field-container" display="flex" flexDirection="row" justifyContent={'space-between'}>
              <Box flexGrow={1}>
                <TextField
                  className="text-field"
                  label={`New ${objectType} Name`}
                  variant="filled"
                  fullWidth
                  id="newName"
                  name="newName"
                  value={newName}
                  onChange={(event) => {
                    setNewName(event.target.value);
                  }}
                />
              </Box>
              <Tooltip title={undoTitle}>
                <IconButton disabled={!canUndo} onClick={onUndo}>
                  <UndoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelForm} color="primary">
            Cancel
          </Button>
          <Tooltip title={placeholderText === newName ? 'Must be a new name' : ''}>
            <div>
              <Button onClick={submitForm} color="primary" autoFocus disabled={placeholderText === newName}>
                Save
              </Button>
            </div>
          </Tooltip>
        </DialogActions>
      </React.Fragment>
    </Dialog>
  );
}

export default RenameModal;

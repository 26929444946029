import React, { useState } from 'react';
import withSnackbar from '../../hocs/withSnackbar';
import { SNACKBAR_INFO } from '../../constants/snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const TextChipCopy = (props) => {
  const { text, textToCopy, snackBarText, openSnackbar } = props;

  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy ?? text);

    openSnackbar(snackBarText ?? 'Copied to clipboard', SNACKBAR_INFO);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <span
        className="copy-text"
        onClick={handleClick}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        {props.children}
      </span>
      <ContentCopyIcon className={isHovered ? 'copy-icon' : 'copy-icon-hidden'} onClick={handleClick} />
    </div>
  );
};

export default withSnackbar(TextChipCopy);

export const mapVisualSelectionTypes = (visualSelectionTypesData) => {
  if (!visualSelectionTypesData) {
    return;
  }

  const visualArray = visualSelectionTypesData.map((obj) => {
    return {
      visualSelectionTypeId: obj.VisualSelectionTypeId,
      selectionType: obj.SelectionType,
    };
  });

  return visualArray;
};

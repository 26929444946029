import * as Yup from 'yup';
import errors from '../../constants/errors';
import validations from '../../constants/validations';

export const keyVaultSchema = () => {
  return Yup.object().shape({
    keyVaultUrl: Yup.string().required(errors.KEY_VAULT_URL.REQUIRED).max(800, errors.KEY_VAULT_URL.MAX_LENGTH(800)),
    tenantId: Yup.string()
      .required(errors.TENANT_ID.REQUIRED)
      .max(800, errors.TENANT_ID.MAX_LENGTH(800))
      .matches(validations.GUID, errors.TENANT_ID.CRITERION),
    servicePrincipalAppId: Yup.string()
      .required(errors.SP_APP_ID.REQUIRED)
      .max(800, errors.SP_APP_ID.MAX_LENGTH(800))
      .matches(validations.GUID, errors.SP_APP_ID.CRITERION),
    servicePrincipalSecret: Yup.string()
      .required(errors.SP_APP_SECRET.REQUIRED)
      .max(800, errors.SP_APP_SECRET.MAX_LENGTH(800)),
  });
};

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as moment from 'moment';

import Menu from '../../../../components/Menu';
import TablePaginationActions from '../../../../components/TablePaginationActions';
import TableRowMenu from '../../../../components/TableRowMenu';
import TableSkeleton from '../../../../components/TableSkeleton';
import { getRowIndex } from '../../../../utils/TablePagination';

export default function UserList(props) {
  const { users, loading, toggleModal, formOpen } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event, index, id, row) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            top: event.clientY - 6,
            left: event.clientX + 2,
            index,
            id,
            row,
          }
        : null,
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [users]);

  const deactivateUserAccount = (index, id) => {
    toggleModal(id);
  };

  const options = [
    {
      // label: 'Deactivate',
      getOptionLabel: (row) => (row?.status === 'Active' ? 'Deactivate' : 'Activate'),
      action: deactivateUserAccount,
      disabledCriteria: (row) => row?.isCurrentAccount || row?.role === 'Global Admin',
    },
  ];

  return (
    <React.Fragment>
      {!!contextMenu?.top && (
        <Menu
          anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
          options={options}
          handleClose={() => setContextMenu(null)}
          index={contextMenu?.index}
          id={contextMenu?.id}
          row={contextMenu?.row}
        />
      )}
      <TableContainer>
        <Table className="list-table fixed" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="column">Name</TableCell>
              <TableCell className="column">User Principal</TableCell>
              {!formOpen && (
                <React.Fragment>
                  <TableCell className="column">Role</TableCell>
                  <TableCell className="column">Status</TableCell>
                  <TableCell className="column">Created On</TableCell>
                  <TableCell className="column menu-column"></TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              (users && users?.length > 0
                ? rowsPerPage > 0
                  ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : users
                : []
              ).map((row, index) => (
                <TableRow
                  key={row.userPrincipal}
                  onContextMenu={(event) =>
                    handleContextMenu(event, getRowIndex(index, page, rowsPerPage), row.userPrincipal, row)
                  }
                >
                  <TableCell className="column break-all" component="td" scope="row">
                    <Typography noWrap variant={'body1'}>
                      {row.displayName}
                    </Typography>
                  </TableCell>
                  <TableCell className="column break-all" component="td" scope="row">
                    <Typography noWrap variant={'body1'}>
                      {row.userPrincipal}
                    </Typography>
                  </TableCell>

                  {!formOpen && (
                    <React.Fragment>
                      <TableCell className="column break-all" component="td" scope="row">
                        {row.role}
                      </TableCell>
                      <TableCell className="column break-all" component="td" scope="row">
                        {row.status}
                      </TableCell>
                      <TableCell className="column break-all" component="td" scope="row">
                        {moment.utc(row.createdDateTimeUTC).local().format('lll')}
                      </TableCell>

                      <TableCell className="column menu-column">
                        <TableRowMenu
                          index={getRowIndex(index, page, rowsPerPage)}
                          id={row.userPrincipal}
                          options={options}
                          row={row}
                        />
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              ))
            ) : (
              <TableSkeleton columns={5} rows={10} hasMenu />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={users?.length ?? 0}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, Divider, Link, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

import Autocomplete from '../../../components/Autocomplete';
import FormTextField from '../../../components/FormTextField';
import ResponseCard from '../../../components/ResponseCard';
import StepperComponent from '../../../components/Stepper';
import { getSideDrawerSubHeading } from '../../../utils/sidedrawer';
import { useGetConnectionTypes } from '../../../api/queries/connectionType';
import { useGetAuthenticationTypes } from '../../../api/queries/authenticationType';
import ConnectionDetailsForm from './ConnectionDetailsForm';

function CreateConnectionForm(props) {
  const {
    values: { connectionName, connectionType, authenticationType, powerBIDetails },
    LAST_FORM_NUMBER,
    handleBack,
    responseStatus,
    responseError,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    isDetailsSubmitted,
    isDetailsSubmitting,
    actionType,
    formNumber,
    connectionGuid,
  } = props;

  const { data: connectionTypes, isLoading: connectionTypesIsLoading } = useGetConnectionTypes();

  const { data: authenticationTypes } = useGetAuthenticationTypes(connectionType?.connectionTypeId);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    // When the conncetion type changes reset the auth type
    if (touched.connectionType && connectionType) {
      setFieldValue('authenticationType', null);
    }
    // eslint-disable-next-line
  }, [connectionType]);

  const getResponseCard = () => {
    const result = [
      { key: 'Name', value: connectionName },
      { key: 'Type', value: connectionType.connectionType },
    ];

    if (authenticationType?.authenticationType) {
      result.push({ key: 'Authentication Type', value: authenticationType?.authenticationType });
    } else if (connectionType === 'Destination') {
      result.push({ key: 'Power BI Workspace', value: powerBIDetails?.workspaceId });
    }

    const subheader = isDetailsSubmitted
      ? getSideDrawerSubHeading('CONNECTION', actionType, false)
      : getSideDrawerSubHeading('CONNECTION', actionType, true);

    return (
      <ResponseCard
        title={!responseError ? subheader : 'An error occured'}
        values={result}
        responseType={responseStatus || 'pre-submit'}
        errorDetails={responseError}
      />
    );
  };

  const getNextButtonText = () => {
    if (formNumber === LAST_FORM_NUMBER) {
      return 'Save';
    }
    return 'Next';
  };

  const steps = ['Connection name', 'Connection details'];

  const getConnectionDescription = () => {
    switch (connectionType?.connectionType) {
      case 'Azure SQL Database':
        return (
          <Stack>
            <span>Connect to an Azure SQL database to use as source for your test cases.</span>
            <Link
              href="https://wild-data-solutions.outseta.com/support/kb/articles/dQGnKdm4/database-connection-details"
              target="_blank"
            >
              Azure SQL database guide
            </Link>
          </Stack>
        );
      case 'Microsoft Fabric':
        return (
          <Stack>
            <span>
              (Preview)Connect to an Microsoft Fabric Warehouse or Lakehouse to use as source for your test cases.
            </span>
            <Link
              href="https://wild-data-solutions.outseta.com/support/kb/articles/496Dd0mX/microsoft-fabric-connections"
              target="_blank"
            >
              More information on Microsoft Fabric connections
            </Link>
          </Stack>
        );
      case 'Power BI':
        return (
          <Stack>
            <span>
              Power BI connections store the information required to connect to Power BI reports. <br />
              PowerTester can connect to Power BI using Service Principal or a master user OAuth connection.
            </span>
            <Link
              href="https://wild-data-solutions.outseta.com/support/kb/articles/XQY2eO9P/power-bi-connections"
              target="_blank"
            >
              More information on Power BI connections and authentication
            </Link>
          </Stack>
        );
      case 'Power BI - Fabric':
        return (
          <Stack>
            <span>
              (Preview) Power BI connections for Fabric is a special connection for Power BI Reports built in a
              Microsoft Fabric workspace. <br />
              Fabric reports can only connect using a master user oauth authentications.
            </span>
            <Link
              href="https://wild-data-solutions.outseta.com/support/kb/articles/496Dd0mX/microsoft-fabric-connections"
              target="_blank"
            >
              More information on Microsoft Fabric connections
            </Link>
          </Stack>
        );
      case 'Key Vault':
        return (
          <Stack>
            <span>
              Key Vault connections are a special connection type used to retrieve secrets from your Azure Key Vault for
              authentication, rather than storing externally in the PowerTester cloud environment.
            </span>
            <Link
              href="https://learn.microsoft.com/en-us/azure/key-vault/general/" // TODO
              target="_blank"
            >
              More information on Azure Key Vaults
            </Link>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box display="flex" pr={4} py={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <StepperComponent steps={steps} activeStep={formNumber - 1} />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ minWidth: '150px' }}
        >
          <Stack direction="row" spacing={1}>
            {formNumber !== 1 && (!isDetailsSubmitted || responseError) && (
              <Button className="grey form-button" variant="contained" onClick={handleBack}>
                Back
              </Button>
            )}
            {!isDetailsSubmitted && (
              <LoadingButton
                type="submit"
                className="yellow form-button"
                variant="contained"
                loading={isDetailsSubmitting}
              >
                {getNextButtonText()}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box px={4} mt={3}>
        <React.Fragment>
          {formNumber === 1 && (
            <Box>
              <Stack spacing={2} direction={'column'} mb={2}>
                <p>
                  Connections hold the connection information for your data source or Power BI.
                  <br />
                  <Link
                    href="https://wild-data-solutions.outseta.com/support/kb/articles/j9bxqvmn/creating-connections"
                    target="_blank"
                  >
                    Connection getting started guide
                  </Link>
                </p>
                <p>
                  <LockIcon fontSize="small" />
                  Security is our number one priority.
                  <br />
                  <Link href="https://www.powertester.app/security" target="_blank">
                    Learn how PowerTester keeps your details secure
                  </Link>
                </p>
                <Divider>Conection info</Divider>
              </Stack>
              <Stack spacing={3} direction={'column'}>
                <FormTextField
                  label="Connection Name"
                  variant="filled"
                  fullWidth
                  id="connectionName"
                  name="connectionName"
                  value={connectionName}
                  onChange={change.bind(null, 'connectionName')}
                  touched={touched}
                  errors={errors}
                />
                <Box className="form-field-container">
                  <Autocomplete
                    preselectOne
                    groupBy={'connectionTypeGroup'}
                    tooltip={!!connectionGuid && 'Cannot change connection type'}
                    disabled={!!connectionGuid}
                    loading={connectionTypesIsLoading}
                    multiple={false}
                    name="connectionType"
                    setFieldValue={setFieldValue}
                    options={connectionTypes}
                    label={'connectionType'}
                    value={'connectionTypeId'}
                    selectedItem={connectionType?.connectionTypeId || null}
                    inputLabel={'Select connection type'}
                    error={touched.connectionType && Boolean(errors.connectionType)}
                    helpText={{
                      content:
                        'Select the connection type, note this cannot be changed once the conneciton is created.',
                      linkText: 'For more information view this KB article',
                      link: 'https://wild-data-solutions.outseta.com/support/kb/articles/7maro2QE/connection-types',
                    }}
                  />
                  {errors.connectionType && touched.connectionType && (
                    <Box color="error.main" className="form-field-error-text">
                      {errors.connectionType}
                    </Box>
                  )}
                </Box>
                {getConnectionDescription()}
              </Stack>
            </Box>
          )}
          {formNumber === 2 && (
            <Box>
              <ConnectionDetailsForm
                values={props.values}
                errors={errors}
                touched={touched}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                connectionGuid={connectionGuid}
                authenticationTypes={authenticationTypes}
              />
            </Box>
          )}
          {formNumber === LAST_FORM_NUMBER && getResponseCard()}
        </React.Fragment>
      </Box>
    </form>
  );
}
export default CreateConnectionForm;

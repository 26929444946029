const helpText = {
  connection: {
    workspaceId: {
      HELP_TEXT: 'Enter the Power BI workspace Id',
      LINK_TEXT: 'How to find your workspace Id',
      LINK_URL:
        'https://learn.microsoft.com/en-us/power-bi/developer/embedded/embed-sample-for-customers?tabs=node-js#workspace-id',
    },
  },
  testCase: {
    testCaseName: {
      HELP_TEXT: 'Enter unique name for Test case',
      LINK_TEXT: 'Click here for more information on creating test cases',
      LINK_URL: 'https://wild-data-solutions.outseta.com/support/kb/articles/j9bxDvmn/creating-test-cases',
    },
    tags: {
      HELP_TEXT: 'Zero or more tags used for organising tests into test plans',
      LINK_TEXT: 'See more about Test Plans here',
      LINK_URL:
        'https://wild-data-solutions.outseta.com/support/kb/articles/rm0AKJQX/creating-and-executing-test-plans',
    },
    sourceQuery: {
      HELP_TEXT: 'SQL Query that returns the expected results from the source system',
      LINK_TEXT: 'Click here on guide for writing test case SQL',
      LINK_URL: 'https://wild-data-solutions.outseta.com/support/kb/articles/E9LZnnmw/writing-sql-for-test-cases',
    },
    pageName: {
      HELP_TEXT: 'Page name in Power BI report',
      LINK_TEXT: null,
      LINK_URL: null,
    },
    altText: {
      HELP_TEXT: 'Alt-text of the report visual to be tested',
      LINK_TEXT: 'Click here for more information on alt-text',
      LINK_URL: 'https://wild-data-solutions.outseta.com/support/kb/articles/ZmNZ5pm2/alt-text',
    },
  },
};

export default helpText;

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';

import ExportToCsvButton from '../../../components/ExportToCsvButton';
import Info from '../../../components/Info';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { formatMilliseconds } from '../../../utils/date';

export default function TestRunSummary(props) {
  const { summary, viewTestCase } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSummaryInfo = () => [
    { label: 'Total Cases', value: summary?.totalNumberOfTests },
    { label: 'Passed', value: summary?.passedTest },
    { label: 'Failed', value: summary?.failedTest },
    {
      label: 'Pass',
      value: summary?.passPercentage !== undefined ? _.round(summary?.passPercentage * 100) + '%' : '0%',
    },
    { label: 'Duration', value: formatMilliseconds(summary?.durationInMs) },
  ];

  const getExportCSVData = () => {
    if (!summary) return [];
    if (!summary.testCases) return [];
    if (summary.testCases.length === 0) return [];

    return summary.testCases.map((testCase) => ({
      testPlanName: summary.testPlanName,
      testRunGuid: summary.testRunGuid,
      runId: summary.runId,
      totalNumberOfTests: summary.totalNumberOfTests,
      passedTest: summary.passedTest,
      failedTest: summary.failedTest,
      inconclusiveTest: summary.inconclusiveTest,
      durationInMs: summary.durationInMs,
      passPercentage: summary.passPercentage,
      ...testCase,
      result: summary.result,
    }));
  };

  return (
    <React.Fragment>
      <Info data={getSummaryInfo()} />
      <Box mt={4}>
        <TableContainer>
          <TableContainer>
            <Table className="list-table table-type-2 fixed" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" className="column font-600">
                    Test Case Name
                  </TableCell>
                  <TableCell component="th" className="column font-600">
                    Report Name
                  </TableCell>
                  <TableCell component="th" className="column font-600">
                    Duration
                  </TableCell>
                  <TableCell component="th" className="column font-600">
                    Result
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {summary?.testCases &&
                  summary?.testCases?.length > 0 &&
                  (rowsPerPage > 0
                    ? summary.testCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : summary.testCases
                  ).map((testCase, index) =>
                    summary?.testCases[0]?.testCaseName ? (
                      <TableRow
                        key={index}
                        className="pointer"
                        onClick={() => (viewTestCase ? viewTestCase(testCase, 'TEST_CASE') : '')}
                      >
                        <TableCell className="column font-600">{testCase.testCaseName}</TableCell>
                        <TableCell className="column">{testCase.reportName || '-'}</TableCell>
                        <TableCell className="column">{formatMilliseconds(testCase.duration)}</TableCell>
                        <TableCell className="column ">{testCase.result}</TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={index}>
                        <TableCell className="column font-400" colSpan={4} align="center">
                          No test cases were found
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={summary?.testCases ? summary.testCases.length : 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </TableContainer>
      </Box>
      <Box pt={2} display={'flex'} justifyContent={'flex-end'}>
        <ExportToCsvButton
          data={getExportCSVData()}
          filename={summary?.testRunGuid ?? 'data.csv'}
          buttonDisabled={!summary || !summary?.testCases || !summary?.testCases?.length === 0}
        />
      </Box>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox, FormControlLabel, Box } from '@mui/material';

import { isObjEmpty } from '../../utils/obj';
import { getChildByGuid } from '../../utils/treeview';

export default function CheckboxTreeView(props) {
  const { options, onSelect, selectedItems, defaultExpanded, defaultSelectAll, optionsFlattened } = props;
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    // If options exists (visuals)

    if (defaultSelectAll && optionsFlattened) {
      onSelect(optionsFlattened.filter((obj) => !obj.disabled));
    }

    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (selectedItems) {
      if (Array.isArray(selectedItems) && selectedItems.length > 0) {
        setSelected(selectedItems.map((obj) => obj.uniqueGuid));
      }
    }
    // eslint-disable-next-line
  }, [selectedItems]);

  function getOnChange(checked, nodes) {
    // Get all child nodes
    const allNode = getChildByGuid(options, nodes.guid);
    const guidList = allNode.map((obj) => obj.uniqueGuid);

    // Merge the selected with the new nodes
    let newSelected = checked ? [...selected, ...guidList] : selected.filter((value) => !guidList.includes(value));

    // Remove dups
    newSelected = newSelected.filter((v, i) => newSelected.indexOf(v) === i);

    // Get just child nodes and return obj
    const filteredOptions = optionsFlattened.filter((obj) => newSelected.includes(obj.uniqueGuid) && !obj.disabled);

    // Set the selected items
    setSelected(newSelected);
    // Only select the children
    onSelect(filteredOptions);
  }

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.guid}
      nodeId={nodes.id}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <FormControlLabel
            sx={{ flexGrow: 1 }}
            control={
              <Checkbox
                disabled={nodes.disabled}
                checked={selected.some((item) => item === nodes.uniqueGuid)}
                onChange={(event) => getOnChange(event.currentTarget.checked, nodes)}
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.name}</>}
            key={nodes.id}
          />
          {nodes.tooltip}
        </Box>
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <React.Fragment>
      {options && !isObjEmpty(options) && (
        <TreeView
          className="tree-view-style"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={defaultExpanded}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(options)}
        </TreeView>
      )}
    </React.Fragment>
  );
}

import { Box, Stack, Link } from '@mui/material';
import React from 'react';

import Autocomplete from '../../../../components/Autocomplete';
function ReportVisualSelection(props) {
  const { setFieldValue, reports, report, errors, touched, visuals, visual, powerBIVisualsLoading } = props;

  return (
    <React.Fragment>
      <Stack spacing={1} mb={4}>
        <span>Select a visualisation from the report.</span>
        <Link
          href="https://wild-data-solutions.outseta.com/support/kb/articles/rQVPYkW6/powertester-visuals-guide"
          target="_blank"
        >
          Visual not in list? Add it with this guide.
        </Link>
      </Stack>
      <Stack direction={'column'} spacing={4}>
        <Box className="form-field-container">
          <Autocomplete
            name="report"
            setFieldValue={setFieldValue}
            options={reports}
            label={'reportName'}
            value={'reportGuid'}
            selectedItem={report}
            inputLabel={'Select report'}
          />
          {errors.report && touched.report && (
            <Box color="error.main" className="form-field-error-text">
              {errors.report}
            </Box>
          )}
        </Box>
        <Box className="form-field-container">
          <Autocomplete
            disabled={!report?.reportGuid}
            loading={powerBIVisualsLoading}
            groupBy="pageDisplayName"
            name="visual"
            setFieldValue={setFieldValue}
            options={visuals}
            label={'visualDisplayName'}
            value={'uniqueGuid'}
            selectedItem={visual}
            inputLabel={'Select visual'}
          />
          {errors.visual && touched.visual && (
            <Box color="error.main" className="form-field-error-text">
              {errors.visual}
            </Box>
          )}
        </Box>
      </Stack>
    </React.Fragment>
  );
}

export default ReportVisualSelection;

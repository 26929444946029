import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';

import ConfirmationModal from '../../../components/ConfirmationModal';
import Menu from '../../../components/Menu';
import StatusBadge from '../../../components/StatusBadge';
import TablePaginationActions from '../../../components/TablePaginationActions';
import TableRowMenu from '../../../components/TableRowMenu';
import TableSkeleton from '../../../components/TableSkeleton';
import { EDIT_FORM, VIEW_DETAILS } from '../../../constants/sidedrawer';
import { getRowIndex } from '../../../utils/TablePagination';
import { formatDate } from '../../../utils/date';
import OverflowTooltip from '../../../components/OverflowTooltip';

export default function TestCasesList(props) {
  const { testCases, formOpen, loading, isFormClosing } = props;
  const [deleteListItemId, setDeleteListItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event, index, id, row) => {
    if (formOpen) return;
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            top: event.clientY - 6,
            left: event.clientX + 2,
            index,
            id,
            row,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [testCases]);

  const toggleModal = () => {
    setDeleteListItemId(null);
    setModalOpen(!modalOpen);
  };

  const deleteTestCase = (index, id) => {
    toggleModal();
    setDeleteListItemId(id);
  };

  const confirmDelete = () => {
    props.deleteTestCase(deleteListItemId);
    toggleModal();
  };

  const selectTestCase = (index, id, actionType) => {
    props.selectTestCase(index, actionType);
  };

  const rowClassName = () => {
    if (!formOpen) return 'pointer';

    if (formOpen && props.actionType === VIEW_DETAILS) return 'pointer';
  };

  const onExecuteQuickRun = (index, guid) => {
    props.executeQuickRun({ testCaseGuid: guid });
  };

  const onRename = (index, guid, action, row) => {
    props.onRenameAction(guid, row.testCaseName);
  };

  const options = [
    { label: 'Edit', action: selectTestCase, actionType: EDIT_FORM },
    { label: 'Delete', action: deleteTestCase },
    { label: 'Rename', action: onRename },
    { label: 'Quick Run', action: onExecuteQuickRun },
  ];

  return (
    <React.Fragment>
      <ConfirmationModal
        title="Delete Test case"
        modalOpen={modalOpen}
        confirm={confirmDelete}
        cancel={toggleModal}
        confirmationButtonText={'Delete'}
        msg={'Are you sure you want to delete?'}
      />
      {!!contextMenu?.top && (
        <Menu
          anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
          options={options}
          handleClose={() => setContextMenu(null)}
          index={contextMenu?.index}
          id={contextMenu?.id}
          row={contextMenu?.row}
        />
      )}
      <TableContainer>
        <Table className="list-table fixed" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" className="column">
                Test Case Name
              </TableCell>
              {(formOpen && isLarge) || !formOpen ? (
                <TableCell component="th" scope="row" className="column">
                  Report Name
                </TableCell>
              ) : (
                <></>
              )}
              {!formOpen && !isFormClosing && !isSmall && (
                <>
                  <TableCell component="th" scope="row" className="column">
                    Page
                  </TableCell>
                  <TableCell component="th" scope="row" className="column">
                    Visual Selection type
                  </TableCell>
                  {!formOpen && !isFormClosing && !isSmall && !isMedium && (
                    <>
                      <TableCell component="th" scope="row" className="column">
                        Last Run Time
                      </TableCell>
                      <TableCell component="th" scope="row" className="column">
                        Last Run Status
                      </TableCell>
                      <TableCell component="th" scope="row" className="column menu-column"></TableCell>
                    </>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              (testCases && testCases?.length > 0
                ? rowsPerPage > 0
                  ? testCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : testCases
                : []
              ).map((row, index) => (
                <TableRow
                  key={row.testCaseGuid}
                  onClick={(event) => {
                    selectTestCase(getRowIndex(index, page, rowsPerPage), '', VIEW_DETAILS);
                    event.stopPropagation();
                  }}
                  className={rowClassName()}
                  onContextMenu={(event) =>
                    handleContextMenu(event, getRowIndex(index, page, rowsPerPage), row.testCaseGuid, row)
                  }
                >
                  <TableCell className="column long">
                    <OverflowTooltip text={row.testCaseName} variant={'body1'} />
                  </TableCell>

                  {(formOpen && isLarge) || !formOpen ? (
                    <TableCell className="column">{row.reportName}</TableCell>
                  ) : (
                    <></>
                  )}
                  {!formOpen && !isFormClosing && !isSmall ? (
                    <>
                      <TableCell className="column">{row.pageDisplayName}</TableCell>
                      <TableCell className="column">{row.selectionType}</TableCell>
                      {!formOpen && !isFormClosing && !isSmall && !isMedium && (
                        <>
                          <TableCell className="column">{formatDate(row.lastRunDateTime, '')}</TableCell>
                          <TableCell className="column">
                            <StatusBadge status={row.status} showText={!formOpen && !isSmall} />
                          </TableCell>
                          <TableCell className="column menu-column text-right">
                            <TableRowMenu
                              index={getRowIndex(index, page, rowsPerPage)}
                              id={row.testCaseGuid}
                              options={options}
                              row={row}
                            />
                          </TableCell>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))
            ) : (
              <TableSkeleton columns={6} rows={10} hasMenu />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={testCases?.length ?? 0}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 50, 100, { value: -1, label: 'All' }]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

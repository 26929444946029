import { Box, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useGetTestCaseHistory } from '../../../api/queries/testCase';
import { useGetTestCaseSummary } from '../../../api/queries/testRun';
import TabComponent from '../../../components/Tabs';
import { subtractDate } from '../../../utils/date';
import History from './History';
import Properties from './Properties';
import Summary from './Summary';

export default function TestCaseView(props) {
  const DEFAULT_SELECTED_TAB = 'SUMMARY';

  const { testCaseGuid, testRunGuid, testRunDate } = props;
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);
  const [age, setAge] = React.useState(7);

  const {
    data: summary,
    isLoading: testCasesIsLoading,
    isFetching: testCasesIsFetching,
  } = useGetTestCaseSummary(!!testCaseGuid && !!testRunGuid, { testRunGuid, testCaseGuid });

  const {
    data: history,
    isLoading: historyIsLoading,
    isFetching: historyIsFetching,
  } = useGetTestCaseHistory(!!testCaseGuid && !!testRunGuid, {
    testCaseGuid: testCaseGuid,
    fromDate: subtractDate(age, 'days', true, testRunDate),
  });

  const isLoading = testCasesIsLoading || historyIsLoading;
  const isFetching = testCasesIsFetching || historyIsFetching;

  const handleDateChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange = async (tabValue) => {
    setSelectedTab(tabValue);
  };

  const tabViews = {
    SUMMARY: () => {
      return <Summary summary={summary} />;
    },
    HISTORY: () => {
      return <History handleDateChange={handleDateChange} graphData={history} age={age} />;
    },
    PROPERTIES: () => {
      return <Properties properties={summary} />;
    },
  };

  useEffect(() => {
    handleChange(selectedTab);
    // eslint-disable-next-line
  }, [testCaseGuid]);

  const getTabViews = () => {
    if (isLoading || isFetching) {
      return <CircularProgress />;
    }
    return tabViews[selectedTab]();
  };

  const tabOptions = [
    { name: 'Summary', value: 'SUMMARY' },
    { name: 'History', value: 'HISTORY' },
    { name: 'Properties', value: 'PROPERTIES' },
    // { name: 'Notes', value: 'NOTES' },
  ];
  return (
    <Box>
      <Box display="flex" px={4} pb={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <span className="sub-header"> </span>
      </Box>
      <TabComponent handleChange={handleChange} selectedTab={selectedTab} options={tabOptions} />
      <Divider />
      <Box px={4} mt={3}>
        {getTabViews()}
      </Box>
    </Box>
  );
}

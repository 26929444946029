import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { getFriendlyVisualTypeName, getVisualImage } from '../../utils/visual';
import OverflowTooltip from '../OverflowTooltip';

const VisualCard = memo((props) => {
  const {
    visual: { visualName, visualType, customName, inScope, visualDisplayName },
    onCheck,
    isUpdating,
    openEditModal,
    openQuickAddModal,
    visualTypeSupported,
  } = props;

  const visualDisplayTitle = customName || visualDisplayName || visualName || getFriendlyVisualTypeName(visualType);

  const handleCheck = (e) => {
    onCheck(e.target.checked, props.visual);
  };

  const viewDetails = () => {
    openEditModal(props.visual);
  };
  const quickAdd = () => {
    openQuickAddModal(props.visual);
  };

  const isActive = inScope && visualTypeSupported;

  return (
    <Tooltip title={visualTypeSupported ? '' : 'Visual type not supported'}>
      <>
        <div className={`visual-card ${!isActive ? 'OOS' : ''}`}>
          <div className="visual-card-title">
            <OverflowTooltip
              text={visualDisplayTitle}
              maxWidth="130px"
              style={{
                fontSize: '10px!important',
                fontWeight: 800,
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '130px',
              }}
            />
          </div>
          <div className="visual-card-image">{getVisualImage(visualType)}</div>
          <div className="visual-card-footer">
            {visualTypeSupported && isActive && (
              <React.Fragment>
                <Tooltip title="Quick Add Test Case">
                  <IconButton className="visual-card-button" onClick={quickAdd}>
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Visual information">
                  <IconButton className="visual-card-button" onClick={viewDetails}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )}
            <Tooltip title={'Visual in scope'}>
              <Checkbox checked={isActive} onChange={handleCheck} disabled={isUpdating} />
            </Tooltip>
          </div>
          {isUpdating && (
            <div className="visual-card-overlay">
              <CircularProgress />
            </div>
          )}
        </div>
      </>
    </Tooltip>
  );
});
export default VisualCard;

import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index';
import { useSnackbar } from 'notistack';

import * as testCase from '../endpoints/testCase';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { mapHistoryData } from '../mappings/testcase';

const allTestCasesKey = ['testCase', 'all'];

// Queries
export const useGetTestCase = (enabled, queryKey, nameFilter) => {
  const { enqueueSnackbar } = useSnackbar();

  const cacheKey = queryKey === 'all' ? allTestCasesKey : ['testCase', ...Object.values(queryKey)];

  return useQuery({
    queryKey: cacheKey,
    queryFn: () => {
      if (queryKey !== 'all') {
        return testCase.get(queryKey);
      } else {
        return testCase.get();
      }
    },
    enabled: enabled,
    select: (testCases) => {
      if (!nameFilter) return testCases;
      return testCases.filter((c) => c.testCaseName?.toLowerCase().includes(nameFilter.toLowerCase()));
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestCaseSummary = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testCaseSummary', { testCaseGuid: queryKey.testCaseGuid }, { testRunGuid: queryKey.testRunGuid }],
    queryFn: () => {
      const { testCaseGuid, ...params } = queryKey;
      return testCase.summary(params, testCaseGuid);
    },
    enabled: enabled,

    select: (data) => {
      if (data?.resultOutcomeDescription == 'In progress' || data?.resultOutcomeDescription == 'Queued') {
        queryClient.invalidateQueries([
          'testCaseSummary',
          { testCaseGuid: queryKey.testCaseGuid },
          { testRunGuid: queryKey.testRunGuid },
        ]); // invalidate the query if its in progress so it always refetces
      }
      return data;
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestPlanForTestCase = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testPlan', 'testcase', queryKey.testCaseGuid],
    queryFn: () => {
      return testCase.getTestPlans(queryKey.testCaseGuid);
    },
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestCaseQuickRuns = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testCaseQuickRuns', queryKey],
    queryFn: () => testCase.getQuickRuns(),
    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case quick runs');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useGetTestCaseHistory = (enabled, queryKey) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['testCaseHistory', queryKey.testCaseGuid],
    queryFn: () => {
      const { testCaseGuid, ...params } = queryKey;
      return testCase.history(params, testCaseGuid);
    },

    enabled: enabled,
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
    select: (testCaseHistory) => {
      return mapHistoryData(testCaseHistory);
    },
  });
};

// Mutations
export const useCreateTestCase = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => testCase.create({ ...rawData }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: allTestCasesKey }); // invalidate the test case list (easier than updating state)
      queryClient.invalidateQueries(['tags', 'all']); // invalidate the tags

      // Show success snackbar
      enqueueSnackbar('Test case created successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the test case');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useUpdateTestCase = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { testCaseGuid, ...body } = rawData;
      return testCase.update(body, testCaseGuid);
    },

    onSuccess: (data, updatedTestCase) => {
      queryClient.invalidateQueries({ queryKey: allTestCasesKey }); // invalidate the testCaseSummary
      queryClient.invalidateQueries({ queryKey: ['testCaseSummary', { testCaseGuid: updatedTestCase.testCaseGuid }] }); // invalidate the testCaseSummary
      queryClient.invalidateQueries({ queryKey: ['testCaseHistory', { testCaseGuid: updatedTestCase.testCaseGuid }] }); // invalidate the testCaseHistory
      queryClient.invalidateQueries(['tags', 'all']); // invalidate the tags

      // Show success snackbar
      enqueueSnackbar('Test case updated successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to update the test case');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useRenameTestCase = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (rawData) => {
      const { testCaseGuid, ...body } = rawData;
      return testCase.rename(body, testCaseGuid);
    },

    onSuccess: (data, updatedTestCase) => {
      // TODO Convert this to update state manually - review updateReportVisual
      queryClient.invalidateQueries({ queryKey: allTestCasesKey }); // invalidate the testCaseSummary
      queryClient.invalidateQueries({ queryKey: ['testCaseSummary', { testCaseGuid: updatedTestCase.testCaseGuid }] }); // invalidate the testCaseSummary
      queryClient.invalidateQueries({ queryKey: ['testCaseHistory', { testCaseGuid: updatedTestCase.testCaseGuid }] }); // invalidate the testCaseHistory

      // Show success snackbar
      enqueueSnackbar('Test case renamed', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to rename the test case');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useDeleteTestCase = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (testCaseGuid) => testCase.remove(testCaseGuid),

    onSuccess: (data, testCaseGuid) => {
      // Remove the tc from the reports cache (faster than invalidating)
      queryClient.setQueryData(allTestCasesKey, (old) => old.filter((t) => t.testCaseGuid !== testCaseGuid));

      // Show success snackbar
      enqueueSnackbar('Test case deleted successfully', { variant: SNACKBAR_SUCCESS });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to delete the test case');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

export const useExecuteTestCase = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (rawData) => {
      const { testCaseGuid } = rawData;
      return testCase.execute(testCaseGuid);
    },

    onSuccess: (data) => {
      if (!data?.data) {
        enqueueSnackbar('Test exeution status unknown.', { variant: SNACKBAR_WARNING });
        return;
      }

      if (data.data.Message === 'Nothing to execute') {
        enqueueSnackbar('Nothing to execute', { variant: SNACKBAR_WARNING });
        return;
      }

      const msg = data.data.Message ?? 'Test case running';
      const guid = data.data.TestRunGuid;
      const type = data.data.Type ?? 'Test Run';
      let linkTo = type === 'Test Case' ? '/testcases/runs' : '/testruns';

      if (guid) linkTo = linkTo + `/${guid}`;

      enqueueSnackbar(msg, {
        subtitle: 'Request submitted succesfully',
        variant: 'collapseable',
        linkText: `Browse ${type} for progress information`,
        linkTo: linkTo,
        isExtenalLink: false,
      });
    },
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Cannot execute test plan');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
  });
};

//prefetch
export const prefetchTestCaseList = () => {
  return queryClient.prefetchQuery(allTestCasesKey, () => testCase.get());
};

// reload
export const reloadQuery = (queryKey) => {
  queryClient.invalidateQueries(queryKey);
};

import { isValidCron } from '../utils/cron';

export const maxLengthError = (length) => {
  return `Maximum ${length} characters allowed`;
};

// TODO Split these into schema errors
const errorMessages = {
  CONNECTION_NAME: {
    REQUIRED: 'Please enter a connection name',
    MAX_LENGTH: maxLengthError,
  },
  SERVER_NAME: {
    REQUIRED: 'Please enter a server name',
    MAX_LENGTH: maxLengthError,
  },
  USERNAME: {
    REQUIRED: 'Please enter a server name',
    MAX_LENGTH: maxLengthError,
  },
  DATABASE: {
    REQUIRED: 'Please enter a database name',
    MAX_LENGTH: maxLengthError,
  },
  PORT: {
    TYPE: 'Port must be whole number only',
    MAX_LENGTH: maxLengthError,
  },
  PASSWORD: {
    REQUIRED: 'Please enter a password',
    MAX_LENGTH: maxLengthError,
  },
  CONNECTION_STRING: {
    MAX_LENGTH: maxLengthError,
  },
  CONNECTION_SELECTION: {
    REQUIRED: 'Please select a connection',
  },
  CONNECTION_TYPE_SELECTION: {
    REQUIRED: 'Please select a connection type',
  },
  AUTHENTICATION_TYPE_SELECTION: {
    REQUIRED: 'Please select a authentication type',
  },

  VISUALS_SELECTION: {
    MIN: 'Please select at least one visual',
  },
  WORKSPACE_ID: {
    REQUIRED: 'Please enter a valid workspace Id',
    CRITERION: 'Workspace Id must be a guid',
  },
  REPORT_SELECTION: {
    REQUIRED: 'Please select a report',
    MAX_LENGTH: maxLengthError,
  },
  REPORT_NAME: {
    REQUIRED: 'Please enter a report name',
    MAX_LENGTH: maxLengthError,
  },
  TEST_CASE: {
    REQUIRED: 'Please enter a valid test case name',
  },
  TEST_CASE_NAME: {
    REQUIRED: 'Please select min 1 test case',
    MAX_LENGTH: maxLengthError,
  },
  SQL_QUERY: {
    REQUIRED: 'Please enter a valid SQL query',
    MAX_LENGTH: maxLengthError,
  },
  ALT_TEXT: {
    REQUIRED: 'Please enter a valid alt text',
    MAX_LENGTH: maxLengthError,
  },
  PAGE_NAME: {
    REQUIRED: 'Please select a page',
    MAX_LENGTH: maxLengthError,
  },
  TEST_PLAN_NAME: {
    REQUIRED: 'Please enter a valid plan name',
    MAX_LENGTH: maxLengthError,
  },
  SCHEDULE: {
    REQUIRED: 'Please enter a valid CRON value',
    VALIDATE: isValidCron,
  },
  TAGS: {
    REQUIRED: 'Select at least one tag',
  },
  ENVIRONMENT: {
    MIN: 'Please select environment name',
    REQUIRED: 'Please select an environment',
    MAX_LENGTH: maxLengthError,
  },
  REFRESH_TOKEN: {
    REQUIRED: 'Please authorize connection',
  },
  VISUAL_SELECTION_TYPE: {
    REQUIRED: 'Please select visual type',
  },
  VISUAL: {
    REQUIRED: 'Please select a visual',
  },
  VISUAL_URL: {
    REQUIRED: 'Please enter a valid visual Url',
    MAX_LENGTH: maxLengthError,
  },

  KEY_VAULT_URL: {
    REQUIRED: 'Please enter a key vault Url',
    MAX_LENGTH: maxLengthError,
  },
  TENANT_ID: {
    REQUIRED: 'Please enter a tenant Id',
    CRITERION: 'Tenant Id must be a guid',
    MAX_LENGTH: maxLengthError,
  },
  SP_APP_ID: {
    REQUIRED: 'Please enter a service principal app id',
    CRITERION: 'service principal app id must be a guid',
    MAX_LENGTH: maxLengthError,
  },
  SP_APP_SECRET: {
    REQUIRED: 'Please enter a service principal secret',
    MAX_LENGTH: maxLengthError,
  },
  TENANT_ID_KV: {
    REQUIRED: 'Please select secret containing tenant Id',
  },
  SP_APP_ID_KV: {
    REQUIRED: 'Please select secret containing service principal App Id',
  },
  SP_APP_SECRET_KV: {
    REQUIRED: 'Please select secret containing service principal secret',
  },
  KV_CONNECTION: {
    REQUIRED: 'Please select Key Vault connection',
  },
  CONNECTION_STRING_KV: {
    REQUIRED: 'Please select secret containing connection string',
  },
  TIMEZONE: {
    REQUIRED: 'Please select a timezone',
  },
};

export const errorCodes = {
  SQL_ERRORS: {
    // Global
    50000: 'Unknown error',
    50001: 'Unauthorised',

    // Connection
    51001: 'Connection name already exists',
    51002: 'Connection does not exist',
    51003: 'Cannot delete connection as it is being used in a test case',
    51004: 'AuthoriseConnection expects UserAccountGuid OR AccountGuid',
    51005: 'Cannot delete connection as it is being used in a report',

    // Environment
    60001: 'Environment name already exists',
    60002: 'Environment does not exists',
    60003: 'Cannot delete environment as it is being used in a testplan',
    60004: 'Cannot delete environment as it is the default environment',

    // Report
    70001: 'Report already exists',
    70002: 'Report does not exist',
    70003: 'Cannot delete report as it is being used in a test case',
    70004: 'Cannot mark visual out of scope when it referenced in a test case',

    // TestCase
    80001: 'Test case does not exist',
    80002: 'Cannot delete test case as it is being used in a test plan',
    80003: 'Test case does not exist',
    80004: 'Test case name already exists',

    // TestPlan
    90001: 'Test plan does not exist',

    // SETTINGS
    91001: 'Notification setting does not exists',
  },
};

export const GENERIC_ERRORS = {
  REPORT: {
    CREATE: 'Error creating report, please contact support',
    UPDATE: 'Error updating report, please contact support',
  },
  REPORT_VISUAL: {
    UPDATE: 'Error updating visual, please contact support',
  },
};

export default errorMessages;

export const exportTestPlan = (testPlanData, testCases) => {
  const { testPlanGuid, testPlanName, isActiveSchedule, lastRunDateTime, cronSchedule, tags, status } = testPlanData;

  let extractedTestCaseData = [];
  if (testCases && testCases.length > 0) {
    extractedTestCaseData = testCases.map((testCase) => ({
      testCaseGuid: testCase.testCaseGuid,
      testCaseName: testCase.testCaseName,
      reportGuid: testCase.reportGuid,
      reportName: testCase.reportName,
      testCaseLastRunStatus: testCase.testCaseStatus,
    }));
  }

  let parsedTags = [];
  if (tags) {
    parsedTags = JSON.parse(tags);
  }
  return {
    testPlanGuid,
    testPlanName,
    isActiveSchedule,
    lastRunDateTime,
    lastRunStatus: status,
    cronSchedule,
    testCases: extractedTestCaseData,
    tags: parsedTags,
  };
};

import _ from 'lodash';
import * as moment from 'moment';

export const mapHistoryData = (data) => {
  const sortedData = _.orderBy(
    data,
    (o) => {
      if (o.Date) {
        return moment(o.Date).format('YYYYMMDD');
      }
    },
    ['asc'],
  );
  let filteredData = sortedData;
  const passData = _.map(filteredData, (cb) => cb.PassedRate);
  const failData = _.map(filteredData, (cb) => cb.FailedRate);
  const inconclusiveData = _.map(filteredData, (cb) => cb.InconclusiveRate);
  const labels = _.map(filteredData, (cb) => moment.utc(cb.Date).format('DD MMM'));

  return { passData, failData, inconclusiveData, labels };
};

export const mapQuickCreateTestCaseData = (
  testCaseName,
  sourceQuery,
  connectionGuid,
  visual,
  reportVisualSelectionTypeId,
  reportGuid,
  powerBIReportGuid,
  reportName,
) => {
  const data = {
    testCaseName: testCaseName,
    sourceQuery: sourceQuery,
    connectionGuid: connectionGuid,
    visualSelectionTypeId: reportVisualSelectionTypeId,
    tags: [],

    /* Based on the selection type add specific properties */
    pageDisplayName: visual?.pageDisplayName,
    pageGuid: visual?.pageGuid,
    reportGuid: reportGuid,
    pageOrder: visual.pageOrder,
    visualGuid: visual.visualGuid,
    powerBIReportGuid: powerBIReportGuid,
    powerBIReportDisplayName: reportName,
  };

  return data;
};

export const mapCreateTestCaseData = (values) => {
  if (!values) return;

  const data = {
    testCaseName: values.testCaseName,
    sourceQuery: values.sourceQuery,
    connectionGuid: values.connection?.connectionGuid,
    visualSelectionTypeId: values.visualSelectionType?.visualSelectionTypeId,
    tags: values.tags.map((tag) => tag.tagValue || tag),
  };
  /* Based on the selection type add specific properties */
  if (values.visualSelectionType.selectionType === 'Alt-text') {
    data.altText = values.altText;
    data.reportGuid = values.report?.reportGuid;
    data.pageDisplayName = values.page.pageDisplayName;
    data.pageGuid = values.page.pageGuid;
    data.pageOrder = values.page.pageOrder;
    data.powerBIReportGuid = values.report?.powerBIReportGuid;
    data.powerBIReportDisplayName = values.report?.reportName;
  } else if (values.visualSelectionType.selectionType === 'Report Visual Selection') {
    data.pageDisplayName = values.visual?.pageDisplayName;
    data.pageGuid = values.visual?.pageGuid;
    data.reportGuid = values.report?.reportGuid;
    data.pageOrder = values.visual.pageOrder;
    data.visualGuid = values.visual.visualGuid;
    data.powerBIReportGuid = values.report?.powerBIReportGuid;
    data.powerBIReportDisplayName = values.report?.reportName;
  } else if (values.visualSelectionType.selectionType === 'Visual URL') {
    data.visualUrl = values.visualUrl;
    data.visualGuid = values.visual?.visualGuid;
    data.powerBIReportGuid = values.page?.reportGuid;
    data.pageDisplayName = values.page?.pageDisplayName;
    data.pageGuid = values.page?.pageGuid;
    data.pageOrder = values.page?.pageOrder;
    data.powerBIReportDisplayName = values.page?.reportName;
  } else {
    throw Error(JSON.stringify({ Message: 'Unknown selection type' }));
  }

  return data;
};

export const mapUpdateTestCase = (values) => {
  if (!values) return;

  const data = {
    testCaseGuid: values.testCaseGuid,
    testCaseName: values.testCaseName,
    sourceQuery: values.sourceQuery,
    connectionGuid: values.connection?.connectionGuid,
    visualSelectionTypeId: values.visualSelectionType?.visualSelectionTypeId,
    tags: values.tag?.map((t) => t.tagValue ?? t),
  };

  /* Based on the selection type add specific properties */
  if (values.visualSelectionType.selectionType === 'Alt-text') {
    data.altText = values.altText;
    data.reportGuid = values.report?.reportGuid;
    data.pageDisplayName = values.page.pageDisplayName;
    data.pageGuid = values.page.pageGuid;
    data.pageOrder = values.page.pageOrder;
    data.powerBIReportGuid = values.report?.powerBIReportGuid;
  } else if (values.visualSelectionType.selectionType === 'Report Visual Selection') {
    data.pageDisplayName = values.visual?.pageDisplayName;
    data.pageGuid = values.visual?.pageGuid;
    data.reportGuid = values.report?.reportGuid;
    data.pageOrder = values.visual.pageOrder;
    data.visualGuid = values.visual.visualGuid;
    data.powerBIReportGuid = values.report?.powerBIReportGuid;
  } else if (values.visualSelectionType.selectionType === 'Visual URL') {
    data.visualUrl = values.reportVisualUrl;
    data.visualGuid = values.visualGuid;
    data.powerBIReportGuid = values.page?.reportGuid;
    data.pageDisplayName = values.page?.pageDisplayName;
    data.pageGuid = values.page?.pageGuid;
    data.pageOrder = values.page?.pageOrder;
    data.powerBIReportDisplayName = values.page?.reportName;
  } else {
    throw Error(JSON.stringify({ Message: 'Unknown selection type' }));
  }

  return data;
};

import { Box, CircularProgress, Divider } from '@mui/material';
import React, { useEffect } from 'react';

import TabComponent from '../../../components/Tabs';
import TestRunSummary from './Summary';

export default function TestRunView(props) {
  const DEFAULT_SELECTED_TAB = 'SUMMARY';
  const { viewTestCase, testCaseGuid, testRunSummary, isLoading } = props;

  useEffect(() => {
    if (testCaseGuid && testRunSummary) {
      const testcase = testRunSummary.testCases?.find((x) => x.testCaseGuid === testCaseGuid);
      viewTestCase(testcase, 'TEST_CASE');
    }
    // eslint-disable-next-line
  }, [testRunSummary]);

  const tabOptions = [{ name: 'Summary', value: 'SUMMARY' }];
  return (
    <React.Fragment>
      <Box>
        <Box display="flex" px={4} pb={1} flexDirection="row" justifyContent="space-between" alignItems="center"></Box>
        <TabComponent selectedTab={DEFAULT_SELECTED_TAB} options={tabOptions} />
        <Divider />
        {isLoading ? (
          <Box px={4} mt={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Box px={4} mt={3}>
            <TestRunSummary summary={testRunSummary} viewTestCase={viewTestCase} />
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

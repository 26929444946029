import React from 'react';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const getIcon = (iconName, iconProps) => {
  switch (iconName.toLowerCase()) {
    case 'rocket':
      return <RocketLaunchIcon {...iconProps} />;
    case 'delete':
      return <DeleteIcon {...iconProps} />;
    case 'edit':
    case 'rename':
      return <EditIcon {...iconProps} />;
    case 'export':
      return <FileDownloadIcon {...iconProps} />;
    default:
      return null;
  }
};

export const Icon = (props) => {
  const { icon, ...iconProps } = props;
  return getIcon(icon, iconProps);
};

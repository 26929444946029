import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

function CheckboxContainer(props) {
  const { handleChecked, label, initialState } = props;
  const [checked, setChecked] = React.useState(initialState);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleChecked(event.target.checked);
  };

  return label ? (
    <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={label} />
  ) : (
    <Tooltip title={props.tooltip}>
      <Checkbox checked={checked} onChange={handleChange} />
    </Tooltip>
  );
}

export default CheckboxContainer;

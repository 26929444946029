import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import TableSkeleton from '../../../components/TableSkeleton';

export default function TestCases(props) {
  const { testCases, isLoading } = props;
  const navigate = useNavigate();

  const selectTestCase = (testCaseGuid) => {
    navigate(`/testcases/${testCaseGuid}`);
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table className="list-table table-type-2 fixed" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" className="column">
                Test Case Name
              </TableCell>
              <TableCell component="th" scope="row" className="column">
                Report Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              testCases &&
              testCases.map((row) => (
                <TableRow key={row.testCaseGuid} onClick={() => selectTestCase(row.testCaseGuid)} className="pointer">
                  <TableCell className="column">{row.testCaseName}</TableCell>
                  <TableCell className="column">{row.reportName}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableSkeleton columns={2} rows={10} hasMenu />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

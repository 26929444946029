import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import * as visualSelectionTypes from '../endpoints/visualSelectionTypes';
import { SNACKBAR_ERROR } from '../../constants/snackbar';
import { getResponseErrorMsg } from '../../utils/response';
import { mapVisualSelectionTypes } from '../mappings/visualSelectionTypes';

// Queries

export const useGetVisualSelectionTypes = (enabled) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: ['visualSelectionTypes'],
    queryFn: () => visualSelectionTypes.get(),
    enabled: enabled,
    staleTime: 60 * (60 * 1000), // 60 mins
    onError: (error) => {
      const errorMsg = getResponseErrorMsg(error?.response, 'Unable to retrieve test case data');
      enqueueSnackbar(errorMsg, { variant: SNACKBAR_ERROR });
    },
    select: (visualTypes) => {
      return mapVisualSelectionTypes(visualTypes);
    },
  });
};

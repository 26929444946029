import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Divider, Link as ExternalLink, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useGetConnections } from '../../../api/queries/connection';
import { useGetPowerBIPages, useGetPowerBIReportVisualQuery } from '../../../api/queries/powerBI';
import { useGetReportVisuals, useGetReports } from '../../../api/queries/report';
import { useCopilotGenerateSQL } from '../../../api/queries/copilot';
import AIIcon from '../../../assets/images/icons/ai.svg';
import Autocomplete from '../../../components/Autocomplete';
import Code from '../../../components/Code';
import CopilotSQLConfirmModal from '../../../components/CopilotSQLModal';
import FieldHelpText from '../../../components/FieldHelpText';
import FormTextField from '../../../components/FormTextField';
import FreeSolo from '../../../components/FreeSolo';
import Image from '../../../components/Image';
import MissingObjectWarningComponent from '../../../components/MissingObjectWarning';
import ResponseCard from '../../../components/ResponseCard';
import StepperComponent from '../../../components/Stepper';
import helpText from '../../../constants/helpText';
import { getStorageItem, setStorageItem } from '../../../services/storage';
import { filterListByProperty, findObjectByProperty } from '../../../utils/array';
import { getSideDrawerSubHeading } from '../../../utils/sidedrawer';
import AltTextSelection from './AltTextSelection';
import ReportVisualSelection from './ReportVisualSelection';
import UrlSelection from './UrlSelection';

function CreateTestCaseForm(props) {
  const {
    values: {
      sourceQuery,
      testCaseName,
      altText,
      page,
      report,
      visual,
      tags,
      visualSelectionType,
      visualUrl,
      connection,
    },
    visualSelectionTypes,
    setValues,
    responseStatus,
    responseError,
    errors,
    touched,
    handleSubmit,
    handleBack,
    handleChange,
    setFieldTouched,
    isDetailsSubmitted,
    isDetailsSubmitting,
    formNumber,
    LAST_FORM_NUMBER,
    setFieldValue,
    toggleForm,
    actionType,
    tagList,
    tagsLoading,
    setErrors,
  } = props;

  const [openSQLCopilotDialog, setOpenSQLCopilotDialog] = useState(false);
  const [generateSQLFromCopilot, setGenerateSQLFromCopilot] = useState(false);
  const [sqlGenerated, setSQLGenerated] = useState(false);
  const [databaseConnections, setDatabaseConnections] = useState([]);
  const [reportConnection, setReportConnection] = useState(false);

  const { data: reports, isLoading: reportsIsLoading } = useGetReports(true, 'all', null);
  const { data: connections, isLoading: connectionsIsLoading } = useGetConnections(true, 'all', null, null, null);

  useEffect(() => {
    if (connections && connections.length > 0) {
      setDatabaseConnections(filterListByProperty(connections, 'connectionTypeGroup', 'Database'));

      if (report?.connectionGuid)
        setReportConnection(findObjectByProperty(connections, 'connectionGuid', report.connectionGuid));
    }

    // eslint-disable-next-line
  }, [connections, report]);

  const [getPowerBIQuery, setGetPowerBIQuery] = useState(false);

  const { data: powerBIReportPages, isLoading: powerBIPagesIsLoading } = useGetPowerBIPages(
    visualSelectionType?.selectionType === 'Alt-text' &&
      !!report?.powerBIReportGuid &&
      !!report?.workspaceId &&
      !!reportConnection?.connectionGuid, // enabled
    reportConnection,
    report?.workspaceId,
    report?.powerBIReportGuid,
  );

  const { data: visuals, isLoading: visualsIsLoading } = useGetReportVisuals(
    visualSelectionType?.selectionType === 'Report Visual Selection' && !!report?.reportGuid,
    report?.reportGuid,
  );

  const { data: powerBIVisualQuery, isFetching: powerBIVisualQueryIsFetching } = useGetPowerBIReportVisualQuery(
    getPowerBIQuery && !!visual.visualGuid && !!reportConnection, // enabled
    reportConnection,
    report?.powerBIReportGuid,
    visual?.visualGuid,
    visual?.pageGuid,
    false, // refresh
  );

  const { data: copilotSQL, isFetching: copilotSQLIsFetching } = useCopilotGenerateSQL(
    !!powerBIVisualQuery && generateSQLFromCopilot, // enabled
    powerBIVisualQuery,
    visual?.visualGuid,
  );

  const copilotIsFetching = powerBIVisualQueryIsFetching || copilotSQLIsFetching;

  useEffect(() => {
    setSQLGenerated(false);
  }, [visualSelectionType, visual]);

  useEffect(() => {
    if (!copilotSQLIsFetching && copilotSQL && copilotSQL.response) {
      handleSQLChange(copilotSQL.response);
      setGenerateSQLFromCopilot(false);
      setSQLGenerated(true);
    }
  }, [copilotSQLIsFetching]);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleSQLChange = (e) => {
    const newState = { ...props.values };
    newState.sourceQuery = e;
    setValues(newState);
  };

  const getResponseCard = () => {
    const result = [
      { key: 'Name', value: testCaseName },
      { key: 'Report', value: report?.reportName || page?.reportName || visual?.reportName },
      { key: 'Page', value: page?.pageDisplayName || visual?.pageDisplayName },
      { key: 'Connection', value: connection?.connectionName },
    ];
    return (
      <ResponseCard
        title={!responseError ? getSideDrawerSubHeading('TEST_CASE', actionType) : responseError}
        values={result}
        responseType={responseStatus || 'pre-submit'}
        toggleForm={toggleForm}
        errorDetails={responseError}
      />
    );
  };

  const getNextButtonText = () => {
    if (formNumber === LAST_FORM_NUMBER) {
      return 'Save';
    }
    return 'Next';
  };

  function renderAltTextSelection() {
    if (visualSelectionType && visualSelectionType.selectionType === 'Alt-text') {
      return (
        <AltTextSelection
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          touched={touched}
          errors={errors}
          reports={reports}
          reportsIsLoading={reportsIsLoading}
          helpText={helpText}
          report={report}
          page={page}
          altText={altText}
          powerBIReportPages={powerBIReportPages}
          powerBIPagesLoading={powerBIPagesIsLoading}
        />
      );
    }
  }

  function renderReportVisualSelection() {
    if (visualSelectionType && visualSelectionType.selectionType === 'Report Visual Selection') {
      return (
        <ReportVisualSelection
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          visuals={visuals}
          visual={visual}
          reports={reports}
          report={report}
          powerBIVisualsLoading={visualsIsLoading}
        />
      );
    }
  }

  function renderUrlSelection() {
    if (visualSelectionType && visualSelectionType.selectionType === 'Visual URL') {
      return (
        <UrlSelection
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          visualUrl={visualUrl}
          setErrors={setErrors}
          reportConnection={reportConnection}
          reports={reports}
        />
      );
    }
  }

  const steps = ['Test case name', 'Visual selection', 'Source details'];

  const generateSQL = (hideModal) => {
    if (hideModal) {
      setStorageItem('hideCopilotModal', true);
    }
    setOpenSQLCopilotDialog(false);
    setGenerateSQLFromCopilot(true);
    setGetPowerBIQuery(false);
  };

  const generateSQLClick = () => {
    if (copilotSQLIsFetching || sqlGenerated) return;

    if (getStorageItem('hideCopilotModal')) {
      generateSQL();
    } else {
      setOpenSQLCopilotDialog(true);
    }

    setGetPowerBIQuery(true);
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <CopilotSQLConfirmModal
        modalOpen={openSQLCopilotDialog}
        cancel={() => setOpenSQLCopilotDialog(false)}
        confirm={generateSQL}
      />
      <Box display="flex" pr={4} py={3} flexDirection="row" justifyContent="space-between" alignItems="center">
        <StepperComponent steps={steps} activeStep={formNumber - 1} />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ minWidth: '150px' }}
        >
          <Stack direction="row" spacing={1}>
            {formNumber !== 1 && (!isDetailsSubmitted || responseError) && (
              <Button className="grey form-button" variant="contained" onClick={handleBack}>
                Back
              </Button>
            )}
            {!isDetailsSubmitted && (
              <LoadingButton
                type="submit"
                className="yellow form-button"
                variant="contained"
                loading={isDetailsSubmitting}
              >
                {getNextButtonText()}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box px={4} mt={3}>
        <React.Fragment>
          {formNumber === 1 && (
            <Stack direction="column" spacing={3}>
              <p>
                Test cases are isolated tests for each Power BI report visualisation.
                <br />
                <ExternalLink href={helpText.testCase.testCaseName.LINK_URL} target="_blank" rel="noopener">
                  Test Cases creation guide
                </ExternalLink>
              </p>
              <FormTextField
                label="Test Case Name"
                variant="filled"
                fullWidth
                id="testCaseName"
                name="testCaseName"
                value={testCaseName}
                onChange={change.bind(null, 'testCaseName')}
                touched={touched}
                errors={errors}
                InputProps={{
                  endAdornment: (
                    <FieldHelpText
                      content={helpText.testCase.testCaseName.HELP_TEXT}
                      linkText={helpText.testCase.testCaseName.LINK_TEXT}
                    />
                  ),
                }}
              />
              <Box>
                <FreeSolo
                  Loading={tagsLoading}
                  multiple={true}
                  name="tags"
                  setFieldValue={setFieldValue}
                  options={tagList}
                  label={'tagValue'}
                  value={'tagId'}
                  freeSolo={true}
                  selectedItem={tags}
                  inputLabel={'Search tags'}
                />
                {errors.tags && touched.tags && (
                  <Box color="error.main" className="form-field-error-text">
                    {errors.tags}
                  </Box>
                )}
              </Box>
              <MissingObjectWarningComponent
                isLoading={reportsIsLoading}
                array={reports}
                alertTitle="No reports detected."
                linkTo="/reports/add"
                linkText="Click to go to new report screen."
              />
              <MissingObjectWarningComponent
                isLoading={connectionsIsLoading}
                array={databaseConnections}
                alertTitle="No source connections detected."
                linkTo="/connections/add"
                linkText="Click to go to new connection screen."
              />
            </Stack>
          )}
          {formNumber === 2 && (
            <Box>
              <Box mb={1}>
                <Stack spacing={1} mb={2}>
                  <span>PowerTester can ultise various methods to identify the visual on a report.</span>
                  <ExternalLink
                    href="https://wild-data-solutions.outseta.com/support/kb/articles/Nmde1wQ0/visual-selection-types"
                    target="_blank"
                  >
                    Which should I choose?
                  </ExternalLink>
                </Stack>
                <Autocomplete
                  multiple={false}
                  name="visualSelectionType"
                  setFieldValue={setFieldValue}
                  options={visualSelectionTypes}
                  label={'selectionType'}
                  value={'visualSelectionTypeId'}
                  selectedItem={visualSelectionType}
                  inputLabel={'Find visual By...'}
                  error={touched.visualSelectionType && Boolean(errors.visualSelectionType)}
                />
                {errors.visualSelectionType && touched.visualSelectionType && (
                  <Box color="error.main" className="form-field-error-text">
                    {errors.visualSelectionType}
                  </Box>
                )}
              </Box>
              {/* Note only one of the below can be active at any time */}
              {renderAltTextSelection()}
              {renderReportVisualSelection()}
              {renderUrlSelection()}
            </Box>
          )}
          {formNumber === 3 && (
            <Box>
              <Box mb={3} className="form-field-container">
                <Autocomplete
                  loading={connectionsIsLoading}
                  multiple={false}
                  name="connection"
                  setFieldValue={setFieldValue}
                  options={databaseConnections}
                  label={'connectionName'}
                  value={'connectionGuid'}
                  selectedItem={connection}
                  inputLabel={'Selection connection'}
                  error={touched.connection && Boolean(errors.connection)}
                />
                {errors.connection && touched.connection && (
                  <Box color="error.main" className="form-field-error-text">
                    {errors.connection}
                  </Box>
                )}
              </Box>
              <Box mb={5} className="form-field-container">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ height: '36px' }}
                >
                  <span>Enter SQL Query</span>
                  {visualSelectionType?.selectionType === 'Report Visual Selection' && (
                    <Tooltip
                      title={
                        sqlGenerated
                          ? 'SQL already generated'
                          : copilotIsFetching
                          ? 'SQL Generating please wait'
                          : 'Use PowerTester co-pilot to generate AI'
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={'center'}
                        className={copilotIsFetching || sqlGenerated ? '' : 'pointer underline'}
                        onClick={generateSQLClick}
                        sx={{ color: copilotIsFetching || sqlGenerated ? 'grey' : 'black' }}
                      >
                        <Box sx={{ width: '40px' }}>
                          <Image src={AIIcon} altText={'Auto generate SQL'} />
                        </Box>
                        <span>Generate SQL</span>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Code
                  disabled={copilotIsFetching}
                  placeholder="Enter SQL Query..."
                  value={sourceQuery}
                  onValueChange={handleSQLChange}
                  id="sourceQuery"
                  className={`editor${errors.sourceQuery && touched.sourceQuery ? ' error' : ''}`}
                />
                {copilotIsFetching && (
                  <Box mt={5}>
                    <Box display={'flex'} alignItems={'center'}>
                      <CircularProgress />
                      <Box ml={2}>
                        <i>Generating AI SQL, please wait</i>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box>
                  <FieldHelpText
                    content={helpText.testCase.sourceQuery.HELP_TEXT}
                    linkText={helpText.testCase.sourceQuery.LINK_TEXT}
                    link={helpText.testCase.sourceQuery.LINK_URL}
                    sqlOverride
                  />
                </Box>

                {errors.sourceQuery && touched.sourceQuery && (
                  <Box color="error.main" className="form-field-error-text">
                    {errors.sourceQuery}
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {formNumber === LAST_FORM_NUMBER && getResponseCard()}
        </React.Fragment>
      </Box>
    </form>
  );
}
export default CreateTestCaseForm;

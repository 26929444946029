import { Box } from '@mui/material';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useDeleteTestPlan,
  useExecuteTestPlan,
  useGetTestPlan,
  useGetTestPlanSummary,
} from '../../api/queries/testPlan';
import Header from '../../components/Header';
import SearchFilter from '../../components/SearchFilter';
import SideDrawer from '../../components/SideDrawer';
import { VIEW_DETAILS } from '../../constants/sidedrawer';
import withFormInteraction from '../../hocs/withFormInteraction';
import withSnackbar from '../../hocs/withSnackbar';
import { getSideDrawerHeading } from '../../utils/sidedrawer';
import TestCaseView from '../testruns/TestCaseView';
import TestRunView from '../testruns/TestRunView';
import CreateTestPlan from './CreateTestPlan';
import TestPlanList from './TestPlanList';
import TestPlanView from './TestPlanView';
import { useGetTestRun, useGetTestRunSummary } from '../../api/queries/testRun';
import { SignalRContext } from '../../services/signalR';
import { subtractDate } from '../../utils/date';
import StatusBadge from '../../components/StatusBadge';
import ConfirmationModal from '../../components/ConfirmationModal';
import { SNACKBAR_ERROR } from '../../constants/snackbar';

function TestPlan(props) {
  const { formOpen, toggleForm, actionType, toggleSplitScreen, splitScreen, openSnackbar } = props;
  const [testPlanData, setTestPlanData] = useState({});
  const [splitScreenData, setSplitScreenData] = useState({});
  const [splitScreenData2, setSplitScreenData2] = useState({});
  const [splitScreenView, setSplitScreenView] = useState({});
  const [splitScreen2, setSplitScreen2] = useState(false);
  const [testPlanNameFilter, setTestPlanNameFilter] = useState('');
  const [dotInidcator, setDotInidcator] = useState('');
  const [isReloading, setIsReloading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTestPlanGuid, setDeleteTestPlanGuid] = useState(null);

  const formOpenRef = useRef(formOpen); // Initialize the useRef with the current value of formOpen
  const splitScreenDataRef = useRef(splitScreenData); // Initialize the useRef with the current value of formOpen

  const { startNotify, stopNotify } = useContext(SignalRContext);

  const { testPlanGuidUrl } = useParams();

  const navigate = useNavigate();

  // Capture the state of formOpen so that we can reference it in the reload function correctly
  useEffect(() => {
    formOpenRef.current = formOpen;
  }, [formOpen]);

  // Capture the state of testPlanData so that we can reference it in the reload function correctly
  useEffect(() => {
    splitScreenDataRef.current = splitScreenData;
  }, [splitScreenData]);

  const {
    data: testPlans,
    isLoading: testPlansIsLoading,
    isFetching: testPlansIsFetching,
    refetch: refetchTestPlans,
  } = useGetTestPlan(true, 'all', testPlanNameFilter);

  const { refetch: refetchTestPlanSummary } = useGetTestPlanSummary(!!testPlanData?.testPlanGuid, {
    testPlanGuid: testPlanData.testPlanGuid,
    fromDate: subtractDate(30, 'days', false),
  });

  const {
    data: testRunSummary,
    isLoading: testRunSummaryIsLoading,
    refetch: refetchTestRunSummary,
  } = useGetTestRunSummary(!!splitScreenData.testRunGuid, splitScreenData.testRunGuid);

  const { refetch: refetchTestRuns } = useGetTestRun(!!testPlanData?.testPlanGuid, {
    testPlanGuid: testPlanData.testPlanGuid,
  });

  // Updates
  const { mutate: deleteTestPlanHook } = useDeleteTestPlan();
  const { mutate: executeTestPlanHook } = useExecuteTestPlan();

  const setFilterTestPlans = (data) => {
    setTestPlanNameFilter(data);
  };

  const reload = (showProgress) => {
    if (showProgress) setIsReloading(true);

    const currentFormOpen = formOpenRef.current; // Access the current value of formOpen from the ref
    const currentSplitScreenData = splitScreenDataRef.current; // Access the current value of formOpen from the ref

    const promises = [refetchTestPlans()];

    // If form is open also refresh the test run summary
    if (currentFormOpen) {
      promises.push(refetchTestPlanSummary());
      promises.push(refetchTestRuns());
      if (currentSplitScreenData?.testRunGuid) promises.push(refetchTestRunSummary());
    }

    Promise.all(promises)
      .then(() => {
        if (showProgress) setIsReloading(false);
      })
      .catch((error) => {
        // Handle any errors that might occur during the fetch
        if (showProgress) setIsReloading(false);
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    reload(false);
  }, []);

  const onTestRunResult = (msg) => {
    if (!msg) return;

    reload(false);
  };

  useEffect(() => {
    startNotify('StartTestRun', onTestRunResult, 'testPlan');
    startNotify('FinishTestRun', onTestRunResult, 'testPlan');
    startNotify('FinishTestCase', onTestRunResult, 'testPlan');

    return () => {
      stopNotify('StartTestRun');
      stopNotify('FinishTestRun');
      stopNotify('FinishTestCase');
    };

    // eslint-disable-next-line
  }, []);

  const searchFilterProps = {
    searchProps: {
      placeholder: 'Search test plans',
      list: testPlans,
      searchKey: 'testPlanName',
      setFilterListHook: setFilterTestPlans,
      returnValue: true,
    },
  };

  useEffect(() => {
    if (testPlanGuidUrl && !testPlansIsLoading && !testPlansIsFetching) {
      selectTestPlanFromUrl(testPlanGuidUrl.toUpperCase());
      toggleForm(VIEW_DETAILS);
    }
    // eslint-disable-next-line
  }, [testPlans, testPlanGuidUrl]);

  const selectTestPlanFromUrl = (testPlanGuid) => {
    setTestPlanData(testPlans.find((x) => x.testPlanGuid === testPlanGuid));
  };

  const togglePage = (page) => {
    if (page !== '/testplans') navigate(page);
  };

  const switchOptions = {
    list: [
      { label: 'Test Plans', action: () => togglePage('/testplans') },
      { label: 'Test Runs', action: () => togglePage('/testruns') },
    ],
    activeSwitch: 'Test Plans',
  };

  const resetPlanCaseData = () => {
    setTestPlanData({});
  };

  const selectTestPlan = (index, newActionType) => {
    if (formOpen && actionType !== VIEW_DETAILS) {
      return;
    }
    setTestPlanData(testPlans[index]);

    if (formOpen) {
      return;
    }

    toggleForm(newActionType);
  };

  const resetSplitScreenData = () => {
    setSplitScreenView('');
    setSplitScreenData({});
  };

  const openSplitScreen = (testRun, splitScreenView) => {
    setSplitScreenView(splitScreenView);
    setSplitScreenData(testRun);
    toggleSplitScreen(true);
  };

  const openSplitScreen2 = (testCase) => {
    toggleForm('', 'dontopen');
    setSplitScreenData2(testCase);
    setSplitScreen2(true);
  };

  const onExecuteTestPlan = (data) => {
    refetchTestPlans();
    executeTestPlanHook(data);
    setDotInidcator('Test Runs');
  };

  const getSideDrawerContent = () => {
    if (actionType === VIEW_DETAILS) {
      return (
        <TestPlanView
          testPlanData={testPlanData}
          viewTestRun={openSplitScreen}
          toggleDeleteModal={toggleDeleteModal}
          onEdit={toggleForm}
          executeTestPlan={onExecuteTestPlan}
        />
      );
    }
    return (
      <CreateTestPlan
        actionType={actionType}
        resetPlanCaseData={resetPlanCaseData}
        testPlanData={testPlanData}
        toggleForm={toggleForm}
        executeTestPlan={onExecuteTestPlan}
      />
    );
  };

  const getSplitScreenView = () => {
    if (splitScreenView === 'TEST_CASE') {
      return <TestCaseView testCaseGuid={splitScreenData.testCaseGuid} testRunDate={splitScreenData.testRunDate} />;
    } else {
      return (
        <TestRunView
          viewTestCase={openSplitScreen2}
          testRunGuid={splitScreenData.testRunGuid}
          testRunSummary={testRunSummary}
          isLoading={testRunSummaryIsLoading}
        />
      );
    }
  };

  const getSplitScreenView2 = () => {
    return (
      <TestCaseView
        testCaseGuid={splitScreenData2.testCaseGuid}
        testRunGuid={splitScreenData.testRunGuid}
        testRunDate={splitScreenData.EndDateTime}
      />
    );
  };

  const splitScreenHeading = () => {
    switch (splitScreenView) {
      case 'TEST_CASE':
        return splitScreenData['testCaseName'];
      case 'TEST_RUN':
        return splitScreenData['testPlanName'];
      default:
        break;
    }
  };

  const splitScreenHeading2 = () => {
    return splitScreenData2['testCaseName'];
  };

  const getSubTitle = (pageOverwrite) => {
    if (pageOverwrite === 'TEST_CASE') {
      if (!splitScreenData2 || !splitScreenData2?.result) return;
      return <StatusBadge status={splitScreenData2.result} showText={true} />;
    }
    if (splitScreenView === 'TEST_RUN') {
      if (!splitScreenData || !splitScreenData?.status) return;
      return <StatusBadge status={splitScreenData.status} showText={true} />;
    }
  };

  const toggleDeleteModal = (testPlanGuid) => {
    if (testPlanGuid) {
      setDeleteTestPlanGuid({ testPlanGuid, closeForm: false });
    } else if (testPlanData?.testPlanGuid) {
      setDeleteTestPlanGuid({ testPlanGuid: testPlanData.testPlanGuid, closeForm: true });
    }
    setDeleteModalOpen(!deleteModalOpen);
  };

  const confirmDelete = () => {
    if (!deleteTestPlanGuid?.testPlanGuid) {
      openSnackbar('Unknown Error deleting test plan', SNACKBAR_ERROR);
      return;
    }
    deleteTestPlanHook(deleteTestPlanGuid.testPlanGuid);
    toggleDeleteModal();
    if (deleteTestPlanGuid.closeForm) toggleForm();
  };

  return (
    <Box className="page-details">
      <Header
        title="Test Plans"
        buttonText="Add Test Plan"
        toggleForm={() => {
          toggleForm();
          resetPlanCaseData();
        }}
        switchOptions={switchOptions}
        buttonDisabled={formOpen}
        helpText="Test Plans are a selection of test cases ran together to create a test plan."
        refreshAction={() => reload(true)}
        dotIndicatorOption={dotInidcator}
      />
      <ConfirmationModal
        title="Delete Test plan"
        modalOpen={deleteModalOpen}
        confirm={confirmDelete}
        cancel={toggleDeleteModal}
        confirmationButtonText={'Delete'}
        msg={'Are you sure you want to delete?'}
      />
      <Box px={4} className={'page-details__list-container '} display="flex" pb={4} flexDirection="row">
        <Box className={'page-details-container ' + (splitScreen ? 'split-screen-open' : '')} pr={3} flex={1}>
          <SearchFilter {...searchFilterProps} />
          <TestPlanList
            formOpen={formOpen}
            actionType={actionType}
            executeTestPlan={onExecuteTestPlan}
            toggleDeleteModal={toggleDeleteModal}
            testPlans={testPlans}
            selectTestPlan={selectTestPlan}
            isLoading={(testPlansIsLoading || isReloading) ?? true}
          />
        </Box>
        <SideDrawer
          toggleForm={() => {
            toggleForm();
            resetPlanCaseData();

            resetSplitScreenData();
            toggleSplitScreen(false);
            setSplitScreen2(false);
          }}
          formOpen={formOpen}
          title={getSideDrawerHeading('TEST_PLAN', actionType, testPlanData['testPlanName'])}
          isSplitScreen={splitScreen}
        >
          {getSideDrawerContent()}
        </SideDrawer>

        <SideDrawer
          toggleForm={() => {
            resetSplitScreenData();
            toggleSplitScreen(false);
          }}
          formOpen={splitScreen}
          title={splitScreenHeading()}
          subtitle={getSubTitle()}
          isSplitScreen={splitScreen2}
        >
          {getSplitScreenView()}
        </SideDrawer>
        <SideDrawer
          toggleForm={() => {
            setSplitScreen2(false);
          }}
          formOpen={splitScreen2}
          title={splitScreenHeading2()}
          subtitle={getSubTitle('TEST_CASE')}
          hideScroll
        >
          {getSplitScreenView2()}
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withSnackbar(withFormInteraction(TestPlan));

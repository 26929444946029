import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box } from '@mui/material';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function LineChart(props) {
  const { chartData, chartOptions, error, noData } = props;

  return (
    <React.Fragment>
      {!error && !noData ? (
        <div
          style={{
            position: 'relative',
            margin: 'auto',
            width: props.width,
            height: props.height,
          }}
        >
          <Line data={chartData} options={chartOptions} />
        </div>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" width={props.width} height={props.height}>
          <h2>No data to display</h2>
        </Box>
      )}
    </React.Fragment>
  );
}

export default LineChart;

import Popover from '@mui/material/Popover';
import React from 'react';

export default function SimplePopover(props) {
  const { children, handleClose, anchorEl } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: 1,
          },
        }}
      >
        <div className="popover-help">{children}</div>
      </Popover>
    </div>
  );
}

import { CheckCircle } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { Box } from '@mui/material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../../../components/Header';
import SideDrawer from '../../../components/SideDrawer';
import { VIEW_DETAILS } from '../../../constants/sidedrawer';
import withFormInteraction from '../../../hocs/withFormInteraction';
import { getSideDrawerHeading } from '../../../utils/sidedrawer';
import TestCaseView from './TestCaseView';
import QuickRunList from './QuickRunList';
import { useGetTestCaseQuickRuns } from '../../../api/queries/testCase';
import { useGetTestCaseSummary } from '../../../api/queries/testRun';
import { SignalRContext } from '../../../services/signalR';
import { findObjectByPropertyCI } from '../../../utils/array';
import { enqueueSnackbar } from 'notistack';

function TestCasesQuickRuns(props) {
  const { formOpen, toggleForm, actionType, splitScreen } = props;
  const [quickRunData, setQuickRunData] = useState({});
  const [isReloading, setIsReloading] = useState(false);
  const [subtitle, setSubtitle] = useState('');

  const formOpenRef = useRef(formOpen); // Initialize the useRef with the current value of formOpen

  const { startNotify, stopNotify } = useContext(SignalRContext);
  const { testRunGuidUrl } = useParams();

  const navigate = useNavigate();

  // Capture the state of formOpen so that we can reference it in the reload function correctly
  useEffect(() => {
    formOpenRef.current = formOpen;
  }, [formOpen]);

  const {
    data: quickRuns,
    isLoading: quickRunsIsLoading,
    isFetching: quickRunsIsFetching,
    refetch: refetchQuickRuns,
  } = useGetTestCaseQuickRuns(true, 'all');

  const {
    data: testCaseSummary,
    refetch: refetchTestCaseSummary,
    isLoading: testCasesIsLoading,
  } = useGetTestCaseSummary(false, {
    testRunGuid: quickRunData?.testRunGuid,
    testCaseGuid: quickRunData?.testCaseGuid,
  });

  const togglePage = (page) => {
    if (page !== '/testcases/runs') navigate(page);
  };

  const reload = () => {
    setIsReloading(true);
    refetchQuickRuns().then(() => {
      setIsReloading(false);
    });
  };

  useEffect(() => {
    if (testRunGuidUrl && !quickRunsIsLoading && !quickRunsIsFetching) {
      if (!formOpen) toggleForm(VIEW_DETAILS);
      const quickRun = findObjectByPropertyCI(quickRuns, 'testRunGuid', testRunGuidUrl);

      setQuickRunData(quickRun);
      setSubtitle(getSubTitle());
    }
    // eslint-disable-next-line
  }, [quickRunsIsLoading, quickRunsIsFetching, testRunGuidUrl]);

  useEffect(() => {
    if (!formOpen || !quickRunData?.testRunGuid) return;
    refetchTestCaseSummary();

    setSubtitle(getSubTitle());

    // eslint-disable-next-line
  }, [quickRunData]);

  const onTestCaseResult = (msg) => {
    if (!msg) return;

    refetchQuickRuns();

    const currentFormOpen = formOpenRef.current; // Access the current value of formOpen from the ref

    if (currentFormOpen) {
      refetchTestCaseSummary();
    }

    const status = msg.Outcome;

    const snackBarMessage = `Test Case Executed`;
    const linkTo = '/testcases/runs';

    let subtitle;

    switch (status) {
      case 'Skipped':
      case 'Inconclusive':
        subtitle = 'Test case could not run';
        break;
      case 'Failed':
        subtitle = 'Test case result error';
        break;
      case 'Passed':
        subtitle = 'Test case run passed';
        break;
      default:
        'Unknown result';
    }

    enqueueSnackbar(snackBarMessage, {
      subtitle: subtitle,
      variant: 'collapseable',
      linkText: `Browse Test cases for progress information`,
      linkTo: linkTo,
      status: status,
      isExtenalLink: false,
    });
  };

  useEffect(() => {
    startNotify('FinishTestCase', onTestCaseResult, 'testCase');
    startNotify('StartTestRun', refetchQuickRuns, 'testCase');

    // The cleanup function will be called when the component unmounts or when the dependency array changes
    return () => {
      stopNotify('FinishTestCase');
      stopNotify('StartTestRun');
    };
    // eslint-disable-next-line
  }, []);

  const switchOptions = {
    list: [
      { label: 'Test Cases', action: () => togglePage('/testcases') },
      { label: 'Quick Runs', action: () => togglePage('/testcases/runs') },
    ],
    activeSwitch: 'Quick Runs',
  };

  const resetPlanCaseData = () => {
    setQuickRunData({});
  };

  const selectQuickRun = (index, newActionType) => {
    if (formOpen && actionType !== VIEW_DETAILS) {
      return;
    }
    setQuickRunData(quickRuns[index]);

    if (formOpen) {
      return;
    }

    toggleForm(newActionType);
  };

  const getSubTitle = () => {
    if (actionType === VIEW_DETAILS) {
      if (!quickRunData) return;
      const status = quickRunData['testRunResult'];
      switch (status) {
        case 'Passed':
          return (
            <Box
              color="success.main"
              className="status small"
              display="flex"
              flexDirection="row"
              alignItems="center"
              mr={2}
            >
              <CheckCircle />
              <h3 className="italic" style={{ color: 'black' }}>
                {status}
              </h3>
            </Box>
          );
        case 'Failed':
          return (
            <Box
              color="error.main"
              className="status small"
              display="flex"
              flexDirection="row"
              alignItems="center"
              mr={2}
            >
              <CancelIcon />
              <h3 className="italic" style={{ color: 'black' }}>
                {status}
              </h3>
            </Box>
          );
        case 'Inconclusive':
          return (
            <Box color="#ff9100" className="status small" display="flex" flexDirection="row" alignItems="center" mr={2}>
              <CancelIcon />
              <h3 className="italic" style={{ color: 'black' }}>
                {status}
              </h3>
            </Box>
          );
        case 'Queued':
        case 'In progress':
          return (
            <Box display="flex" flexDirection="row" alignItems="center">
              <DataUsageIcon style={{ color: '#2d9cdb' }} className="rotate" />
              <h3 className="italic" style={{ color: 'black' }}>
                {status}
              </h3>
            </Box>
          );
        default:
          return;
      }
    }
  };

  return (
    <Box className="page-details">
      <Header
        title={'Test Case Quick Runs'}
        buttonText="Add Test Case"
        toggleForm={toggleForm}
        switchOptions={switchOptions}
        buttonDisabled
        refreshAction={() => reload()}
      />
      <Box px={4} className={'page-details__list-container '} display="flex" pb={4} flexDirection="row">
        <Box className={'page-details-container ' + (splitScreen ? 'split-screen-open' : '')} pr={3} flex={1}>
          <QuickRunList
            smallScreen={formOpen}
            quickRuns={quickRuns}
            selectQuickRun={selectQuickRun}
            isLoading={(quickRunsIsLoading || isReloading) ?? true}
          />
        </Box>
        <SideDrawer
          toggleForm={() => {
            toggleForm();
            resetPlanCaseData();
          }}
          formOpen={formOpen}
          title={getSideDrawerHeading('TEST_PLAN', actionType, quickRunData?.testCaseName)}
          subtitle={subtitle}
          hideScroll
        >
          <TestCaseView
            summary={testCaseSummary}
            testCasesIsLoading={testCasesIsLoading}
            testCaseGuid={quickRunData?.testCaseGuid}
            testRunDate={quickRunData?.runTime}
          />
        </SideDrawer>
      </Box>
    </Box>
  );
}

export default withFormInteraction(TestCasesQuickRuns);

import React, { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, Tooltip } from '@mui/material';
import FieldHelpText from '../FieldHelpText';
const iconRadio = <RadioButtonUncheckedIcon fontSize="small" />;
const checkedRadioIcon = <RadioButtonCheckedIcon fontSize="small" style={{ color: 'primary' }} />;

const iconCheckbox = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedCheckboxIcon = <CheckBoxIcon fontSize="small" style={{ color: 'primary' }} />;

export default function AutocompleteComponent(props) {
  const { options, label, selectedItem, groupBy, multiple, preselectOne } = props;
  const error = props.error ? props.error : false;
  const classes = {
    autoComplete: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  };

  const onChange = (e, a) => {
    props.setFieldValue(props.name, a);
  };

  const getValue = () => {
    if (preselectOne && selectedItem === null && props.options && props.options.length === 1) {
      return props.options[0];
    }
    if (selectedItem === null) {
      return null;
    }

    if (!props.options) {
      return null;
    }

    if (typeof selectedItem === 'number') {
      return props.options.find((v) => v[props.value] === selectedItem);
    }

    if (typeof props.selectedItem === 'object' && props.selectedItem.length > 0 && props.options.length > 0) {
      if (!props.options) return [];
      const foundRecords = props.options.filter((v) =>
        props.selectedItem.some((v2) => v[props.value] === v2[props.value]),
      );
      return foundRecords;
    }

    if (typeof props.selectedItem === 'object') {
      const foundItem = props.options.find((v) => v[props.value] === selectedItem[props.value]);

      if (!foundItem) return [];
      return foundItem;
    }
    return selectedItem;
  };

  let inputRef;

  useEffect(() => {
    if (props.autoOpen) {
      inputRef.focus();
    }
    // eslint-disable-next-line
  }, [props.autoOpen]);

  // Conditionally set the groupBy property based on whether props.groupBy exists
  const groupByProp = groupBy ? { groupBy: (option) => option[props.groupBy] } : {};

  const valueProp = props.value;

  const getOptionEqualTo = (option, value) => {
    if (!props.option && !props.value) return option === value;
    if (!option || !value) return false;
    return value[props.value] === option[props.value];
  };

  const getLabel = (option) => {
    if (option[label]) return option[label];

    return option;
  };

  return (
    <Tooltip title={props.tooltip}>
      <Box display="flex" flexDirection={'row'} alignItems="center">
        <Autocomplete
          defaultValue={props.defaultValue}
          limitTags={props.limitTags}
          loading={props.loading}
          loadingText={props.loadingText || 'Loading...'}
          disabled={props.disabled}
          disableCloseOnSelect={multiple}
          disablePortal
          openOnFocus
          multiple={multiple}
          sx={{
            ...(props.applyStyle
              ? {
                  input: classes.autoComplete,
                  option: classes.autoComplete,
                }
              : {}),
            width: '100%',
            flexGrow: 1,
          }}
          id="autocomplete"
          // Conditional rendering of groupBy
          {...groupByProp} // spread the groupBy property if it exists
          name={props.name}
          options={options || []}
          getOptionLabel={(option) => getLabel(option)}
          value={getValue()}
          onChange={onChange}
          isOptionEqualToValue={(option, value) => getOptionEqualTo(option, value)}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option[valueProp] ?? option}>
              <Checkbox
                icon={multiple ? iconCheckbox : iconRadio}
                checkedIcon={multiple ? checkedCheckboxIcon : checkedRadioIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {getLabel(option)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              variant="outlined"
              inputRef={(input) => {
                inputRef = input;
              }}
              style={props.textStyle}
              label={props.inputLabel}
            />
          )}
        />
        {props.helpText && (
          <Box>
            <FieldHelpText
              content={props.helpText.content}
              linkText={props.helpText.linkText}
              link={props.helpText.link}
            />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}
